import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cardDetails: {},
  loading: false,
  error: [],
};

const cardRedeem = createSlice({
  name: 'cardredeem',
  initialState,
  reducers: {
    saveCardDetails(state, action) {
      state.cardDetails = action.payload || [];
    },
  }
});

export const { saveCardDetails, saveCardDetailsSuccess, saveCardDetailsFailure } =
  cardRedeem.actions;

export default cardRedeem.reducer;
