import { startOfDay, endOfDay } from 'date-fns';

export const InitialFilterHeader = {
    logId: '',
    tcnCustomerId: '',
    serialStart: '',
    serialEnd: '',
    reference: '',
    environment: "All",
    campaignId: '',
    userId: 'All',
    productId: '',
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
    method: 'All'
};