import { combineReducers } from "redux";
import cardValidation from "./slices/cardValidation";
import issueCard from "./slices/issueCard";
import user from "./slices/user";
import cardRedeem from './slices/customRedeem';
import agentIssuance from './slices/agentIssuance';
import department from "./slices/department";
import issuanceType from './slices/issuanceType';
import whiteListIps from './slices/whiteListIps';

export default combineReducers({
  cardValidation,
  issueCard,
  user,
  cardRedeem,
  agentIssuance,
  department,
  issuanceType,
  whiteListIps
});