import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CampaignForm from '../../../components/campaign-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from "../../../components/notify";
import YesNoDialog from '../../../components/yes-no-dialog';
import Loader from '../../../components/loader';
import { createPromotionAPI, getCustomerInfoAPI } from '../../../services/api';
import './CreateNewCampaign.scss';

const CreateNewCampaign = () => {
  const [customerInfo, setCustomerInfo]                   = useState({});
  const [createCampaignPayload, setCreateCampaignPayload] = useState({});
  const [openDialog, setOpenDialog]                       = useState(false);
  const [openNotification, setOpenNotification]           = useState(false);
  const [errors, setErrors]                               = useState({});
  const [loading, setLoading]                             = useState(false);
  const [isPromotionLoaded, setIsPromotionLoaded]         = useState(false);
  const history = useHistory();

  useEffect(() => {
    getCustomerInfo();
  }, []);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setIsPromotionLoaded(true);
    } catch (err) {
      console.log('err :: ', err);
    }
  };

  const handleSubmit = async (payload) => {
    setCreateCampaignPayload(payload);
    handleOpenDialog();
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const redirectToCampaignList = () => {
    history.push('/campaign');
  }

  const handleYes = async () => {
    try {
      setLoading(true);
      const response = await createPromotionAPI(createCampaignPayload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        redirectToCampaignList();
      }, 1000);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Campaign Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h2>Create New Campaign</h2>
          </div>
          <CampaignForm
            promotions={customerInfo.promotion}
            isPromotionLoaded={isPromotionLoaded}
            handleSubmit={handleSubmit}
            redirectToCampaignList={redirectToCampaignList}
          />
        </div>
      </div>
      <Footer />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
      />
      <Loader loading={loading} />

      <YesNoDialog
        openDialog={openDialog}
        message={'Are you sure you want to create campaign?'}
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
      />
    </>
  );
}

export default CreateNewCampaign;