import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FilterHeader from './filter-header';
import CardTable from './card-table';
import CardModal from "./card-modal";
import ViewNoteModel from './view-note-modal';
import {
  getCardsAPI,
  updateCardStatusAPI,
  resendEmailAPI,
  exportCardsDataAPI,
  sendCardNoteAPI
} from '../../../services/api';
import Notify from '../../../components/notify';
import './IssueCardTable.scss';
import { getUserName } from '../../../helpers/localStorage';

const IssueCardTable = ({customerInfo}) => {
  const [issuedCards, setIssuedCards]                   = useState([]);
  const [selectedCard, setSelectedCard]                 = useState({});
  const [openNotify, setOpenNotify]                     = useState(false);
  const [notifySuccessMessage, setNotifySuccessMessage] = useState(null);
  const [openModal, setOpenModal]                       = useState(false);
  const [viewNoteModal, setViewNoteModal]               = useState(false);
  const [errors, setErrors]                             = useState({});
  const [loading, setLoading]                           = useState(false);
  const [page, setPage]                                 = useState(0);
  const [totalRowCount, setTotalRowCount]               = useState(0);
  const [rowsPerPage, setRowsPerPage]                   = useState(10);
  const [filterData, setFilterData]                     = useState({});

  useEffect(() => {
    getCards({
      limit: 10
    });
  }, []);

  const getCards = async (data = {}) => {
    try {
      setLoading(true);
      const response = await getCardsAPI(data, false);
      setIssuedCards(response?.issuedcard || []);
      setTotalRowCount(response.totalcount);
      setLoading(false);
    } catch (err) {
      setIssuedCards([]);
      setErrors(err);
      setOpenNotify(true);
    }
  };

  const handleFilter = async(data) => {
    setLoading(true);
    setFilterData(data);
    await getCards({ ...data, limit: rowsPerPage, page });
    setLoading(false);
  };

  const handleClearFilter = async () => {
    setLoading(true);
    setFilterData(null);
    setPage(0);
    await getCards({ page: 0, limit: rowsPerPage});
    setLoading(false);
  };

  const handleExportTable = async (data) => {
    setLoading(true);
    const response = await exportCardsDataAPI(data);
    setLoading(false);
    let byteArray = new Uint8Array(response.data);
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    );
    a.download = 'IssuedCards.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleSelectCard = (card) => {
    setSelectedCard(card);
    setOpenModal(true);
  };

  const handleDeactiveCard = async(note) => {
    try {
      setLoading(true);
      setErrors({});
      const response = await updateCardStatusAPI(selectedCard.cardId, { note });
      setNotifySuccessMessage(
        'Your card has been deactivated. For any queries, please contact your support team. '
      );
      getCards();
      // setOpenModal(false);
      setOpenNotify(true);
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  }

  const handleResendEmail = async(email) => {
    try {
      setLoading(true);
      setErrors({});
      const response = await resendEmailAPI({ ...selectedCard, email });
      setNotifySuccessMessage(
        'Your email has been sent. Please check the Digital Card – Email Status field to confirm delivery. '
        + ' For any queries, please contact your support team.'
      );
      getCards();
      // setOpenModal(false);
      setOpenNotify(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  }

  const handleSendNote = async (note) => {
    try {
      setLoading(true);
      setErrors({});
      const response = await sendCardNoteAPI(selectedCard.cardId, {
        notebody: note,
        noteuser: getUserName()
      });
      setOpenNotify(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  };

  const handleViewNoteModal = () => {
    setViewNoteModal(true);
  }

  const handleCloseViewNoteModal=() => {
    setViewNoteModal(false);
  }

  const handleChangePage = async(event, newPage) => {
    setPage(newPage);
    setLoading(true);
    await getCards({ ...filterData, limit: rowsPerPage, page: newPage });
    setLoading(false);
  };

  const handleChangeRowsPerPage = async(event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
    await getCards({ limit: newRowsPerPage, page: newPage });
    setLoading(false);
  };

  return (
    <>
      <div className="recently-issued-card-container">
        <div className="heading-container">
          <h2 className="text-center">Recently Issued Cards</h2>
          <p className="text-center">Campaigns by Issuance Value</p>
        </div>
        <FilterHeader
          handleFilter={handleFilter}
          handleClearFilter={handleClearFilter}
          handleExportTable={handleExportTable}
        />
        <CardTable
          loading={loading}
          page={page}
          issuedCards={issuedCards}
          totalRowCount={totalRowCount}
          rowsPerPage={rowsPerPage}
          handleSelectCard={handleSelectCard}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
      <CardModal
        handleDeactiveCard={handleDeactiveCard}
        handleResendEmail={handleResendEmail}
        handleSendNote={handleSendNote}
        selectedCard={selectedCard}
        modalIsOpen={openModal}
        closeModal={handleCloseModal}
        handleViewNoteModal={handleViewNoteModal}
        customerInfo={customerInfo}
      />
      <ViewNoteModel
        modalIsOpen={viewNoteModal}
        selectedCard={selectedCard}
        closeModal={handleCloseViewNoteModal}
      />
      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage={notifySuccessMessage}
      />
    </>
  );
};

IssueCardTable.propTypes = {
  issueCard: PropTypes.array,
  customerInfo: PropTypes.object
};

export default IssueCardTable;
