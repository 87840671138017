import React from 'react'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const CampaignTable = ({ campaigns, hideCostOfCardsIssued=false }) => {
  const createRows = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = {
        id: data[i].promotion_id,
        promotionName: data[i].promotion_name,
        status: data[i].promotion_status,
        cardIssued: data[i].sumcard,
        valueOfCardsIssued: data[i].sumcardvalue_initial,
        costOfCardsIssued: data[i].sumcardvalue
      };

      rows.push(row);
    }
    return rows;
  };

  const classes = useStyles();
  const rows = campaigns.length > 0 ? createRows(campaigns) : [];

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Campaign Id</TableCell>
              <TableCell align="left">Campaign Name</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Cards Issued</TableCell>
              <TableCell align="left">Value Of Cards Issued</TableCell>
              {!hideCostOfCardsIssued && <TableCell align="left">Cost Of Cards Issued</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.promotionName}</TableCell>
                  <TableCell align="left">
                    {row.status === '1' ? 'Active' : 'Inactive'}
                  </TableCell>
                  <TableCell align="left">{row.cardIssued}</TableCell>
                  <TableCell align="left">{row.valueOfCardsIssued}</TableCell>
                  {!hideCostOfCardsIssued && <TableCell align="left">{row.costOfCardsIssued}</TableCell>}
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CampaignTable.propTypes = {
  campaigns: PropTypes.array,
  hideCostOfCardsIssued: PropTypes.bool
};

export default CampaignTable;