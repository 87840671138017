// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-campaign-container {
  padding: 36px 20px; }
  @media screen and (min-width: 768px) {
    .top-campaign-container {
      padding: 93px 20px 95px; } }
  .top-campaign-container .heading-container {
    padding-bottom: 42px; }
    .top-campaign-container .heading-container p {
      margin-top: 7px; }
      @media screen and (min-width: 768px) {
        .top-campaign-container .heading-container p {
          margin-top: 12px; } }
  .top-campaign-container .button-container {
    margin-top: 40px; }
    @media screen and (min-width: 768px) {
      .top-campaign-container .button-container {
        margin-top: 30px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/top-campaigns/top-campaigns.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB,EAAA;EAClB;IAFJ;MAGQ,uBAAuB,EAAA,EAiB9B;EApBD;IAYQ,oBAAoB,EAAA;IAZ5B;MAOY,eAAe,EAAA;MACf;QARZ;UASgB,gBAAgB,EAAA,EAEvB;EAXT;IAeQ,gBAAgB,EAAA;IAChB;MAhBR;QAiBY,gBAAgB,EAAA,EAEvB","sourcesContent":[".top-campaign-container{\n    padding: 36px 20px;\n    @media screen and (min-width: 768px) {\n        padding: 93px 20px 95px;\n    }\n    .heading-container{\n        p{\n            margin-top: 7px;\n            @media screen and (min-width: 768px) {\n                margin-top: 12px;\n            }\n        }\n        padding-bottom: 42px;\n    }\n    .button-container{\n        margin-top: 40px;\n        @media screen and (min-width: 768px) {\n            margin-top: 30px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
