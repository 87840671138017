import { RequestHandleType } from "../../../constants/requestHandle";

const validateForm = (data, deliveryMethod) => {
  let errors = { hasError: false, msg: {} };
  if (!data.units) {
    errors.hasError = true;
    errors.units = true;
  }

  if (!errors.units && data.units < 0) {
    errors.hasError = true;
    errors.units = true;
    errors.msg.units = '*Value should be greater than 0';
  }

  if (deliveryMethod !== RequestHandleType.Physical || data.variantPopulateAmount === "Dynamic") {
    if (!data.denomination) {
      errors.hasError = true;
      errors.denomination = true;
    }
    if (!errors.denomination && data.denomination < 0) {
      errors.hasError = true;
      errors.denomination = true;
      errors.msg.denomination = '*Value should be greater than 0';
    }
  }

  if (!data.deliveryMethod) {
    errors.hasError = true;
    errors.deliveryMethod = true;
  }

  if (deliveryMethod === RequestHandleType.Physical && !data.variantGuid) {
    errors.hasError = true;
    errors.variant = true;
  }

  if (!data.campaign) {
    errors.hasError = true;
    errors.campaign = true;
  }

  if (!data.product) {
    errors.hasError = true;
    errors.product = true;
  }

  if (!data.orderReference) {
    errors.hasError = true;
    errors.orderReference = true;
  }

  return errors;
};

export default validateForm;