const validateForm = (data) => {
  let errors = { hasError: false };
  if (!data.email) {
    errors.hasError = true;
    errors.email = true;
  }

  return errors;
};

export default validateForm;