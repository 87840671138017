

export const getBrandName = (promotionid) => {
  switch (promotionid) {
    case '11246':
      return 'AAMI';
    case '11247':
      return 'GIO';
    case '11248':
      return 'Suncorp';
    case '11249':
    case '11459':
      return 'APIA';
    default:
      return 'AAMI';
  }
};

export const getBrandThemeColor = (promotionid) => {
  switch (promotionid) {
    case '11246':
      return '#cc0000';
    case '11247':
      return '#005296';
    case '11248':
      return '#008c80';
    case '11249':
    case '11459':
      return '#00369c';
    default:
      return '#cc0000';
  }
};

export const getRedeemStepColor = (promotionid) => {
  switch (promotionid) {
    case '11246':
    case '11247':
      return '#FFFFFF';
    case '11248':
      return '#008c80';
    case '11249':
    case '11459':
      return '#f5f5f5';
    default:
      return '#cc0000';
  }
};

export const getViewName = (promotionid) => {
  switch (promotionid) {
    case '11246':
    case '11247':
      return 'view1';
    case '11248':
    case '11249':
    case '11459':
      return 'view2';
    default:
      return 'view1';
  }
};

export const getPageTitle = (promotionid) => {
  switch (promotionid) {
    case '11246':
      return 'AAMI Rewards Redemption';
    case '11247':
      return 'GIO Rewards Redemption';
    case '11248':
      return 'Suncorp Brighter Rewards Redemption';
    case '11249':
      return 'Apia Rewards Redemption';
    case '11459':
      return 'TcnSuncorpUat';
    default:
      return 'TCNCP';
  }
};

export const generateTermLink = (promotionid) => {
  return 'https://thinkwink.com.au/tos';
}


export const getRedeemImageUrl = (promotionid) => {
  const brandName = getBrandName(promotionid);
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/${brandName}/Redeem/unlock_rewards.png`;
};

export const getFrameImageUrl = (promotionid) => {
  const brandName = getBrandName(promotionid);
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/${brandName}/Redeem/Frame.png`;
};

export const getStep1ImageUrl = (promotionid) => {
  const brandName = getBrandName(promotionid);
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/${brandName}/Redeem/step1.png`;
};

export const getStep2ImageUrl = (promotionid) => {
  const brandName = getBrandName(promotionid);
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/${brandName}/Redeem/step2.png`;
};

export const getStep3ImageUrl = (promotionid) => {
  const brandName = getBrandName(promotionid);
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/${brandName}/Redeem/step3.png`;
};



