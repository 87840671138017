import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id              : data[i]?.id || '',
      integerId       : data[i]?.integerId || '',
      email           : data[i]?.email || '',
      failReason      : data[i]?.failReason || '',
      ipAddress       : data[i]?.ipAddress || '',
      isUatEnv        : data[i]?.isUatEnv,
      customerId      : data[i]?.customerId || '',
      ipWhiteListMatch: data[i]?.ipWhiteListMatch,
      result          : data[i]?.result,
      requestIp       : data[i].requestIp || '',
      userId          : data[i]?.user?.id || '',
      mfaEnabled      : data[i]?.user?.enable_mfa === 1,
      createdAt       : data[i]?.created_at || '',

      userType        : data[i]?.userType || '',
      loginType       : data[i]?.loginType || '',
      tenantId        : data[i]?.tenantId || '',
      roleGroupId     : data[i]?.roleGroupId || ''
    };

    rows.push(row);
  }
  return rows;
};

const LogsBody = ({
  logs,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalRowCount,
  page
}) => {
  const classes = useStyles();
  const rows = logs.length > 0 ? createRows(logs) : [];

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper} className="table-container">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Id </TableCell>
                <TableCell align="left">IntegerId </TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">User ID</TableCell>
                <TableCell align="left">User Type</TableCell>
                <TableCell align="left">CustomerId</TableCell>
                <TableCell align="left">Environment</TableCell>
                <TableCell align="left">MFA Used</TableCell>
                <TableCell align="left">IP Address</TableCell>
                <TableCell align="left">IP WhiteList Match</TableCell>
                <TableCell align="left">Result</TableCell>
                <TableCell align="left">Fail Reason</TableCell>
                <TableCell align="left">Time Stamp</TableCell>
                <TableCell align="left">Login Type</TableCell>
                <TableCell align="left">Tenant Id</TableCell>
                <TableCell align="left">Role Group Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.integerId}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.userId}</TableCell>
                    <TableCell align="left">{row.userType}</TableCell>
                    <TableCell align="left">{row.customerId}</TableCell>
                    <TableCell align="left">
                      {row.isUatEnv ? 'UAT' : 'PROD' }
                    </TableCell>
                    <TableCell align="left">
                      {row.mfaEnabled.toString()}
                    </TableCell>
                    <TableCell align="left">{row.ipAddress}</TableCell>
                    <TableCell align="left">
                      {row.ipWhiteListMatch.toString()}
                    </TableCell>
                    <TableCell align="left">{row.result.toString()}</TableCell>
                    <TableCell align="left">{row.failReason}</TableCell>
                    <TableCell align="left">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>

                    <TableCell align="left">{row.loginType}</TableCell>
                    <TableCell align="left">{row.tenantId}</TableCell>
                    <TableCell align="left">{row.roleGroupId}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 1 }}>
                  <TableCell colSpan={6}>
                    {' '}
                    No data available in table{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

LogsBody.propTypes = {
  logs                 : PropTypes.array,
  handleChangePage       : PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page                   : PropTypes.number,
  rowsPerPage            : PropTypes.number,
  totalRowCount          : PropTypes.number,
};

export default LogsBody;
