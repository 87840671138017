// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forget-container {
  min-height: calc(100vh - 62px);
  max-width: 690px; }
  .forget-container .avatar {
    width: 100%;
    border-radius: 0;
    background: none;
    margin-bottom: 56px; }
    @media (min-width: 768px) {
      .forget-container .avatar {
        margin-bottom: 95px; } }
  .forget-container .info-text {
    color: #fff;
    font-size: 27px; }
  .forget-container .submit-btn {
    margin: 50px 0px;
    display: flex;
    justify-content: center;
    max-width: 500px; }
`, "",{"version":3,"sources":["webpack://./src/pages/forget-password/forget-password-confirmation/ForgetPasswordConfirmation.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gBAAgB,EAAA;EAFlB;IAII,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB,EAAA;IACnB;MARJ;QASM,mBAAmB,EAAA,EAEtB;EAXH;IAcI,WAAW;IACX,eAAe,EAAA;EAfnB;IAmBI,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,gBAAgB,EAAA","sourcesContent":[".forget-container {\n  min-height: calc(100vh - 62px);\n  max-width: 690px;\n  .avatar {\n    width: 100%;\n    border-radius: 0;\n    background: none;\n    margin-bottom: 56px;\n    @media (min-width: 768px) {\n      margin-bottom: 95px;\n    }\n  }\n\n  .info-text {\n    color: #fff;\n    font-size: 27px;\n  }\n\n  .submit-btn {\n    margin: 50px 0px;\n    display: flex;\n    justify-content: center;\n    max-width: 500px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
