// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 150px; }
  .page-not-found-wrapper .status-code {
    font-size: 250px; }
  .page-not-found-wrapper .message {
    font-size: 50px; }
`, "",{"version":3,"sources":["webpack://./src/pages/page-not-found/PageNotFound.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,sBAAsB;EACtB,UAAU,EAAA;EANZ;IASI,gBAAgB,EAAA;EATpB;IAaI,eAAe,EAAA","sourcesContent":[".page-not-found-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  flex-direction: column;\n  gap: 150px;\n\n  .status-code {\n    font-size: 250px;\n  }\n\n  .message {\n    font-size: 50px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
