import { Button, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { formatDate } from '../../../../helpers/common';
import { hasAdminUserPermissionForCTA } from '../../../../helpers/permission';
import EnhancedTableHead from './EnhancedTableHead';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const CardTable = ({
  order,
  orderBy,
  issuedCards,
  rowsPerPage,
  totalRowCount,
  page,
  handleChangePage,
  handleMangeCard,
  handleSortTable,
  handleChangeRowsPerPage
}) => {
  const classes = useStyles();
  const hidePaginationArrow = rowsPerPage > totalRowCount;

  const createRows = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = {
        cardId: data[i].card_id,
        cardIssuedDate: data[i].card_issued_date,
        cardCancel: data[i]?.card_cancel_flag === 'TRUE' || data[i]?.card_cancel_request_flag === 'TRUE' ? 'YES' : 'NO',
        campaignName: data[i].promotion_name,
        productId: data[i].product_id,
        productName: data[i].product_name,
        cardStatus: data[i].card_status,
        cardValue: data[i].card_initialvalue,
        cardCreationDate: data[i].card_creation_date,
        sourceSerial: data[i].source_serial,
        cardActivationCode: data[i].cardactivationcode,
        cardType: data[i].card_type,
        cardValueExpiryDate: data[i]?.card_value_expiry_date,
        cardAgentissuedFlag: data[i]?.issuance_metadata?.card_agent_issued,
        cardPromoRequireAgentissuedFlag:
          data[i]?.issuance_metadata?.card_agent_promotionrequireissuance,
        referenceId:
          data[i]?.issuance_metadata?.card_agent_customerissuancereference
      };

      rows.push(row);
    }
    return rows;
  };

  const rows = createRows(issuedCards);
  return (
    <>
      <TableContainer component={Paper} className="table-container mt-4">
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={(event, property) => handleSortTable(property)}
          />
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.productName}</TableCell>
                  <TableCell align="left">{row.campaignName}</TableCell>
                  <TableCell align="left">{row.cardValue}</TableCell>
                  <TableCell align="left">{row.referenceId}</TableCell>
                  <TableCell align="center">{row.cardStatus}</TableCell>
                  <TableCell align="left">{row.sourceSerial}</TableCell>
                  <TableCell align="left">{row.cardActivationCode}</TableCell>
                  <TableCell align="left">{row.cardType}</TableCell>
                  <TableCell align="left">{row.cardCancel}</TableCell>
                  <TableCell align="left">
                    {formatDate(row.cardIssuedDate, 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell align="left">
                    {formatDate(row.cardValueExpiryDate, 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleMangeCard(row)}
                    >
                      {!hasAdminUserPermissionForCTA() ? 'View' : 'Manage'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={
          hidePaginationArrow ? { style: { visibility: 'hidden' } } : {}
        }
        nextIconButtonProps={
          hidePaginationArrow ? { style: { visibility: 'hidden' } } : {}
        }
      />
    </>
  );
};

CardTable.propTypes = {
  issuedCards            : PropTypes.array,
  handleResend           : PropTypes.func,
  page                   : PropTypes.number,
  rowsPerPage            : PropTypes.number,
  totalRowCount          : PropTypes.number,
  handleChangePage       : PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleMangeCard        : PropTypes.func,
  handleSortTable        : PropTypes.func,
  order                  : PropTypes.string,
  orderBy                : PropTypes.string
};

export default CardTable;
