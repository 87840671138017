// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-crown-container {
  min-height: calc(100vh - 62px);
  max-width: 690px; }
  .login-crown-container .login-crown-body {
    width: 50%; }
    .login-crown-container .login-crown-body .avatar {
      width: 100%;
      border-radius: 0;
      background: none;
      margin-bottom: 56px; }
      @media (min-width: 768px) {
        .login-crown-container .login-crown-body .avatar {
          margin-bottom: 95px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/login-crown-sso/Login.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gBAAgB,EAAA;EAFlB;IAKI,UAAU,EAAA;IALd;MAQM,WAAW;MACX,gBAAgB;MAChB,gBAAgB;MAChB,mBAAmB,EAAA;MAEnB;QAbN;UAcQ,mBAAmB,EAAA,EAEtB","sourcesContent":[".login-crown-container {\n  min-height: calc(100vh - 62px);\n  max-width: 690px;\n\n  .login-crown-body {\n    width: 50%;\n\n    .avatar {\n      width: 100%;\n      border-radius: 0;\n      background: none;\n      margin-bottom: 56px;\n\n      @media (min-width : 768px) {\n        margin-bottom: 95px;\n      }\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
