import React from 'react';

import IpList from './ip-list';
import './IpLists.scss';

const WhiteListIps = () => {
  return (
    <>
      <div className="flex justify-center items-center campain-table-container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3 color-white">White List IP</h2>
          </div>
          <IpList />
        </div>
      </div>
    </>
  );
};

export default WhiteListIps;
