// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-heading {
  padding-top: 76px; }
  @media screen and (min-width: 768px) {
    .sub-heading {
      padding: 145px 0 24px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/users/edit-user/EditUser.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAA;EACjB;IAFF;MAGI,qBAAqB,EAAA,EAExB","sourcesContent":[".sub-heading{\n  padding-top: 76px;\n  @media screen and (min-width: 768px) {\n    padding: 145px 0 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
