import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id                    : data[i]?.id || '',
      userId                : data[i]?.userId || '',
      userEmail             : data[i]?.user?.email || '',
      tcnCustomerId         : data[i]?.tcnCustomerId || '',
      requestIp             : data[i]?.requestIp || '',
      environment           : data[i]?.environment || '',
      count                 : data[i]?.count || '',
      method                : data[i]?.method || '',
      productId             : data[i]?.productId || '',
      startSerial           : data[i]?.startSerial || '',
      endSerial             : data[i]?.endSerial || '',
      reference             : data[i]?.reference || '',
      customerPrivacyInteger: data[i]?.customerPrivacyInteger || '',
      issuanceType          : data[i]?.issuanceType || '',
      campaignId            : data[i]?.campaignId || '',
      createdAt             : data[i]?.createdAt || ''
    };

    rows.push(row);
  }
  return rows;
};

const AgentIssuanceTable = ({
  agentIssuanceLogs,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalRowCount,
  page
}) => {
  const classes = useStyles();
  const rows = agentIssuanceLogs?.length > 0 ? createRows(agentIssuanceLogs) : [];

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper} className="table-container">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Id </TableCell>
                <TableCell align="left">Email </TableCell>
                <TableCell align="left">TCNCustomerId </TableCell>
                <TableCell align="left">IpAddress</TableCell>
                <TableCell align="left">Environment</TableCell>
                <TableCell align="left">Count</TableCell>
                <TableCell align="left">Method</TableCell>
                <TableCell align="left">ProductId</TableCell>
                <TableCell align="left">CampaignId</TableCell>
                <TableCell align="left">CustomerPrivacyInteger</TableCell>
                <TableCell align="left">IssuanceType</TableCell>
                <TableCell align="left">StartSerial</TableCell>
                <TableCell align="left">EndSerial</TableCell>
                <TableCell align="left">Reference</TableCell>
                <TableCell align="left">CreatedAt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.userEmail}</TableCell>
                    <TableCell align="left">{row.tcnCustomerId}</TableCell>
                    <TableCell align="left">{row.requestIp}</TableCell>
                    <TableCell align="left">{row.environment}</TableCell>
                    <TableCell align="left">{row.count}</TableCell>
                    <TableCell align="left">{row.method}</TableCell>
                    <TableCell align="left">{row.productId}</TableCell>
                    <TableCell align="left">{row.campaignId}</TableCell>
                    <TableCell align="left">{row.customerPrivacyInteger}</TableCell>
                    <TableCell align="left">{row.issuanceType}</TableCell>
                    <TableCell align="left">{row.startSerial}</TableCell>
                    <TableCell align="left">{row.endSerial}</TableCell>
                    <TableCell align="left">{row.reference}</TableCell>
                    <TableCell align="left">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 1 }}>
                  <TableCell colSpan={6}>
                    {' '}
                    No data available in table{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

AgentIssuanceTable.propTypes = {
  agentIssuanceLogs: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRowCount: PropTypes.number
};

export default AgentIssuanceTable;
