import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import validateForm from './validateForm.helper';
import "./UserForm.scss";

const DepartmentForm = ({
  department = {},
  loading = true,
  handleSubmit,
  redirectToDepartmentList
}) => {
  const isEditDepartment = Object.keys(department).length > 0;

  const [name, setName] = useState(department?.name || '');
  const [validationErrors, setValidationErrors] = useState({});

  const submit = () => {
    const payload = { name };
    const validate = validateForm(isEditDepartment, payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleSubmit(payload);
  };

  return (
    <div className="form-container">
      <TextField
        id="standard-basic"
        label="Department Name"
        type="text"
        name="name"
        helperText="*Is required."
        error={validationErrors.name}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />

      <div className="action-footer">
        <Button
          disabled={loading}
          variant="outlined"
          endIcon={
            loading ? (
              <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
            ) : (
              <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
            )
          }
          onClick={submit}
        >
          Continue
        </Button>
        <Button variant="outlined" onClick={redirectToDepartmentList}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

DepartmentForm.propTypes = {
  department              : PropTypes.object,
  loading                 : PropTypes.bool,
  handleSubmit            : PropTypes.func,
  redirectToDepartmentList: PropTypes.func
};

export default DepartmentForm;