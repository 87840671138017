import React, { useEffect, useState } from 'react';

import { getAllAgentIssuanceLogAPI, getAllUsersAPI } from '../../../../services/api';
import FilterHeader from './filter-header';
import Loader from "../../../../components/loader";
import NavBar from '../../../../components/Navbar';
import Notify from "../../../../components/notify";
import TableBody from "./table-body";
import { InitialFilterHeader } from '../initialValues';
import { endOfDay, startOfDay } from 'date-fns';

const AgentIssuanceList = () => {
  const [agentIssuanceLogs, setAgentIssuanceLogs]    = useState([]);
  const [filter, setFilter]               = useState(InitialFilterHeader);
  const [users, setUsers]                 = useState([]);
  const [loading, setLoading]             = useState(true);
  const [openNotify, setOpenNotify]       = useState(false);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const [errors, setErrors]               = useState({});


  useEffect(() => {
    const filterValue = mapFilterValues(filter);
    getAllUsers();
    getAllAgentIssuanceLog(rowsPerPage, page, filterValue);
  }, []);

  const getAllAgentIssuanceLog = async (
    currentRowPerPage,
    currentPage,
    filterPayload
  ) => {
    try {
      const response = await getAllAgentIssuanceLogAPI({
        limit: currentRowPerPage,
        page: currentPage,
        filter: filterPayload
      });
      setAgentIssuanceLogs(response?.logs || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All agent issuance logs failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await getAllUsersAPI({});
      setUsers(response?.users || []);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Users Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const filterValue = mapFilterValues(filter);
    getAllAgentIssuanceLog(rowsPerPage, newPage, filterValue);
  };

  const handleChangeRowsPerPage = (event) => {
    const newPage = 0;
    const newRowsPerPage = event.target.value;
    const filterValue = mapFilterValues(filter);

    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllAgentIssuanceLog(newRowsPerPage, newPage, filterValue);
  };

  const handleFilter = () => {
    setLoading(true);
    const filterValue = mapFilterValues(filter);
    getAllAgentIssuanceLog(rowsPerPage, page, filterValue);
  };

  const handleSetFilter = (payload) => {
    setFilter({
      ...filter,
      ...payload
    });
  };

  const handleClearFilter = async () => {
    setFilter(InitialFilterHeader);
    const filterValue = mapFilterValues(InitialFilterHeader);
    getAllAgentIssuanceLog(10, 0, filterValue);
  };

  const mapFilterValues = (fil) => {
    return {
      ...fil,
      startDate: fil.startDate ? startOfDay(fil.startDate) : null,
      endDate: fil.endDate ? endOfDay(fil.endDate) : null,
      environment: fil.environment === 'All' ? '': fil.environment,
      userId: fil.userId === 'All' ? '' : fil.userId,
      method: fil.method === 'All' ? '' : fil.method
    }
  }

  return (
    <>
      <NavBar />
      <FilterHeader
        filter={filter}
        users={users}
        handleFilter={handleFilter}
        handleSetFilter={handleSetFilter}
        handleClearFilter={handleClearFilter}
      />
      <TableBody
        agentIssuanceLogs={agentIssuanceLogs}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default AgentIssuanceList;