import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import ArrowDownwardOutlinedIcon from '@material-ui/icons/ArrowDownwardOutlined';

const headCells = [
  {
    id: 'productname',
    disablePadding: false,
    label: 'Product Name',
    enableSort: true
  },
  {
    id: 'campaignname',
    disablePadding: false,
    label: 'Campaign Name',
    enableSort: true
  },
  {
    id: 'value',
    disablePadding: false,
    label: 'Value',
    enableSort: false
  },
  {
    id: '',
    disablePadding: false,
    label: 'Card Status',
    enableSort: false
  },
  {
    id: 'serial',
    disablePadding: false,
    label: 'Serial',
    enableSort: true
  },
  {
    id: '',
    disablePadding: false,
    label: 'Card Activation Code',
    enableSort: false
  },
  {
    id: '',
    disablePadding: false,
    label: 'Stock Order Date',
    enableSort: false
  },
  {
    id: '',
    disablePadding: false,
    label: 'Card Issuance Deadline',
    enableSort: false
  }
];

const EnhancedTableHead = (props) => {
  const {
    order,
    orderBy,
    onRequestSort
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={!headCell.enableSort}
              direction={orderBy === headCell.id ? order : 'ASC'}
              onClick={
                headCell.enableSort ? createSortHandler(headCell.id) : null
              }
            >
              <b>{headCell.label}</b>
              {orderBy === headCell.id && headCell.enableSort ? (
                <span>
                  {order === 'DESC' ? (
                    <ArrowUpwardSharpIcon
                      style={{ color: '#FFFB00', fontSize: '20px' }}
                    />
                  ) : (
                    <ArrowDownwardOutlinedIcon
                      style={{ color: '#FFFB00', fontSize: '20px' }}
                    />
                  )}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['ASC', 'DESC']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

export default EnhancedTableHead;
