const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data.reasonCardCancellation) {
    errors.hasError = true;
    errors.reasonCardCancellation = true;
  } else if (
    !['Lost', 'Stolen', 'Damaged'].includes(data.reasonCardCancellation) &&
    !data.notes
  ) {
      errors.hasError = true;
      errors.notes = true;
  }

  return errors;
};

export default validateForm;
