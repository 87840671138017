import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isTokenValid } from '../../helpers/token';
import { clearStorage } from '../../helpers/localStorage';
import { isAdministratorUser, isMainTcncpUser } from '../../helpers/role';

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  if (isTokenValid() && (isMainTcncpUser() || isAdministratorUser())) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  clearStorage();
  return <Redirect to="/" />;
};

AuthorizedRoute.propTypes = {
  component: PropTypes.func
};

export default AuthorizedRoute;
