// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center; }

.min-h-full.dashboard-container {
  padding-top: 0px !important; }
  .min-h-full.dashboard-container .seperator {
    margin: 0 26px;
    border: none;
    border-bottom: 0.5px solid #BFBFBF; }
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/Dashboard.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAErB;EACE,2BAA4B,EAAA;EAD9B;IAII,cAAc;IACd,YAAW;IACX,kCAAkC,EAAA","sourcesContent":[".loading-container {\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.min-h-full.dashboard-container{\n  padding-top : 0px !important;\n  .seperator{\n\n    margin: 0 26px;\n    border:none;\n    border-bottom: 0.5px solid #BFBFBF;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
