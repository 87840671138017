// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner-container {
  width: 100%;
  max-height: 100vh;
  height: 485px;
  position: relative; }
  @media screen and (min-width: 768px) {
    .banner-container {
      height: 556px; } }
  .banner-container .banner-image {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 100vh;
    height: 485px; }
    @media screen and (min-width: 768px) {
      .banner-container .banner-image {
        height: 556px; } }
    .banner-container .banner-image img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .banner-container .banner-text-container {
    position: absolute;
    top: 0;
    height: 485px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .banner-container .banner-text-container {
        height: 556px; } }
    .banner-container .banner-text-container h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      padding: 0 20px;
      text-align: center;
      margin: 0 auto; }
      @media screen and (min-width: 768px) {
        .banner-container .banner-text-container h2 {
          font-size: 38px;
          max-width: 905px;
          padding: 0; } }
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/banner/Banner.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,kBAAkB,EAAA;EAClB;IALJ;MAMQ,aAAa,EAAA,EAyCpB;EA/CD;IASQ,UAAU;IACV,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,aAAa,EAAA;IACb;MAdR;QAeY,aAAa,EAAA,EAOpB;IAtBL;MAkBY,YAAY;MACZ,WAAW;MACX,iBAAiB,EAAA;EApB7B;IAwBQ,kBAAkB;IAClB,MAAM;IACN,aAAa;IACb,WAAW,EAAA;IACX;MA5BR;QA6BY,aAAa,EAAA,EAiBpB;IA9CL;MAgCY,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,YAAY;MACZ,WAAW;MACX,eAAe;MACf,kBAAkB;MAClB,cAAc,EAAA;MACd;QAxCZ;UAyCgB,eAAe;UACf,gBAAgB;UAChB,UAAU,EAAA,EAEjB","sourcesContent":[".banner-container{\n    width: 100%;\n    max-height: 100vh;\n    height: 485px;\n    position: relative;\n    @media screen and (min-width: 768px) {\n        height: 556px;\n    }\n    .banner-image{\n        padding: 0;\n        margin: 0;\n        width: 100%;\n        max-height: 100vh;\n        height: 485px;\n        @media screen and (min-width: 768px) {\n            height: 556px;\n        }\n        img{\n            height: 100%;\n            width: 100%;\n            object-fit: cover;\n        }\n    }\n    .banner-text-container{\n        position: absolute;\n        top: 0;\n        height: 485px;\n        width: 100%;\n        @media screen and (min-width: 768px) {\n            height: 556px;\n        }\n        h2{\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            height: 100%;\n            width: 100%;\n            padding: 0 20px;\n            text-align: center;\n            margin: 0 auto;\n            @media screen and (min-width: 768px) {\n                font-size: 38px;\n                max-width: 905px;\n                padding: 0;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
