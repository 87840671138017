import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WelcomeSection = ({ welcomeRef, promotionid }) => {
  return (
    <>
      <div
        className={classNames('view2-greeting-message', {
          'apia-background-image': promotionid === '11249'
        })}
        ref={welcomeRef}
      >
        <div
          className={classNames('view2-welcome-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? 'Check your phone.'
            : "We've sent you an SMS."}
        </div>
        <div
          className={classNames('view2-welcome-text-highlight', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? 'Redeem your credits.'
            : 'Now spend on something nice.'}
        </div>
      </div>
      <div className="view2-greeting-sub-message">
        <div
          className={classNames('view2-message-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          You couldn&apos;t be closer to your digital Prepaid Visa card.
        </div>
        <div
          className={classNames('view2-message-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          Simply check your phone for an SMS from us and follow the steps below.
        </div>
        <div
          className={classNames('view2-message-sub-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          The link within your SMS will expire three months after you receive
          it. You must download your digital Prepaid Visa card within this time,
          or you will lose the rewards balance within the card.
        </div>
      </div>
    </>
  );
};

WelcomeSection.propTypes = {
  welcomeRef: PropTypes.PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  promotionid: PropTypes.string
};

export default WelcomeSection;
