import { format, add } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getCardsAPI,
  updateCardAdditionalInfoAPI} from '../../../services/api';
import CardTable from './card-table';
import Loader from '../../../components/loader';
import Notify from '../../../components/notify';
import { getTcnCustomerId } from '../../../helpers/localStorage';

const UnAvailableCards = () => {
  const [issuedCards, setIssuedCards]           = useState([]);
  const [openNotify, setOpenNotify]             = useState(false);
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);
  const [page, setPage]                         = useState(0);
  const [totalRowCount, setTotalRowCount]       = useState(0);
  const [rowsPerPage, setRowsPerPage]           = useState(10);

  const defaultFilterValue = {
    promotion: getTcnCustomerId() === 1199 ? '13216' : '',
    createend: format(add(new Date(), { days: 1 }), `yyyy-MM-dd`),
    createstart: '2023-01-01',
    agentissueflag: '0',
    dispatchflag: '1',
    status: 'reserved',
    blockflag: '1',
    cardvariant: 'physical',
    requireagentissuance: '1'
  };

  useEffect(() => {
    setLoading(true);
    getCards({
      ...defaultFilterValue,
      limit: rowsPerPage
    }, false);
  }, []);

  const getCards = async (data = {}, agentIssuanceFlag) => {
    try {
      const response = await getCardsAPI(data, agentIssuanceFlag);
      setIssuedCards(response?.issuedcard || []);
      setTotalRowCount(response.totalcount);
      setLoading(false);
    } catch (err) {
      setIssuedCards([]);
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      limit: rowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      limit: newRowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const handleUnBlockCard = async (cardProxy) => {
    setLoading(true);
    await updateCardAdditionalInfoAPI(cardProxy, {
      blockFlag: '0'
    });
    getCards(
      {
        ...defaultFilterValue,
        limit: rowsPerPage
      },
      false
    );
  };

  return (
    <>
      <div className="issue-card-wrapper mt-4">
        <CardTable
          page={page}
          issuedCards={issuedCards}
          totalRowCount={totalRowCount}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleUnBlockCard={handleUnBlockCard}
        />
      </div>

      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage="Your cards have been successfully issued. To view details refer to Issued Cards tab. "
      />
      <Loader loading={loading} />
    </>
  );
}


UnAvailableCards.propTypes = {
  issueCard: PropTypes.array
};

export default UnAvailableCards;