import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBrandThemeColor } from '../../../../helpers/customRedeem';

const BottomInfoSection = ({ promotionid }) => {
  return (
    <>
      <div className="view2-redeem-step-bottom">
        <div
          className={classNames('view2-redeem-step-bottom-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
          style={{
            color: getBrandThemeColor(promotionid)
          }}
        >
          Using the card
        </div>
        <div
          className={classNames('view2-redeem-step-bottom-sub-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? `To spend your reward, just tap anywhere Visa is accepted. Once activated you'll have one year to spend on something that might make a difference to your day.`
            : `To spend your reward, just tap anywhere Visa is accepted. Once activated you’ll have one year to spend your reward on what or whom you love. `}
        </div>
      </div>
      <div
        className={classNames('view2-info-bottom', {
          'apia-background-image': promotionid === '11249'
        })}
      >
        <div
          className={classNames('view2-info-bottom-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? `Need help with your digital Prepaid Visa card? Just call the support team at The Card Network on
          1300 375 346 from 9am-6pm on weekdays or 10am-5pm on Saturdays AEDT.`
            : `Need help redeeming your reward? Just call our support team at The Card Network on
          1300 375 346 from 9am-6pm on weekdays or 10am-5pm on Saturdays AEDT.`}
        </div>
        <div
          className={classNames('view2-info-bottom-subtext', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? `From the Brighter Rewards team, we hope your reward makes a difference to your day.`
            : `That's all from your Apia Rewards team. Now it's time to get set and go reward yourself.`}
        </div>
      </div>
    </>
  );
};

BottomInfoSection.propTypes = {
  promotionid: PropTypes.string
};

export default BottomInfoSection;
