
import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const Notify = ({ open, anchorOrigin, onClose, errors, successMessage }) => {
  const closeNotification = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  }

  return (
    <Snackbar
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      {Object.keys(errors).length > 0 ? (
        <Alert onClose={onClose} severity="error">
          {errors?.errormessage || errors?.message || 'Something went wrong !!'}
        </Alert>
      ) : (
        <Alert onClose={closeNotification} severity="success">
          {successMessage || 'Success !!'}
        </Alert>
      )}
    </Snackbar>
  );
};

Notify.propTypes = {
  open             : PropTypes.bool,
  onClose: PropTypes.func,
  errors           : PropTypes.object,
  anchorOrigin     : PropTypes.object,
  successMessage   : PropTypes.string
};

export default Notify;