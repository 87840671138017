import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHeader from "./table-header";
import TableBody from "./table-body";
import { getAllDepartments } from '../../../store/slices/department';

const DepartmentList = () => {
  const [departments, setDepartments]             = useState([]);
  const [departmentsFilter, setDepartmentsFilter] = useState([]);
  const [loading, setLoading]               = useState(true);
  const dispatch = useDispatch();

  const loadingReducer  = useSelector((state) => state.department.loading);
  const departmentReducer = useSelector((state) => state.department.departments);

  useEffect(() => {
    dispatch(getAllDepartments());
  }, []);

  useEffect(() => {
    setDepartments(departmentReducer || []);
    setDepartmentsFilter(departmentReducer || []);
    setLoading(loadingReducer);
  }, [loading, departmentReducer]);


  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';
    const filteredRow = [];

    departments.forEach((depart) => {
      const name = depart?.name?.toLowerCase() || '';

      if (
        name.includes(searchText)
      ) {
        filteredRow.push(depart);
      }
    });
    setDepartmentsFilter(filteredRow);
  }

  return (
    <>
      <TableHeader handleSearch={handleSearch} />
      <TableBody users={departmentsFilter} />
    </>
  );
}

export default DepartmentList;