import React from 'react';

import UserList from './user-list';
import './GlobalReadUserList.scss';

const Users = () => {
  return (
    <>
      <div className="flex justify-center items-center campain-table-container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3 color-white">Users</h2>
          </div>
          <UserList />
        </div>
      </div>
    </>
  );
};

export default Users;
