const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (data.serialStart <= 0) {
    errors.hasError = true;
    errors.serialStart = true;
  }

  if (data.serialEnd <= 0) {
    errors.hasError = true;
    errors.serialEnd = true;
  }

  if (!data.productId) {
    errors.hasError = true;
    errors.productId = true;
  }

  if (
    data.serialStart >= 0 &&
    data.serialEnd >= 0 &&
    Math.abs(data.serialStart - data.serialEnd) > 500
  ) {
    errors.hasError = true;
    errors.serialEnd = true;
    errors.msg.serialEnd = "*Serial difference should not exceed 500";
  }

  return errors;
};

export default validateForm;
