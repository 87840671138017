import { fork, takeLatest, call, put } from 'redux-saga/effects';
import {
  getSubUsers,
  getSubUsersSuccess,
  getSubUsersFailure
} from '../slices/user';
import { getSubUsersAPI } from '../../services/api';

function* getSubUserSaga({ payload }) {
  const data = yield call(getSubUsersAPI, payload);
  yield put(getSubUsersSuccess(data));
}


export function* userSaga() {
  yield takeLatest(getSubUsers, getSubUserSaga);
}