const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };
  if (!data.promotionId) {
    errors.hasError = true;
    errors.promotionId = true;
  }

  if (!data.firstName) {
    errors.hasError = true;
    errors.firstName = true;
  }

  if (!data.lastName) {
    errors.hasError = true;
    errors.lastName = true;
  }

  if (!data.email) {
    errors.hasError = true;
    errors.email = true;
  }

  if (!data.mobile) {
    errors.hasError = true;
    errors.mobile = true;
  }

  if (data.mobile && data.mobile.length !== 10) {
    errors.hasError = true;
    errors.mobile = true;
  }

  if (!data.confirmMobile) {
    errors.hasError = true;
    errors.confirmMobile = true;
  }

  if (data.mobile && data.confirmMobile && data.mobile !== data.confirmMobile) {
    errors.hasError = true;
    errors.confirmMobile = true;
    errors.msg.confirmMobile = "Mobile number don't match"
  }

  // if (!data.testMode) {
  //   errors.hasError = true;
  //   errors.testMode = true;
  // }

  return errors;
};

export default validateForm;