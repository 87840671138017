import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import 'react-datepicker/dist/react-datepicker.css';

import './CardModal.scss';
import validateForm from './validateForm.helper';

const ReplaceCardModel = ({
  modalIsOpen,
  closeModal,
  cardByProxy,
  customerInfo,
  handleReplaceCard
}) => {
  const [productId, setProductId] = useState('');
  const [cardAmount, setCardAmount] = useState(0);
  const [serialNumber, setSerialNumber] = useState('');
  const [referenceId, setReferenceId] = useState(
    cardByProxy?.issuance_metadata?.card_agent_customerissuancereference || ''
  );
  const [validationErrors, setValidationErrors] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    firstName:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancefname || '',
    lastName:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancelname || '',
    mobile:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancemobile || '',
    dateOfBirth:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancedob || '',
    email:
      cardByProxy?.issuance_metadata?.card_agent_customerissuanceemail || '',
    address1:
      cardByProxy?.issuance_metadata?.card_agent_customerissuanceaddress1 || '',
    address2:
      cardByProxy?.issuance_metadata?.card_agent_customerissuanceaddress2 || '',
    city: cardByProxy?.issuance_metadata?.card_agent_customerissuancecity || '',
    state:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancestate || '',
    postcode:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancezipcode || '',
    country:
      cardByProxy?.issuance_metadata?.card_agent_customerissuancecountry || '',
    notes: ''
  });

  const handleAdditionalInfo = (payload) => {
    setAdditionalInfo({...additionalInfo, ...payload})
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleClick = () => {
    const validate = validateForm({
      productId,
      serialNumber
    });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    handleReplaceCard({
      productId,
      serialNumber,
      cardAmount,
      referenceId,
      additionalInfo
    });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Replace Card</h2>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Cancelled Card Serial"
              type="text"
              name="productId"
              value={cardByProxy.source_serial}
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className="cardActionsForms">
            <FormControl variant="standard" error={validationErrors.productId}>
              <InputLabel id="demo-simple-select-standard-label">
                Product
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="productId"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
              >
                {customerInfo?.product?.map((promotion) => (
                  <MenuItem
                    key={promotion.product_id}
                    value={promotion.product_id}
                  >
                    {promotion.product_id}&nbsp;-&nbsp;
                    {promotion.product_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>
            <TextField
              id="standard-basic"
              label="Serial Number"
              type="text"
              name="serialNumber"
              value={serialNumber}
              error={validationErrors.serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Campaign Id"
              type="text"
              name="campaignName"
              value={cardByProxy.promotion_id}
              inputProps={{ readOnly: true }}
            />
            <TextField
              id="standard-basic"
              label="Reference ID"
              type="text"
              name="referenceId"
              className="mt-3"
              helperText="*Is required."
              value={referenceId}
              onChange={(e) => setReferenceId(e.target.value)}
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Value to load per card"
              type="text"
              name="cardAmount"
              className="mt-3"
              value={cardAmount}
              onChange={(e) => setCardAmount(e.target.value)}
            />
            <TextField
              id="standard-basic"
              label="Department"
              type="text"
              name="department"
              className="mt-3"
              value={
                cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancelabel || ''
              }
              helperText="*Is required."
            />
          </div>
          <Accordion
            className="accordionWrapper"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="accordionHeading">
                Additional Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={additionalInfo.firstName}
                  onChange={(e) =>
                    handleAdditionalInfo({ firstName: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={additionalInfo.lastName}
                  onChange={(e) =>
                    handleAdditionalInfo({ lastName: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields field-button-container">
                <TextField
                  id="standard-basic"
                  label="Mobile"
                  type="text"
                  name="mobile"
                  helperText="Mobile Number should be of 10 digits"
                  value={additionalInfo.mobile}
                  onChange={(e) =>
                    handleAdditionalInfo({ mobile: e.target.value })
                  }
                />

                <div className="date-container">
                  <span>Date of Birth</span>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={
                      additionalInfo?.dateOfBirth
                        ? new Date(additionalInfo.dateOfBirth || '')
                        : ''
                    }
                    onChange={(update) =>
                      handleAdditionalInfo({ dateOfBirth: update })
                    }
                    className="date-picker-field campaign-input"
                  />
                </div>
              </div>

              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Email"
                  type="text"
                  name="email"
                  value={additionalInfo.email}
                  onChange={(e) =>
                    handleAdditionalInfo({ email: e.target.value })
                  }
                />
              </div>

              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Address 1/ Company Name"
                  type="text"
                  name="address1"
                  value={additionalInfo.address1}
                  onChange={(e) =>
                    handleAdditionalInfo({ address1: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Address 2"
                  type="text"
                  name="address2"
                  value={additionalInfo.address2}
                  onChange={(e) =>
                    handleAdditionalInfo({ address2: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Suburb"
                  type="text"
                  name="suburb"
                  value={additionalInfo.city}
                  onChange={(e) =>
                    handleAdditionalInfo({ city: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="State"
                  type="text"
                  name="state"
                  value={additionalInfo.state}
                  onChange={(e) =>
                    handleAdditionalInfo({ state: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Post Code"
                  type="text"
                  name="postCode"
                  value={additionalInfo.postcode}
                  onChange={(e) =>
                    handleAdditionalInfo({ postcode: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Country"
                  type="text"
                  name="country"
                  value={additionalInfo.country}
                  onChange={(e) =>
                    handleAdditionalInfo({ notes: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Notes"
                  type="text"
                  name="notes"
                  value={additionalInfo.notes}
                  onChange={(e) =>
                    handleAdditionalInfo({ notes: e.target.value })
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="button-container">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ReplaceCardModel.propTypes = {
  selectedCard: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  updateAgentIssueCard: PropTypes.func,
  cardByProxy: PropTypes.object,
  customerInfo: PropTypes.object,
  handleReplaceCard: PropTypes.func
};

export default ReplaceCardModel;
