import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTab: null,
  selectedAgentIssuance: {}
};

const agentIssuance = createSlice({
  name: 'agentIssuance',
  initialState,
  reducers: {
    setSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
    setSelectedAgentIssuance(state, action) {
      state.selectedAgentIssuance = action.payload;
    }
  }
});

export const { setSelectedAgentIssuance, setSelectedTab } = agentIssuance.actions;

export default agentIssuance.reducer;
