import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subUsers: [],
  loading: false,
  error: [],
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getSubUsers(state, action) {
      state.loading = true;
    },
    getSubUsersSuccess(state, action) {
      state.loading = false;
      state.subUsers = action?.payload || [];
    },
    getSubUsersFailure(state, action) {
      state.loading = false;
    }
  }
});

export const { getSubUsers, getSubUsersSuccess, getSubUsersFailure } = user.actions;

export default user.reducer;
