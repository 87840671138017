// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 100%; }
  .form-container .MuiTextField-root {
    margin-bottom: 15px !important; }
    @media screen and (min-width: 768px) {
      .form-container .MuiTextField-root.halfWidth {
        width: 50% !important; }
        .form-container .MuiTextField-root.halfWidth:nth-child(odd) {
          width: calc( 50% - 15px) !important;
          margin-right: 15px; } }
  .form-container .preview-header-action {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px; }
    @media screen and (min-width: 768px) {
      .form-container .preview-header-action {
        margin-top: 35px; } }
    .form-container .preview-header-action button {
      width: 100%; }
      @media screen and (min-width: 768px) {
        .form-container .preview-header-action button {
          width: 50%;
          margin: 0; }
          .form-container .preview-header-action button:first-child {
            width: calc( 50% - 15px);
            margin-right: 15px; } }
`, "",{"version":3,"sources":["webpack://./src/components/campaign-form/UserForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAW,EAAA;EADf;IAKM,8BAA8B,EAAA;IAE5B;MAPR;QAQU,qBAAqB,EAAA;QAR/B;UAUY,mCAAoC;UACpC,kBAAkB,EAAA,EACnB;EAZX;IAiBM,aAAa;IACb,eAAe;IACf,gBAAgB,EAAA;IAChB;MApBN;QAqBQ,gBAAgB,EAAA,EAanB;IAlCL;MAwBQ,WAAW,EAAA;MACX;QAzBR;UA0BU,UAAU;UACV,SAAQ,EAAA;UA3BlB;YA6BY,wBAAyB;YACzB,kBAAkB,EAAA,EACnB","sourcesContent":[".form-container{\n    width: 100%;\n    // max-width: 726px;\n    // padding: 0 18px;\n    .MuiTextField-root{\n      margin-bottom: 15px !important;\n      &.halfWidth{\n        @media screen and (min-width: 768px) {\n          width: 50% !important;\n          &:nth-child(odd){\n            width: calc( 50% - 15px ) !important;\n            margin-right: 15px;\n          }\n        }\n      }\n    }\n    .preview-header-action{\n      display: flex;\n      flex-wrap: wrap;\n      margin-top: 50px;\n      @media screen and (min-width: 768px) {\n        margin-top: 35px;\n      }\n      button{\n        width: 100%;\n        @media screen and (min-width: 768px) {\n          width: 50%;\n          margin:0;\n          &:first-child{\n            width: calc( 50% - 15px );\n            margin-right: 15px;\n          }\n        }\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
