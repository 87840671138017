import React, { useEffect, useState } from 'react';

import TableBody from "./table-body";
import Notify from "../../../../components/notify";
import Loader from "../../../../components/loader";
import {
  getAllUsersAPI
} from '../../../../services/api';
import NavBar from '../../../../components/Navbar';
import FilterHeader from './filter-header';
import { getInitialFilterHeader } from './initialValues';

const UserList = () => {
  const [usersFilter, setUsersFilter]     = useState([]);
  const [users, setUsers]                 = useState([]);
  const [filter, setFilter]               = useState(getInitialFilterHeader());
  const [loading, setLoading]             = useState(true);
  const [openNotify, setOpenNotify]       = useState(false);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const [errors, setErrors]               = useState({});

  useEffect(() => {
    getAllUsersFilters();
    getAllUsers(rowsPerPage, page, filter);
  }, []);

  const getAllUsersFilters = async () => {
    try {
      const response = await getAllUsersAPI({});
      setUsersFilter(response?.users || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Users Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

    const getAllUsers = async (currentRowPerPage, currentPage, filterBy) => {
      try {
        const filterPayload = mapFilterValues(filterBy);
        const response = await getAllUsersAPI({
          limit: currentRowPerPage,
          page: currentPage,
          filter: filterPayload
        });
        setUsers(response?.users || []);
        setTotalRowCount(response?.count || 0);
        setLoading(false);
      } catch (err) {
        const errors =
          err && Object.keys(err).length > 0
            ? err
            : { errormessage: 'Getting All Users Failed !!' };
        setErrors(errors);
        setOpenNotify(true);
        setLoading(false);
      }
    };

  const mapFilterValues = (payload) => {
    const {
      tcnCustomerId,
      email,
      createdEndDate,
      createdStartDate,
      status,
      environment,
      ssoFlag,
      ssoId
    } = payload || filter;

    return {
      tcnCustomerId,
      createdEndDate,
      createdStartDate,
      email,
      status: status === 'All' ? '' : status,
      environment: environment === 'All' ? '' : environment,
      ssoFlag: ssoFlag === 'All' ? '' : ssoFlag,
      ssoId
    };
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllUsers(rowsPerPage, newPage, filter);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllUsers(newRowsPerPage, newPage, filter);
  };

  const handleClearFilter = async () => {
    setLoading(true);
    setFilter(getInitialFilterHeader());
    getAllUsers(10, 0, getInitialFilterHeader());
  };

  const handleSetFilter = (payload) => {
    setFilter({
      ...filter,
      ...payload
    });
  };

  const handleFilter = () => {
    setLoading(true);
    getAllUsers(rowsPerPage, page, filter);
  };

  return (
    <>
      <NavBar />
      <FilterHeader
        filter={filter}
        handleFilter={handleFilter}
        handleSetFilter={handleSetFilter}
        handleClearFilter={handleClearFilter}
      />
      <TableBody
        userList={usersFilter}
        users={users}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify open={openNotify} onClose={() => setOpenNotify(false)} errors={errors} />
    </>
  );
}

export default UserList;