import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./Dialog.scss";

const DialogContentBox = ({ title, openDialog, renderContent, handleCloseDialog }) => {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title dialog-title">{title || "Alert!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {renderContent()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="dialog-action-btn"
            onClick={handleCloseDialog}
            color="primary"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DialogContentBox.propTypes = {
  title: PropTypes.string,
  openDialog: PropTypes.bool,
  message: PropTypes.string,
  renderContent: PropTypes.func,
  handleCloseDialog: PropTypes.func
};


export default DialogContentBox;
