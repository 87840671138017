import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllWhitListIps } from '../../../store/slices/whiteListIps';
import TableHeader from "./table-header";
import TableBody from "./table-body";

const ManageIpList = () => {
  const [issuanceTypes, setWhiteListIps]            = useState([]);
  const [whiteListIpsFilter, setWhiteListIpsFilter] = useState([]);
  const [loading, setLoading]                       = useState(true);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const dispatch = useDispatch();

  const loadingReducer  = useSelector((state) => state.whiteListIps.loading);
  const whiteListIpsReducer = useSelector((state) => state.whiteListIps.whiteListIps);


  useEffect(() => {
    getAllWhiteListIp(rowsPerPage, page);
  }, []);

  useEffect(() => {
    setTotalRowCount(whiteListIpsReducer?.count || 0);
    setWhiteListIps(whiteListIpsReducer?.ipLists || []);
    setWhiteListIpsFilter(whiteListIpsReducer?.ipLists || []);
    setLoading(loadingReducer);
  }, [loading, whiteListIpsReducer]);

  const getAllWhiteListIp = (currentRowPerPage, currentPage) => {
    dispatch(getAllWhitListIps({
      limit: currentRowPerPage,
      page: currentPage,
    }));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllWhiteListIp(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllWhiteListIp(newRowsPerPage, newPage);
  };

  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';
    const filteredRow = [];

    issuanceTypes.forEach((issuanceType) => {
      const id = issuanceType?.id?.toLowerCase() || '';
      const name = issuanceType?.name?.toLowerCase() || '';

      if (
        id.includes(searchText) ||
        name.includes(searchText)
      ) {
        filteredRow.push(issuanceType);
      }
    });
    setWhiteListIpsFilter(filteredRow);
  }

  return (
    <>
      <TableHeader handleSearch={handleSearch} />
      <TableBody
        ipList={whiteListIpsFilter}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </>
  );
}

export default ManageIpList;