// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-wrapper {
  padding: 20px; }
  .table-wrapper .table-container {
    margin-top: 5rem; }

.campain-table-container .campain-title h2 {
  margin-bottom: 74px; }
  @media screen and (min-width: 1024px) {
    .campain-table-container .campain-title h2 {
      margin-bottom: 93px; } }
  .campain-table-container .campain-title h2 .table-filte-body button {
    width: 100%; }

.campain-table-container .campain-title .table-container {
  margin-top: 32px; }
  @media screen and (min-width: 1024px) {
    .campain-table-container .campain-title .table-container {
      margin-top: 25px; } }

.table-filter-body .form-container .field-container {
  margin: 23px 0; }
  .table-filter-body .form-container .field-container .MuiFormControl-root:first-child {
    margin-right: 24px; }
  .table-filter-body .form-container .field-container .form-control.w-50 {
    width: 50% !important; }

.table-filter-body .form-container .field-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .table-filter-body .form-container .field-button-container .w-50 {
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .table-filter-body .form-container .field-button-container .w-50 {
        width: 50% !important; } }
    .table-filter-body .form-container .field-button-container .w-50 button {
      width: 100%;
      margin-top: 15px; }
      @media screen and (min-width: 1024px) {
        .table-filter-body .form-container .field-button-container .w-50 button {
          width: calc( 50% - 14px);
          margin: 0 7px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/manage-whitelist-ips/ManageIP.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAA;EADf;IAGI,gBAAgB,EAAA;;AAGpB;EAGM,mBAAmB,EAAA;EACnB;IAJN;MAKQ,mBAAmB,EAAA,EAOtB;EAZL;IASU,WAAW,EAAA;;AATrB;EAcM,gBAAgB,EAAA;EAChB;IAfN;MAgBQ,gBAAgB,EAAA,EAEnB;;AAGL;EAGM,cAAc,EAAA;EAHpB;IAKQ,kBAAkB,EAAA;EAL1B;IASU,qBAAqB,EAAA;;AAT/B;EAcM,aAAa;EACb,eAAe;EACf,8BAA8B,EAAA;EAhBpC;IAkBQ,WAAW,EAAA;IACX;MAnBR;QAoBU,qBAAqB,EAAA,EAUxB;IA9BP;MAuBU,WAAW;MACX,gBAAgB,EAAA;MAChB;QAzBV;UA0BY,wBAAyB;UACzB,aAAY,EAAA,EAEf","sourcesContent":[".table-wrapper {\n  padding: 20px;\n  & .table-container {\n    margin-top: 5rem;\n  }\n}\n.campain-table-container{\n  .campain-title{\n    h2{\n      margin-bottom: 74px;\n      @media screen and (min-width: 1024px) {\n        margin-bottom: 93px;\n      }\n      .table-filte-body{\n        button{\n          width: 100%;\n        }\n      }\n    }\n    .table-container{\n      margin-top: 32px;\n      @media screen and (min-width: 1024px) {\n        margin-top: 25px;\n      }\n    }\n  }\n}\n.table-filter-body {\n  .form-container {\n    .field-container {\n      margin: 23px 0;\n      .MuiFormControl-root:first-child {\n        margin-right: 24px;\n      }\n      .form-control {\n        &.w-50 {\n          width: 50% !important;\n        }\n      }\n    }\n    .field-button-container {\n      display: flex;\n      flex-wrap: wrap;\n      justify-content: space-between;\n      .w-50{\n        width: 100%;\n        @media screen and (min-width: 1024px) {\n          width: 50% !important;\n        }\n        button{\n          width: 100%;\n          margin-top: 15px;\n          @media screen and (min-width: 1024px) {\n            width: calc( 50% - 14px );\n            margin:0 7px;\n          }\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
