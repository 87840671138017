import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { FormControl, InputLabel, makeStyles, MenuItem } from "@material-ui/core";
import PublishSharpIcon from "@material-ui/icons/PublishSharp";
import Info from '@material-ui/icons/Info';
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { TCNCP_API } from '../../services/axios';
import { validateCards } from "../../store/slices/cardValidation";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import validateForm from './validateForm.helper';
import "./BulkCardIssue.scss";
import Notify from "../../components/notify";
import Loader from '../../components/loader';
import SelectInput from "../../components/Select";
import { hasAdminUserPermissionForCTA } from "../../helpers/permission";

const useStyles = makeStyles({
  root: {
    '& label': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'white'
    },
    '& .MuiInputBase-input': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white'
    }
  }
});

function BulkCardIssue() {
  const [fileName, setFileName]                 = useState("");
  const [file, setFile]                         = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [openNotify, setOpenNotify]             = useState(false);
  // const [errors, setErrors]                     = useState({});
  const [customerOrderReference] = useState('');
  const [orderReference, setOrderReference]     = useState('Customer generated');
  const classes                 = useStyles();
  const history                 = useHistory();
  const dispatch                = useDispatch();

  const loading   = useSelector((state) => state.cardValidation.loading);
  const errors = useSelector((state) => state.cardValidation.error);
  const fileInput = useRef(null);

  useEffect(() => {
    if (Object.keys(errors).length >0 ) {
      setOpenNotify(true);
    }
  }, [errors]);

  const handleCapture = (event) => {
    if (event?.target?.files?.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleSubmit = () => {
    const validate = validateForm({ file, fileName });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    dispatch(
      validateCards({
        file,
        fileName,
        orderReference,
        history,
        customerOrderReference
      })
    );
  };

  const handleCancel = () => {
    fileInput.current.value = '';
    setFile(null);
    setFileName("");
  };

  const downloadTemplate = () => {
    try {
      window.open(`${TCNCP_API}/card/download/template`, '_blank');
    } catch (err) {
      setOpenNotify(true);
    }
  }

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="bulk-card-container">
          <div className="flex flex-col text-center">
            <h2>Bulk Card Issue</h2>
            <h3>Step 1- Issue card(Bulk card issue)</h3>
          </div>
          <div className="bulk-card-body">
            <div className="text-field-container">
              <TextField
                error={validationErrors.fileName}
                className={classes.root}
                id="standard-basic"
                label="File Name"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                helperText="Is required."
              />
            </div>

            <div className="flex mb-3">
              <FormControl
                variant="standard"
                error={validationErrors.orderReference}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Order Reference
                </InputLabel>
                <SelectInput
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="orderReference"
                  value={orderReference}
                  onChange={(val) => setOrderReference(val)}
                >
                  <MenuItem value={'Customer generated'}>
                    Customer generated
                  </MenuItem>
                  <MenuItem value={'System generated'}>
                    System generated
                  </MenuItem>
                </SelectInput>
              </FormControl>
            </div>

            <div className="upload-file-container flex">
              <input
                accept=".csv, text/csv, .xlsx"
                style={{ display: 'none' }}
                id="contained-button-file"
                onChange={handleCapture}
                type="file"
                ref={fileInput}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  className="file-upload-btn"
                  component="span"
                  startIcon={<PublishSharpIcon />}
                >
                  Upload File
                </Button>
              </label>
              <div className="file-upload-message">
                {file ? file.name : 'No file chosen'}
              </div>
            </div>

            <div className="flex items-center">
              <Info
                onClick={downloadTemplate}
                style={{ color: '#FFFB00', marginRight: '5px' }}
              />{' '}
              The file import template
            </div>

            <div className="action-footer">
              <Button
                variant="outlined"
                className="large "
                onClick={handleSubmit}
                disabled={!hasAdminUserPermissionForCTA() || loading}
                endIcon={
                  <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                }
              >
                Continue
              </Button>
              <Button
                variant="outlined"
                className="large"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Notify
        open={openNotify}
        onClose={()=> setOpenNotify(false)}
        errors={errors}
      />
      <Loader loading={loading} />
    </>
  );
}
export default BulkCardIssue;