/* eslint-disable no-undef */
const MsalConfig = {
  auth: {
    clientId:  process.env.REACT_APP_AZURE_SSO_CLIENT_ID,
    authority: process.env.REACT_APP_AZURE_SSO_AUTHORITY,
    redirectUri: process.env.REACT_APP_AZURE_SSO_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_SSO_POST_LOGOUT_REDIRECT_URI
  }
}

export default MsalConfig;