// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfa-journey {
  color: white !important;
  font-size: 20px; }
  .mfa-journey .mfa-journey-steps {
    margin: 10px 0px; }
  .mfa-journey .step1-child {
    padding: 20px; }
  .mfa-journey .qr-code {
    padding: 20px; }
    .mfa-journey .qr-code .qr-code-header {
      font-weight: bold;
      font-size: 25px;
      margin: 20px 0px; }
  .mfa-journey .setup-keys {
    padding: 20px; }
    .mfa-journey .setup-keys .setup-key-header {
      font-size: 25px;
      margin: 20px 0px; }
  .mfa-journey .text-blue {
    font-weight: 400;
    font-size: 20px;
    line-height: 15.41px;
    color: #005296;
    text-decoration: underline; }

.action-footer {
  margin-top: 20px; }
  .action-footer button {
    width: 30%;
    font-size: 16px;
    margin-bottom: 14px; }
    @media (min-width: 720px) {
      .action-footer button {
        width: calc(50% - 7px); }
        .action-footer button:first-child {
          margin-right: 14px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/login/mfa-guide-journey/MfaGuideJourney.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,eAAe,EAAA;EAFjB;IAII,gBAAgB,EAAA;EAJpB;IAOI,aAAa,EAAA;EAPjB;IAUI,aAAa,EAAA;IAVjB;MAYM,iBAAiB;MACjB,eAAe;MACf,gBAAgB,EAAA;EAdtB;IAkBI,aAAa,EAAA;IAlBjB;MAoBM,eAAe;MACf,gBAAgB,EAAA;EArBtB;IA0BI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,cAAc;IACd,0BAA0B,EAAA;;AAI9B;EACE,gBAAgB,EAAA;EADlB;IAGI,UAAU;IACV,eAAe;IACf,mBAAmB,EAAA;IACnB;MANJ;QAOM,sBAAsB,EAAA;QAP5B;UASQ,kBAAkB,EAAA,EACnB","sourcesContent":[".mfa-journey {\n  color: white !important;\n  font-size: 20px;\n  .mfa-journey-steps {\n    margin: 10px 0px;\n  }\n  .step1-child {\n    padding: 20px;\n  }\n  .qr-code {\n    padding: 20px;\n    .qr-code-header {\n      font-weight: bold;\n      font-size: 25px;\n      margin: 20px 0px;\n    }\n  }\n  .setup-keys {\n    padding: 20px;\n    .setup-key-header {\n      font-size: 25px;\n      margin: 20px 0px;\n    }\n  }\n\n  .text-blue {\n    font-weight: 400;\n    font-size: 20px;\n    line-height: 15.41px;\n    color: #005296;\n    text-decoration: underline;\n  }\n}\n\n.action-footer {\n  margin-top: 20px;\n  button {\n    width: 30%;\n    font-size: 16px;\n    margin-bottom: 14px;\n    @media (min-width: 720px) {\n      width: calc(50% - 7px);\n      &:first-child {\n        margin-right: 14px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
