import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

import validateForm from './validateForm.helper';
import './CardModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

const AddCardNoteModel = ({ modalIsOpen, closeModal, handleSendNote }) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [note, setNote] = useState('');

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleClick = () => {
    const validate = validateForm({ note });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    handleSendNote(note);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Add Card Note</h2>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Note"
              type="text"
              name="note"
              value={note}
              error={validationErrors.note}
              helperText="*Is required"
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div className="button-container">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

AddCardNoteModel.propTypes = {
  selectedCard: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  handleSendNote: PropTypes.func
};

export default AddCardNoteModel;
