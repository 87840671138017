import { format } from "date-fns";
import { getTcnCustomerId } from "../../../helpers/localStorage";
import { add } from "date-fns";

export const getDefaultFilterValues = () => {
  return Object.assign(
    {},
    {
      requireagentissuance: '1',
      agentissueflag: '1',
      cardvariant: 'physical'
    },
    getTcnCustomerId() === 1199
      ? {
          promotion: '13216'
        }
      : null
  );
}

export const getInitialFilterHeader = () => {
  return {
    promotion: getTcnCustomerId() === 1199 ? '13216' : 'All',
    departmentId: 'All',
    agent: 'All',
    cardCancel: 'All',
    cardIssuedStartDate: null,
    cardIssuedEndDate: null,
    referenceId: '',
    search: '',
    sortingcolumn: null,
    sortingorder: null,
    createend: format(add(new Date(), { days: 1 }), `yyyy-MM-dd`),
    createstart: '2023-01-01',
    searchParams: null,
    productId: 'All',
    cardType: 'physical',
    serialStart: '',
    serialEnd: ''
  };
};
