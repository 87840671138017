import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../helpers/currency';
import './Counter.scss';

const AgentIssuanceCounter = ({
  floatData,
  totalCardAvailable,
  customerInfo
}) => {
  const hideAvailableBalance = customerInfo?.customer_privacy_integer !== "2" &&
                                  customerInfo?.customer_privacy_integer !== "4";
  return (
    <>
      <section className="counter-items-container">
        <ul className="counter-items">
          <li className="counter-item">
            <h1>{floatData.issuecardcount}</h1>
            <p>Total cards supplied</p>
          </li>
          <li className="counter-item">
            <h1>{formatMoney(floatData.initialvalue)}</h1>
            <p>Value of cards issued</p>
          </li>
          {totalCardAvailable && (
            <li className="counter-item">
              <h1>{totalCardAvailable}</h1>
              <p>Available Cards</p>
            </li>
          )}

          {hideAvailableBalance && (
            <li className="counter-item">
              <h1>
                {floatData.creditflag === '1'
                  ? formatMoney(
                      parseFloat(floatData.balance) +
                        parseFloat(floatData.creditlimit)
                    )
                  : formatMoney(floatData.balance)}
              </h1>
              <p>Available Balance</p>
            </li>
          )}
        </ul>
      </section>
    </>
  );
};

AgentIssuanceCounter.propTypes = {
  floatData: PropTypes.object,
  totalCardAvailable: PropTypes.number,
  customerInfo: PropTypes.object
};

export default AgentIssuanceCounter;
