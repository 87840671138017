import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getBrandName } from '../../../../helpers/customRedeem';

const FormTitleSection = ({ promotionid }) => {
  return (
    <>
      <div
        className={classNames('form-title', {
          'aami-font': promotionid === '11246',
          'secondary-font': promotionid === '11247'
        })}
        // className="form-title secondary-font"
      >
        Enter your mobile number to get started.
      </div>
      <div
        className={classNames('form-subtitle', {
          'aami-font': promotionid === '11246',
          'secondary-font': promotionid === '11247'
        })}
        // className="form-subtitle secondary-font"
      >
        These details are shared with TCN to set up access to your card. By
        submitting your details, you confirm your request to receive a digital
        Prepaid Visa card containing your {getBrandName(promotionid)} Rewards
        balance from TCN.
      </div>
    </>
  );
};

FormTitleSection.propTypes = {
  promotionid: PropTypes.string
};

export default FormTitleSection;
