import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';

import Loader from '../../../components/loader';
import Notify from '../../../components/notify';

export default function SignInOidc() {
  const [loading, setLoading] = useState(true);
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [errors, setErrors]   = useState({});
  const history = useHistory();
  const { instance, accounts, inProgress, logger  } = useMsal();

  // useEffect(() => {
  //   handleRedirect();
  // }, [inProgress]);

  const handleRedirect = async () => {
    // const authenticationResult = await instance.getAllAccounts(); // Get the first account
    // console.log('authenticationResult: ', authenticationResult);
    // history.push('/signin-oidc/verify-token');
    // authResult contains the authentication result, including tokens
  };

  // const loginCrownUser = async() => {
  //   debugger;
  //   const TCNCP_API = process.env.REACT_APP_TCNCP_API;
  //   const accountInfo = accounts[0] || null;
  //   console.log("inProgress :: ", inProgress);
  //   console.log("logger :: ", logger);

  //   if(accountInfo) {
  //     const payload = { email: accountInfo.username };
  //     const headers = {
  //       'Content-Type': 'application/json',
  //       'azure-token' : accountInfo.idToken
  //     }

  //     axios
  //       .post(`${TCNCP_API}/auth/crown/login`, payload, {
  //         headers
  //       })
  //       .then((res) => {
  //         console.log('loginCrownUser :: res: ', res);
  //       }).catch((err) => {
  //         console.log('err: ', err);
  //         const errors = err?.response?.data || { errormessage: 'Something Went Wrong !!!!' };
  //         setErrors(errors);
  //         setLoading(false);
  //         setOpenSuccessNotify(true);
  //       });
  //   }

  // }

  return (
    <>
      <Loader loading={loading} />
      <Notify
          open={openSuccessNotify}
          onClose={() => setOpenSuccessNotify(false)}
          errors={errors}
        />
    </>
  );
}