import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';

import './CardModal.scss';
import 'react-datepicker/dist/react-datepicker.css';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const ReplaceCardSuccessSummaryModel = ({
  modalIsOpen,
  closeModal,
  serialNumber,
  navigateToIssuedCardTable,
  navigateToViewCard
}) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <div className="close-icon">
            <CloseOutlinedIcon
              style={{ color: '#FFFB00' }}
              size="20px"
              onClick={closeModal}
            />
          </div>
          <div className="card-issued-summary-text">
            Card Serial number {serialNumber} has been successfully issued.
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              color="primary"
              onClick={navigateToIssuedCardTable}
            >
              Back To Issued Card Table
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={navigateToViewCard}
            >
              View Card
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

ReplaceCardSuccessSummaryModel.propTypes = {
  selectedCard: PropTypes.object,
  serialNumber: PropTypes.string,
  modalIsOpen : PropTypes.bool,
  closeModal  : PropTypes.func,
  navigateToViewCard : PropTypes.func,
  navigateToIssuedCardTable: PropTypes.func
};

export default ReplaceCardSuccessSummaryModel;
