import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer';
import validateForm from './validateForm.helper';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import Notify from '../../components/notify';
import './ForgetPassword.scss';
import Loader from '../../components/loader';

export default function ForgetPassword() {
  const [openNotification, setOpenNotify] = useState(false);
  const [email, setEmail] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    const TCNCP_API = process.env.REACT_APP_TCNCP_API;
    const payload = { email };
    const validate = validateForm(payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    setLoading(true);
    axios
      .post(`${TCNCP_API}/auth/forget-password`, payload)
      .then((res) => {
        setLoading(false);
        history.push('/forget-password/confirmation');
        // setOpenNotify(true);
      })
      .catch((err) => {
        const errors =
          err && Object.keys(err?.response?.data).length > 0
            ? { errormessage: err?.response?.data?.errormessage || '' }
            : { errormessage: 'Something Went Wrong !!!!' };
        setErrors(errors);
        setLoading(false);
        setOpenNotify(true);
      });
  };

  return (
    <>
      <Container
        component="main"
        className="forget-password-container flex-col container"
      >
        <CssBaseline />
        <div className="flex flex-col items-center forget-password-wrapper">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>
          <form
            className="forget-password-form"
            noValidate
            onSubmit={handleSubmit}
          >
            <TextField
              error={validationErrors.email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              className="login-field"
              helperText="*Is required."
            />
            <Button
              type="submit"
              fullWidth
              className="submit-btn"
              disabled={loading}
              endIcon={
                <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
              }
            >
              Send password link
            </Button>
          </form>
        </div>
      </Container>
      <Footer />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage="Password reset link has been sent to your email!"
      />
      <Loader loading={loading} />
    </>
  );
}
