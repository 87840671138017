import { format, isValid } from "date-fns";

export const generateLogoUrl = (customerId) => {
  return `https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/tcncplogos/${customerId}.png`;
};

export const formatDate = (date, dateFormat) => {
  if (isValid(new Date(date))) {
    return date ? format(new Date(date), dateFormat) : null;
  } else {
    return 'Invalid date';
  }
}

export const isValidCIDR = (input) => {
  const cidrRegex = /^(?:\d{1,3}\.){3}\d{1,3}\/(?:[1-9]|[1-2][0-9]|3[0-2])$/;
  return cidrRegex.test(input);
}

export const isValidIPAddress = (input) => {
  const ipRegex = /^(?:\d{1,3}\.){3}\d{1,3}$/;
  return ipRegex.test(input);
}
