import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllWhitListIps, getAllWhitListIpsSuccess} from '../slices/whiteListIps';
import { getAllWhiteListIpsAPI } from '../../services/api';

function* getAllWhiteListIpsSaga({payload}) {
  const data = yield call(getAllWhiteListIpsAPI, payload);
  yield put(getAllWhitListIpsSuccess(data));
}


export function* whiteListIpsSaga() {
  yield takeLatest(getAllWhitListIps, getAllWhiteListIpsSaga);
}