import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { Select } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Notify from '../../components/notify';
import Loader from '../../components/loader';
import { createSettingsAPI } from '../../services/api';
import "./Settings.scss";
import NavBar from '../../components/Navbar';
import { getSettingAPI } from '../../services/api';
import { clearStorage } from '../../helpers/localStorage';

const Setting = () => {
  const [applyTestMode, setApplyTestMode] = useState(0);
  const [openNotify, setOpenNotify]       = useState(false);
  const [loading, setLoading]             = useState(false);
  const [errors, setErrors]               = useState({});
  const history = useHistory();

  useEffect(() => {
    getSetting();
  }, []);

  const getSetting = async () => {
    try {
      setLoading(true);
      const response = await getSettingAPI();
      setApplyTestMode(response?.applyTestMode || 0);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  };

  const handleSave = async() => {
    setLoading(true);
    try {
      const response = await createSettingsAPI({ applyTestMode });
      setOpenNotify(true);
      setLoading(false);
      clearStorage();
      history.push('/login');
    } catch(err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  }

  const handleGoBack = () => {
    history.push('/dashboard');
  }

  return (
    <>
      <NavBar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
      <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h2>Settings</h2>
          </div>

          <Alert severity="info" className="mt-3 mb-4">
            NOTE: Changes on this page will redirect you to login page!!
          </Alert>

          <div className="flex justify-between flex-wrap  fix-width field-container">
            <FormControl variant="standard" className="form-control">
              <InputLabel id="demo-simple-select-standard-label">
                Apply Test Mode
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="campaign"
                value={applyTestMode}
                onChange={(e) => setApplyTestMode(e.target.value)}
                IconComponent={() => <KeyboardArrowDown />}
              >
                <MenuItem value={1}>True</MenuItem>
                <MenuItem value={0}>False</MenuItem>
              </Select>
            </FormControl>

            <Button
              className="button"
              onClick={()=> handleSave()}
              variant="contained"
              color="primary"
            >
              Save
            </Button>

            <Button
              className="button"
              onClick={handleGoBack}
              variant="contained"
              color="primary"
            >
              Go Back
            </Button>
          </div>
        </div>
      </div>
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage="Settings Updated Sucessfully!"
      />
      <Loader loading={loading} />
    </>
  );
}

export default Setting;