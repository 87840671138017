import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';

import logo from '../../../assets/images/tcn-logo.svg';
import Footer from '../../../components/Footer';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import './MfaGuideJourney.scss';
import { getUserInfoAPI } from '../../../services/api';
import Loader from '../../../components/loader';

export default function MfaGuideJourney() {
  const [userInfo, setUserInfo] = useState({});
  const [isCustomerLoaded, setIsUserLoaded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getCustomerInfo(true);
  }, []);

  const getCustomerInfo = async () => {
    try {
      setIsUserLoaded(true);
      const response = await getUserInfoAPI();
      setUserInfo(response);
      setIsUserLoaded(false);
    } catch (err) {
      setIsUserLoaded(false);
      setUserInfo([]);
    }
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  const redirectToMfaCheckpoint = () => {
    history.push('/checkpoint/mfa');
  };

  return (
    <>
      <Container
        component="main"
        className="login-container flex-col container"
      >
        <CssBaseline />
        <div className="flex flex-col">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>
          <div className="mfa-journey">
            <div className="mfa-journey-steps">
              1. Open the Google Authenticator app. If you do not have the
              Google Authenticator on your mobile device, you can download the
              free app below:
            </div>
            <div className="step1-child">
              ios-{' '}
              <a
                href="https://apps.apple.com/au/app/google-authenticator/id388497605"
                target="_blank"
                className="text-blue"
                rel="noreferrer"
              >
                here
              </a>
            </div>
            <div className="step1-child">
              Android-{' '}
              <a
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pli=1"
                target="_blank"
                className="text-blue"
                rel="noreferrer"
              >
                here
              </a>
            </div>
            <div className="mfa-journey-steps">
              2. Set up the TCN Portal Authentication Account by scanning the QR
              code with the Google Authenticator App or entering the set-up key.
              This is a one time set-up.
            </div>
            <div className="qr-code">
              <div className="qr-code-header">QR Code</div>
              <img src={userInfo?.mfaSecrets?.generateQRCode} />
            </div>
            <div className="setup-keys">
              <div className="setup-key-header"> Set-up key</div>
              <div className="setup-key-body">Account: TCNCP_Admin</div>
              <div className="setup-key-body">
                Secret Key: {userInfo?.mfaSecrets?.secret?.base32}
              </div>
            </div>
          </div>
          <div className="action-footer">
            <Button
              variant="outlined"
              endIcon={
                <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
              }
              onClick={redirectToMfaCheckpoint}
            >
              Continue
            </Button>
            <Button variant="outlined" onClick={redirectToLogin}>
              Cancel
            </Button>
          </div>
        </div>
      </Container>
      <Footer />
      <Loader loading={isCustomerLoaded} />
    </>
  );
}
