import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import logo from '../../../assets/images/tcn-logo.svg';
import Footer from '../../../components/Footer';
import './ForgetPasswordConfirmation.scss';

export default function ForgetPasswordConfirmation() {
  const history = useHistory();

  const redirectToLogin = () => {
    history.push('/login');
  }
  return (
    <>
      <Container
        component="main"
        className="forget-container flex-col container"
      >
        <CssBaseline />
        <div className="flex flex-col items-center">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>

          <div className="info-text">
            An email has been sent to your inbox to reset your portal password.
            If the email has not arrived in your inbox, check your junk folder.
          </div>
          <Button
            type="submit"
            fullWidth
            className="submit-btn" onClick={redirectToLogin}
          >
            Back to Login
          </Button>
        </div>
      </Container>
      <Footer />
    </>
  );
}
