import React from 'react';
import PropTypes from 'prop-types';
import { format, add, isFuture } from 'date-fns';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { Button, TablePagination } from '@material-ui/core';

const CardTable = ({
  issuedCards,
  rowsPerPage,
  totalRowCount,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleUnBlockCard
}) => {
  const hidePaginationArrow = rowsPerPage > totalRowCount;

  const createRows = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = {
        cardId: data[i].card_id,
        campaign: data[i].promotion_id,
        campaignName: data[i].promotion_name,
        product: data[i].product_id,
        productName: data[i].product_name,
        promotionId: data[i].promotion_id,
        cardStatus: data[i].card_status,
        cardValue: data[i].card_initialvalue,
        email: data[i].card_recipient_email,
        recipientName: data[i].card_recipient_name,
        cardDispatchDate: data[i].card_dispatch_date,
        cardBlocked: data[i]?.card_block_flag === 'TRUE' ? 'Yes' : 'No',
        digitalCardSmsStatus: data[i].swapstatus,
        cardPromotionTcnarp: data[i].card_promotion_tcnarp,
        senderName: `${data[i]?.card_original_fname || ''} ${
          data[i]?.card_original_lname || ''
        }`,
        cardCreationDate: data[i].card_creation_date,
        digitalCardEmailStatus: data[i].logmailgun_deliverystatus,
        orderRef: data[i].card_external_id,
        mobile: data[i].swap_mobile,
        requestHandle: data[i].card_requesthandle,
        sourceSerial: data[i].source_serial,
        cardActivationCode: data[i].cardactivationcode,
        cardAgentissuedFlag: data[i]?.issuance_metadata?.card_agent_issued,
        cardPromoRequireAgentissuedFlag:
          data[i]?.issuance_metadata?.card_agent_promotionrequireissuance,
        showUnBlockedBtn:
          data[i]?.card_block_flag === 'TRUE' &&
          isFuture(
            add(new Date(data[i]?.card_dispatch_date), {
              days: data[i]?.card_promotion_tcnarp
            })
          )
      };

      rows.push(row);
    }
    return rows;
  };

  const rows = createRows(issuedCards);
  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Product Name</TableCell>
              <TableCell align="left">Campaign Name</TableCell>
              <TableCell align="left">Value</TableCell>
              <TableCell align="left">Card Status</TableCell>
              <TableCell align="left">Card Blocked</TableCell>
              <TableCell align="left">Serial</TableCell>
              <TableCell align="left">Card Activation Code</TableCell>
              <TableCell align="left">Card Dispatch Date</TableCell>
              <TableCell align="left">Card Activation Deadline </TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.productName}</TableCell>
                  <TableCell align="left">{row.campaignName}</TableCell>
                  <TableCell align="left">{row.cardValue}</TableCell>
                  <TableCell align="center">{row.cardStatus}</TableCell>
                  <TableCell align="center">{row.cardBlocked}</TableCell>
                  <TableCell align="left">{row.sourceSerial}</TableCell>
                  <TableCell align="left">{row.cardActivationCode}</TableCell>
                  <TableCell align="left">
                    {format(
                      new Date(row.cardDispatchDate),
                      "dd MMM yyyy 'at' HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {format(
                      add(new Date(row.cardDispatchDate), {
                        days: row.cardPromotionTcnarp
                      }),
                      "dd MMM yyyy 'at' HH:mm"
                    )}
                  </TableCell>
                  {row.showUnBlockedBtn && (
                    <TableCell align="left">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          handleUnBlockCard(row.cardActivationCode)
                        }
                      >
                        Unblock
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        backIconButtonProps={
          hidePaginationArrow ? { style: { visibility: 'hidden' } } : {}
        }
        nextIconButtonProps={
          hidePaginationArrow ? { style: { visibility: 'hidden' } } : {}
        }
      />
    </>
  );
};

CardTable.propTypes = {
  issuedCards: PropTypes.array,
  handleResend: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRowCount: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleUnBlockCard: PropTypes.func
};

export default CardTable;
