import React from 'react';

import LogList from './log-list';
import './Log.scss';

const Logs = () => {
  return (
    <>
      <div className="flex justify-center items-center campain-table-container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3 color-white">Logs</h2>
          </div>
          <LogList />
        </div>
      </div>
    </>
  );
};

export default Logs;
