import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import CampaignTable from '../../../components/campaign-table';
import PropTypes from 'prop-types';
import './top-campaigns.scss';

const TopCampaigns = ({ promotionData }) => {
  const history = useHistory();

  const redirectToCampaign = () => {
    history.push('/campaign');
  }

  return (
    <>
      <div className="top-campaign-container">
        <div className="heading-container">
          <h2 className="text-center">Top Campaigns</h2>
          <p className="text-center">Campaigns by Issuance Value</p>
        </div>
        <CampaignTable campaigns={promotionData} hideCostOfCardsIssued={true} />
        <div className="flex justify-center button-container">
          <Button
            variant="outlined"
            className="large"
            onClick={redirectToCampaign}
          >
            Campaign Page
          </Button>
        </div>
      </div>
    </>
  );
}

TopCampaigns.propTypes = {
  promotionData: PropTypes.array
};

export default TopCampaigns;