import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ReactJson from 'react-json-view';


import './CardModal.scss';

const ViewCardModel = ({ modalIsOpen, closeModal, selectedLog }) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div className="close-icon">
          <CloseOutlinedIcon
            style={{ color: '#FFFB00' }}
            size="20px"
            onClick={closeModal}
          />
        </div>
        <h2>Detail View</h2>
        <div className="detail-log-wrapper">
          <div className="detail-log-info">
            <h3>Prior Value</h3>
            <ReactJson name="Prior Value" src={selectedLog?.priorValue} />
          </div>
          <div className="detail-log-info">
            <h3>Prior Value</h3>
            <ReactJson name="Updated Value" src={selectedLog?.updatedValue} />
          </div>
        </div>
      </Modal>
    </>
  );
};

ViewCardModel.propTypes = {
  selectedLog: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  updateAgentIssueCard: PropTypes.func,
  updateBulkAgentIssueCard: PropTypes.func
};

export default ViewCardModel;
