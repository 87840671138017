import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import UnavailableCards from './unavailable-cards';
import Navbar from '../../components/Navbar';
import AvailableCards from './available-cards';
import IssuedCards from './issued-cards';
import './IssueCardTable.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTab } from '../../store/slices/agentIssuance';
import AgentIssuanceCounter from '../../components/counter-info/AgentIssuanceCounter';
import { getCustomerInfoAPI } from '../../services/api';

const IssueCardTable = () => {
  const [customerInfo, setCustomerInfo] = useState({});
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);
  const [totalAvailableCards, setTotalAvailableCards] = useState(null);

  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.agentIssuance.selectedTab);

  useEffect(() => {
    getCustomerInfo();
  }, []);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setIsCustomerLoaded(true);
    } catch (err) {
      setCustomerInfo([]);
    }
  };

  const handleSwitchTabs = (tabId) => {
    dispatch(setSelectedTab(tabId));
  };

  const updateAvailableCards = (value) => {
    setTotalAvailableCards(value);
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="agent-issuance-wrapper">
          <div className="heading-container mt-4">
            <h2 className="text-center">Agent Issuance</h2>
          </div>
          {isCustomerLoaded ? (
            <AgentIssuanceCounter
              floatData={customerInfo.floatdata}
              totalCardAvailable={totalAvailableCards}
              customerInfo={customerInfo?.customer?.[0]}
            />
          ) : (
            <div className="min-h-full loading-container">Loading....</div>
          )}
          <div className="switch-tabs">
            <div
              className={classNames('tabs', {
                active: selectedTab === 0
              })}
              onClick={() => handleSwitchTabs(0)}
            >
              Available Cards
            </div>
            <div
              className={classNames('tabs', {
                active: selectedTab === 1
              })}
              onClick={() => handleSwitchTabs(1)}
            >
              Issued Cards
            </div>
            <div
              className={classNames('tabs', {
                active: selectedTab === 2
              })}
              onClick={() => handleSwitchTabs(2)}
            >
              Unavailable Cards
            </div>
          </div>
          {!Number.isInteger(selectedTab) && (
            <div className="no-action-row">
              <div className="no-action-message">
                {' '}
                Please click on the tabs to proceed further..
              </div>
            </div>
          )}

          {selectedTab === 0 && (
            <AvailableCards
              customerInfo={customerInfo}
              updateAvailableCards={updateAvailableCards}
            />
          )}
          {selectedTab === 1 && <IssuedCards customerInfo={customerInfo} />}
          {selectedTab === 2 && <UnavailableCards />}
        </div>
      </div>
    </>
  );
};

IssueCardTable.propTypes = {
  issueCard: PropTypes.array
};

export default IssueCardTable;
