// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter-items-container {
  padding: 50px 0 60px 0;
  margin: 0 20px; }
  @media screen and (min-width: 768px) {
    .counter-items-container {
      margin: 0 26px; } }
  @media screen and (min-width: 768px) {
    .counter-items-container {
      padding: 117px 0 128px 0; } }
  .counter-items-container .counter-items {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .counter-items-container .counter-items .counter-item {
      list-style-type: none;
      width: 100%;
      text-align: center;
      padding: 20px 0; }
      .counter-items-container .counter-items .counter-item h1 {
        font-size: 40px;
        line-height: 45px; }
      .counter-items-container .counter-items .counter-item p {
        font-size: 16px;
        line-height: 25px; }
      @media screen and (min-width: 768px) {
        .counter-items-container .counter-items .counter-item {
          width: 25%; } }
`, "",{"version":3,"sources":["webpack://./src/components/counter-info/Counter.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc,EAAA;EACd;IAHF;MAII,cAAc,EAAA,EAiCjB;EA/BC;IANF;MAOI,wBAAwB,EAAA,EA8B3B;EArCD;IAUI,SAAS;IACT,UAAU;IACV,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB,EAAA;IAfvB;MAiBM,qBAAqB;MACrB,WAAW;MACX,kBAAkB;MASlB,eAAe,EAAA;MA5BrB;QAqBQ,eAAe;QACf,iBAAiB,EAAA;MAtBzB;QAyBQ,eAAe;QACf,iBAAiB,EAAA;MAGnB;QA7BN;UA8BQ,UAAU,EAAA,EAKb","sourcesContent":[".counter-items-container {\n  padding: 50px 0 60px 0;\n  margin: 0 20px;\n  @media screen and (min-width: 768px) {\n    margin: 0 26px;\n  }\n  @media screen and (min-width: 768px) {\n    padding: 117px 0 128px 0;\n  }\n  .counter-items {\n    margin: 0;\n    padding: 0;\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    align-items: center;\n    .counter-item {\n      list-style-type: none;\n      width: 100%;\n      text-align: center;\n      h1 {\n        font-size: 40px;\n        line-height: 45px;\n      }\n      p {\n        font-size: 16px;\n        line-height: 25px;\n      }\n      padding: 20px 0;\n      @media screen and (min-width: 768px) {\n        width: 25%;\n      }\n      // @media screen and (min-width: 1024px) {\n      //     width: 25%;\n      // }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
