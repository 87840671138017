import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames';

import YesNoDialog from '../../../components/yes-no-dialog';
import validateForm from "./validateForm.helper";
import { createSwapAPI, createUatSwapAPI } from '../../../services/api';
import Notify from '../../../components/notify';
import Loader from '../../../components/loader';
import {
  generateTermLink,
  getFrameImageUrl,
  getViewName,
  getPageTitle
} from '../../../helpers/customRedeem';
import View1WelcomeSection from './view1/WelcomeSection';
import View2WelcomeSection from './view2/WelcomeSection';
import View1FormTitleSection from './view1/FormTitleSection';
import View2FormTitleSection from './view2/FormTitleSection';
import View1BottomBarSection from './view1/BottomBarSection';
import View2BottomBarSection from './view2/BottomBarSection';

import './customRedeem.scss';

const CustomRedeemFinalStep = () => {
  const [promotionId, setpromotionId]           = useState('');
  const [firstName, setFirstName]               = useState('');
  const [lastName, setLastName]                 = useState('');
  const [email, setEmail]                       = useState('');
  const [mobile, setMobile]                     = useState('');
  const [confirmMobile, setConfirmMobile]       = useState('');
  const [successMessage, setSuccessMessage]     = useState('');
  const [testMode, setTestMode]                 = useState(1);
  const [openDialog, setOpenDialog]             = useState(false);
  const [loading, setLoading]                   = useState(false);
  const [disableButton, setDisableButton]       = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [openNotification, setOpenNotification] = useState(false);
  const [redeemPayload, setRedeemPayload]       = useState({});
  const [errors, setErrors]                     = useState({});
  const { promotionId: promotionid } = useParams();
  const viewName = getViewName(promotionid);

  const cardDetails = useSelector((state) => state.cardRedeem.cardDetails);
  const history = useHistory();

  useEffect(() => {
    //SET TITLE
    document.title = getPageTitle(promotionid);
  }, []);

  useEffect(() => {
    if (cardDetails) {
      const name = cardDetails?.name?.split(' ') || [];
      const email = cardDetails?.email || '';
      const mobile = cardDetails?.mobile || '';
      const promotionId = cardDetails?.promotion_id || '';

      setFirstName(name?.[0] || '');
      setLastName(name?.[1] || '')
      setEmail(email);
      setMobile(mobile);
      setConfirmMobile(mobile);
      setpromotionId(promotionId);
    }
  }, [cardDetails]);

  useEffect(() => {
    if (mobile && mobile.length !== 10) {
      setValidationErrors({
        ...validationErrors,
        hasError: true,
        mobile: true
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        mobile: false
      });
    }
  }, [mobile]);

  const handleSubmit = async() => {
    const data = {
      cardStatus: cardDetails?.card_status || '',
      cardDetails,
      promotionId: cardDetails?.promotion_id || '',
      firstName,
      lastName,
      email,
      mobile,
      testMode
    };

    const validate = validateForm({...data, confirmMobile});
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    setRedeemPayload(data);
    setOpenDialog(true);
  };

  const onChange = (value) => {
    if (value) {
      setDisableButton(false);
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleYes = async () => {
    try {
      setLoading(true);
      handleCloseDialog();

      if (
        cardDetails?.card_status !== 'active' &&
        cardDetails?.card_status !== 'originated'
      ) {
        setErrors({
          errormessage: 'This card is not eligible to proceed'
        });

        setOpenNotification(true);
        setLoading(false);
        return;
      }

      if (promotionId === '11459') {
        await createUatSwapAPI(redeemPayload);
      } else {
        await createSwapAPI(redeemPayload);
      }

      setLoading(false);
      history.push(`/${promotionid}/redeem/success`);
    } catch(err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Swap Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  const renderView2Form = () => {
    return (
      <div className="form-container">
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.mobile
          })}
        >
          <label
            htmlFor="mobile-number"
            className={classNames('custom-label', {
              'apia-text-color apia-font': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            Mobile Number
          </label>
          <input
            id="mobile-number"
            type="number"
            className="custom-input"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.confirmMobile
          })}
        >
          <label
            htmlFor="confirm-mobile-number"
            className={classNames('custom-label', {
              'apia-text-color apia-font': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            Confirm Number
          </label>
          <input
            id="confirm-mobile-number"
            type="number"
            className="custom-input "
            value={confirmMobile}
            onChange={(e) => setConfirmMobile(e.target.value)}
          />
        </div>
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.firstName
          })}
        >
          <label
            htmlFor="firstname"
            className={classNames('custom-label', {
              'apia-text-color apia-font ': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            First Name
          </label>
          <input
            id="firstname"
            type="text"
            className="custom-input"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.lastName
          })}
        >
          <label
            htmlFor="lastname"
            className={classNames('custom-label', {
              'apia-text-color apia-font': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            Last Name
          </label>
          <input
            id="lastname"
            type="text"
            className="custom-input"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.email
          })}
        >
          <label
            htmlFor="email"
            className={classNames('custom-label', {
              'apia-text-color apia-font': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            Email
          </label>
          <input
            id="email"
            type="email"
            className="custom-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        {viewName === 'view2' && (
          <span
            className={classNames('meta-form-info', {
              'apia-font': promotionid === '11249',
              'suncorp-font': promotionid === '11248'
            })}
          >
            {promotionid === '11248'
              ? `These details are sent to TCN to set up access to your card. By
            submitting your details, you confirm your request to receive a
            digital Prepaid Visa card containing your Brighter Rewards balance
            from TCN.`
              : `These details are shared with TCN to set up access to your card. By submitting your details, you confirm your request to receive a digital Prepaid Visa
              card containing your Apia Rewards balance from TCN.`}
          </span>
        )}

        <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6LciNiQhAAAAACaOHFxUEadUjP7s6h491E9mFADP"
            onChange={onChange}
          />
        </div>
        <div
          className={classNames('custom-btn-holder', {
            'apia-button-color': promotionid === '11249',
            'suncorp-button-color': promotionid === '11248'
          })}
        >
          <input
            type="submit"
            value="Submit"
            onClick={handleSubmit}
            disabled={disableButton}
          />
        </div>
      </div>
    );
  }

  const renderView1Form = () => {
    return (
      <div className="form-container">
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.mobile
          })}
        >
          <label
            htmlFor="mobile-number"
            className={classNames('custom-label', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
          >
            Mobile number <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="mobile-number"
            type="number"
            className="custom-input"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div
          className={classNames('custom-form-control', {
            'custom-form-control--error': validationErrors.confirmMobile
          })}
        >
          <label
            htmlFor="confirm-mobile-number"
            className={classNames('custom-label', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
          >
            Confirm mobile number <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            id="confirm-mobile-number"
            type="number"
            className="custom-input "
            value={confirmMobile}
            onChange={(e) => setConfirmMobile(e.target.value)}
          />
        </div>

        <TextField
          className="text_field_black"
          error={validationErrors.firstName}
          id="standard-number"
          label="First name"
          type="text"
          name="firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />

        <TextField
          className="text_field_black"
          error={validationErrors.lastName}
          id="standard-number"
          label="Last name"
          type="text"
          name="lastName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        <TextField
          className="text_field_black"
          error={validationErrors.email}
          id="standard-number"
          label="Email"
          type="text"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6LciNiQhAAAAACaOHFxUEadUjP7s6h491E9mFADP"
            onChange={onChange}
          />
        </div>
        <div className="btn-action">
          <Button
            className={classNames({
              'btn-submit-aami': promotionid === '11246',
              'btn-submit-gio': promotionid === '11247'
            })}
            variant="outlined"
            onClick={handleSubmit}
            disabled={disableButton}
            endIcon={
              loading ? (
                <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
              ) : null
            }
          >
            SUBMIT
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="redeem-page-wrapper">
        <div className="redeem-header mx-auto">
          <img src={getFrameImageUrl(promotionid)} alt="Redeem Image" />
        </div>
        {viewName === 'view1' ? (
          <View1WelcomeSection
            cardDetails={cardDetails}
            promotionid={promotionid}
          />
        ) : (
          <View2WelcomeSection promotionid={promotionid} />
        )}

        <div
          className="form-wrapper"
          style={{
            backgroundColor: viewName === 'view2' ? '#FFFFFF' : '#F5F5F5'
          }}
        >
          <div className="form mx-auto">
            {viewName === 'view1' && (
              <View1FormTitleSection promotionid={promotionid} />
            )}
            {viewName === 'view2' && (
              <View2FormTitleSection promotionid={promotionid} />
            )}

            {viewName === 'view1' ? renderView1Form() : renderView2Form()}
          </div>
        </div>
        {viewName === 'view1' && (
          <View1BottomBarSection
            promotionid={promotionid}
            termLink={generateTermLink(promotionid)}
          />
        )}
        {viewName === 'view2' && (
          <View2BottomBarSection promotionid={promotionid} />
        )}
      </div>

      <YesNoDialog
        openDialog={openDialog}
        message={
          cardDetails?.card_current_value === '0.00'
            ? 'Your card has already been redeemed.'
            : 'Please confirm you would like to submit your details.'
        }
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
        title=""
        showCloseButton={cardDetails?.card_current_value === '0.00'}
      />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
        successMessage={successMessage}
      />
      <Loader loading={loading} />
    </>
  );
};

export default CustomRedeemFinalStep;
