import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getTcnCustomerId } from '../../../../../helpers/users';
import { getUserPermissionType } from '../../../../../helpers/role';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id: data[i]?.id || '',
      status: data[i]?.active ? "Active" : "Inactive",
      username: data[i]?.username || '',
      email: data[i]?.email || '',
      firstName: data[i]?.first_name || '',
      lastName: data[i]?.last_name || '',
      customerId: data[i]?.customer_id,
      tcnCustomerId: data[i]?.tcn_customer_id || '',
      enableMfa: data[i]?.enable_mfa,
      departmentId: data[i]?.departmentId,
      departmentName: data[i]?.department?.name || '',
      lastLogin: data[i].last_login || '',
      tcnCustomerEnv: data[i].tcn_customer_env || '',
      crownSsoFlag: data[i]?.crownSsoFlag || false,
      ssoId: data[i]?.sso_id || '',
      type: Number.isInteger(data[i]?.type)
        ? getUserPermissionType(
            data[i].type,
            data[i]?.tcn_customer_id,
            data[i]?.customer_id
          )
        : '',
      createdAt: data[i]?.created_at || ''
    };

    rows.push(row);
  }
  return rows;
};

const UserListTable = ({
  userList,
  users,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalRowCount,
  page
}) => {
  const classes = useStyles();
  const rows = users?.length > 0 ? createRows(users) : [];

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper} className="table-container">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Id </TableCell>
                <TableCell align="left">Email </TableCell>
                <TableCell align="left">Username</TableCell>
                <TableCell align="left">Environment</TableCell>
                <TableCell align="left">TcnCustomerId</TableCell>
                <TableCell align="left">CustomerId</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">MFAEnabled</TableCell>
                <TableCell align="left">Department</TableCell>
                <TableCell align="left">Permission</TableCell>
                <TableCell align="left">SsoFlag</TableCell>
                <TableCell align="left">SsoId</TableCell>
                <TableCell align="left">LastLogin</TableCell>
                <TableCell align="left">CreatedAt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.username}</TableCell>
                    <TableCell align="left">{row.tcnCustomerEnv}</TableCell>
                    <TableCell align="left">
                      {getTcnCustomerId(userList, row)}
                    </TableCell>
                    <TableCell align="left">{row.customerId}</TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      {row.enableMfa === 1 ? 'true' : 'false'}
                    </TableCell>
                    <TableCell align="left">{row.departmentName}</TableCell>
                    <TableCell align="left">{row.type}</TableCell>
                    <TableCell align="left">{row.crownSsoFlag.toString()}</TableCell>
                    <TableCell align="left">{row.ssoId}</TableCell>
                    <TableCell align="left">
                      {row.lastLogin
                        ? format(parseISO(row.lastLogin), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>
                    <TableCell align="left">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 1 }}>
                  <TableCell colSpan={6}>
                    {' '}
                    No data available in table{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

UserListTable.propTypes = {
  users: PropTypes.array,
  userList: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRowCount: PropTypes.number
};

export default UserListTable;
