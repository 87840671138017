import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  getRedeemImageUrl,
} from '../../../../helpers/customRedeem';

const WelcomeSection = ({ cardDetails, promotionid }) => {
    const fullName = cardDetails?.name?.split(' ');
    const firstName = fullName?.length > 0 ? fullName[0] : '';

  return (
    <div className="greeting-message">
      <div className="greeting-message-content mx-auto">
        <div
          className={classNames('greeting-message-text', {
            'aami-font': promotionid === '11246',
            'secondary-font': promotionid === '11247'
          })}
        >
          Hi {firstName}, follow the steps below to download your digital
          Prepaid Visa card!
        </div>
        <div className="greeting-message-image">
          <img src={getRedeemImageUrl(promotionid)} alt="Redeem Image" />
        </div>
      </div>
    </div>
  );
};

WelcomeSection.propTypes = {
  cardDetails: PropTypes.object,
  promotionid: PropTypes.string
};

export default WelcomeSection;
