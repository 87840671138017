import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Button, makeStyles, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id                 : data[i]?.id || '',
      ipAddress          : data[i]?.ipAddress || '',
      tcnCustomerId      : data[i]?.tcnCustomerId || '',
      testEnvironmentFlag: data[i]?.testEnvironmentFlag || '',
      status             : data[i]?.status === 1 ? "Active" : "Inactive",
      createdAt          : data[i]?.created_at || ''
    };

    rows.push(row);
  }
  return rows;
};

const ManageIpListTable = ({
  ipList,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalRowCount,
  page
}) => {
  const classes = useStyles();
  const rows = ipList?.length > 0 ? createRows(ipList) : [];
  const history = useHistory();

  const redirectToEditWhiteListIp = (manageIpId) => {
    history.push(`/manage-whitelist-ips/edit/${manageIpId}`);
  }

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper} className="table-container">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Id </TableCell>
                <TableCell align="left">TCNCustomerId </TableCell>
                <TableCell align="left">IpAddress</TableCell>
                <TableCell align="left">TestEnvironment</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">CreatedAt</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{row.tcnCustomerId}</TableCell>
                    <TableCell align="left">{row.ipAddress}</TableCell>
                    <TableCell align="left">
                      {row?.testEnvironmentFlag?.toString() || ''}
                    </TableCell>
                    <TableCell align="left">{row.status}</TableCell>
                    <TableCell align="left">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>
                    <TableCell align="left">
                    <Button
                      variant="outlined"
                      onClick={() => redirectToEditWhiteListIp(row.id)}
                      className="w-100"
                    >
                      Edit
                    </Button>
                  </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 1 }}>
                  <TableCell colSpan={6}>
                    {' '}
                    No data available in table{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

ManageIpListTable.propTypes = {
  ipList: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRowCount: PropTypes.number
};

export default ManageIpListTable;
