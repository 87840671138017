import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

const issueCard = createSlice({
  name: 'issueCard',
  initialState,
  reducers: {
    setIssueCardData(state, action) {
      state.data = action.payload;
    },
  }
});

export const { setIssueCardData } = issueCard.actions;

export default issueCard.reducer;
