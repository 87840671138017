import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  getRedeemImageUrl,
  getStep1ImageUrl,
  getStep2ImageUrl,
  getStep3ImageUrl,
  getBrandThemeColor,
} from '../../../../helpers/customRedeem';

const WelcomeSection = ({ cardDetails, promotionid }) => {
  const fullName = cardDetails?.name?.split(' ');
  const firstName = fullName?.length > 0 ? fullName[0] : '';

  return (
    <>
      <div className="greeting-message">
        <div className="greeting-message-content mx-auto">
          <div
            className={classNames('greeting-message-text', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
            // className="greeting-message-text secondary-font"
          >
            Hi {firstName}, follow the steps below to download your digital
            Prepaid Visa card!
          </div>
          <div className="greeting-message-image">
            <img src={getRedeemImageUrl(promotionid)} alt="Redeem Image" />
          </div>
        </div>
      </div>

      <div className="redeem-steps-wrapper mx-auto">
        <div
          className={classNames('redeem-step-text', {
            'aami-font': promotionid === '11246',
            'secondary-font': promotionid === '11247'
          })}
          // className="redeem-step-text secondary-font"
          style={{ color: getBrandThemeColor(promotionid) }}
        >
          {promotionid === '11246'
            ? `It's as easy as 1, 2, 3!`
            : `You'll know with 3 simple steps!`}
        </div>
        <div className="redeem-steps">
          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep1ImageUrl(promotionid)} alt="step-1" />
            </div>
            <div className="redeem-circle-text">
              <p
                className={classNames({
                  'aami-font': promotionid === '11246',
                  'secondary-font': promotionid === '11247'
                })}
                // className="secondary-font"
              >
                Enter your mobile number to receive an SMS with a link to
                download The Card Network (TCN) App.
              </p>
            </div>
          </div>

          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep2ImageUrl(promotionid)} alt="step-2" />
            </div>
            <div className="redeem-circle-text">
              <p
                className={classNames({
                  'aami-font': promotionid === '11246',
                  'secondary-font': promotionid === '11247'
                })}
                // className="secondary-font"
              >
                You&apos;ll then see your digital Prepaid Visa card in the TCN
                App.
              </p>

              <br />

              <p
                className={classNames({
                  'aami-font': promotionid === '11246',
                  'secondary-font': promotionid === '11247'
                })}
                // className="secondary-font"
              >
                Already have the App? The link will give you access to your
                personalised card.
              </p>
            </div>
          </div>

          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep3ImageUrl(promotionid)} alt="step-3" />
            </div>
            <div className="redeem-circle-text">
              <p
                className={classNames({
                  'aami-font': promotionid === '11246',
                  'secondary-font': promotionid === '11247'
                })}
                // className="secondary-font"
              >
                Add your digital Prepaid Visa card to your phone’s wallet to
                spend your reward credit anywhere Visa is accepted.
              </p>
            </div>
          </div>
        </div>

        <div className="redeem-step-bottom">
          <div
            className={classNames('redeem-step-bottom-text', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
            // className="redeem-step-bottom-text secondary-font"
          >
            The link within your SMS will expire three months after you receive
            it. You must download your digital Prepaid Visa card within this
            time, or you will lose the rewards balance within the card.{' '}
          </div>
        </div>
      </div>
    </>
  );
};

WelcomeSection.propTypes = {
  cardDetails: PropTypes.object,
  promotionid: PropTypes.string
};

export default WelcomeSection;