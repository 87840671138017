import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "../../../../assets/images/search-yellow.svg";
import SelectInput from "../../../../components/Select";

const FilterHeader = ({
  filter,
  isLoading,
  customerInfo,
  agents,
  departments,
  handleFilter,
  handleExportTable,
  handleClearFilter,
  handleSetFilter
}) => {

  const filterValue = () => {
    handleFilter();
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="table-filter-body">
            <div className="form-container">
              <div className="flex add-spacing">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Campaign
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="promotion"
                    value={filter.promotion}
                    onChange={(val) => handleSetFilter({ promotion: val })}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {customerInfo?.promotion?.map((promotion) => (
                      <MenuItem
                        key={promotion.promotion_id}
                        value={promotion.promotion_id}
                      >
                        {promotion.promotion_id}&nbsp;-&nbsp;
                        {promotion.promotion_name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard">
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="Reference ID"
                    name="referenceId"
                    value={filter.referenceId}
                    onChange={(e) =>
                      handleSetFilter({ referenceId: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon}></img>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    User
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="agent"
                    value={filter.agent}
                    onChange={(val) => handleSetFilter({ agent: val })}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {agents?.map((agent) => (
                      <MenuItem key={agent.id} value={agent.username}>
                        {agent.username}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Department
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="department"
                    value={filter.departmentId}
                    onChange={(val) => handleSetFilter({ departmentId: val })}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {departments?.map((depart) => (
                      <MenuItem key={depart.id} value={depart.name}>
                        {depart.name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
              </div>
              <div className="flex add-spacing field-container">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Card Cancelled
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="cardCancel"
                    value={filter.cardCancel}
                    onChange={(val) => handleSetFilter({ cardCancel: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'1'}>YES</MenuItem>
                    <MenuItem value={'0'}>NO</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Product
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="productId"
                    value={filter.productId}
                    onChange={(val) => handleSetFilter({ productId: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    {customerInfo?.product?.map((promotion) => (
                      <MenuItem
                        key={promotion.product_id}
                        value={promotion.product_id}
                      >
                        {promotion.product_id}&nbsp;-&nbsp;
                        {promotion.product_name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
                <TextField
                  id="standard-number"
                  label="Start Serial"
                  type="number"
                  name="serialStart"
                  value={filter.serialStart}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) =>
                    handleSetFilter({ serialStart: e.target.value })
                  }
                />
                <TextField
                  id="standard-number"
                  label="End Serial"
                  type="number"
                  name="serialEnd"
                  value={filter.serialEnd}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) =>
                    handleSetFilter({ serialEnd: e.target.value })
                  }
                />
              </div>
              <div className="flex add-spacing">
                <div className="agen-issuance-button-container date-container">
                  <span>Card Agent Issuance Date</span>
                  <DatePicker
                    selectsRange={true}
                    startDate={filter.cardIssuedStartDate}
                    endDate={filter.cardIssuedEndDate}
                    onClickOutside={() =>
                      handleSetFilter({
                        cardIssuedStartDate: null,
                        cardIssuedEndDate: null
                      })
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(update) => {
                      handleSetFilter({
                        cardIssuedStartDate: update[0],
                        cardIssuedEndDate: update[1]
                      });
                    }}
                    isClearable={true}
                    className="date-picker-field campaign-input"
                  />
                </div>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Card Type
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="cardType"
                    value={filter.cardType}
                    onChange={(val) => handleSetFilter({ cardType: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={'physical'}>Physical</MenuItem>
                    <MenuItem value={'digital'}>Digital</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard" style={{ maxWidth: 450 }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Search
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="searchParams"
                    value={filter.searchParams}
                    onChange={(val) => handleSetFilter({ searchParams: val })}
                  >
                    <MenuItem value="firstname">First Name</MenuItem>
                    <MenuItem value="lastname">Last Name</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="company">Company</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard" style={{ maxWidth: 450 }}>
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="Search"
                    name="search"
                    value={filter.search}
                    inputProps={{ readOnly: !filter?.searchParams }}
                    onChange={(e) =>
                      handleSetFilter({ search: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon}></img>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </div>
              <div className="issued-card-filter-buttons">
                <div className="filter-buttons">
                  <Button variant="outlined" className="" onClick={filterValue}>
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleClearFilter}
                  >
                    Clear Filters
                  </Button>
                </div>
                <div className="export-buttons">
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleExportTable}
                  >
                    Export Issued Cards
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.object,
  customerInfo: PropTypes.object,
  agents: PropTypes.object,
  departments: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  handleExportTable: PropTypes.func,
  handleClearFilter: PropTypes.func
};


export default FilterHeader;