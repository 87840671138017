import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  loading: false,
  error: {},
  bulkCardData: {},
  createInBulkSuccess: false,
  bulkCardFormData: {}
};

const cardValidation = createSlice({
  name: 'cardValidation',
  initialState,
  reducers: {
    validateCards(state, action) {
      state.bulkCardFormData = {
        file: action?.payload?.file || '',
        fileName: action?.payload?.fileName || '',
        orderReference: action?.payload?.orderReference || ''
      };
      state.loading = true;
      state.createInBulkSuccess=false;
    },
    validateCardsSuccess(state, action) {
      state.loading = false;
      state.createInBulkSuccess = false;
      state.data = action.payload;
    },
    validateCardFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    createCardsInBulk(state, action) {
      state.loading = true;
    },
    addBulkCardResponse(state, action) {
      state.bulkCardData = action.payload;
    },
    createCardsInBulkSuccess(state, action) {
      state.createInBulkSuccess=true;
      state.loading = false;
    },
    createCardsInBulkFailure(state, action) {
      state.createInBulkSuccess = false;
      state.loading = false;
    },

  }
});

export const {
  validateCards,
  validateCardsSuccess,
  validateCardFailure,
  createCardsInBulk,
  createCardsInBulkSuccess,
  createCardsInBulkFailure,
  addBulkCardResponse
} = cardValidation.actions;

export default cardValidation.reducer;
