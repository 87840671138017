import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  whiteListIps: [],
  loading: false,
  error: [],
};

const whiteListIps = createSlice({
  name: 'whiteListIps',
  initialState,
  reducers: {
    getAllWhitListIps(state) {
      state.loading = true;
    },
    getAllWhitListIpsSuccess(state, action) {
      state.loading = false;
      state.whiteListIps = action?.payload || [];
    },
    getAllWhitListIpsFailure(state) {
      state.loading = false;
    }
  }
});

export const {
  getAllWhitListIps,
  getAllWhitListIpsSuccess,
  getAllWhitListIpsFailure
} = whiteListIps.actions;

export default whiteListIps.reducer;
