import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FooterSection = ({ thankyouRef, termLink, promotionid }) => {
  const renderFooter = () => {
    if (promotionid === '11246') {
      return (
        <div
          className={classNames('thankyou-footer-text mx-auto', {
            'aami-font': promotionid === '11246',
            'secondary-font': promotionid === '11247'
          })}
          // className="thankyou-footer-text mx-auto secondary-font"
        >
          The Card Network (“TCN”) is the issuer of the digital Prepaid Visa
          Card (“Card”). You are responsible for all transactions on the Card,
          except where there has been fraud or negligence by TCN staff or
          agents. TCN may restrict or stop the use of the Card if suspicious
          activities are noticed. You are responsible for checking your
          transaction history, knowing the available balance and date of expiry
          for the Card. Balance and transaction history are available to you
          within the TCN App. Any changes to the Terms and Conditions can be
          viewed{' '}
          <a
            href="https://thinkwink.com.au/tos"
            className="text-blue"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . Information will be disclosed to third parties about the Card, or
          transactions made with the Card, whenever allowed by law, and also
          where necessary to operate the Card and process transactions. TCN’s
          privacy policy can be viewed{' '}
          <a
            href="https://thecardnetwork.com.au/pages/privacy-policy"
            className="text-blue"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </div>
      );
    } else {
      return (
        <div className="thankyou-footer-text mx-auto secondary-font">
          The Card Network (“TCN”) is the issuer of the digital Prepaid Visa
          Card (“Card”). You are responsible for all transactions on the Card,
          except where there has been fraud or negligence by TCN staff or
          agents. TCN may restrict or stop the use of the Card if suspicious
          activities are noticed. You are responsible for checking your
          transaction history, knowing the available balance and date of expiry
          for the Card. Balance and transaction history are available to you
          within the TCN App. Any changes to the Terms and Conditions can be
          viewed{' '}
          <a
            href="https://thinkwink.com.au/tos"
            className="text-blue"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . Information will be disclosed to third parties about the Card, or
          transactions made with the Card, whenever allowed by law, and also
          where necessary to operate the Card and process transactions. TCN’s
          privacy policy can be viewed{' '}
          <a
            href="https://thecardnetwork.com.au/pages/privacy-policy"
            className="text-blue"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </div>
      );
    }
  }


  return (
    <div className="thankyou-text-wrapper" ref={thankyouRef}>
      <div className="thankyou-content">
        <div
          className={classNames('thankyou-title', {
            'aami-font': promotionid === '11246',
            'secondary-font': promotionid === '11247'
          })}
          // className="thankyou-title secondary-font"
        >
          Thank you. You should receive an SMS soon.
        </div>
        <div className="thankyou-subtitle ">
          <div
            className={classNames({
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
            // className="secondary-font"
          >
            Follow the steps in the SMS to complete the download. The link
            within your SMS will expire three months after you receive it. You
            must download your digital Prepaid Visa card within this time, or
            you will lose the rewards balance within the card.
          </div>

          <div className="mt-3 secondary-font">
            If you need help downloading your digital Prepaid Visa card, get in
            touch with the support team at The Card Network on{' '}
            <span
              className={classNames({
                'text-red': promotionid === '11246',
                'text-gio-blue': promotionid === '11247'
              })}
            >
              1300 375 346
            </span>
            .
          </div>

          <div
            className={classNames('mt-3', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
            // className="mt-3 secondary-font"
          >
            Available Monday to Friday, 9am – 6pm and Saturday, 10am – 5pm AEDT.{' '}
          </div>
        </div>

        {renderFooter()}
      </div>
    </div>
  );
};

FooterSection.propTypes = {
  thankyouRef: PropTypes.PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  termLink: PropTypes.string,
  promotionid: PropTypes.string
};

export default FooterSection;
