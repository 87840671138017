// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-container {
  text-align: left; }
  @media screen and (min-width: 768px) {
    .date-container {
      margin-right: 24px; } }
  .date-container span {
    color: white;
    width: 100px;
    font-size: 19px; }
  .date-container .date-picker-field {
    background-color: black;
    border: none;
    border-bottom: 1px solid white;
    min-width: 240px;
    width: 100%;
    color: white;
    min-width: 500px; }
`, "",{"version":3,"sources":["webpack://./src/pages/global-read-only-tables/users/user-list/filter-header/FilterHeader.scss"],"names":[],"mappings":"AAAA;EAEA,gBAAgB,EAAA;EAChB;IAHA;MAKE,kBAAkB,EAAA,EAgBnB;EArBD;IAQE,YAAY;IACZ,YAAY;IACZ,eAAe,EAAA;EAVjB;IAaE,uBAAuB;IACvB,YAAY;IACZ,8BAA8B;IAC9B,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB,EAAA","sourcesContent":[".date-container {\n// width: 100%;\ntext-align: left;\n@media screen and (min-width: 768px) {\n  // width: calc( 50% - 24px );\n  margin-right: 24px;\n}\nspan {\n  color: white;\n  width: 100px;\n  font-size: 19px;\n}\n.date-picker-field {\n  background-color: black;\n  border: none;\n  border-bottom: 1px solid white;\n  min-width: 240px;\n  width: 100%;\n  color: white;\n  min-width: 500px;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
