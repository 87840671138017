import React from 'react';
import bannerImg from '../../../assets/images/banner-image.png';
import './Banner.scss';
const Dashboard = () => {
  return (
    <>
      <section className="banner-container">
        <div className="banner-text-container">
          <h2>Corporate Portal</h2>
        </div>
      </section>
    </>
  );
}

export default Dashboard;