import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import YesNoDialog from '../../../../components/yes-no-dialog';
import {
  validateSendEmailForm,
  validateDeactivateForm
} from './validateForm.helper';
import { getCardAPI } from '../../../../services/api';
import "./CardModal.scss";
import { hasAdminUserPermissionForCTA } from '../../../../helpers/permission';

const CardModel = ({
  selectedCard,
  modalIsOpen,
  closeModal,
  handleDeactiveCard,
  handleResendEmail,
  handleSendNote,
  handleViewNoteModal,
  customerInfo
}) => {
  const [cardInfo, setCardInfo]                 = useState({});
  const [cardActionNote, setCardActionNote]     = useState('');
  const [openDialog, setOpenDialog]             = useState(false);
  const [dialogParams, setDialogParams]         = useState({});
  const [email, setEmail]                       = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading]                   = useState(true);
  let showDeactivateBtn = false;
  let showSendEmailBtn = false;
  const showCardUsedErrorMessage =
    cardInfo.card_current_value !== cardInfo.card_initial_value;

  if (
    (selectedCard.cardStatus === 'originated' ||
      selectedCard.cardStatus === 'active') &&
    cardInfo.card_current_value === cardInfo.card_initial_value &&
    (selectedCard.requestHandle === '1' ||
      selectedCard.requestHandle === '3' ||
      selectedCard.requestHandle === '5')
  ) {
    showDeactivateBtn = true;
  }

  if (
    (selectedCard.cardStatus === 'originated' ||
      selectedCard.cardStatus === 'active') &&
    selectedCard.requestHandle === '3'
  ) {
    showSendEmailBtn = true;
  }

  useEffect(() => {
    if (modalIsOpen) {
      getCardInfo();
      setEmail(selectedCard.email);
    }
  }, [modalIsOpen]);

  const getCardInfo = async () => {
    try {
      const card = await getCardAPI(selectedCard.cardId);
      setCardInfo(card.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const sendEmail = () => {
    const validate = validateSendEmailForm({ email, cardActionNote });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    handleResendEmail(email);
    sendNote();
  };

  const deactivateCard = () => {
    const validate = validateDeactivateForm({ email, cardActionNote });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleDeactiveCard(cardActionNote);
    sendNote();
  };

  const sendNote = () => {
    if(!cardActionNote) {
      setValidationErrors({ hasError: true, cardActionNote: true});
      return;
    }
    handleSendNote(cardActionNote);
  }

  const handleOpenDialog = (params) => {
    setDialogParams(params);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDialogParams({});
  };

  const handleYes = () => {
    if (dialogParams.deactivate) {
      deactivateCard();
    }

    if (dialogParams.sendEmail) {
      sendEmail();
    }

    handleCloseDialog();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Card Modal"
      className="modal"
    >
      {loading ? (
        <div>
          <h3 className="mt-2">Loading...</h3>
        </div>
      ) : (
        <>
          <div>
            <h2>Card Actions </h2>
            <TableContainer className="mt-2 mb-2">
              <Table className="" aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell>Card Id</TableCell>
                    <TableCell>{selectedCard.cardId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recipient Name</TableCell>
                    <TableCell>{selectedCard.recipientName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Campaign Id</TableCell>
                    <TableCell>{selectedCard.campaign}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Campaign Name</TableCell>
                    <TableCell>{selectedCard.campaignName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Product Name</TableCell>
                    <TableCell>{cardInfo.product_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Delivery Method</TableCell>
                    <TableCell>{selectedCard.deliveryMethod}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card Initial Value</TableCell>
                    <TableCell>{cardInfo.card_initial_value}</TableCell>
                  </TableRow>
                  {
                    customerInfo?.customer?.[0]?.AllowTcncpTxLive === "TRUE" &&
                    <TableRow>
                      <TableCell>Card Current Value</TableCell>
                      <TableCell>{cardInfo.card_current_value}</TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {
              <>
                <TextField
                  id="standard-basic"
                  label="Resend Email to:"
                  type="text"
                  name="email"
                  helperText="*Is required."
                  error={validationErrors.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="resend-email-field"
                />

                <TextField
                  id="standard-basic"
                  label="Note"
                  type="text"
                  name="cardActionNote"
                  helperText="*Is required."
                  value={cardActionNote}
                  error={validationErrors.cardActionNote}
                  onChange={(e) => setCardActionNote(e.target.value)}
                  className="resend-email-field"
                />
              </>
            }

            {showCardUsedErrorMessage && (
              <Alert severity="error">Card has already been used.</Alert>
            )}
            {!showDeactivateBtn && !showSendEmailBtn && (
              <Alert severity="error">
                {' '}
                No actions are available for this card.
              </Alert>
            )}

            <div className="button-container">
              <Button
                variant="outlined"
                color="primary"
                onClick={handleViewNoteModal}
              >
                View Notes
              </Button>

              {showDeactivateBtn && (
                <Button
                  variant="outlined"
                  onClick={() =>
                    handleOpenDialog({
                      message: `Are you sure you want to deactivate card?`,
                      deactivate: true,
                      sendEmail: false
                    })
                  }
                  color="secondary"
                  disabled={!hasAdminUserPermissionForCTA()}
                >
                  Deactivate Card
                </Button>
              )}

              {showSendEmailBtn && (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!hasAdminUserPermissionForCTA()}
                  onClick={() =>
                    handleOpenDialog({
                      message: `Are you sure you want to send your card to: ${email}`,
                      deactivate: false,
                      sendEmail: true
                    })
                  }
                >
                  Send Email
                </Button>
              )}

              <Button variant="outlined" onClick={closeModal} color="primary">
                Close
              </Button>
            </div>
          </div>
        </>
      )}
      <YesNoDialog
        openDialog={openDialog}
        message={dialogParams.message}
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
      />
    </Modal>
  );
};

CardModel.propTypes = {
  selectedCard       : PropTypes.object,
  customerInfo       : PropTypes.object,
  modalIsOpen        : PropTypes.bool,
  closeModal         : PropTypes.func,
  customStyles       : PropTypes.object,
  handleDeactiveCard : PropTypes.func,
  handleResendEmail  : PropTypes.func,
  handleSendNote     : PropTypes.func,
  handleViewNoteModal: PropTypes.func
};

export default CardModel;
