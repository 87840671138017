// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agent-issuance-log-table-container {
  margin-top: 90px; }
  .agent-issuance-log-table-container .table-wrapper {
    padding: 40px; }
    .agent-issuance-log-table-container .table-wrapper .table-container {
      margin-top: 5rem; }
`, "",{"version":3,"sources":["webpack://./src/pages/global-read-only-tables/agent-issuance-logs/AgentIssuanceLogs.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;EADlB;IAGI,aAAa,EAAA;IAHjB;MAKM,gBAAgB,EAAA","sourcesContent":[".agent-issuance-log-table-container {\n  margin-top: 90px;\n  .table-wrapper {\n    padding: 40px;\n    & .table-container {\n      margin-top: 5rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
