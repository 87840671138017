import { UserType } from "../constants/userType";
import { getUserType } from "./localStorage";

export const hasAdminUserPermissionForCTA = () => {
  const userType = getUserType();
  return userType === UserType.Administrator || userType === UserType.User;
};

export const hasAdminPermissionForCTA = () => {
  const userType = getUserType();
  return userType === UserType.Administrator;
};
