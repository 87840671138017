import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import PublishSharpIcon from '@material-ui/icons/PublishSharp';
import { Info } from '@material-ui/icons';

import { TCNCP_API } from '../../../../services/axios';
import { hasAdminUserPermissionForCTA } from '../../../../helpers/permission';
import validateForm from './validateForm.helper';
import ConfirmationDialog from './confirmation-dialog';
import 'react-datepicker/dist/react-datepicker.css';
import './CardModal.scss';
import { getAllIssuanceTypeAssoDepartmentAPI, updateBulkAgentIssueAPI } from '../../../../services/api';
import Loader from '../../../../components/loader';

const CardModel = ({
  modalIsOpen,
  closeModal,
  department,
  departments,
  updateBulkAgentIssueCard,
  customerInfo
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [bulkFileData, setBulkFileData] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [customerIssuanceTypeSource, setCustomerIssuanceTypeSource] = useState('');
  const [customerIssuanceType, setCustomerIssuanceType] = useState('');
  const [issuanceTypes, setIssuanceTypes] = useState([]);

  const customerPrivacyInteger = customerInfo?.customer?.[0]?.customer_privacy_integer;
  const belongsToCustomerPrivacyInteger = ["3", "4"].includes(customerPrivacyInteger);

  const fileInput = useRef(null);

  useEffect(() => {
    if (departments?.length && department) {
      const departmentId = departments.find(depart => depart.name === department)?.id || null;
      getIssuanceTypes(departmentId);
    }
  }, [departments, department]);

  const getIssuanceTypes = async (departmentId) => {
    try {
      const issuanceTypes = await getAllIssuanceTypeAssoDepartmentAPI(departmentId);
      setIssuanceTypes(issuanceTypes);
      //issuanceTypes?.length && setCustomerIssuanceType(issuanceTypes[0].name);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const handleCapture = (event) => {
    if (event?.target?.files?.length > 0) {
      setFile(event.target.files[0]);
    }
  };

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleClick = async () => {
    const validate = validateForm({ customerInfo: customerInfo?.customer?.[0], fileName, file, customerIssuanceTypeSource, customerIssuanceType });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('uploaded_file', file);
    formData.append('fileName', fileName);

    const bulkFileData = await updateBulkAgentIssueAPI(formData, true);
    setBulkFileData(bulkFileData);
    setLoading(false);
    setOpenDialog(true);
  };

  const downloadTemplate = () => {
    try {
      const templateUrl = customerInfo?.customer?.[0]?.customer_issuance_type_flag === 'FALSE'
        ? `${TCNCP_API}/card/download/agentIssuance/template`
        : `${TCNCP_API}/card/download/agentIssuanceIssuanceType/template`;

      window.open(
        templateUrl,
        '_blank'
      );
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const handleCloseDialog = async () => {
    setOpenDialog(false);
  };

  const handleYes = async () => {
    setOpenDialog(false);
    setLoading(true);
    const formData = new FormData();
    formData.append('uploaded_file', file);
    formData.append('fileName', fileName);
    formData.append('department', department);
    formData.append('customerIssuanceTypeSource', customerIssuanceTypeSource);
    formData.append('customerIssuanceType', customerIssuanceType);

    await updateBulkAgentIssueCard(formData, false);
    setLoading(false);
  };

  const renderIssuanceType = () => {
    if (customerIssuanceTypeSource === 'fixed' && belongsToCustomerPrivacyInteger) {
      return (
        <div className="cardActionsForms">
          <FormControl
            variant="standard"
            className="form-control mb-2"
            error={validationErrors.customerIssuanceType}
          >
            <InputLabel shrink id="demo-simple-select-standard-label">
              Issuance Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="customerIssuanceType"
              value={customerIssuanceType}
              onChange={(e) => setCustomerIssuanceType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="">Please select</MenuItem>
              {issuanceTypes?.map((issuanceType) => (
                <MenuItem key={issuanceType.id} value={issuanceType.name}>
                  {issuanceType.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>*Is required.</FormHelperText>
          </FormControl>
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Bulk Card Actions </h2>
          <div className="bulkCardActionsForms">
            <TextField
              id="standard-basic"
              label="File Name"
              type="text"
              name="fileName"
              helperText="*Is required."
              onChange={(e) => setFileName(e.target.value)}
              error={validationErrors.fileName}
            />

            <TextField
              id="standard-basic"
              label="Department"
              type="text"
              name="department"
              helperText="*Is required."
              value={department}
            />

            {customerInfo?.customer?.[0]?.customer_issuance_type_flag !== 'FALSE' && <FormControl
              variant="standard"
              className="form-control mb-2"
              error={validationErrors.customerIssuanceTypeSource}
            >
              <InputLabel id="customerIssuanceTypeSourceLabel">
                Issuance Type Source
              </InputLabel>
              <Select
                labelId="customerIssuanceTypeSourceLabel"
                id="customerIssuanceTypeSource"
                name="customerIssuanceTypeSource"
                value={customerIssuanceTypeSource}
                onChange={(e) => setCustomerIssuanceTypeSource(e.target.value)}
              >
                <MenuItem key="issuanceTypeFile" value="file">File</MenuItem>
                <MenuItem key="issuanceTypeFixed" value="fixed">Fixed Issuance Type</MenuItem>
              </Select>
              <FormHelperText>*Is required.</FormHelperText>
              {customerIssuanceTypeSource === 'fixed'
                && <FormHelperText>Please select from Issuance Type list</FormHelperText>}
            </FormControl>}


            {renderIssuanceType()}

            <div className="upload-file-container flex">
              <input
                accept=".csv, text/csv, .xlsx"
                style={{ display: 'none' }}
                id="contained-button-file"
                onChange={handleCapture}
                type="file"
                ref={fileInput}
              />
              <label htmlFor="contained-button-file">
                <Button
                  variant="outlined"
                  className="file-upload-btn"
                  component="span"
                  startIcon={<PublishSharpIcon />}
                >
                  Upload File
                </Button>
              </label>
              <div className="file-upload-message">
                {file ? file.name : 'No file chosen'}
              </div>
            </div>
          </div>

          <div
            className="flex items-center downloadFile"
            onClick={downloadTemplate}
          >
            <Info
              onClick={downloadTemplate}
              style={{ color: '#FFFB00', marginRight: '5px' }}
            />{' '}
            Download file import template here
          </div>

          <div className="button-container">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClick}
              disabled={!hasAdminUserPermissionForCTA()}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        openDialog={openDialog}
        message={'Are you sure you want to continue?'}
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
        data={{
          fileName,
          bulkFileData,
          department,
          customerIssuanceTypeSource,
          customerIssuanceType
        }}
      />
      <Loader loading={loading} />
    </>
  );
};

CardModel.propTypes = {
  selectedCard: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  updateAgentIssueCard: PropTypes.func,
  updateBulkAgentIssueCard: PropTypes.func,
  department: PropTypes.string,
  departments: PropTypes.array,
  customerInfo: PropTypes.object,
};

export default CardModel;
