import {all} from "redux-saga/effects";
import {cardValidationSaga} from "./saga/cardValidation";
import { userSaga } from './saga/user';
import { departmentSaga } from './saga/department';
import { issuanceTypeSaga } from './saga/issuanceType';
import { whiteListIpsSaga } from './saga/whiteListIps';

export default function* rootSaga () {
  yield all([
    cardValidationSaga(),
    userSaga(),
    departmentSaga(),
    issuanceTypeSaga(),
    whiteListIpsSaga()
  ]);
}