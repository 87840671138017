import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import HeaderPreview from "./HeaderPreview";
import PromotionBudgetExceedPreview from './PromotionBudgetExceedPreview';
import SuccessCardPreview from "./SuccessCardPreview";
import FailedCardPreview from "./FailedCardPreview";
import { createCardsInBulk } from '../../../store/slices/cardValidation';
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import Loader from '../../../components/loader';
import "./BulkCardValidationPreview.scss";
import Notify from '../../../components/notify';

const BulkCardValidationPreview = () => {
  const [promotionBudgetErr, setPromotionBudgetErr] = useState([]);
  const [creditLimitError, setCreditLimitError]     = useState([]);
  const [openNotification, setOpenNotification]     = useState(false);
  const validateData            = useSelector(state => state.cardValidation.data);
  const bulkCardFormData = useSelector(
    (state) => state.cardValidation.bulkCardFormData
  );

  const bulkCardCreationSuccess = useSelector(
    (state) => state.cardValidation.createInBulkSuccess
  );

  const failedCard   = validateData?.failedCard || [];
  const successCard  = validateData?.successCard || [];
  const history = useHistory();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.cardValidation.loading);

  useEffect(() => {
    if (!Object.keys(validateData).length) {
      history.push('/bulkCard');
      return;
    }
    const promotionIds = Object.keys(validateData.promotionSummary);
    const promotionBudErr = [];
    const credLimitErr    = [];
    if (!failedCard.length) {
      for (let i = 0; i < promotionIds.length; i++) {
        const promotion = validateData.promotionSummary[promotionIds[i]];

        if (
          promotion.budgetEnforceFlag &&
          promotion.totalBudget <=
            promotion.totalEstimatedCardValue + promotion.promotionIssuedSum
        ) {
          promotionBudErr.push(promotion);
        } else if (
          !promotion.budgetEnforceFlag &&
          promotion.creditFlag &&
          promotion.totalEstimatedCardValue >=
            promotion.balance + promotion.creditLimit
        ) {
          credLimitErr.push(promotion);
        } else if (
          !promotion.budgetEnforceFlag &&
          !promotion.creditFlag &&
          promotion.balance < promotion.totalEstimatedCardValue) {
          credLimitErr.push(promotion);
        }
      }

      setPromotionBudgetErr(promotionBudErr);
      setCreditLimitError(credLimitErr);
    }

  }, [])

  useEffect(() => {
    if (bulkCardCreationSuccess) {
      setOpenNotification(true);
      setTimeout(() => {
        history.push('/bulkCard');
      }, 1000);
    }
  }, [bulkCardCreationSuccess]);

  const handleCancel = () => {
    history.push("/bulkCard");
  }

  const handleSubmit = async () => {
    dispatch(createCardsInBulk({ bulkCardFormData, successCard, history }));
  }

  const hasValidationError  =
    promotionBudgetErr.length || creditLimitError.length || failedCard.length;
  return (
    <>
      <Navbar />
      <div className="preview-container min-h-full flex flex-wrap justify-center items-center ">
        <HeaderPreview
          balance={validateData.balance}
          totalIssueAmount={validateData.totalIssueAmount}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          hasValidationError={hasValidationError}
          loading={loading || openNotification}
        />
        <hr className="seperator"></hr>
        {creditLimitError.length > 0 && (
          <Alert severity="error">
            {' '}
            The issue card total is greater than Remaining balance. Please check
            with TCN admin.
          </Alert>
        )}

        {failedCard.length === 0 && promotionBudgetErr.length > 0 && (
          <PromotionBudgetExceedPreview data={promotionBudgetErr} />
        )}
        {failedCard.length > 0 && <FailedCardPreview data={failedCard} />}
        {successCard.length > 0 && <SuccessCardPreview data={successCard} />}

        <Notify
          open={openNotification}
          onClose={() => setOpenNotification(false)}
          errors={{}}
          successMessage={"Cards Created Successfully !!"}
        />
        <Loader loading={loading} />
      </div>
      <Footer />
    </>
  );
}

export default BulkCardValidationPreview;