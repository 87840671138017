import { RequestHandleType } from '../constants/requestHandle';

export const getHelperText = (deliveryMethod) => {
  switch (deliveryMethod) {
    case RequestHandleType.CardNumberAndPin:
      return 'Choose this method if you intend to issue digital card details via your own technology';
    case RequestHandleType.SMS:
      return 'A link to the Card Type selected will be sent to the recipient via SMS ';
    case RequestHandleType.Email:
      return 'The Card Type selected will be sent to the recipient via Email';
    case RequestHandleType.Physical:
      return 'The Card Type selected will be dispatched by the TCN fulfilment team within 48 business hours of the order being placed ';
    default:
      return '*Is required.';
  }
};
