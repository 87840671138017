import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

import UserType from '../../constants/userType';
import { generatePassword } from '../../helpers/password';
import validateForm from './validateForm.helper';
import SelectInput from '../Select';
import "./UserForm.scss";

const UserForm = ({
  user = {},
  loading = true,
  departments,
  handleSubmit,
  redirectToUserList,
  generate2FA,
  mfaSecrets,
  customer
}) => {
  const isEditUser = Object.keys(user).length > 0;
  const [department, setDepartment] = useState(user?.departmentId || '');
  const [userType, setUserType] = useState(
    typeof user?.type === 'number' ? user?.type : ''
  );
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [password, setPassword] = useState(
    isEditUser ? '' : generatePassword()
  );
  const [validationErrors, setValidationErrors] = useState({});
  const [status, setStatus] = useState(isEditUser ? (user?.active ? 1 : 2) : 1);
  const [enableMFA, setEnableMFA] = useState(user?.enable_mfa || 0);
  const [enableCrownSsoFlag, setEnableCrownSsoFlag] = useState(user?.crownSsoFlag || false);

  useEffect(() => {
    if (Object.keys(user?.mfa_secret || {}).length === 0 && enableMFA && !mfaSecrets?.generateQRCode) {
      generate2FA();
    }
  }, [enableMFA]);

  const submit = () => {
    const payload = {
      department,
      firstName,
      lastName,
      email,
      phone,
      password,
      status,
      userType,
      enableMFA,
      mfaSecret:
        enableMFA === 1 && Object.keys(mfaSecrets).length > 0
          ? mfaSecrets
          : null
    };
    const validate = validateForm(isEditUser, payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleSubmit(payload);
  };

  return (
    <div className="form-container">
      <FormControl
        variant="standard"
        className="form-control mb-2"
        error={validationErrors.department}
      >
        <InputLabel id="demo-simple-select-standard-label">
          Department
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="department"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
        >
          {departments?.map((depart) => (
            <MenuItem key={depart.id} value={depart.id}>
              {depart.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>
      <FormControl
        variant="standard"
        className="form-control"
        error={validationErrors.userType}
      >
        <InputLabel id="demo-simple-select-standard-label">
          User Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="userType"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
        >
          <MenuItem value={UserType.Administrator}>Administrator</MenuItem>
          <MenuItem value={UserType.User}>User</MenuItem>
          <MenuItem value={UserType.ReadOnly}>Read-only</MenuItem>
        </Select>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>
      <TextField
        id="standard-basic"
        label="First Name"
        type="text"
        name="firstName"
        helperText="*Is required."
        error={validationErrors.firstName}
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />
      <TextField
        id="standard-basic"
        label="Last Name"
        type="text"
        name="lastName"
        helperText="*Is required."
        error={validationErrors.lastName}
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />
      <TextField
        id="standard-basic"
        label="Email"
        type="email"
        name="email"
        helperText="*Is required."
        inputProps={{ readOnly: isEditUser }}
        error={validationErrors.email}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        id="standard-number"
        label="Mobile"
        type="number"
        name="phone"
        error={validationErrors.phone}
        value={phone}
        onChange={(e) => setPhone(e.target.value)}
      />
      <TextField
        className='mb-2'
        id="standard-basic"
        label={isEditUser ? 'New Password' : 'Password (Auto generated)'}
        type="text"
        name="password"
        helperText={validationErrors?.msg?.password || '*Is required.'}
        error={validationErrors.password}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <FormControl variant="standard" className="form-control mb-2">
        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
        <SelectInput
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="status"
          value={status}
          onChange={(val) => setStatus(val)}
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={2}>Inactive</MenuItem>
        </SelectInput>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      <FormControl variant="standard" className="form-control mb-2">
        <InputLabel id="demo-simple-select-standard-label">
          Enforce MFA
        </InputLabel>
        <SelectInput
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="enableMFA"
          value={enableMFA}
          onChange={(val) => setEnableMFA(val)}
          className="mt-4"
        >
          <MenuItem value={1}>True</MenuItem>
          <MenuItem value={0}>False</MenuItem>
        </SelectInput>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      {
        isEditUser && customer?.[0]?.customer_expectsso_flag === "TRUE" &&
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-standard-label">
            Require Sso Flag
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="enableCrownSsoFlag"
              value={enableCrownSsoFlag}
              disabled={true}
              className="mt-4"
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
            <FormHelperText>*Is required.</FormHelperText>
          </FormControl>
      }



      {/* {enableMFA === 1 && mfaSecrets?.generateQRCode && (
        <div className="mfa-qr-code">
          <div className="mfa-instruction">
            Please scan QR code with Google Authenticator app to setup Multi
            Factor Authenticator. This is only one time action.
          </div>
          <img src={mfaSecrets.generateQRCode} />
          <div className="mfa-instruction">
            Alternatively, you can use following credentials to setup Google Authenticator.
          </div>
          <div className="mfa-secret-info">
            <b>Account:</b> {mfaSecrets?.key}
          </div>
          <div className="mfa-secret-info">
            <b>Secret Key:</b> {mfaSecrets?.secret?.base32}
          </div>
        </div>
      )} */}

      <div className="action-footer">
        <Button
          disabled={loading}
          variant="outlined"
          endIcon={
            loading ? (
              <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
            ) : (
              <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
            )
          }
          onClick={submit}
        >
          Continue
        </Button>
        <Button variant="outlined" onClick={redirectToUserList}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

UserForm.propTypes = {
  departments       : PropTypes.object,
  mfaSecrets        : PropTypes.object,
  user              : PropTypes.object,
  customer          : PropTypes.object,
  loading           : PropTypes.bool,
  handleSubmit      : PropTypes.func,
  redirectToUserList: PropTypes.func,
  generate2FA       : PropTypes.func
};

export default UserForm;