import React from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const createSuccessRow = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      errormessage  : data[i].response.errormessage || "Something went wrong !!",
      rowNo         : data[i].row,
      eoid          : data[i].EOID,
      cardName      : data[i].CardName,
      cardEmail     : data[i].CardEmail,
      cardMobile    : data[i].CardMobile,
      recipientName : data[i].RecipientName,
      recipientEmail: data[i].RecipientEmail,
      productionId  : data[i].ProductID,
      promotion     : data[i].Promotion,
      amount        : data[i].Amount,
    };

    rows.push(row);
  }
  return rows;
};

const FailedCardPreview = ({ data }) => {
  const classes = useStyles();
  const rows    = createSuccessRow(data);

  return (
    <div className="validation-result">
      <h3> Failed cards </h3>
      <TableContainer component={Paper} className="error-table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Error Message</TableCell>
              <TableCell align="left">Row No.</TableCell>
              <TableCell align="left">EOID</TableCell>
              <TableCell align="left">Card Name</TableCell>
              <TableCell align="left">Card Email</TableCell>
              <TableCell align="left">Card Mobile</TableCell>
              <TableCell align="left">Recipient Name</TableCell>
              <TableCell align="left">Recipient Email</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="left">Campaign</TableCell>
              <TableCell align="left">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row.errormessage}
                </TableCell>
                <TableCell align="left">{row.rowNo}</TableCell>
                <TableCell align="left">{row.eoid}</TableCell>
                <TableCell align="left">{row.cardName}</TableCell>
                <TableCell align="left">{row.cardEmail}</TableCell>
                <TableCell align="left">{row.cardMobile}</TableCell>
                <TableCell align="left">{row.recipientName}</TableCell>
                <TableCell align="left">{row.recipientEmail}</TableCell>
                <TableCell align="left">{row.productionId}</TableCell>
                <TableCell align="left">{row.promotion}</TableCell>
                <TableCell align="left">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

FailedCardPreview.propTypes = {
  data: PropTypes.array
};

export default FailedCardPreview;
