export const UserType = {
  Administrator: 0,
  ReadOnly: 1,
  User: 2
}

const type = ['Administrator', 'ReadOnly', 'User'];

const toString = (id) => {
  switch (id) {
    case UserType.Administrator:
      return type[0];
    case UserType.ReadOnly:
      return type[1];
    case UserType.User:
      return type[2];
    default:
      return null;
  }
}

export default Object.assign({}, UserType, { toString });