import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles, TablePagination } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getTcnCustomerId } from '../../../../helpers/users';
import { getUserPermissionType } from '../../../../helpers/role';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      createdAt: data[i]?.createdAt || '',
      email: data[i]?.user?.email || '',
      requestIp: data[i]?.requestIp || '',
      eventType: data[i]?.eventType || ''
    };

    rows.push(row);
  }
  return rows;
};

const ActivityLogTable = ({
  customerActivityLogs,
  handleChangePage,
  handleChangeRowsPerPage,
  rowsPerPage,
  totalRowCount,
  page
}) => {
  const classes = useStyles();
  const rows = customerActivityLogs?.length > 0 ? createRows(customerActivityLogs) : [];

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer component={Paper} className="table-container">
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Created At</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Ip Address</TableCell>
                <TableCell align="left">Event Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">
                      {row.createdAt
                        ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                        : '-'}
                    </TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.requestIp}</TableCell>
                    <TableCell align="left">{row.eventType}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 1 }}>
                  <TableCell colSpan={6}>
                    {' '}
                    No data available in table{' '}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalRowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

ActivityLogTable.propTypes = {
  customerActivityLogs: PropTypes.array,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalRowCount: PropTypes.number
};

export default ActivityLogTable;
