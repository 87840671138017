import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getCardLiveTransactionAPI } from '../../../../services/api';

import './CardModal.scss';

const GetLiveTxModel = ({ modalIsOpen, closeModal, cardProxy }) => {
  const [liveTransactions, setLiveTransactions] = useState([]);

  useEffect(() => {
    getLiveTransaction();
  }, []);

  const getLiveTransaction = async () => {
    try {
      const response = await getCardLiveTransactionAPI(cardProxy);
      const transactions = response?.data?.transactions || [];
      setLiveTransactions(
        transactions.sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const handleCloseModel = () => {
    closeModal();
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Live Transactions</h2>
          <div className="cardActionsForms">
            <TableContainer component={Paper} className="table-container">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">Amount</TableCell>
                    <TableCell align="left">Transaction Details</TableCell>
                    <TableCell align="left">Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {liveTransactions.length > 0 ? (
                    liveTransactions.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{row.Type}</TableCell>
                        <TableCell align="left">{row.Amount}</TableCell>
                        <TableCell align="left">{row.Note}</TableCell>
                        <TableCell align="left">{row.Date}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow style={{ height: 53 * 1 }}>
                      <TableCell colSpan={6}>
                        {' '}
                        No data available in table{' '}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

GetLiveTxModel.propTypes = {
  selectedCard: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  cardProxy: PropTypes.string
};

export default GetLiveTxModel;
