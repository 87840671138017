import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createSuccessRow = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      promotionId          : data[i].id,
      promotionName        : data[i].name,
      totalIssuedSum       : data[i].promotionIssuedSum,
      currentOrder         : data[i].totalEstimatedCardValue,
      estimatedCardIssueSum: 
        data[i].promotionIssuedSum + data[i].totalEstimatedCardValue,
      budget              : data[i].totalBudget
    };

    rows.push(row);
  }
  return rows;
};

const PromotionBudgetExceedPreview = ({data}) => {
  const classes = useStyles();
  const rows = createSuccessRow(data);

  return (
    <div className="validation-result">
      <h3> Sum of issued card exceeds promotion budget </h3>
      <TableContainer component={Paper} className="error-table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Promotion Id</TableCell>
              <TableCell align="left">Promotion Name</TableCell>
              <TableCell align="left">Total Issued Amount</TableCell>
              <TableCell align="left">Current Order</TableCell>
              <TableCell align="left">Estimated Card Issue Sum</TableCell>
              <TableCell align="left">Budget</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row.promotionId}
                </TableCell>
                <TableCell align="left">{row.promotionName}</TableCell>
                <TableCell align="left">{row.totalIssuedSum}</TableCell>
                <TableCell align="left">{row.currentOrder}</TableCell>
                <TableCell align="left">{row.estimatedCardIssueSum}</TableCell>
                <TableCell align="left">{row.budget}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

PromotionBudgetExceedPreview.propTypes = {
  data: PropTypes.array
};

export default PromotionBudgetExceedPreview;
