import React, { useEffect, useRef } from  "react";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from "classnames";

import {
  getFrameImageUrl,
  getStep1ImageUrl,
  getStep2ImageUrl,
  getStep3ImageUrl,
  getBrandThemeColor,
  getViewName,
  generateTermLink,
  getRedeemStepColor,
  getPageTitle
} from '../../../helpers/customRedeem';
import View1WelcomeSection from './view1/WelcomeSection';
import View2WelcomeSection from './view2/WelcomeSection';
import View1FooterSection from './view1/FooterSection';
import View2FooterSection from './view2/FooterSection';
import BottomInfoSection from './view2/BottomInfoSection';

import './success.scss';

const RedeemSuccess = () => {
  const { promotionId: promotionid } = useParams();
  const thankyouRef = useRef(null);
  const welcomeRef = useRef(null);

  const cardDetails = useSelector((state) => state.cardRedeem.cardDetails);
  const viewName = getViewName(promotionid);

  useEffect(() => {
    //SET TITLE
    document.title = getPageTitle(promotionid);

    if (viewName === 'view1') {
      executeScrollForView1();
    }

    if (viewName === 'view2') {
      executeScrollForView2();
    }
  }, []);

  const executeScrollForView1 = () =>
    thankyouRef.current.scrollIntoView({
      behavior: 'smooth'
    });

  const executeScrollForView2 = () =>
    welcomeRef.current.scrollIntoView({
      behavior: 'smooth'
    });



  return (
    <div className="redeem-page-wrapper">
      <div className="redeem-header mx-auto">
        <img src={getFrameImageUrl(promotionid)} alt="Redeem Image" />
      </div>

      {viewName === 'view1' && (
        <View1WelcomeSection
          cardDetails={cardDetails}
          promotionid={promotionid}
        />
      )}

      {viewName === 'view2' && (
        <View2WelcomeSection
          welcomeRef={welcomeRef}
          promotionid={promotionid}
        />
      )}

      <div
        className="redeem-steps-wrapper"
        style={{
          backgroundColor: getRedeemStepColor(promotionid)
        }}
      >
        {viewName === 'view1' && (
          <div
            className={classNames('redeem-step-text', {
              'aami-font': promotionid === '11246',
              'secondary-font': promotionid === '11247'
            })}
            style={{ color: getBrandThemeColor(promotionid) }}
          >
            {promotionid === '11246'
              ? `It's as easy as 1, 2, 3!`
              : `You'll know with 3 simple steps!`}
          </div>
        )}

        <div className="redeem-steps mx-auto">
          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep1ImageUrl(promotionid)} alt="step-1" />
            </div>
            <div className="redeem-circle-text">
              {viewName === 'view1' && (
                <p
                  className={classNames({
                    'aami-font': promotionid === '11246',
                    'secondary-font': promotionid === '11247'
                  })}
                >
                  Enter your mobile number to receive an SMS with a link to
                  download The Card Network (TCN) App.
                </p>
              )}
              {viewName === 'view2' && (
                <p
                  className={classNames({
                    'color-white suncorp-font': promotionid === '11248',
                    'color-black apia-font': promotionid === '11249'
                  })}
                >
                  Click the link in the SMS we sent you to download The Card
                  Network (TCN) App.
                </p>
              )}
            </div>
          </div>

          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep2ImageUrl(promotionid)} alt="step-2" />
            </div>
            <div className="redeem-circle-text">
              {viewName === 'view1' && (
                <>
                  <p
                    className={classNames({
                      'aami-font': promotionid === '11246',
                      'secondary-font': promotionid === '11247'
                    })}
                  >
                    You&apos;ll then see your digital Prepaid Visa card in the
                    TCN App.
                  </p>
                  <br />
                </>
              )}
              {viewName === 'view2' && (
                <p
                  className={classNames({
                    'color-white suncorp-font': promotionid === '11248',
                    'color-black apia-font': promotionid === '11249'
                  })}
                >
                  After downloading, you&apos;ll see your digital Prepaid Visa
                  card in the TCN App.
                </p>
              )}
              {viewName === 'view2' && (
                <p
                  className={classNames('secondary-font', {
                    'color-white suncorp-font': promotionid === '11248',
                    'color-black apia-font': promotionid === '11249'
                  })}
                  style={{ fontWeight: 'bold' }}
                >
                  Already got the TCN App?
                </p>
              )}
              {viewName === 'view1' && (
                <p
                  className={classNames({
                    'aami-font': promotionid === '11246',
                    'secondary-font': promotionid === '11247'
                  })}
                >
                  {' '}
                  Already have the App? The link will give you access to your
                  personalised card.
                </p>
              )}
              {viewName === 'view2' && (
                <p
                  className={classNames({
                    'color-white suncorp-font': promotionid === '11248',
                    'color-black apia-font': promotionid === '11249'
                  })}
                >
                  The link will give you access to your personalised card.
                </p>
              )}
            </div>
          </div>

          <div className="redeem-steps-count">
            <div className="redeem-circle">
              <img src={getStep3ImageUrl(promotionid)} alt="step-3" />
            </div>
            <div className="redeem-circle-text">
              {viewName === 'view1' && (
                <p
                  className={classNames({
                    'aami-font': promotionid === '11246',
                    'secondary-font': promotionid === '11247'
                  })}
                >
                  Add your digital Prepaid Visa card to your phone&apos;s wallet
                  to spend your reward credit anywhere Visa is accepted.
                </p>
              )}
              {viewName === 'view2' && (
                <p
                  className={classNames('secondary-font', {
                    'color-white suncorp-font': promotionid === '11248',
                    'color-black apia-font': promotionid === '11249'
                  })}
                >
                  Add it to your phone&apos;s wallet and spend on anything you
                  like wherever Visa is accepted.
                </p>
              )}
            </div>
          </div>
        </div>

        {viewName === 'view1' && (
          <div className="redeem-step-bottom">
            <div
              className={classNames('redeem-step-bottom-text', {
                'aami-font': promotionid === '11246',
                'secondary-font': promotionid === '11247'
              })}
            >
              The link within your SMS will expire three months after you
              receive it. You must download your digital Prepaid Visa card
              within this time, or you will lose the rewards balance within the
              card.{' '}
            </div>
          </div>
        )}
      </div>

      {viewName === 'view2' && <BottomInfoSection promotionid={promotionid} />}

      {viewName === 'view1' && (
        <View1FooterSection
          thankyouRef={thankyouRef}
          termLink={generateTermLink(promotionid)}
          promotionid={promotionid}
        />
      )}

      {viewName === 'view2' && <View2FooterSection promotionid={promotionid} />}
    </div>
  );
}

export default RedeemSuccess;