// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Overlay--after-open {
  z-index: 101; }

.modal {
  background-color: black;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  width: 90vw; }
  .modal .close-icon {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    cursor: pointer; }
  .modal .detail-log-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 20px; }
    .modal .detail-log-wrapper .detail-log-info {
      display: flex;
      width: 500px;
      background-color: floralwhite;
      text-align: left;
      overflow: auto; }
  @media screen and (min-width: 480px) {
    .modal {
      width: 450px; } }
  @media screen and (min-width: 768px) {
    .modal {
      width: 750px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/user-logs/view-log-modal/CardModal.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA;;AAOd;EACE,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,WAAW,EAAA;EATb;IAWI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,eAAe,EAAA;EAdnB;IAkBI,aAAa;IACb,SAAS;IACT,gBAAgB,EAAA;IApBpB;MAsBM,aAAa;MACb,YAAY;MACZ,6BAA6B;MAC/B,gBAAgB;MAChB,cAAc,EAAA;EAKhB;IA/BF;MAiCI,YAAY,EAAA,EAOf;EAJC;IApCF;MAqCI,YAAY,EAAA,EAGf","sourcesContent":[".ReactModal__Overlay--after-open {\n  z-index: 101;\n  // background-color: rgba(255, 255, 255, 0.1) !important;\n  // background-color: rgba(85, 76, 76, 0.253) !important;\n  // background-color: red !important;\n  // background-color: unset !important;\n}\n\n.modal {\n  background-color: black;\n  color: red;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  text-align: center;\n  width: 90vw;\n  .close-icon {\n    display: flex;\n    justify-content: flex-end;\n    padding: 0px 10px;\n    cursor: pointer;\n  }\n\n  .detail-log-wrapper {\n    display: flex;\n    gap: 10px;\n    margin-top: 20px;\n    .detail-log-info {\n      display: flex;\n      width: 500px;\n      background-color: floralwhite;\n    text-align: left;\n    overflow: auto;\n    }\n  }\n\n  // border-radius: 5%;\n  @media screen and (min-width: 480px) {\n    // border-radius: 10%;\n    width: 450px;\n  }\n\n  @media screen and (min-width: 768px) {\n    width: 750px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
