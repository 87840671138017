import React from 'react';
import './PageNotFound.scss';

export default function PageNotFound() {
  return (
    <div className='page-not-found-wrapper'>
    <h1 className='status-code'>404</h1>
    <h3 className='message'>Page Not Found!!!</h3>
    </div>
  );
}
