const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data?.firstName) {
    errors.hasError = true;
    errors.firstName = true;
  }

  if (!data?.lastName) {
    errors.hasError = true;
    errors.lastName = true;
  }

    if (!data?.email) {
    errors.hasError = true;
    errors.email = true;
  }

  if (!data?.mobile) {
    errors.hasError = true;
    errors.mobile = true;
  }

  if (!data?.dob) {
    errors.hasError = true;
    errors.dob = true;
  }

  if (!data?.address1) {
    errors.hasError = true;
    errors.address1 = true;
  }

  if (!data?.address2) {
    errors.hasError = true;
    errors.address2 = true;
  }

  if (!data?.address2) {
    errors.hasError = true;
    errors.address2 = true;
  }

  return errors;
};

export default validateForm;
