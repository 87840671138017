import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import {
  getCardTransactionAPI,
  getCardByProxyAPI,
  cancelCardByProxyAPI,
  getCardNoteAPI,
  updateCardAdditionalInfoAPI,
  sendCardNoteAPI,
  getCustomerInfoAPI,
  replaceCardAPI,
} from '../../../services/api';
import Navbar from '../../../components/Navbar';
import { Button, CircularProgress } from '@material-ui/core';
import { format, isValid } from 'date-fns';
import Notify from '../../../components/notify';
import Loader from '../../../components/loader';
import { getUserName } from '../../../helpers/localStorage';
import { hasAdminUserPermissionForCTA } from '../../../helpers/permission';
import CardCancelModal from './card-cancel-modal';
import CardTransactionTable from './card-transaction-table';
import CardNotes from './card-notes';
import { formatMoney } from '../../../helpers/currency';
import EditCardInfoModel from './edit-card-detail-modal';
import ReplaceCardModel from './replace-card-modal';
import CardCancelSummaryModel from './card-cancel-summary-modal';
import AddCardNoteModel from './add-card-note-modal';
import GetLiveTxModel from './get-live-tx-modal';
import ReplaceCardSummaryModal from './replace-card-summary-modal';
import ReplaceCardSuccessSummaryModel from './replace-card-success-summary-modal';
import "./ManageAgentIssuance.scss";

const ManageAgentIssuance = () => {
  const [openEditCardModel, setOpenEditCardModel] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [openReplaceCardModel, setOpenReplaceCardModel] = useState(false);
  const [openReplaceCardSummaryModel, setOpenReplaceCardSummaryModel] = useState(false);
  const [openReplaceCardSuccessModel, setOpenReplaceCardSuccessModel] = useState(false);
  const [openCardNoteModel, setOpenCardNoteModel] = useState(false);
  const [openLiveTxModel, setOpenLiveTxModel] = useState(false);
  const [openCardCancelSummaryModal, setOpenCardCancelSummaryModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [cancellingCard, setCancellingCard] = useState(false);
  const [replaceCardData, setReplaceCardData] = useState({
    productId: '',
    serialNumber: ''
  });
  const [replaceCardSuccessData, setReplaceCardSuccessData] = useState({});
  const [openCardCancelModal, setOpenCardCancelModal] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [cardByProxy, setCardByProxy] = useState({});
  const [cardTransactions, setCardTransactions] = useState([]);
  const [cardNotes, setCardNotes] = useState([]);
  const history = useHistory();

  const selectedAgentIssuance = useSelector(
    (state) => state.agentIssuance.selectedAgentIssuance
  );

  let { cardProxy, cardId } = useParams();
  const customerPrivacyInteger = customerInfo?.customer?.[0]?.customer_privacy_integer;
  const belongsToCustomerPrivacyInteger = ["3", "4"].includes(customerPrivacyInteger);

  useEffect(() => {
    setLoading(true);
    getCardTransaction();
    getCardByProxy();
    getCardNote();
    getCustomerInfo();
  }, [cardProxy, cardId]);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setLoading(false);
    } catch (err) {
      console.log('err :: ', err);
    }
  };

  const toggleCardCancelModel = (val) => {
    setOpenCardCancelModal(val);
  };

  const getCardByProxy = async () => {
    try {
      setErrors({});
      const response = await getCardByProxyAPI(cardProxy);
      setCardByProxy(response?.data || {});
      setLoading(false);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getCardTransaction = async () => {
    try {
      setErrors({});
      const response = await getCardTransactionAPI(cardProxy);
      setCardTransactions(response?.data || []);
      setLoading(false);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getCardNote = async () => {
    try {
      const response = await getCardNoteAPI(cardId);
      setCardNotes(response?.response?.notes?.reverse() || []);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const cancelCard = async (payload) => {
    try {
      setErrors({});
      setCancellingCard(true);
      toggleCardCancelModel(false);
      const response = await cancelCardByProxyAPI(cardProxy, {
        ...payload,
        cardId,
        cancelAgent: getUserName()
      });

      if (payload?.notes) {
        handleSendNote(payload.notes);
      }

      getCardByProxy();
      setCancellingCard(false);
      setOpenCardCancelSummaryModal(true);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
      setCancellingCard(false);
    }
  };

  const handleSendNote = async (note) => {
    try {
      setLoading(true);
      toggleAddCardNoteModel(false);
      setErrors({});
      const response = await sendCardNoteAPI(cardId, {
        notebody: note,
        noteuser: getUserName()
      });
      getCardNote();
      setSuccessMessage('Successfully added notes!');
      setOpenNotify(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrors(err);
      setOpenNotify(true);
    }
  };

  const updateAdditionalInfoProxy = async (additionalDetails) => {
    try {
      setErrors({});
      toggleEditCardModel(false);
      setLoading(true);
      const response = await updateCardAdditionalInfoAPI(cardProxy, {
        ...additionalDetails,
        blockFlag: cardByProxy.card_block_flag === 'TRUE' ? '1' : '0'
      });
      reloadPage();
      setSuccessMessage('Successfully updated card additional info!!');
      setOpenNotify(true);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const redirectToAgentIssuance = () => {
    history.push('/agent-issuance');
  };

  const toggleEditCardModel = (val) => {
    setOpenEditCardModel(val);
  };

  const toggleReplaceCardModel = (val) => {
    setOpenReplaceCardModel(val);
  };

  const toggleAddCardNoteModel = (val) => {
    setOpenCardNoteModel(val);
  };

  const toggleCardCancelSummaryModal = (val) => {
    setOpenCardCancelSummaryModal(val);
  };

  const toggleLiveTxModel = (val) => {
    setOpenLiveTxModel(val);
  };

  const toggleReplaceCardSummaryModel = (val) => {
    setOpenReplaceCardSummaryModel(val);
  };

  const toggleReplaceCardSuccessModel = (val) => {
    setOpenReplaceCardSuccessModel(val);
  };

  const reloadPage = () => {
    setLoading(true);
    getCardTransaction();
    getCardByProxy();
  }

  const handleReplaceCardBtn = () => {
    toggleCardCancelSummaryModal(false);
    toggleReplaceCardModel(true);
  }

  const handleReplaceCard = async(data) => {
    setReplaceCardData(data);
    toggleReplaceCardSummaryModel(true);
  }

  const handleReplaceCardSummary = async () => {
    toggleReplaceCardSummaryModel(false);
    toggleReplaceCardModel(false);
    const {
      card_activation_code,
      issuance_metadata: {
        card_agent_customerissuancelabel
      }
    } = cardByProxy;

    try {
      setLoading(true);
      toggleReplaceCardSummaryModel(false);
      toggleReplaceCardModel(false);
      const replaceCardResponse = await replaceCardAPI({
        department: card_agent_customerissuancelabel,
        referenceId: replaceCardData.referenceId,
        productId: replaceCardData.productId,
        cardAmount: replaceCardData.cardAmount,
        serialNumber: replaceCardData.serialNumber,
        replacedCard: card_activation_code,
        additionalDetails: {
          firstName: replaceCardData.additionalInfo.firstName,
          address1: replaceCardData.additionalInfo.address1,
          address2: replaceCardData.additionalInfo.address2,
          country: replaceCardData.additionalInfo.country,
          dob: replaceCardData.additionalInfo.dateOfBirth,
          email: replaceCardData.additionalInfo.email,
          lastName: replaceCardData.additionalInfo.lastName,
          mobile: replaceCardData.additionalInfo.mobile,
          notes: replaceCardData.additionalInfo.notes,
          postcode: replaceCardData.additionalInfo.postcode,
          state: replaceCardData.additionalInfo.state,
          suburb: replaceCardData.additionalInfo.city
        }
      });

      setReplaceCardSuccessData(replaceCardResponse.issuedcard);
      toggleReplaceCardSuccessModel(true);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Update Agent Issuance Failed !!!!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const navigateToIssuedCardTable = () => {
    toggleReplaceCardSuccessModel(false);
    history.push(`/agent-issuance`);
  };

  const navigateToViewCard = () => {
    toggleReplaceCardSuccessModel(false);
    history.push(
      `/agent-issuance/${replaceCardSuccessData.card_id}/card-proxy/${replaceCardSuccessData.cardactivationcode}`
    );
  };

  return (
    <>
      <Navbar />
      <div className="manage-agent-issuance-wrapper min-h-full">
        <img
          className="smart-image"
          src={cardByProxy?.smartimage_link || ''}
          alt="smartImage"
        />
        <div className="card-information">
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Product:</div>
              <div className="card-information-data-value">
                {' '}
                {cardTransactions.card_product_id} ({cardByProxy.product_name})
              </div>
            </div>
            <div className="reload-btn">
              <Button
                disabled={loading}
                variant="outlined"
                endIcon={
                  <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                }
                onClick={reloadPage}
              >
                Reload
              </Button>
            </div>
          </div>
        </div>
        <div className="card-information">
          <h2 className="card-information-header">Card Information: </h2>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Campaign Name: </div>
              <div className="card-information-data-value">
                {' '}
                {selectedAgentIssuance?.campaignName || ''}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">
                Card creation date:
              </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata?.card_agent_issuancetimestamp
                  ? format(
                        new Date(
                          cardByProxy?.issuance_metadata?.card_agent_issuancetimestamp
                        ),
                        'dd-MM-yyyy HH:mm:ss'
                      )
                  : ''}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Card Status:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy.card_status}
              </div>
            </div>

            <div className="card-information-data">
              <div className="card-information-data-label">
                Card Agent Issuance Date:
              </div>
              <div className="card-information-data-value">
                {' '}
                {selectedAgentIssuance?.cardIssuedDate
                  ? format(
                      new Date(selectedAgentIssuance.cardIssuedDate),
                      'dd-MM-yyyy HH:mm:ss'
                    )
                  : ''}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">
                Card Initial Value:
              </div>
              <div className="card-information-data-value">
                {' '}
                {formatMoney(cardByProxy.card_initial_value)}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">
                Card Activation Code:
              </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy.card_activation_code}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            {customerInfo?.customer?.[0]?.AllowTcncpCb === 'TRUE' ? (
              <div className="card-information-data">
                <div className="card-information-data-label">
                  Card Current Value:
                </div>
                <div className="card-information-data-value">
                  {' '}
                  {cardByProxy?.card_cancel_flag === 'TRUE' || cardByProxy?.card_cancel_request_flag === 'TRUE'
                    ? '$0.00'
                    : formatMoney(cardByProxy.card_current_value)}
                </div>
              </div>
            ) : (
              <div className="card-information-data">
                <div className="card-information-data-label">Card Type:</div>
                <div className="card-information-data-value">
                  {' '}
                  {cardByProxy?.card_type || ''}
                </div>
              </div>
            )}

            <div className="card-information-data">
              <div className="card-information-data-label">Serial:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy.source_serial}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Card Issued by:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata?.card_agent_issuer}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Department:</div>
              <div className="card-information-data-value">
                {' '}
                {
                  cardByProxy?.issuance_metadata
                    ?.card_agent_customerissuancelabel
                }
              </div>
            </div>
          </div>
          {
            belongsToCustomerPrivacyInteger &&
            <div className="card-information-info">
              <div className="card-information-data">
                <div className="card-information-data-label"></div>
                <div className="card-information-data-value">
                </div>
              </div>
              <div className="card-information-data">
                <div className="card-information-data-label">Issuance Type:</div>
                <div className="card-information-data-value">
                  {' '}
                  {cardByProxy?.issuance_metadata?.card_agent_issuancetype}
                </div>
              </div>
            </div>
          }
        </div>

        {(cardByProxy?.card_cancel_flag === 'TRUE' || cardByProxy?.card_cancel_request_flag === 'TRUE') && (
          <>
            <div className="card-information">
              <h2 className="card-information-sub-header">Card Cancelled:</h2>
              <div className="card-information-info">
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Card cancellation date:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardByProxy?.card_cancel_date}
                  </div>
                </div>
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Card cancelled by:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardByProxy?.card_cancel_agent}
                  </div>
                </div>
              </div>
              <div className="card-information-info">
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Card Cancelled:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardByProxy?.card_cancel_flag === 'TRUE' || cardByProxy?.card_cancel_request_flag === 'TRUE' ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Cancel Reason:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardTransactions.card_cancel_reason}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {cardByProxy?.card_isreplaced === 'TRUE' && (
          <>
            <div className="card-information">
              <h2 className="card-information-sub-header">Replaced Card:</h2>
              <div className="card-information-info">
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Card replacement date:
                  </div>
                  <div className="card-information-data-value"> </div>
                </div>
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Card replaced by:
                  </div>
                  <div className="card-information-data-value"> </div>
                </div>
              </div>
              <div className="card-information-info">
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Replaced Card:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardByProxy.card_isreplaced === 'TRUE' ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Replaced Card Serial:
                  </div>
                  <div className="card-information-data-value"> </div>
                </div>
              </div>
              <div className="card-information-info">
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Replacement Card:
                  </div>
                  <div className="card-information-data-value">
                    {' '}
                    {cardByProxy.card_isreplacement === 'TRUE' ? 'Yes' : 'No'}
                  </div>
                </div>
                <div className="card-information-data">
                  <div className="card-information-data-label">
                    Replacement Card Serial:
                  </div>
                  <div className="card-information-data-value"> </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="card-information">
          <div className="agent-issuance-header-action">
            <h2 className="card-information-header">Additional Details: </h2>
            <div className="reload-btn">
              <Button
                disabled={loading}
                variant="outlined"
                endIcon={<EditOutlinedIcon style={{ color: '#FFFB00' }} />}
                onClick={() => toggleEditCardModel(true)}
              >
                Edit
              </Button>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Reference ID: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancereference || ''}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Email: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuanceemail || ''}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">First Name:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancefname || ''}{' '}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Last Name:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancelname || ''}{' '}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Mobile: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancemobile || ''}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Date Of Birth: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata?.card_agent_customerissuancedob
                  ? isValid(
                      new Date(
                        cardByProxy?.issuance_metadata?.card_agent_customerissuancedob
                      )
                    )
                    ? format(
                        new Date(
                          cardByProxy?.issuance_metadata?.card_agent_customerissuancedob
                        ),
                        'dd MMMMMM yyyy'
                      )
                    : 'Invalid date'
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <div className="card-information">
          <h2 className="card-information-sub-header">Address Details: </h2>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">
                Address1/Company:{' '}
              </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuanceaddress1 || ''}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Address2: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuanceaddress2 || ''}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Suburb:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancecity || ''}{' '}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">State: </div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancestate || ''}
              </div>
            </div>
          </div>
          <div className="card-information-info">
            <div className="card-information-data">
              <div className="card-information-data-label">Postcode:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancezipcode || ''}{' '}
              </div>
            </div>
            <div className="card-information-data">
              <div className="card-information-data-label">Country:</div>
              <div className="card-information-data-value">
                {' '}
                {cardByProxy?.issuance_metadata
                  ?.card_agent_customerissuancecountry || ''}{' '}
              </div>
            </div>
          </div>
        </div>

        <div className="card-information">
          <div className="agent-issuance-header-action">
            <h2 className="card-information-header">Card Notes: </h2>
            <div className="reload-btn">
              <Button
                disabled={loading}
                variant="outlined"
                endIcon={<EditOutlinedIcon style={{ color: '#FFFB00' }} />}
                onClick={() => toggleAddCardNoteModel(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <CardNotes cardNotes={cardNotes} />
        </div>
        {customerInfo?.customer?.[0]?.AllowTcncpTxDb === 'TRUE' && (
          <div className="card-information">
            <h2 className="card-information-header">Card Transactions: </h2>
            {customerInfo?.customer?.[0]?.AllowTcncpTxLive === 'TRUE' && (
              <div className="transaction-subheader">
                Transactions displayed below have a 24 hour delay, click{' '}
                <span
                  className="transaction-subheader-link"
                  onClick={() => toggleLiveTxModel(true)}
                >
                  here
                </span>{' '}
                to view live transactions.
              </div>
            )}
            <CardTransactionTable
              cardTransactions={cardTransactions?.transactions || []}
            />
          </div>
        )}
        <div className="manage-agent-action">
          <Button
            variant="contained"
            color="primary"
            onClick={redirectToAgentIssuance}
          >
            Back to Issued Cards Table
          </Button>

          {customerInfo?.customer?.[0]?.AllowTcncpCancelNoSwap === 'TRUE' &&
            cardByProxy?.card_cancel_flag !== 'TRUE' &&
            cardByProxy?.card_cancel_request_flag !== 'TRUE' &&
            cardByProxy?.card_status === 'active' && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => toggleCardCancelModel(true)}
                disabled={!hasAdminUserPermissionForCTA()}
                endIcon={
                  cancellingCard ? (
                    <CircularProgress
                      style={{ color: '#FFFB00' }}
                      size="20px"
                    />
                  ) : (
                    <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                  )
                }
              >
                Cancel Card
              </Button>
            )}

          {(cardByProxy?.card_cancel_flag === 'TRUE' || cardByProxy?.card_cancel_request_flag === 'TRUE') &&
            cardByProxy?.card_isreplaced === 'FALSE' && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => toggleReplaceCardModel(true)}
                disabled={!hasAdminUserPermissionForCTA()}
              >
                Replace this card
              </Button>
            )}
        </div>
      </div>
      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage={successMessage}
      />
      <Loader loading={loading} />
      {openEditCardModel && (
        <EditCardInfoModel
          data={cardByProxy?.issuance_metadata}
          modalIsOpen={openEditCardModel}
          closeModal={() => toggleEditCardModel(false)}
          updateAdditionalInfoProxy={updateAdditionalInfoProxy}
        />
      )}

      {openCardNoteModel && (
        <AddCardNoteModel
          modalIsOpen={openCardNoteModel}
          closeModal={() => toggleAddCardNoteModel(false)}
          handleSendNote={handleSendNote}
        />
      )}

      {openLiveTxModel && (
        <GetLiveTxModel
          modalIsOpen={openLiveTxModel}
          closeModal={() => toggleLiveTxModel(false)}
          cardProxy={cardProxy}
        />
      )}

      {openReplaceCardModel && (
        <ReplaceCardModel
          modalIsOpen={openReplaceCardModel}
          closeModal={() => toggleReplaceCardModel(false)}
          cardByProxy={cardByProxy}
          customerInfo={customerInfo}
          handleReplaceCard={handleReplaceCard}
        />
      )}

      {openCardCancelModal && (
        <CardCancelModal
          modalIsOpen={openCardCancelModal}
          closeModal={() => toggleCardCancelModel(false)}
          cancelCard={cancelCard}
        />
      )}

      {openCardCancelSummaryModal && (
        <CardCancelSummaryModel
          serialNumber={cardByProxy?.source_serial}
          modalIsOpen={openCardCancelSummaryModal}
          closeModal={() => toggleCardCancelSummaryModal(false)}
          navigateToIssuedCardTable={redirectToAgentIssuance}
          handleReplaceCardBtn={handleReplaceCardBtn}
        />
      )}

      {openReplaceCardSummaryModel && (
        <ReplaceCardSummaryModal
          openDialog={openReplaceCardSummaryModel}
          message={'Are you sure you want to continue?'}
          handleCloseDialog={() => toggleReplaceCardSummaryModel(false)}
          handleYes={handleReplaceCardSummary}
          data={{
            campaignId: cardByProxy.promotion_id,
            productId: replaceCardData.productId,
            noOfCardSelected: 1,
            initialCardValue: cardByProxy.card_initial_value,
            cardAmount: cardByProxy.card_initial_value,
            referenceId:
              cardByProxy?.issuance_metadata
                ?.card_agent_customerissuancereference || ''
          }}
        />
      )}

      {openReplaceCardSuccessModel && (
        <ReplaceCardSuccessSummaryModel
          serialNumber={replaceCardData.serialNumber}
          modalIsOpen={openReplaceCardSuccessModel}
          closeModal={() => toggleReplaceCardSuccessModel(false)}
          navigateToIssuedCardTable={navigateToIssuedCardTable}
          navigateToViewCard={navigateToViewCard}
        />
      )}
    </>
  );
};

ManageAgentIssuance.propTypes = {
  issueCard: PropTypes.array
};

export default ManageAgentIssuance;
