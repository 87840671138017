// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-table-filter-body {
  padding: 0px 40px; }
  .user-table-filter-body .form-container {
    display: flex;
    flex-direction: row;
    gap: 20px; }
  .user-table-filter-body .add-spacing {
    gap: 20px;
    margin-top: 20px; }
  .user-table-filter-body button {
    margin: 15px 7px;
    width: 100%; }
    @media screen and (min-width: 768px) {
      .user-table-filter-body button {
        width: calc(33.33% - 14px);
        margin-top: 0px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/activity-log/ActivityLogList.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAA;EADnB;IAGI,aAAa;IACb,mBAAmB;IACnB,SAAS,EAAA;EALb;IASI,SAAS;IACT,gBAAgB,EAAA;EAVpB;IAcI,gBAAgB;IAChB,WAAW,EAAA;IACX;MAhBJ;QAiBM,0BAA0B;QAC1B,eAAe,EAAA,EAElB","sourcesContent":[".user-table-filter-body {\n  padding: 0px 40px;\n  .form-container {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n  }\n\n  .add-spacing {\n    gap: 20px;\n    margin-top: 20px;\n  }\n\n  button {\n    margin: 15px 7px;\n    width: 100%;\n    @media screen and (min-width: 768px) {\n      width: calc(33.33% - 14px);\n      margin-top: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
