import { getDepartmentName } from "../../../../helpers/localStorage";
import { isMainTcncpUser } from "../../../../helpers/role";

export const getInitialAdditionalDetails = () => {
  return {
    firstName: '',
    lastName: '',
    mobile: '',
    dob: '',
    email: '',
    address1: '',
    address2: '',
    suburb: '',
    state: '',
    country: 'Australia',
    postcode: '',
    notes: ''
  };
};

export const getInitialDepartment = () => isMainTcncpUser() ? '' : getDepartmentName()
