import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import findCodeExample from '../../../assets/images/find-code-example.png';
import 'react-datepicker/dist/react-datepicker.css';
import './FindCodeModal.scss';

const FindCodeModal = ({ modalIsOpen, closeModal }) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <div className="close-icon">
            <CloseOutlinedIcon
              style={{ color: '#FFFB00' }}
              size="20px"
              onClick={closeModal}
            />
          </div>
          <div className='find-code-image-wrapper'>
            <img src={findCodeExample} alt="search-icon"></img>
          </div>
        </div>
      </Modal>
    </>
  );
};

FindCodeModal.propTypes = {
  selectedCard        : PropTypes.object,
  modalIsOpen         : PropTypes.bool,
  closeModal          : PropTypes.func,
};

export default FindCodeModal;
