import axios from "axios";
import { getAuthToken, getUserId } from '../helpers/localStorage';

export const TCNCP_API = process.env.REACT_APP_TCNCP_API;

const Axios = axios.create({
  baseURL: TCNCP_API,
});

Axios.interceptors.request.use(function (config) {
  const token = getAuthToken();

  config.headers['auth-token'] = token ? `${token}` : '';
  config.headers['userId']     = getUserId();
  return config;
});


export default Axios;
