import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "../../../assets/images/search-yellow.svg";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  fullWidth: {
    width: '700px'
  },
  root: {
    width: '300px'
  }
});

const TableHeader = ({ handleSearch, exportData, applyTestMode }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToCreateCampaign = () => {
    history.push('/campaign/create');
  };

  return (
    <>
      <div className="table-filter-body">
        <div className="form-container">
          <div className="flex field-button-container">
            <FormControl variant="standard" className="form-control w-50">
              <TextField
                className={classes.root}
                id="standard-basic"
                label="Search for information"
                name="search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <img src={SearchIcon}></img>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <div className="flex flex-wrap justify-between w-50">
              {applyTestMode === 1 && (
                <Button
                  variant="outlined"
                  className=""
                  onClick={redirectToCreateCampaign}
                >
                  Request new campaign
                </Button>
              )}

              <Button variant="outlined" onClick={exportData}>
                Export Campaign report
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TableHeader.propTypes = {
  handleSearch : PropTypes.func,
  exportData   : PropTypes.func,
  applyTestMode: PropTypes.number
};

export default TableHeader;
