import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import './liveCampaigns.scss';

const LiveCampaignChart = ({ promotionData }) => {
  let categories      = [];
  let seriesData      = [];
  let promotionBudget = [];

  for (let i = 0; i < promotionData.length; i++) {
    const promotion = promotionData[i];
    const promotionName = promotion.promotion_name;
    if (promotionName.length > 60) {
      categories.push([
        promotionName.substring(0, 30),
        promotionName.substring(30, 60),
        `${promotionName.substring(60, 70)}...`
      ]);
    } else if (promotionName.length < 60 && promotionName.length > 30) {
      categories.push([
        promotionName.substring(0, 30),
        promotionName.substring(30, promotionName.length)
      ]);
    } else {
      categories.push(promotionName);
    }
    seriesData.push(promotion.sumcardvalue_initial);
    promotion.promotion_budget_enforce === 'True'
      ? promotionBudget.push(promotion.promotion_budget)
      : promotionBudget.push(0);
  }

  const state = {
    options: {
      chart: {
        id: 'basic-bar',
        foreColor: '#818181'
      },
      // annotations: {
      //   yaxis: [
      //     {
      //       y: promotionBudget,
      //       borderColor: 'red',
      //       label: {
      //         borderColor: 'red',
      //         style: {
      //           color: '#fff',
      //           background: 'red',
      //           fontSize: '15px'
      //         },
      //         text: 'Promotion Limit'
      //       }
      //     }
      //   ]
      // },
      colors: ['#FFFB00', '#ff0000'],
      legends: {
        fontSize: '20px'
      },
      fill: {
        opacity: 1
      },
      xaxis: {
        labels: {
          rotate: 0
        },
        categories: categories
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return '$' + value;
          }
        }
      },
      dataLabels: {
        enabled: false
      }
    },
    colors: ['#008FFB', '#00E396'],
    series: [
      {
        name: 'Total Card Value',
        data: seriesData
      },
      {
        name: 'Promotion Budget',
        data: promotionBudget
      }
    ]
  };
  return (
    <div className="live-campaign-container">
      <div className="row">
        <h2 className="text-center">Top Live Campaigns</h2>
        <div className="mixed-chart">
          <Chart
            className="flex live-campaign-chart justify-center"
            options={state.options}
            series={state.series}
            type="bar"
            width="1800"
            height="425"
          />
        </div>
      </div>
    </div>
  );
};

LiveCampaignChart.propTypes = {
  promotionData: PropTypes.array
};

export default LiveCampaignChart;
