import UserType from '../constants/userType';
import { getTcnCustomerId, getUserInfo, getCustomerId } from './localStorage';

export const isMainTcncpUser = () => {
  const tcncpCustomerId = getTcnCustomerId();
  const customerId = getCustomerId();
  const isRoleAdministrator = isAdministratorUser();
  return tcncpCustomerId && !customerId && isRoleAdministrator;
}

export const isAdministratorUser = () => {
  const userInfo = getUserInfo();
  return userInfo?.type === UserType.Administrator;
};

export const isRegularUser = () => {
  const userInfo = getUserInfo();
  return userInfo?.type === UserType.User;
};

export const isReadOnlyUser = () => {
  const userInfo = getUserInfo();
  return userInfo?.type === UserType.ReadOnly;
};

export const getUserPermissionType = (userType, tcnCustomerId, customerId) => {
  if(userType === UserType.Administrator && tcnCustomerId && !customerId) {
    return "Master User";
  } else {
    return UserType.toString(userType);
  }
}