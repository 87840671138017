import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../../../components/loader';
import Notify from '../../../../components/notify';
import { getCardByIdentifierAPI } from '../../../../services/api';
import './EdmGio.scss';

const AAMIEdm = () => {
  const [recfn, setRecfn]                       = useState('');
  const [redemtionLink, setRedemtionLink]       = useState('');
  const [initialValue, setIntialValue]          = useState(0);
  const [loading, setLoading]                   = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors]                     = useState({});

  const { identifier } = useParams();

  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = async () => {
    try {
      const card = await getCardByIdentifierAPI(identifier);
      const fullName = card?.data?.name?.split(' ');
      const initialvalue = card?.data?.card_initial_value || 0;
      const link = card?.data?.redemptionlink || '';

      const firstName = fullName?.length > 0 ? fullName[0] : '';
      setRecfn(firstName);
      setIntialValue(initialvalue);
      setRedemtionLink(link);

      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Failed to get card details!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="edm-gio-wrapper">
        <table
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="em_full_wrap"
        >
          <tr>
            <td align="center" valign="top">
              <table
                className="em_main_table"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', tableLayout: 'fixed' }}
              >
                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="680"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '680px', backgroundColor: '#F7F7F7' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="620"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '620px',
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  fontWeight: 'normal',
                                  color: '#000000',
                                  padding: '20px 0 10px 0'
                                }}
                              >
                                Redeem your reward from GIO now!
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="left"
                                style={{
                                  padding: '0 0 15px 0',
                                  borderBottom: '4px solid #035A9C'
                                }}
                              >
                                <a href="">
                                  <img
                                    src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/logo.png"
                                    style={{ display: 'inline-block' }}
                                    width="107"
                                    height="42"
                                    border="0"
                                    alt="Logo"
                                  />
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td
                          align="center"
                          style={{ padding: '20px 15px 0 15px' }}
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="620"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '620px',
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                            <tr>
                              <td align="center">
                                <img
                                  className="em_img"
                                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/banner.png"
                                  style={{ display: 'block' }}
                                  width="620"
                                  height="230"
                                  border="0"
                                  alt="Image"
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr>
                        <td
                          align="center"
                          style={{ padding: '0 15px 20px 15px' }}
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="620"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '620px',
                              backgroundColor: '#FFFFFF'
                            }}
                          >
                            <tr>
                              <td
                                align="center"
                                style={{ padding: '0 15px 0 15px' }}
                              >
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="550"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{ width: '550px' }}
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ padding: '0 0 30px 0' }}
                                    >
                                      <table
                                        className="em_wrapper"
                                        align="center"
                                        width="550"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '550px' }}
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '20px',
                                              lineHeight: '36px',
                                              fontWeight: 650,
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '30px 0 0 0'
                                            }}
                                          >
                                            Hi {recfn},
                                            <br />
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            className="em_font22"
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '30px',
                                              lineHeight: '36px',
                                              fontWeight: 650,
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '0 0 10px 0',
                                              borderBottom: '2px solid #CC0000'
                                            }}
                                          >
                                            Here’s your ${initialValue} reward!
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', backgroundColor: '#F7F7F7' }}
              >
                <tr>
                  <td align="center" style={{ padding: '0 15px 20px 15px' }}>
                    <table
                      className="em_wrapper"
                      align="center"
                      width="620"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '620px', backgroundColor: '#FFFFFF' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '30px 0 0 0' }}>
                          <img
                            className="em_img"
                            src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/reward.png"
                            style={{ width: '320px', display: 'block' }}
                            width="320"
                            height="200"
                            border="0"
                            alt="Image"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="550"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '550px' }}
                          >
                            <tr>
                              <td
                                align="center"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '22px',
                                  fontWeight: 'normal',
                                  color: '#535353',
                                  padding: '20px 0 0 0',
                                  fontStyle: 'normal'
                                }}
                              >
                                You’ve renewed your GIO insurance which means
                                your ${initialValue} worth of GIO rewards credit
                                has been turned into a digital Prepaid Visa card
                                for you to enjoy. Now all you have to do is know
                                how to spend it.
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="center"
                                style={{ padding: '28px 0 30px 0' }}
                              >
                                <table
                                  align="center"
                                  width="212"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '212px',
                                    backgroundColor: ':#ED1B2E',
                                    borderRadius: '4px'
                                  }}
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{
                                        fontFamily:
                                          'Neuzeit S LT Std, Arial, Tahoma',
                                        fontSize: '13px',
                                        lineHeight: '20px',
                                        fontWeight: 'bold',
                                        color: '#FFFFFF',
                                        padding: '15px 0 13px 0',
                                        textTransform: 'uppercase',
                                        fontStyle: 'normal',
                                        backgroundColor: '#ED1B2E',
                                        borderRadius: '4px'
                                      }}
                                    >
                                      <a
                                        href={redemtionLink}
                                        style={{
                                          display: 'block',
                                          textDecoration: 'none',
                                          color: '#FFFFFF'
                                        }}
                                      >
                                        Receive your reward
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', backgroundColor: '#F7F7F7' }}
                // style="width:680px; background-color:#F7F7F7"
              >
                <tr>
                  <td
                    align="center"
                    style={{ padding: '0 15px 20px 15px' }}
                    // style="padding:0 15px 20px 15px"
                  >
                    <table
                      className="em_wrapper"
                      align="center"
                      width="620"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '620px', backgroundColor: '#FFFFFF' }}
                      // style="width:620px; background-color:#FFFFFF"
                    >
                      <tr>
                        <td
                          align="center"
                          style={{ padding: '0 15px 0 15px' }}
                          // style="padding:0 15px 0 15px"
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="542"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '542px' }}
                            // style="width:542px;"
                          >
                            <tr>
                              <td
                                className="em_font22"
                                align="center"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '28px',
                                  lineHeight: '32px',
                                  fontWeight: 650,
                                  fontStyle: 'normal',
                                  color: '#535353',
                                  padding: '25px 0 0 0'
                                }}
                                // style="font-family:Neuzeit S LT Std, Arial, Tahoma; font-size:28px; line-height:32px;
                                //  font-weight:650; font-style:normal; color:#535353; padding:25px 0 0 0;"
                              >
                                Download your digital Prepaid Visa card with the
                                following steps.
                              </td>
                            </tr>

                            <tr>
                              <td align="center">
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="530"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{ width: '530px' }}
                                  // style="width:530px;"
                                >
                                  <tr>
                                    <td>
                                      <table
                                        className="em_wrapper"
                                        align="left"
                                        width="370"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '370px' }}
                                        // style="width:370px;"
                                      >
                                        <tr>
                                          <td>
                                            <table
                                              className="em_wrapper"
                                              align="left"
                                              width="150"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{ width: '150px' }}
                                              // style="width:150px;"
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  style={{
                                                    padding: '18px 0 0 0'
                                                  }}
                                                  // style="padding:18px 0 0 0"
                                                >
                                                  <img
                                                    src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/1.png"
                                                    // style="display:block"
                                                    style={{ display: 'block' }}
                                                    width="60"
                                                    height="60"
                                                    border="0"
                                                    alt="Image"
                                                  />
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  align="center"
                                                  style={{
                                                    fontFamily:
                                                      'Neuzeit S LT Std, Arial, Tahoma',
                                                    fontSize: '14px',
                                                    lineHeight: '18px',
                                                    fontWeight: 'normal',
                                                    fontStyle: 'normal',
                                                    color: '#535353',
                                                    padding:
                                                      '8px 10px 10px 10px'
                                                  }}
                                                  // style="font-family:Neuzeit S LT Std, Arial, Tahoma; Font-size:14px;  line-height:18px;
                                                  // font-weight:normal; font-style:normal; color:#535353; padding:8px 10px 10px 10px"
                                                >
                                                  Click on the button below to
                                                  enter your mobile number to
                                                  receive an SMS with a link to
                                                  download The Card Network
                                                  (TCN) App.
                                                </td>
                                              </tr>
                                            </table>

                                            <table
                                              className="em_wrapper"
                                              align="left"
                                              width="2"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{ width: '2px' }}
                                              // style="width:2px;"
                                            >
                                              <tr>
                                                <td></td>
                                              </tr>
                                            </table>

                                            <table
                                              className="em_wrapper"
                                              align="right"
                                              width="210"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{ width: '210px' }}
                                              // style="width:210px;"
                                            >
                                              <tr>
                                                <td
                                                  align="left"
                                                  valign="top"
                                                  className="em_hide"
                                                >
                                                  <table
                                                    className="em_hide"
                                                    align="left"
                                                    width="29"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '29px' }}
                                                    // style="width:29px;"
                                                  >
                                                    <tr>
                                                      <td
                                                        className="em_padding20"
                                                        align="center"
                                                        style={{
                                                          padding: '34px 0 0 0'
                                                        }}
                                                        // style="padding:34px 0 0 0"
                                                      >
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/arrow.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          // style="display:block"
                                                          width="29"
                                                          height="28"
                                                          border="0"
                                                          alt="Image"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>

                                                <td
                                                  align="left"
                                                  valign="top"
                                                  style={{
                                                    padding: '0 0 0 0'
                                                  }}
                                                  // style="padding:0 0 0 0"
                                                >
                                                  <table
                                                    className="em_wrapper"
                                                    align="center"
                                                    width="152"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '152px' }}
                                                    // style="width:152px;"
                                                  >
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        style={{
                                                          padding: '18px 0 0 0'
                                                        }}
                                                        // style="padding:18px 0 0 0"
                                                      >
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/2.png"
                                                          // style="display:block"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="60"
                                                          height="60"
                                                          border="0"
                                                          alt="Image"
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        style={{
                                                          fontFamily:
                                                            'Neuzeit S LT Std, Arial, Tahoma',
                                                          fontSize: '14px',
                                                          lineHeight: '18px',
                                                          fontWeight: 'normal',
                                                          fontStyle: 'normal',
                                                          color: '#535353',
                                                          padding:
                                                            '8px 10px 10px 10px'
                                                        }}
                                                        // style="font-family:Neuzeit S LT Std, Arial, Tahoma; Font-size:14px;
                                                        // line-height:18px; font-weight:normal; font-style:normal; color:#535353; padding:8px 10px 10px 10px"
                                                      >
                                                        You&apos;ll then see
                                                        your digital Prepaid
                                                        Visa card in the TCN
                                                        App. <br />
                                                        <br />
                                                        Already have the App?
                                                        The link will give you
                                                        access to your
                                                        personalised card.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>

                                                <td
                                                  align="left"
                                                  valign="top"
                                                  className="em_hide"
                                                >
                                                  <table
                                                    className="em_hide"
                                                    align="left"
                                                    width="29"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '29px' }}
                                                    // style="width:29px;"
                                                  >
                                                    <tr>
                                                      <td
                                                        className="em_padding20"
                                                        align="center"
                                                        style={{
                                                          padding: '34px 0 0 0'
                                                        }}
                                                        // style="padding:34px 0 0 0"
                                                      >
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/arrow.png"
                                                          // style="display:block"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="29"
                                                          height="28"
                                                          border="0"
                                                          alt="Image"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>

                                      <table
                                        className="em_wrapper"
                                        align="left"
                                        width="2"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '2px' }}
                                        // style="width:2px;"
                                      >
                                        <tr>
                                          <td></td>
                                        </tr>
                                      </table>

                                      <table
                                        className="em_wrapper"
                                        align="right"
                                        width="150"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '150px' }}
                                        // style="width:150px;"
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              padding: '18px 0 0 0'
                                            }}
                                            // style="padding:18px 0 0 0"
                                          >
                                            <img
                                              src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/GIO/Email/3.png"
                                              style={{ display: 'block' }}
                                              // style="display:block"
                                              width="60"
                                              height="60"
                                              border="0"
                                              alt="Image"
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '14px',
                                              lineHeight: '18px',
                                              fontWeight: 'normal',
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '8px 0 10px 0'
                                            }}
                                            // style="font-family:Neuzeit S LT Std, Arial, Tahoma; Font-size:14px;  line-height:18px;
                                            // font-weight:normal; font-style:normal; color:#535353; padding:8px 0 10px 0"
                                          >
                                            Add your digital Prepaid Visa card
                                            to your phone&apos;s wallet.
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="center"
                                style={{
                                  padding: '20px 0 0 0'
                                }}
                                // style="padding:20px 0 0 0"
                              >
                                <table
                                  align="center"
                                  width="212"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '212px',
                                    backgroundColor: '#ED1B2E',
                                    borderRadius: '4px'
                                  }}
                                  // style="width:212px; background-color:#ED1B2E; border-radius:4px"
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{
                                        fontFamily:
                                          'Neuzeit S LT Std, Arial, Tahoma',
                                        fontSize: '13px',
                                        lineHeight: '20px',
                                        fontWeight: 'bold',
                                        fontStyle: 'normal',
                                        color: '#FFFFFF',
                                        padding: '15px 0 13px 0',
                                        textTransform: 'uppercase',
                                        backgroundColor: '#ED1B2E',
                                        borderRadius: '4px'
                                      }}
                                      // style="font-family:Neuzeit S LT Std, Arial, Tahoma; font-size:13px; line-height:20px;
                                      //  font-weight:bold; font-style:normal; color:#FFFFFF; text-transform:uppercase; padding:15px 0 13px 0;"
                                    >
                                      <a
                                        href={redemtionLink}
                                        style={{
                                          display: 'block',
                                          textDecoration: 'none',
                                          color: '#FFFFFF'
                                        }}
                                        // style="display:block; text-decoration:none; color:#FFFFFF"
                                      >
                                        Receive your reward
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="center"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '18px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#535353',
                                  padding: '25px 5px 25px 5px'
                                }}
                                // style="font-family:Neuzeit S LT Std, Arial, Tahoma; font-size:14px; line-height:18px; font-weight:normal;
                                //  font-style:normal; color:#535353; padding:25px 5px 25px 5px;"
                              >
                                The link within your SMS will expire three
                                months after you receive it. You must download
                                your digital Prepaid Visa card within this time,
                                or you will lose the rewards balance within the
                                card.
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', backgroundColor: '#F7F7F7' }}
                // style="width:680px; background-color:#F7F7F7"
              >
                <tr>
                  <td
                    align="center"
                    style={{ padding: '0 15px 0 15px' }}
                    // style="padding:0 15px 0 15px"
                  >
                    <table
                      className="em_wrapper"
                      align="center"
                      width="620"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '620px', backgroundColor: '#FFFFFF' }}
                      // style="width:620px; background-color:#FFFFFF"
                    >
                      <tr>
                        <td
                          align="center"
                          style={{ padding: '0 15px 0 15px' }}
                          // style="padding:0 15px 0 15px"
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="560"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '560px' }}
                            // style="width:560px;"
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '24px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#2A2A2A',
                                  padding: '30px 0 30px 0'
                                }}
                                // style="font-family:Neuzeit S LT Std, Arial, Tahoma; font-size:14px;
                                // line-height:24px; font-weight:normal; font-style:normal; color:#2A2A2A; padding:30px 0 30px 0;"
                              >
                                If you need help redeeming your reward, get in
                                touch with the support team at The Card Network
                                on{' '}
                                <a
                                  href="tel:1300 375 346"
                                  style={{
                                    color: '#2A2A2A',
                                    textDecoration: 'none'
                                  }}
                                  // style="text-decoration:none; color:#2A2A2A"
                                >
                                  1300 375 346
                                </a>
                                .<br />
                                <br />
                                Available Monday to Friday, 9am – 6pm and
                                Saturday, 10am – 5pm AEDT.
                                <br />
                                <br />
                                Take Care,
                                <br />
                                The GIO Rewards Team
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', backgroundColor: '#F7F7F7' }}
                // style="width:680px; background-color:#F7F7F7"
              >
                <tr>
                  <td
                    align="center"
                    style={{
                      padding: '0 15px 0 15px'
                    }}
                  >
                    <table
                      className="em_wrapper"
                      align="center"
                      width="560"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '560px' }}
                    >
                      <tr>
                        <td
                          align="center"
                          style={{
                            padding: '0 15px 0 15px'
                          }}
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="560"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '560px' }}
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '10px',
                                  lineHeight: '15px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#535353',
                                  padding: '15px 0 130px 0'
                                }}
                              >
                                This email has been sent by TCN Group Pty Ltd
                                ABN 87 626 501 568 (‘TCN’), issuer of the
                                digital Prepaid Visa Card (“Card”). TCN is not a
                                related company of AAI Limited trading as GIO.
                                Full GIO Rewards terms and conditions are
                                available{' '}
                                <a
                                  href="https://gio.com.au/rewards-terms.html"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                . You are responsible for all transactions on
                                the Card, except where there has been fraud or
                                negligence by TCN staff or agents. TCN may
                                restrict or stop the use of the Card if
                                suspicious activities are noticed. You are
                                responsible for checking your transaction
                                history, knowing the available balance and date
                                of expiry for the Card. Balance and transaction
                                history are available to you within the TCN App.
                                Any changes to the Card Terms and Conditions can
                                be viewed{' '}
                                <a
                                  href="https://thinkwink.com.au/tos"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                . Information will be disclosed to third parties
                                about the Card, or transactions made with the
                                Card, whenever allowed by law, and also where
                                necessary to operate the Card and process
                                transactions. TCN’s privacy policy can be viewed{' '}
                                <a
                                  href="https://thecardnetwork.com.au/pages/privacy-policy"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                .
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <Loader loading={loading} />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
        successMessage=""
      />
    </>
  );
};

export default AAMIEdm;
