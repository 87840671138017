import React from 'react';

import ActivityLogList from './activity-log-list';
import './ActivityLogList.scss';
import Navbar from '../../components/Navbar';

const ActivityLogs = () => {
  return (
    <>
    <Navbar />
      <div className="min-h-full flex justify-center items-center campain-table-container container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3 color-white">Activity Log</h2>
          </div>
          <ActivityLogList />
        </div>
      </div>
    </>
  );
};

export default ActivityLogs;
