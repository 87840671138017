let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

const validateForm = (isEditUser, data) => {
  let errors = { hasError: false, msg: {} };

  if (!data.department) {
    errors.hasError = true;
    errors.department = true;
  }

  if (typeof data.userType !== 'number') {
    errors.hasError = true;
    errors.userType = true;
  }

  if (!data.firstName) {
    errors.hasError = true;
    errors.firstName = true;
  }

  if (!data.lastName) {
    errors.hasError = true;
    errors.lastName = true;
  }

  if (!data.email) {
    errors.hasError = true;
    errors.email = true;
  }

  if (!isEditUser && !data.password) {
    errors.hasError = true;
    errors.password = true;
  }

  if (!isEditUser && !errors.password && !strongPassword.test(data.password)) {
    errors.hasError = true;
    errors.password = true;
    errors.msg.password =
      '*Password is weak (Should be atleast 8 character long, one lowercase letter, one uppercase letter and one digit )';
  }

  if (
    isEditUser &&
    data.password &&
    !strongPassword.test(data.password)
  ) {
    errors.hasError = true;
    errors.password = true;
    errors.msg.password =
      '*Password is weak (Should be atleast 8 character long, one lowercase letter, one uppercase letter and one digit )';
  }

  return errors;
};

export default validateForm;
