import React, { useEffect, useState } from 'react';
import TableHeader from "./table-header";
import TableBody from "./table-body";
import { getAllUserLogsAPI } from '../../../services/api';
import ViewCardModel from '../view-log-modal';
import Notify from '../../../components/notify';

const UserLogsList = () => {
  const [userLogs, setUserLogs]             = useState([]);
  const [selectedLog, setSelectedLog]       = useState([]);
  const [userLogsFilter, setUserLogsFilter] = useState([]);
  const [loading, setLoading]               = useState(true);
  const [errors, setErrors] = useState({});
  const [openNotify, setOpenNotify] = useState(false);
  const [openViewLogModal, setOpenViewLogModal] = useState(false);

  useEffect(() => {
    getAllUserInfo();
  }, []);

  const getAllUserInfo = async () => {
    try {
      const userLogs = await getAllUserLogsAPI();
      setUserLogs(userLogs);
      setUserLogsFilter(userLogs);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Departments Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';
    const filteredRow = [];

    userLogs.forEach((subUser) => {
      const fullName = `${subUser.first_name || ''} ${subUser.last_name || ''}`;
      const name = fullName.toLowerCase() || '';
      const email = subUser?.email?.toLowerCase() || '';

      if (
        name.includes(searchText) ||
        email.includes(searchText)
      ) {
        filteredRow.push(subUser);
      }
    });
    setUserLogsFilter(filteredRow);
  }

  const toggleViewLogModel = (val) => {
    setOpenViewLogModal(val);
  };

  const handleViewLogs = (rowId) => {
    const log = userLogs.find(log => log.id === rowId)
    setSelectedLog(log);
    toggleViewLogModel(true);
  }

  return (
    <>
      <TableHeader handleSearch={handleSearch} />
      <TableBody usersLogs={userLogsFilter} handleViewLogs={handleViewLogs} />
      <ViewCardModel
        modalIsOpen={openViewLogModal}
        closeModal={() => toggleViewLogModel(false)}
        selectedLog={selectedLog}
      />
      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default UserLogsList;