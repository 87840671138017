import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.scss';

import BulkIssueCard from "./pages/bulk-card-issue/BulkCardIssue";
import BulkCardValidationPreview from "./pages/bulk-card-issue/bulk-card-validation-preview/BulkCardValidationPreview"
import IssueCardInitialStep from "./pages/issue-card/initialStep";
import IssueCardFinalStep from './pages/issue-card/finalStep';
import Login from "./pages/login/Login";
import LoginTest from './pages/login-test/Login';
import BulkCardFinalize from "./pages/bulk-card-issue/bulk-card-finalize";
import Dashboard from "./pages/dashboard";
import Campaign from "./pages/campaign";
import CreateCampaign from './pages/campaign/create-campaign';
import UserList from "./pages/users";
import CreateNewUser from "./pages/users/create-new-user";
import EditUser from "./pages/users/edit-user";
import DepartmentList from './pages/departments';
import CreateNewDepartment from './pages/departments/create-new-department';
import EditDepartment from './pages/departments/edit-department';
import IssuanceTypeList from './pages/issuance-type';
import CreateNewIssuanceType from './pages/issuance-type/create-new-issuance-type';
// import EditIssuanceType from "./pages/issuance-type/edit-issuance-type";
import ResetPassword from "./pages/reset-password";
import PrivateRoute from "./components/route-check/PrivateRoute";
import AuthorizedRoute from './components/route-check/AuthorizedRoute';
import Setting from './pages/settings';
import CustomRedeemFinalStep from './pages/custom-redeem/finalStep';
import CustomRedeemIntialStep from './pages/custom-redeem/intialStep';
import RedeemSuccess from "./pages/custom-redeem/redeem-success";
import PageNotFound from "./pages/page-not-found";
import AgentIssuance from './pages/agent-issuance';
import Home from './pages/home';
import EdmGio from './pages/custom-redeem/edm/gio';
import EdmAami from './pages/custom-redeem/edm/aami';
import EdmSuncorp from './pages/custom-redeem/edm/suncorp';
import EdmSuncorpBanking from './pages/custom-redeem/edm/suncorp-banking';
import EdmApia from './pages/custom-redeem/edm/apia';
import UatEdmApia from './pages/custom-redeem/edm/uat-apia';
import ManageAgentIssuance from './pages/agent-issuance/manage-agent-issuance';
import MfaCheckPoint from './pages/mfa-checkpoint';
import UserLogsList from './pages/user-logs';
import ForgetPassword from './pages/forget-password';
import LoginAttemptsLogs from './pages/global-read-only-tables/login-attempts-logs';
import AllUsersList from './pages/global-read-only-tables/users';
import WhiteListIps from './pages/global-read-only-tables/ip-white-lists';
import AgentIssuanceLogs from './pages/global-read-only-tables/agent-issuance-logs';
import IssuedCardLogs from './pages/global-read-only-tables/issued-card-logs';
import WhiteListIpsLogs from './pages/global-read-only-tables/ip-white-lists-logs';

import MfaGuideJourney from './pages/login/mfa-guide-journey';
import ForgetPasswordConfirmation from './pages/forget-password/forget-password-confirmation';
import ManageWhiteListIpList from './pages/manage-whitelist-ips'
import CreateManageWhiteListIp from "./pages/manage-whitelist-ips/create-manage-ip";
import EditManageIp from "./pages/manage-whitelist-ips/edit-manage-ip";
import SignInOidc from "./pages/login-crown-sso/signin-oidc";
import CrownSSO from "./pages/login-crown-sso/Login";
import ActivityLog from "./pages/activity-log";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            path="/activity-log"
            component={ActivityLog}
          />
          <Route
            path="/globalreadonlytables/userlogins"
            component={LoginAttemptsLogs}
          />
          <Route
            path="/globalreadonlytables/userlist"
            component={AllUsersList}
          />
          <Route
            path="/globalreadonlytables/ipwhitelists"
            component={WhiteListIps}
          />
          <Route
            path="/globalreadonlytables/agentissuancelogs"
            component={AgentIssuanceLogs}
          />
          <Route
            path="/globalreadonlytables/issuecardlogs"
            component={IssuedCardLogs}
          />
          <Route
            path="/globalreadonlytables/ipwhitelistslogs"
            component={WhiteListIpsLogs}
          />
          <Route path="/11246/edm/:identifier" component={EdmAami} />
          <Route path="/11247/edm/:identifier" component={EdmGio} />
          <Route path="/11248/edm/:identifier" component={EdmSuncorp} />
          <Route path="/11248/edm2/:identifier" component={EdmSuncorpBanking} />
          <Route path="/11249/edm/:identifier" component={EdmApia} />
          <Route path="/11459/edm/:identifier" component={UatEdmApia} />
          <Route
            path="/:promotionId/redeem/success"
            component={RedeemSuccess}
          />
          <Route
            path="/:promotionId/redeem/:cardIdentifier"
            component={CustomRedeemIntialStep}
          />
          <Route
            path="/:promotionId/redeem"
            component={CustomRedeemFinalStep}
          />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute
            path="/agent-issuance/:cardId/card-proxy/:cardProxy"
            component={ManageAgentIssuance}
          />
          <PrivateRoute path="/agent-issuance" component={AgentIssuance} />
          <AuthorizedRoute path="/user/list" component={UserList} />
          <AuthorizedRoute path="/user/logs" component={UserLogsList} />
          <AuthorizedRoute path="/user/edit/:id" component={EditUser} />
          <AuthorizedRoute
            path="/department/create"
            component={CreateNewDepartment}
          />
          <AuthorizedRoute path="/department/list" component={DepartmentList} />
          <AuthorizedRoute
            path="/department/edit/:id"
            component={EditDepartment}
          />

          <AuthorizedRoute
            path="/issuance-type/list"
            component={IssuanceTypeList}
          />
          <AuthorizedRoute
            path="/issuance-type/create"
            component={CreateNewIssuanceType}
          />
          {/* <AuthorizedRoute
            path="/issuance-type/edit/:id"
            component={EditIssuanceType}
          /> */}

          <AuthorizedRoute
            path="/manage-whitelist-ips/list"
            component={ManageWhiteListIpList}
          />
          <AuthorizedRoute
            path="/manage-whitelist-ips/create"
            component={CreateManageWhiteListIp}
          />
          <AuthorizedRoute
            path="/manage-whitelist-ips/edit/:id"
            component={EditManageIp}
          />

          <AuthorizedRoute path="/user/create" component={CreateNewUser} />
          <PrivateRoute path="/bulkCard" component={BulkIssueCard} />
          <PrivateRoute
            path="/card-validation-preview"
            component={BulkCardValidationPreview}
          />
          <PrivateRoute
            path="/card-order-finalize"
            component={BulkCardFinalize}
          />
          <PrivateRoute
            path="/issue-card-initial"
            component={IssueCardInitialStep}
          />
          <PrivateRoute
            path="/issue-card-final"
            component={IssueCardFinalStep}
          />
          <PrivateRoute path="/campaign/create" component={CreateCampaign} />
          <PrivateRoute path="/campaign" component={Campaign} />
          <Route path="/login/mfa-guide" component={MfaGuideJourney} />
          <Route path="/login" component={Login} />
          <Route
            path="/forget-password/confirmation"
            component={ForgetPasswordConfirmation}
          />
          <Route path="/forget-password" component={ForgetPassword} />

          <Route path="/checkpoint/mfa" component={MfaCheckPoint} />
          <Route path="/login-test" component={LoginTest} />
          <Route path="/crownsso" component={CrownSSO} />

          <Route path="/signin-oidc" component={SignInOidc} />

          <Route path="/reset-password/:token" component={ResetPassword} />
          <PrivateRoute path="/testmode" component={Setting} />
          <Route exact path="/" component={Home} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
