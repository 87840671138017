import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ManageWhiteListIpsForm from '../../../components/manage-whitelist-ip-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from '../../../components/notify';
import { updateManageIpsAPI } from '../../../services/api';
import './EditManageIp.scss';

const EditManageIp = () => {
  const whiteListIpsReducer                    = useSelector((state) => state.whiteListIps.whiteListIps);

  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);
  const history = useHistory();
  const params  = useParams();
  const whiteListIpId  = params.id;
  const manageIp = whiteListIpsReducer?.ipLists?.find(whiteListIp => whiteListIp.id === whiteListIpId);

  useEffect(() => {
    if (whiteListIpsReducer.length === 0) {
      redirectToManageIpList();
    }

  }, []);

  const redirectToManageIpList = () => {
    history.push('/manage-whitelist-ips/list');
  };

  const handleSubmit = async(payload) => {
    try {
      setLoading(true);
      const response = await updateManageIpsAPI(whiteListIpId, payload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        redirectToManageIpList();
      }, 1000);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Issuance Type Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h2>Edit Manage Ip Address</h2>
          </div>
          <ManageWhiteListIpsForm
            manageIp={manageIp}
            handleSubmit={handleSubmit}
            redirectToManageIpList={redirectToManageIpList}
            loading={loading}
          />
          <Notify
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            errors={errors}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditManageIp;
