import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DepartmentForm from '../../../components/department-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from '../../../components/notify';
import { updateDepartmentAPI } from '../../../services/api';
import './EditUser.scss';

const EditUser = () => {
  const departmentReducer                       = useSelector((state) => state.department.departments);
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);
  const history = useHistory();
  const params  = useParams();
  const departmentId  = params.id;
  const department = departmentReducer.find(department => department.id === departmentId);

  useEffect(() => {
  if (departmentReducer.length === 0) {
    redirectToDepartmentList();
  }
  }, [])

  const redirectToDepartmentList = () => {
    history.push('/department/list');
  };

  const handleSubmit = async(payload) => {
    try {
      setLoading(true);
      const response = await updateDepartmentAPI(departmentId, payload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        redirectToDepartmentList();
      }, 1000);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Department Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h3>Edit Department</h3>
          </div>
          <DepartmentForm
            department={department}
            loading={loading}
            handleSubmit={handleSubmit}
            redirectToDepartmentList={redirectToDepartmentList}
          />
          <Notify
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            errors={errors}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditUser;
