import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

import SelectInput from '../Select';
import validateForm from './validateForm.helper';
import "./IssuanceTypeForm.scss";

const ManageWhiteListIpsForm = ({
  manageIp,
  loading = true,
  handleSubmit,
  redirectToManageIpList
}) => {
  const isEditManageWhiteListIp = Object.keys(manageIp).length > 0;

  const [ipAddress, setIpAddress] = useState(manageIp?.ipAddress || '');
  const [status, setStatus] = useState(typeof manageIp?.status === 'number' ? manageIp?.status : 1 );
  const [testEnvironmentFlag, setTestEnvironmentFlag] = useState(typeof manageIp?.testEnvironmentFlag === 'number' ? manageIp?.testEnvironmentFlag : 0 );
  const [validationErrors, setValidationErrors] = useState({});

  const submit = () => {
    const payload = { ipAddress: ipAddress?.trim(), testEnvironmentFlag, status };
    const validate = validateForm(payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleSubmit(payload);
  };

  return (
    <div className="issuance-type-form-container">
      <TextField
        id="standard-basic"
        label="Ip Address"
        type="text"
        name="ipAddress"
        className='mb-2'
        helperText={validationErrors?.msg?.ipAddress || '*Is required'}
        error={validationErrors.ipAddress}
        value={ipAddress}
        inputProps={{ readOnly: isEditManageWhiteListIp }}
        onChange={(e) => setIpAddress(e.target.value)}
      />

      <FormControl className='mb-2' variant="standard" error={validationErrors.testEnvironmentFlag}>
        <InputLabel id="demo-simple-select-standard-label">Test Env</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="testEnvironmentFlag"
          value={testEnvironmentFlag}
          onChange={(e) => setTestEnvironmentFlag(e.target.value)}
          inputProps={{ readOnly: isEditManageWhiteListIp }}
          className="mt-4"
        >
          <MenuItem value={1}>True</MenuItem>
          <MenuItem value={0}>False</MenuItem>
        </Select>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={validationErrors.status}>
        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="status"
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className="mt-4"
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={0}>Inactive</MenuItem>
        </Select>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      <div className="action-footer">
        <Button
          disabled={loading}
          variant="outlined"
          endIcon={
            loading ? (
              <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
            ) : (
              <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
            )
          }
          onClick={submit}
        >
          Continue
        </Button>
        <Button variant="outlined" onClick={redirectToManageIpList}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

ManageWhiteListIpsForm.propTypes = {
  manageIp: PropTypes.object,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  redirectToManageIpList: PropTypes.func
};

export default ManageWhiteListIpsForm;