import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectInput from "../../../../../components/Select";

const FilterHeader = ({
  filter,
  users,
  isLoading,
  handleFilter,
  handleClearFilter,
  handleSetFilter
}) => {
  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="table-filter-body">
            <div className="form-container">
              <div className="flex add-spacing field-container">
                <FormControl variant="standard" style={{ minWidth: 350 }}>
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="TCN Customer Id"
                    name="tcnCustomerId"
                    value={filter.tcnCustomerId}
                    type="number"
                    onChange={(e) =>
                      handleSetFilter({ tcnCustomerId: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl style={{ minWidth: 350 }} variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Environment
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="environment"
                    value={filter.environment}
                    onChange={(val) => handleSetFilter({ environment: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={true}>UAT</MenuItem>
                    <MenuItem value={false}>PROD</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard" style={{ minWidth: 350 }}>
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="Campaign Id"
                    name="campaignId"
                    value={filter.campaignId}
                    type="number"
                    onChange={(e) =>
                      handleSetFilter({ campaignId: e.target.value })
                    }
                  />
                </FormControl>
              </div>
              <div className="flex add-spacing field-container">
                <FormControl variant="standard" style={{ minWidth: 350 }}>
                    <TextField
                      className="product-input search-field"
                      id="standard-basic"
                      label="Product Id"
                      name="productId"
                      value={filter.productId}
                      type="number"
                      onChange={(e) =>
                        handleSetFilter({ productId: e.target.value })
                      }
                    />
                  </FormControl>
                <div className="agent-issuance-button-container log-date-container ">
                  <span>Date Range</span>
                  <DatePicker
                    selectsRange={true}
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onClickOutside={() =>
                      handleSetFilter({ startDate:  new Date(), endDate:  new Date() })
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(update) => {
                      handleSetFilter({
                        startDate: update[0],
                        endDate: update[1]
                      });
                    }}
                    isClearable={true}
                    className="date-picker-field campaign-input"
                  />
                </div>
                <FormControl style={{ minWidth: 350 }} variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    User Email
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="userId"
                    value={filter.userId}
                    onChange={(val) => handleSetFilter({ userId: val })}
                  >
                    <MenuItem value="All">All</MenuItem>
                    {users?.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.email}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
              </div>
              <div className="issued-card-filter-buttons">
                <div className="filter-buttons">
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleFilter}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleClearFilter}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.object,
  users: PropTypes.array,
  agents: PropTypes.object,
  departments: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  handleExportTable: PropTypes.func,
  handleClearFilter: PropTypes.func
};


export default FilterHeader;