import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  exportAgentIssuanceDataAPI,
  getAllDepartmentsAPI,
  getCardsAPI,
  getSubUsersAPI} from '../../../services/api';
import Loader from '../../../components/loader';
import Notify from '../../../components/notify';
import CardTable from './card-table';
import { setSelectedAgentIssuance } from '../../../store/slices/agentIssuance';
import FilterHeader from './filter-header';
import { format } from 'date-fns';
import { getDefaultFilterValues, getInitialFilterHeader } from './initialValues';
import YesNoDialog from '../../../components/yes-no-dialog';

const IssuedCards = ({ customerInfo }) => {
  const defaultFilterValue = getDefaultFilterValues();
  const initialFilterHeader = getInitialFilterHeader();
  const [agents, setAgents] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [issuedCards, setIssuedCards] = useState([]);
  const [openNotify, setOpenNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState(initialFilterHeader);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getCards({
      ...defaultFilterValue,
      limit: 10
    });
    getDepartments();
    getSubUsers();
  }, []);

  const getCards = async (data = {}) => {
    try {
      const response = await getCardsAPI(data, false);
      setIssuedCards(response?.issuedcard || []);
      setTotalRowCount(response.totalcount);
      setLoading(false);
    } catch (err) {
      setIssuedCards([]);
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getSubUsers = async () => {
    try {
      const response = await getSubUsersAPI({});
      setAgents(response?.users || []);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getDepartments = async () => {
    try {
      const departments = await getAllDepartmentsAPI();
      setDepartments(departments);
      setIsLoading(false);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      ...mapFilterValues(),
      limit: rowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      ...mapFilterValues(),
      limit: newRowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const handleMangeCard = (row) => {
    dispatch(setSelectedAgentIssuance(row));
    history.push(
      `/agent-issuance/${row.cardId}/card-proxy/${row.cardActivationCode}`
    );
  };

  const mapFilterValues = () => {
    const {
      promotion,
      departmentId,
      agent,
      cardCancel,
      referenceId,
      search,
      sortingcolumn,
      sortingorder,
      cardIssuedStartDate,
      cardIssuedEndDate,
      searchParams,
      productId,
      serialStart,
      serialEnd,
      cardType
    } = filter;

    let searchValue,
      customerfirstname,
      customerlastname,
      customeraddress1,
      customeremail = '';
    if (search && searchParams === 'firstname') {
      customerfirstname = search;
      searchValue = '';
    } else if (search && searchParams === 'lastname') {
      customerlastname = search;
      searchValue = '';
    } else if (search && searchParams === 'email') {
      customeremail = search;
      searchValue = '';
    } else if (search && searchParams === 'company') {
      customeraddress1 = search;
      searchValue = '';
    } else {
      searchValue = search;
    }

    return {
      promotion: promotion === 'All' ? '' : promotion,
      cardvariant: cardType === 'All' ? '' : cardType,
      departmentId: departmentId === 'All' ? '' : departmentId,
      agent: agent === 'All' ? '' : agent,
      referenceId,
      search: searchValue,
      cardCancel: cardCancel === 'All' ? '' : cardCancel,
      cardIssuedStartDate: cardIssuedStartDate
        ? format(new Date(cardIssuedStartDate), `yyyy-MM-dd`)
        : '',
      cardIssuedEndDate: cardIssuedEndDate
        ? format(new Date(cardIssuedEndDate), `yyyy-MM-dd`)
        : '',
      sortingorder,
      sortingcolumn,
      customerfirstname,
      customerlastname,
      customeremail,
      customeraddress1,
      product: productId === 'All' ? '' : productId,
      serialStart,
      serialEnd
    };
  };

  const handleExportData = async () => {
    try {
      setLoading(true);
      toggleYesNoModel(false);
      const payload = mapFilterValues();
      const response = await exportAgentIssuanceDataAPI(
        { ...payload, ...defaultFilterValue, limit: '5000' },
        false
      );
      let byteArray = new Uint8Array(response.data);
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      a.download = 'AgentIssuedCards.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
    } catch (err) {
      toggleYesNoModel(false);
      setLoading(false);
    }
  };

  const handleFilter = () => {
    const payload = mapFilterValues();
    setLoading(true);
    getCards({
      ...defaultFilterValue,
      ...payload,
      limit: 10
    });
  };

  const handleClearFilter = () => {
    setFilter(initialFilterHeader);
    setLoading(true);
    getCards({
      ...defaultFilterValue,
      limit: 10
    });
  };

  const handleSetFilter = (payload) => {
    setFilter({
      ...filter,
      ...payload
    });
  };

  const handleSortTable = (property) => {
    setLoading(true);
    const isAsc =
      filter.sortingcolumn === property && filter.sortingorder === 'ASC';
    const newOrder = isAsc ? 'DESC' : 'ASC';
    setFilter({
      ...filter,
      sortingorder: newOrder,
      sortingcolumn: property
    });
    getCards(
      {
        ...defaultFilterValue,
        ...mapFilterValues(),
        sortingcolumn: property,
        sortingorder: newOrder
      },
      false
    );
  };

  const handleYes = async () => {
    handleExportData();
  };

  const toggleYesNoModel = (val) => {
    setOpenDialog(val);
  }

  return (
    <>
      <div className="issue-card-wrapper mt-4">
        <FilterHeader
          isLoading={isLoading}
          filter={filter}
          customerInfo={customerInfo}
          agents={agents}
          getCards={getCards}
          departments={departments}
          handleExportTable={() => toggleYesNoModel(true)}
          handleFilter={handleFilter}
          handleSetFilter={handleSetFilter}
          handleClearFilter={handleClearFilter}
        />

        <CardTable
          page={page}
          order={filter.sortingorder}
          orderBy={filter.sortingcolumn}
          handleSortTable={handleSortTable}
          issuedCards={issuedCards}
          totalRowCount={totalRowCount}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleMangeCard={handleMangeCard}
        />
      </div>

      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage="Successfully updated agent issuance!!"
      />
      <Loader loading={loading} />
      <YesNoDialog
        openDialog={openDialog}
        message={'Are you sure you want to export issued cards?'}
        handleCloseDialog={() => toggleYesNoModel(false)}
        handleYes={handleYes}
      />
    </>
  );
};

IssuedCards.propTypes = {
  issueCard: PropTypes.array,
  customerInfo: PropTypes.object
};

export default IssuedCards;
