const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };
  if (!data.name) {
    errors.hasError = true;
    errors.name = true;
  }

  if (!data.description) {
    errors.hasError = true;
    errors.description = true;
  }

  if (data.cloneExisting && !data.promotion) {
    errors.hasError = true;
    errors.promotion = true;
  }

  return errors;
};

export default validateForm;
