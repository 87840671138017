import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { useHistory } from 'react-router-dom';
import { format, add } from 'date-fns';
import { useDispatch } from 'react-redux';

import {
  exportAgentIssuanceDataAPI,
  getCardsAPI,
  updateAgentIssueAPI,
  updateBulkAgentIssueAPI,
  blockCardInBulkAPI,
  getAllDepartmentsAPI,
} from '../../../services/api';
import { hasAdminUserPermissionForCTA } from '../../../helpers/permission';
import CardTable from './card-table';
import Loader from '../../../components/loader';
import Notify from '../../../components/notify';
import CardModal from './card-modal';
import BulkCardModal from './bulk-card-modal';
import OptionsModel from './options-modal';
import validateForm from './validateForm.helper';
import BlockCardSummaryDialog from '../../../components/yes-no-dialog';
import YesNoDialog from '../../../components/yes-no-dialog';
import { setSelectedTab } from '../../../store/slices/agentIssuance';
import { formatMoney } from '../../../helpers/currency';
import { getDepartmentName, getTcnCustomerId } from '../../../helpers/localStorage';

const AvailableCards = ({ customerInfo, updateAvailableCards }) => {
  const [openBlockCardSummaryDialog, setOpenBlockCardSummaryDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [productId, setProductId] = useState('');
  const [issuedCards, setIssuedCards] = useState([]);
  const [serialStart, setSerialStart] = useState(null);
  const [serialEnd, setSerialEnd] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [openNotify, setOpenNotify] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [validationErrors, setValidationErrors] = useState({});
  const [disableProceed, setDisableProceed] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [order, setOrder] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [departments, setDepartments] = useState([]);
  const [issuanceTypes, setIssuanceTypes] = useState([]);
  const [department, setDepartment] = useState(getDepartmentName());

  const history = useHistory();
  const dispatch = useDispatch();

  const defaultFilterValue = {
    promotion: getTcnCustomerId() === 1199 ? '13216' : '',
    createend: format(add(new Date(), { days: 1 }), `yyyy-MM-dd`),
    createstart: '2023-01-01',
    agentissueflag: '0',
    dispatchflag: '1',
    status: 'reserved',
    blockflag: '0',
    cardvariant: 'physical',
    requireagentissuance: '1'
  };

  useEffect(() => {
    setLoading(true);
    getCards(
      {
        ...defaultFilterValue,
        limit: rowsPerPage
      },
      false,
      true
    );
    getDepartments();
  }, []);

  const getDepartments = async () => {
    try {
      const departments = await getAllDepartmentsAPI();
      setDepartments(departments);
      if (!department) {
        setDepartment(departments?.[0]?.name || '');
      }
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Departments Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getCards = async (data = {}, agentIssuanceFlag, updateTotalCards = false) => {
    try {
      const response = await getCardsAPI(data, agentIssuanceFlag);
      if (updateTotalCards) {
        updateAvailableCards(response.totalcount || 0);
      }

      const filteredCards = response?.issuedcard?.filter((card) => customerInfo?.promotion?.find((promotion) => promotion.promotion_id === card.promotion_id)?.promotion_hide_flag === "FALSE");

      setIssuedCards(filteredCards || []);
      setTotalRowCount(response.totalcount);
      setLoading(false);
      setValidationErrors({});
      if (agentIssuanceFlag) {
        setDisableProceed(false);
      }
    } catch (err) {
      setIssuedCards([]);
      setErrors(err);
      setOpenNotify(true);
      setLoading(false);
      setDisableProceed(true);
    }
  };

  const handleSortTable = (property) => {
    setLoading(true);
    const isAsc = orderBy === property && order === 'ASC';
    const newOrder = isAsc ? 'DESC' : 'ASC';
    setOrder(newOrder);
    setOrderBy(property);
    getCards(
      {
        ...defaultFilterValue,
        product: productId,
        sortingcolumn: property,
        sortingorder: newOrder,
        limit: rowsPerPage,
        serialStart,
        serialEnd
      },
      false
    );
  };

  const handleFilter = () => {
    const validate = validateForm({ serialStart, serialEnd, productId });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    setLoading(true);
    getCards(
      {
        ...defaultFilterValue,
        product: productId,
        sortingcolumn: orderBy,
        sortingorder: order,
        serialStart,
        serialEnd
      },
      true
    );
  };

  const handleBulkUpdate = () => {
    setOpenBulkModal(true);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      product: productId,
      sortingcolumn: orderBy,
      sortingorder: order,
      serialStart,
      serialEnd,
      limit: rowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    setLoading(true);
    await getCards({
      ...defaultFilterValue,
      product: productId,
      sortingcolumn: orderBy,
      sortingorder: order,
      serialStart,
      serialEnd,
      limit: newRowsPerPage,
      page: newPage
    });
    setLoading(false);
  };

  const toggleModel = (val) => {
    setOpenModal(val);
  };

  const toggleOptionsModel = (val) => {
    setOpenOptionsModal(val);
  };

  const toggleBulkModel = (val) => {
    setOpenBulkModal(val);
  };

  const handleProceed = () => {
    toggleOptionsModel(true);
  };

  const redirectToDashboard = () => {
    setLoading(true);
    setProductId('');
    setSerialStart('');
    setSerialEnd('');
    setDisableProceed(true);
    getCards(
      {
        ...defaultFilterValue,
        limit: rowsPerPage
      },
      false
    );
  };

  const updateAgentIssueCard = async (payload) => {
    setLoading(true);

    try {
      setLoading(true);
      await updateAgentIssueAPI({
        ...payload,
        serialStart,
        serialEnd,
        issuedCards
      });
      getCards({
        ...defaultFilterValue,
        limit: 10
      }, false, true);
      setLoading(false);
      setErrors({});
      setProductId('');
      setSerialStart('');
      setSerialEnd('');
      setOpenNotify(true);
      setDisableProceed(true);
      setSuccessMessage(
        'Your cards have been successfully issued. To view details refer to Issued Cards tab.'
      );
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Update Agent Issuance Failed !!!!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const updateBulkAgentIssueCard = async (payload) => {
    setLoading(true);
    try {
      setLoading(true);
      const updatedCard = await updateBulkAgentIssueAPI(payload);
      setLoading(false);
      setErrors({});
      setOpenNotify(true);
      toggleBulkModel(false);
      setSuccessMessage(
        'Your cards have been successfully issued. To view details refer to Issued Cards tab.'
      );
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Update Agent Issuance Failed !!!!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const exportData = async () => {
    setLoading(true);
    toggleYesNoModel(false);
    try {
      const response = await exportAgentIssuanceDataAPI(
        {
          ...defaultFilterValue,
          product: productId,
          sortingcolumn: orderBy,
          sortingorder: order,
          limit: '5000',
          serialStart,
          serialEnd
        },
        true
      );
      let byteArray = new Uint8Array(response.data);
      let a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(
        new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        })
      );
      a.download = 'AgentAvailableCards.xlsx';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleOptionsModal = () => {
    setOpenModal(true);
    setOpenOptionsModal(false);
  };

  const handleBlockCardSubmit = async () => {
    setLoading(true);
    setOpenOptionsModal(false);
    setOpenBlockCardSummaryDialog(false);
    try {
      setLoading(true);
      const bulkCardCancel = await blockCardInBulkAPI(issuedCards);
      getCards({
        ...defaultFilterValue,
        limit: 10
      });
      setLoading(false);
      setErrors({});
      setOpenNotify(true);
      toggleBulkModel(false);
      setSuccessMessage('Your cards have been successfully blocked.');
      dispatch(setSelectedTab(2));
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Update Agent Issuance Failed !!!!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const toggleBlockCardSummaryDialog = (val) => {
    setOpenBlockCardSummaryDialog(val);
  };

  const getSelectedTotalCardValue = (selectedIssuedCards) => {
    return selectedIssuedCards.reduce((total, current) => {
      return total + parseInt(current.card_initialvalue);
    }, 0);
  };

  const renderBulkCardSummary = () => {
    return (
      <div>
        By clicking Yes, you will be blocking the selected cards, as per the
        below summary: <br />
        <b>Product Name:</b> {issuedCards[0]?.product_name}
        <br />
        <b>Campaign Name:</b> {issuedCards[0]?.promotion_name}
        <br />
        <b>No. of Card Selected:</b> {totalRowCount}
        <br />
        <b>Total Card Value:</b>{' '}
        {formatMoney(getSelectedTotalCardValue(issuedCards))}
        <br />
        <br />
        <b>Are you sure you want to continue? </b>
      </div>
    );
  };

  const handleYes = async () => {
    exportData();
  };

  const toggleYesNoModel = (val) => {
    setOpenDialog(val);
  };

  const navigateToIssueCardPage = () => {
    history.push(`/issue-card-initial?agentIssuance=digital`);
  };

  return (
    <>
      <div className="agent-issuance-fields">
        <FormControl variant="standard" error={validationErrors.productId}>
          <InputLabel id="demo-simple-select-standard-label">
            Product
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            name="productId"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
          >
            {customerInfo?.product?.map((product) => (
              <MenuItem key={product.product_id} value={product.product_id}>
                {product.product_id}&nbsp;-&nbsp;
                {product.product_name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>*Is required.</FormHelperText>
        </FormControl>
        <TextField
          id="standard-number"
          label="Start Serial"
          type="number"
          name="serialStart"
          value={serialStart}
          helperText="*Is required."
          InputProps={{ inputProps: { min: 0 } }}
          error={validationErrors.serialStart}
          onChange={(e) => setSerialStart(e.target.value)}
        />

        <TextField
          id="standard-number"
          label="End Serial"
          type="number"
          name="serialEnd"
          value={serialEnd}
          helperText={`${validationErrors?.serialEnd
            ? validationErrors?.msg?.serialEnd || '*Is required.'
            : '*Is required.'
            }`}
          InputProps={{ inputProps: { min: 0 } }}
          error={validationErrors.serialEnd}
          onChange={(e) => setSerialEnd(e.target.value)}
        />
      </div>
      <div className="agent-issuance-filter">
        <Button
          variant="outlined"
          onClick={handleFilter}
          className="issuance-btn"
        >
          Apply serial range
        </Button>
        <Button
          variant="outlined"
          onClick={handleBulkUpdate}
          className="issuance-btn"
        >
          Bulk Card Issuance
        </Button>
      </div>
      <div className="agent-issuance-filter">
        <Button
          variant="outlined"
          onClick={() => toggleYesNoModel(true)}
          className="issuance-btn"
        >
          Export Available Serial Range
        </Button>
        {customerInfo?.customer?.[0]?.customer_allowagentissuancedigital ===
          'TRUE' && (
            <Button
              variant="outlined"
              onClick={navigateToIssueCardPage}
              className="issuance-btn"
            >
              Issue Digital
            </Button>
          )}
      </div>
      <div className="issue-card-wrapper mt-4">
        <CardTable
          page={page}
          issuedCards={issuedCards}
          totalRowCount={totalRowCount}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortTable={handleSortTable}
          order={order}
          orderBy={orderBy}
        />
      </div>

      <div className="action-footer mt-4">
        <Button variant="outlined" onClick={redirectToDashboard}>
          Clear Filter
        </Button>
        <Button
          variant="outlined"
          endIcon={<ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />}
          onClick={handleProceed}
          disabled={!hasAdminUserPermissionForCTA() || disableProceed}
        >
          Proceed
        </Button>
      </div>
      <Notify
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage={successMessage}
      />
      <Loader loading={loading} />
      <CardModal
        modalIsOpen={openModal}
        closeModal={() => toggleModel(false)}
        data={{
          selectedCard: totalRowCount,
          productId,
          serialEnd,
          serialStart,
          campaignName: issuedCards[0]?.promotion_name,
          productName: issuedCards[0]?.product_name
        }}
        updateAgentIssueCard={updateAgentIssueCard}
        departments={departments}
        customerInfo={customerInfo}
        issuanceTypes={issuanceTypes}
      />
      <OptionsModel
        modalIsOpen={openOptionsModal}
        openAgentIssueModal={handleOptionsModal}
        closeModal={() => toggleOptionsModel(false)}
        handleBlockCard={() => toggleBlockCardSummaryDialog(true)}
      />
      <BulkCardModal
        modalIsOpen={openBulkModal}
        closeModal={() => toggleBulkModel(false)}
        updateBulkAgentIssueCard={updateBulkAgentIssueCard}
        department={department}
        departments={departments}
        customerInfo={customerInfo}
      />
      <BlockCardSummaryDialog
        openDialog={openBlockCardSummaryDialog}
        message={renderBulkCardSummary()}
        handleCloseDialog={() => toggleBlockCardSummaryDialog(false)}
        handleYes={handleBlockCardSubmit}
        title=""
      />
      <YesNoDialog
        openDialog={openDialog}
        message={'Are you sure you want to export available cards?'}
        handleCloseDialog={() => toggleYesNoModel(false)}
        handleYes={handleYes}
      />
    </>
  );
};


AvailableCards.propTypes = {
  issueCard: PropTypes.array,
  customerInfo: PropTypes.object,
  updateAvailableCards: PropTypes.func
};

export default AvailableCards;