export const getTcnCustomerId = (userLists, user) => {
  if (user?.tcn_customer_id || user?.tcnCustomerId) {
    return user.tcn_customer_id || user?.tcnCustomerId;
  } else {
    const mainUser = userLists.filter(
      (u) => u.id === (user?.customer_id || user?.customerId)
    );
    return mainUser?.[0]?.tcn_customer_id || '';
  }
};
