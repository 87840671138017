import { fork, takeLatest, call, put } from 'redux-saga/effects';

import {
  validateCards,
  validateCardsSuccess,
  validateCardFailure,
  createCardsInBulk,
  createCardsInBulkSuccess,
  createCardsInBulkFailure,
  addBulkCardResponse
} from '../slices/cardValidation';
import { validateBulkCard, createBulkCard } from '../../services/api';

function* validateCardsFunc(action) {
  const { history } = action.payload;
  const formData = new FormData();
  formData.append("uploaded_file", action.payload.file);
  formData.append('orderreference', action.payload.orderReference);
  formData.append("fileName", action.payload.fileName);

  try {
    const data = yield call(validateBulkCard, formData);
    if(data) {
      yield put(validateCardsSuccess(data));
      history.push("/card-validation-preview");
    }
  } catch(err) {
    yield put(validateCardFailure(err));
  }
}

function* createCardsInBulkFunc(action) {
  const { history, successCard, bulkCardFormData } = action.payload;
  const data = yield call(createBulkCard, {successCard, bulkCardFormData});
  yield put(addBulkCardResponse(data));

  if (data?.failedCard?.length === 0 && data?.successCard?.length > 0) {
    yield put(createCardsInBulkSuccess());
  } else {
    yield put(createCardsInBulkFailure());
    history.push('/card-order-finalize');
  }
}

export function* cardValidationSaga() {
  yield takeLatest(validateCards, validateCardsFunc);
  yield takeLatest(createCardsInBulk, createCardsInBulkFunc);
}