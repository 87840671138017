const validateForm = (customerPrivacyInteger, customerInfo, data) => {
  let errors = { hasError: false, msg: {} };

  const cardAmountMin = Number(customerInfo?.customer?.[0]?.customer_card_min_value ?? 10);
  const cardAmountMax = Number(customerInfo?.customer?.[0]?.customer_card_max_value ?? 4999);

  if (data.cardAmount < cardAmountMin || data.cardAmount >= cardAmountMax) {
    errors.hasError = true;
    errors.cardAmount = true;
  }

  if (!data?.referenceId) {
    errors.hasError = true;
    errors.referenceId = true;
  }

  if (!data?.department) {
    errors.hasError = true;
    errors.department = true;
  }

  if (data?.additionalDetails?.mobile && data?.additionalDetails?.mobile?.length !== 10) {
    errors.hasError = true;
    errors.mobile = true;
  }

  if (customerPrivacyInteger && !data?.customerIssuanceType) {
    errors.hasError = true;
    errors.customerIssuanceType = true;
  }

  return errors;
};

export default validateForm;
