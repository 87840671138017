import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { toString} from "../../../constants/requestHandle"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const createSuccessRow = ({ data }) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      promotion       : data[i].Promotion,
      productionId    : data[i].ProductID,
      amount          : data[i].Amount,
      eoid            : data[i].EOID,
      cardName        : data[i].CardName,
      cardEmail       : data[i].CardEmail,
      cardMobile      : data[i].CardMobile,
      recipientName   : data[i].RecipientName,
      recipientMessage: data[i].RecipientMessage,
      recipientEmail  : data[i].RecipientEmail,
      deliveryMethod  : toString(data[i].RequestHandle)
    };

    rows.push(row);
  }
  return rows;
}

const SuccessCardPreview = (data) => {
  const classes = useStyles();
  const rows    = createSuccessRow(data);

  return (
    <div className="validation-result">
      <h3> Cards to be issued </h3>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              <TableCell align="left">Campaign</TableCell>
              <TableCell align="left">Amount</TableCell>
              <TableCell align="left">EOID</TableCell>
              <TableCell align="left">Card Name</TableCell>
              <TableCell align="left">Card Email</TableCell>
              <TableCell align="left">Card Mobile</TableCell>
              <TableCell align="left">Recipient Name</TableCell>
              <TableCell align="left">Recipient Message</TableCell>
              <TableCell align="left">Recipient Email</TableCell>
              <TableCell align="left">Delivery Method</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell component="th" scope="row">
                  {row.productionId}
                </TableCell>
                <TableCell align="left">{row.promotion}</TableCell>
                <TableCell align="left">{row.amount}</TableCell>
                <TableCell align="left">{row.eoid}</TableCell>
                <TableCell align="left">{row.cardName}</TableCell>
                <TableCell align="left">{row.cardEmail}</TableCell>
                <TableCell align="left">{row.cardMobile}</TableCell>
                <TableCell align="left">{row.recipientName}</TableCell>
                <TableCell align="left">{row.recipientMessage}</TableCell>
                <TableCell align="left">{row.recipientEmail}</TableCell>
                <TableCell align="left">{row.deliveryMethod}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default SuccessCardPreview;