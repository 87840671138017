import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import validateForm from './validateForm.helper';
import SelectInput from '../Select';
import "./UserForm.scss";
import { hasAdminUserPermissionForCTA } from '../../helpers/permission';

const CampaignForm = ({
  campaign={},
  isPromotionLoaded= false,
  promotions=[],
  loading=false,
  handleSubmit,
  redirectToCampaignList
}) => {
  const [name, setName]                         = useState('');
  const [description, setDescription]           = useState('');
  const [cloneExisting, setCloneExisting]       = useState(false);
  const [promotion, setPromotion]               = useState(null);
  const [validationErrors, setValidationErrors] = useState({});

  const submit = () => {
    const payload = {
      name,
      description,
      cloneExisting,
      promotion
    };
    const validate = validateForm(payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleSubmit(payload);
  }

  return (
    <>
      {!isPromotionLoaded ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <div className="form-container">
          <TextField
            id="standard-basic"
            label="Name"
            type="text"
            name="name"
            helperText="*Is required."
            error={validationErrors.name}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label="Description"
            type="text"
            name="description"
            helperText="*Is required."
            error={validationErrors.description}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-standard-label">
              Clone An Existing Promo
            </InputLabel>
            <SelectInput
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="cloneExisting"
              value={cloneExisting}
              onChange={(val) => setCloneExisting(val)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </SelectInput>
            <FormHelperText>*Is required.</FormHelperText>
          </FormControl>

          {cloneExisting && (
            <FormControl
              className="mt-4"
              variant="standard"
              error={validationErrors.promotion}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Campaign
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="campaign"
                value={promotion}
                onChange={(val) => setPromotion(val)}
              >
                {promotions.map((promotion) => (
                  <MenuItem
                    key={promotion.promotion_id}
                    value={promotion.promotion_id}
                  >
                    {promotion.promotion_id}&nbsp;-&nbsp;
                    {promotion.promotion_name}
                  </MenuItem>
                ))}
              </SelectInput>
            </FormControl>
          )}

          <div className="action-footer">
            <Button
              disabled={!hasAdminUserPermissionForCTA() || loading}
              variant="outlined"
              endIcon={
                loading ? (
                  <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
                ) : (
                  <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                )
              }
              onClick={submit}
            >
              Continue
            </Button>
            <Button variant="outlined" onClick={redirectToCampaignList}>
              Cancel
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

CampaignForm.propTypes = {
  campaign              : PropTypes.object,
  promotions            : PropTypes.array,
  loading               : PropTypes.bool,
  isPromotionLoaded     : PropTypes.bool,
  handleSubmit          : PropTypes.func,
  redirectToCampaignList: PropTypes.func
};

export default CampaignForm;