import React from 'react';

import IpLogList from './ip-log-list';
import './IpLogLists.scss';
import NavBar from '../../../components/Navbar';

const WhiteListIpsLogs = () => {
  return (
    <>
    <NavBar />
      <div className="min-h-full flex justify-center items-center campain-table-container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3 color-white">White List IP Logs</h2>
          </div>
          <IpLogList />
        </div>
      </div>
    </>
  );
};

export default WhiteListIpsLogs;
