import React from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { makeStyles } from "@material-ui/core/styles";

import { formatMoney } from '../../../helpers/currency';

const HeaderPreview = ({
  balance,
  totalIssueAmount,
  handleCancel,
  handleSubmit,
  hasValidationError,
  loading
}) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1)
    }
  }));
  const classes = useStyles();

  return (
    <>
      <div className="preview-card-header">
        <div className="flex flex-col text-center w-100">
          <h2>Preview Card</h2>
          <h3>Step2 - Preview card (bulk card issue)</h3>
        </div>
        <div className="counter-container">
          <ul className="counter-items">
            <li className="counter-item">
              <h1>{formatMoney(balance)}</h1>
              <p>Total Issue Amount</p>
            </li>
            <li className="counter-item">
              <h1>{formatMoney(totalIssueAmount)}</h1>
              <p>Estimated total issue amount</p>
            </li>
            <li className="counter-item">
              <h1>{formatMoney(balance - totalIssueAmount)}</h1>
              <p>Estimated Remaining Balance</p>
            </li>
          </ul>
        </div>
        <div className="preview-button-container">
          <div className="preview-header-action">
            {!hasValidationError && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
                endIcon={<ArrowForwardOutlinedIcon />}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            )}

            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={loading}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

HeaderPreview.propTypes = {
  balance           : PropTypes.number,
  totalIssueAmount  : PropTypes.number,
  handleCancel      : PropTypes.func,
  handleSubmit      : PropTypes.func,
  hasValidationError: PropTypes.number,
  loading           : PropTypes.bool
};

export default HeaderPreview;
