import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CardTransactionTable = ({ cardTransactions }) => {
  const createRows = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = {
        amount: data[i].Amount,
        type: data[i].Type,
        date: data[i].Date,
        originatedDate: data[i].OriginatedDate,
        merchantName: data[i].MerchantName,
        terminalcode: data[i].Terminalcode,
        note: data[i].Note
      };

      rows.push(row);
    }
    return rows.sort((a,b) => new Date(b.date) - new Date(a.date));
  };

  const rows = createRows(cardTransactions);
  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Amount</TableCell>
              <TableCell align="left">Originated Date</TableCell>
              <TableCell align="left">Merchant Name</TableCell>
              <TableCell align="left">Terminal Code</TableCell>
              <TableCell align="left">Reference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.amount}</TableCell>
                  <TableCell align="left">
                    {row?.type === 'Redemption' ||
                    row?.type === 'Decline' ||
                    row?.type === 'Bulk Order'
                      ? row.originatedDate
                      : row.date}
                  </TableCell>
                  <TableCell align="left">
                    {row?.type === 'Redemption' || row?.type === 'Decline'
                      ? row.merchantName
                      : ''}
                  </TableCell>
                  <TableCell align="left">
                    {row?.type === 'Redemption' || row?.type === 'Decline'
                      ? row.terminalcode
                      : ''}
                  </TableCell>
                  <TableCell align="left">{row.note}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CardTransactionTable.propTypes = {
  cardTransactions: PropTypes.array,
};

export default CardTransactionTable;
