export const formatMoney = (number) =>  {
  if (number) {
    const money = typeof number === 'string' ? parseFloat(number) : number;
    return money.toLocaleString('en-US', {
      style   : 'currency',
      currency: 'USD'
    });
  }

  return '$0.00';
}
