import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllIssuanceType } from '../../../store/slices/issuanceType';
import YesNoDialog from '../../../components/yes-no-dialog';
import TableHeader from "./table-header";
import TableBody from "./table-body";
import { updateAgentIssuanceAPI } from '../../../services/api';
import Notify from '../../../components/notify';

const IssuanceTypeList = () => {
  const [openDialog, setOpenDialog]                         = useState(false);
  const [openNotification, setOpenNotification]             = useState(false);
  const [issuanceTypes, setIssuanceTypes]                   = useState([]);
  const [issuanceTypesFilter, setIssuanceTypesFilter]       = useState([]);
  const [loading, setLoading]                               = useState(true);
  const [selectedIssuanceTypeId, setSelectedIssuanceTypeId] = useState('');
  const [successMessage, setSuccessMessage]                 = useState('');
  const [errors, setErrors]                                 = useState({});
  const dispatch = useDispatch();

  const loadingReducer  = useSelector((state) => state.issuanceType.loading);
  const issuanceTypesReducer = useSelector((state) => state.issuanceType.issuanceTypes);

  useEffect(() => {
    dispatch(getAllIssuanceType());
  }, []);

  useEffect(() => {
    setIssuanceTypes(issuanceTypesReducer || []);
    setIssuanceTypesFilter(issuanceTypesReducer || []);
    setLoading(loadingReducer);
  }, [loading, issuanceTypesReducer]);


  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';
    const filteredRow = [];

    issuanceTypes.forEach((issuanceType) => {
      const id = issuanceType?.id?.toLowerCase() || '';
      const name = issuanceType?.name?.toLowerCase() || '';

      if (
        id.includes(searchText) ||
        name.includes(searchText)
      ) {
        filteredRow.push(issuanceType);
      }
    });
    setIssuanceTypesFilter(filteredRow);
  }

  const toggleYesNoModal = (val, issuanceTypeId) => {
    setOpenDialog(val);
    if(issuanceTypeId) {
      setSelectedIssuanceTypeId(issuanceTypeId);
    }
  }

  const handleYes = async() => {
    try {
      setLoading(true);
      const payload = {
        status: 0
      }
      const response = await updateAgentIssuanceAPI(selectedIssuanceTypeId, payload);
      dispatch(getAllIssuanceType());
      setErrors({});
      setLoading(false);
      setOpenNotification(true);
      toggleYesNoModal(false);
      setSuccessMessage(`Issuance Type "${response?.name}" has been deactivated.`);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Deactivating Issuance Type Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  }


  return (
    <>
      <TableHeader handleSearch={handleSearch} />
      <TableBody
        users={issuanceTypesFilter}
        openYesNoModal={toggleYesNoModal}
      />
      <YesNoDialog
        openDialog={openDialog}
        message={'Are you sure that you want to deactivate this issuance type? This action cannot be undone.'}
        handleCloseDialog={() => toggleYesNoModal(false)}
        handleYes={handleYes}
      />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        successMessage={successMessage}
        errors={errors}
      />
    </>
  );
}

export default IssuanceTypeList;