import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHeader from "./table-header";
import TableBody from "./table-body";
import { getSubUsers } from '../../../store/slices/user';
import { TablePagination } from '@material-ui/core';

const UserList = () => {
  const [subUsers, setSubUsers] = useState([]);
  const [filterText, setFilterText] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();

  const loadingReducer = useSelector((state) => state.user.loading);
  const subUsersReducer = useSelector((state) => state.user.subUsers);

  useEffect(() => {
    getSubUser(rowsPerPage, page);
  }, []);

  useEffect(() => {
    setTotalRowCount(subUsersReducer?.count || 0);
    setSubUsers(subUsersReducer?.users || []);
    setLoading(loadingReducer);
  }, [loading, subUsersReducer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getSubUser(rowsPerPage, page, filterText);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filterText]);

  const getSubUser = (currentRowPerPage, currentPage, searchText) => {
    dispatch(getSubUsers({
      limit: currentRowPerPage,
      page: currentPage,
      filter: { search: searchText }
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSubUser(rowsPerPage, newPage, filterText);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getSubUser(newRowsPerPage, newPage, filterText);
  };

  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';

    setFilterText(searchText);
    setPage(0);
  };

  return (
    <>
      <TableHeader handleSearch={handleSearch} />
      <TableBody users={subUsers} />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default UserList;