import { isValidCIDR, isValidIPAddress } from "../../helpers/common";

const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };
  if (!data.ipAddress) {
    errors.hasError = true;
    errors.ipAddress = true;
    errors.msg.ipAddress = "*Is required"
  }

  if(data.ipAddress && !isValidIPAddress(data.ipAddress) && !isValidCIDR(data.ipAddress) ) {
    errors.hasError = true;
    errors.ipAddress = true;
    errors.msg.ipAddress = "*Given IP Address is not valid"
  }

  return errors;
};

export default validateForm;
