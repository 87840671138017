// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
  min-height: calc(100vh - 62px);
  max-width: 690px; }
  .home-container .avatar {
    width: 100%;
    border-radius: 0;
    background: none;
    margin-bottom: 56px; }
    @media (min-width: 768px) {
      .home-container .avatar {
        margin-bottom: 95px; } }
  .home-container .info-text {
    color: #fff;
    font-size: 30px; }
  .home-container .info-subtext {
    color: #fff;
    font-size: 20px; }
    .home-container .info-subtext .text-blue {
      font-weight: 400;
      font-size: 20px;
      line-height: 15.41px;
      color: #005296;
      text-decoration: underline; }
`, "",{"version":3,"sources":["webpack://./src/pages/home/Home.scss"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,gBAAgB,EAAA;EAFlB;IAII,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB,EAAA;IACnB;MARJ;QASM,mBAAmB,EAAA,EAEtB;EAXH;IAcI,WAAW;IACX,eAAe,EAAA;EAfnB;IAmBI,WAAW;IACX,eAAe,EAAA;IApBnB;MAsBM,gBAAgB;MAChB,eAAe;MACf,oBAAoB;MACpB,cAAc;MACd,0BAA0B,EAAA","sourcesContent":[".home-container {\n  min-height: calc(100vh - 62px);\n  max-width: 690px;\n  .avatar {\n    width: 100%;\n    border-radius: 0;\n    background: none;\n    margin-bottom: 56px;\n    @media (min-width: 768px) {\n      margin-bottom: 95px;\n    }\n  }\n\n  .info-text {\n    color: #fff;\n    font-size: 30px;\n  }\n\n  .info-subtext {\n    color: #fff;\n    font-size: 20px;\n    .text-blue {\n      font-weight: 400;\n      font-size: 20px;\n      line-height: 15.41px;\n      color: #005296;\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
