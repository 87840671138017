export const getInitialFilterHeader = () => {
  return {
    tcnCustomerId: '',
    email: '',
    status: "All",
    ssoId: '',
    environment: "All",
    ssoFlag: "All",
    createdStartDate: null,
    createdEndDate: null
  };
};
