import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Loader from '../../../components/loader';
import { getCardByIdentifierAPI, getCardByIdentifierInUatAPI } from '../../../services/api';
import { saveCardDetails } from '../../../store/slices/customRedeem';
import Notify from '../../../components/notify';
import "./customRedeem.scss";

const CustomRedeemInitialStep = () => {
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [openNotify, setOpenNotify] = useState(false);

  let { promotionId, cardIdentifier } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    getCardByIdentifier();
  }, []);

  const getCardByIdentifier = async () => {
    try {
      let response;
      setLoading(true);
      if (promotionId === '11459') {
        response = await getCardByIdentifierInUatAPI(cardIdentifier);
      } else {
        response = await getCardByIdentifierAPI(cardIdentifier);
      }

      setLoading(false);
      dispatch(saveCardDetails(response.data));
      history.push(`/${promotionId}/redeem`);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Swap Creation Failed !!!!' };
      setErrors(errors);
      setLoading(false);
      setOpenNotify(true);
    }
  };

  return (
    <>
      {
        <div className='intial-step-wrapper'>
          <Box className='box-wrapper' component="span" m={1}>
            <h1 className='error-message'>{errors.errormessage}</h1>
          </Box>
        </div>
      }
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
      <Loader loading={loading} />
    </>
  );
};

export default CustomRedeemInitialStep;
