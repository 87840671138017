import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {FormControl, InputLabel, MenuItem} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';

import SelectInput from '../../../../../components/Select';
import 'react-datepicker/dist/react-datepicker.css';
import './FilterHeader.scss';

const FilterHeader = ({
  filter,
  isLoading,
  handleFilter,
  handleClearFilter,
  handleSetFilter
}) => {
  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="user-table-filter-body">
            <div className="flex add-spacing">
              <FormControl variant="standard">
                <TextField
                  className="campaign-input"
                  id="standard-basic"
                  label="TCN Customer Id"
                  name="customerId"
                  type="number"
                  value={filter.tcnCustomerId}
                  onChange={(e) =>
                    handleSetFilter({ tcnCustomerId: e.target.value })
                  }
                />
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className="campaign-input"
                  id="standard-basic"
                  label="Email"
                  name="email"
                  type="text"
                  value={filter.email}
                  onChange={(e) => handleSetFilter({ email: e.target.value })}
                />
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Status
                </InputLabel>
                <SelectInput
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="cardCancel"
                  value={filter.status}
                  onChange={(val) => handleSetFilter({ status: val })}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </SelectInput>
              </FormControl>
              <FormControl variant="standard">
                <TextField
                  className="campaign-input"
                  id="standard-basic"
                  label="SSO Id"
                  name="ssoId"
                  type="text"
                  value={filter.ssoId}
                  onChange={(e) => handleSetFilter({ ssoId: e.target.value })}
                />
              </FormControl>
            </div>
            <div className="flex add-spacing">
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  Environment
                </InputLabel>
                <SelectInput
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="cardCancel"
                  value={filter.environment}
                  onChange={(val) => handleSetFilter({ environment: val })}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={'UAT'}>UAT</MenuItem>
                  <MenuItem value={'PROD'}>PROD</MenuItem>
                </SelectInput>
              </FormControl>
              <FormControl variant="standard">
                <InputLabel id="demo-simple-select-standard-label">
                  SsoFlag
                </InputLabel>
                <SelectInput
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="cardCancel"
                  value={filter.environment}
                  onChange={(val) => handleSetFilter({ ssoFlag: val })}
                >
                  <MenuItem value={'All'}>All</MenuItem>
                  <MenuItem value={true}>TRUE</MenuItem>
                  <MenuItem value={false}>FALSE</MenuItem>
                </SelectInput>
              </FormControl>
              <div className="date-container">
                <span>Created Date</span>
                <DatePicker
                  selectsRange={true}
                  startDate={filter.createdStartDate}
                  endDate={filter.createdEndDate}
                  onClickOutside={() =>
                    handleSetFilter({
                      createdStartDate: null,
                      createdEndDate: null
                    })
                  }
                  dateFormat="dd/MM/yyyy"
                  onChange={(update) => {
                    handleSetFilter({
                      createdStartDate: update[0],
                      createdEndDate: update[1]
                    });
                  }}
                  isClearable={true}
                  className="date-picker-field"
                />
              </div>
              <Button variant="outlined" className="" onClick={handleFilter}>
                Filter
              </Button>
              <Button
                variant="outlined"
                className=""
                onClick={handleClearFilter}
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.object,
  users: PropTypes.array,
  agents: PropTypes.object,
  departments: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  handleExportTable: PropTypes.func,
  handleClearFilter: PropTypes.func
};


export default FilterHeader;