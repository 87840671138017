const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data?.productId) {
    errors.hasError = true;
    errors.productId = true;
  }

  if (!data?.serialNumber) {
    errors.hasError = true;
    errors.serialNumber = true;
  }

  return errors;
};

export default validateForm;
