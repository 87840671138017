const validateForm = (isEditUser, data) => {
  let errors = { hasError: false, msg: {} };
  if (!data.name) {
    errors.hasError = true;
    errors.name = true;
  }

  return errors;
};

export default validateForm;
