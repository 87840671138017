import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import { useMsal } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/loader';
import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer'
import Notify from '../../components/notify';
import './Login.scss'
import { getAuthToken } from '../../helpers/localStorage';

export default function SignIn() {
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [loading, setLoading]                     = useState(false);
  const [errors, setErrors]                       = useState({});
  const { instance, accounts } = useMsal();
  const history = useHistory();

  useEffect(() => {
    if(accounts?.length > 0) {
      setLoading(true);
      loginCrownUser();
    }
  }, [accounts]);

  const initializeSignIn = async() => {
    setLoading(true);
    await instance.loginRedirect({
      scopes: ["user.read"] // optional Array<string>
    });
  };

  const loginCrownUser = async() => {
    const TCNCP_API = process.env.REACT_APP_TCNCP_API;
    const accountInfo = accounts[0] || null;

    if (accountInfo) {
      await instance.initialize();
      let tokenResult = await instance.acquireTokenSilent({
        scopes: ["user.read"],
        account: accountInfo
      });

      if (tokenResult){
        axios
          .get(`${TCNCP_API}/auth/crown/login`, {
            headers : {
              'azure-token' : tokenResult.idToken
            }
          })
          .then((res) => {
            localStorage.setItem('user_info', JSON.stringify(res.data.user));
            localStorage.setItem('auth_token', res.data.token);
            localStorage.setItem('test_mode', res.data.isTestMode);
            res?.data?.user?.applyAgentIssuanceOnly === 'TRUE'
              ? history.push('/agent-issuance')
              : history.push('/dashboard');
          }).catch((err) => {
            const errors = err?.response?.data || { errormessage: 'Something Went Wrong !!!!' };
            setErrors(errors);
            setLoading(false);
            setOpenSuccessNotify(true);
          });
      }
    }
  }

  return (
    <>
    <Container component="main" className="login-crown-container flex-col container">
      <CssBaseline />
      <div className="login-crown-body flex flex-col items-center">
        <Avatar className="avatar">
          <img src={logo} alt="login-logo"></img>
        </Avatar>
          <Button
            type="submit"
            fullWidth
            className="submit-btn"
            onClick={initializeSignIn}
            disabled={loading}
            endIcon={
              <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
            }
          >
            Sign In
          </Button>
      </div>

    </Container>
    <Footer />
    <Notify
        open={openSuccessNotify}
        onClose={() => setOpenSuccessNotify(false)}
        errors={errors}
      />
    <Loader loading={loading} />

    </>
  );
}