import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../helpers/currency';
import './Counter.scss';

const DashboardCounter = ({
  floatData,
}) => {
  return (
    <>
      <section className="counter-items-container">
        <ul className="counter-items">
          <li className="counter-item">
            <h1>{floatData.issuecardcount}</h1>
            <p>
              Total cards issue
            </p>
          </li>
          <li className="counter-item">
            <h1>{formatMoney(floatData.initialvalue)}</h1>
            <p>Value of cards issued</p>
          </li>
          <li className="counter-item">
            <h1>
              {floatData.creditflag === '1'
                ? formatMoney(
                    parseFloat(floatData.balance) +
                      parseFloat(floatData.creditlimit)
                  )
                : formatMoney(floatData.balance)}
            </h1>
            <p>Available Balance</p>
          </li>
        </ul>
      </section>
    </>
  );
};

DashboardCounter.propTypes = {
  floatData: PropTypes.object,
};

export default DashboardCounter;
