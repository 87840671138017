import React, { useEffect, useState } from 'react'

import Banner from './banner/Banner'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import LiveCampaignChart from './live-campaign-chart';
import TopCampaigns from "./top-campaigns";
import IssueCardTable from './issue-card-table';
import DashboardCounter from '../../components/counter-info/DashboardCounter';
import { getCustomerInfoAPI, getPromotionDataAPI } from '../../services/api';
import Notify from '../../components/notify';
import './Dashboard.scss';

const Dashboard = () => {
  const [customerInfo, setCustomerInfo]         = useState({});
  const [campaigns, setCampaigns]               = useState([]);
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);
  const [isCampaignLoaded, setIsCampaignLoaded] = useState(false);
  const [openNotify, setOpenNotify]             = useState(false);
  const [errors, setErrors]                     = useState({});

  useEffect(() => {
    getCustomerInfo();
  }, []);

  useEffect(() => {
    getPromotions();
  }, [isCustomerLoaded]);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setIsCustomerLoaded(true);
    } catch (err) {
      setCustomerInfo([]);
      setErrors(err || { errormessage: 'Something went wrong!!!' });
      setOpenNotify(true);
    }
  };

  const getPromotions = async () => {
    if (!isCustomerLoaded)
      return;

    try {
      const response = await getPromotionDataAPI({ limit: 10 });

      const filteredPromotions = response?.promotion
        ?.filter((promotion) => customerInfo?.promotion?.find((promo) => promotion.promotion_id === promo.promotion_id)?.promotion_hide_flag === "FALSE")
        ?.slice(0, 5);

      setCampaigns(filteredPromotions || []);
      setIsCampaignLoaded(true);
    } catch (err) {
      setCampaigns([]);
      setErrors(err || { errormessage: 'Something went wrong!!!' });
      setOpenNotify(true);
    }
  };

  return (
    <>
      <Navbar />
      {isCustomerLoaded && isCampaignLoaded ? (
        <div className="min-h-full dashboard-container">
          <Banner />
          <DashboardCounter floatData={customerInfo.floatdata} />
          <hr className="seperator"></hr>
          <LiveCampaignChart promotionData={campaigns} />
          <TopCampaigns promotionData={campaigns} />
          <IssueCardTable customerInfo={customerInfo}/>
        </div>
      ) : (
        <div className="min-h-full loading-container">Loading....</div>
      )}
      <Footer />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default Dashboard;