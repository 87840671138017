import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

import './OptionsModal.scss';

const OptionsModel = ({ modalIsOpen, closeModal, handleBlockCard, openAgentIssueModal }) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <div className='close-icon'>
            <CloseOutlinedIcon
              style={{ color: '#FFFB00' }}
              size="20px"
              onClick={closeModal}
            />
          </div>
          <div className="button-container">
            <Button
              variant="outlined"
              color="primary"
              onClick={openAgentIssueModal}
            >
              Agent Issue
            </Button>

            <Button variant="outlined" onClick={handleBlockCard} color="primary">
              Block Cards
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

OptionsModel.propTypes = {
  selectedCard        : PropTypes.object,
  data                : PropTypes.object,
  modalIsOpen         : PropTypes.bool,
  closeModal          : PropTypes.func,
  openAgentIssueModal : PropTypes.func,
  handleBlockCard      : PropTypes.func
};

export default OptionsModel;
