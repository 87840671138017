import React from "react";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import BlockIcon from "@material-ui/icons/Block";
import CheckIcon from "@material-ui/icons/Check";

import { makeStyles } from "@material-ui/core/styles";

const HeaderPreview = ({
  balance,
  redirectToBulkCardPage,
  redirectToDashboard
}) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1)
    }
  }));
  const classes = useStyles();

  return (
    <div className="preview-card-header">
      <h3>Failed Cards</h3>
      <div className="summary-header">
        <div className="summary-label text-white">Balance</div>
        <div className="summary-value text-white">{balance}</div>
      </div>

      <div className="preview-header-action">
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<CheckIcon />}
          onClick={redirectToBulkCardPage}
        >
          Upload More
        </Button>

        <Button
          variant="contained"
          className={classes.button}
          startIcon={<BlockIcon />}
          onClick={redirectToDashboard}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
};

HeaderPreview.propTypes = {
  balance               : PropTypes.number,
  redirectToBulkCardPage: PropTypes.func,
  redirectToDashboard   : PropTypes.func
};

export default HeaderPreview;
