import React from 'react'
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SelectInput from "../../../../../components/Select";
import { getTcnCustomerId } from '../../../../../helpers/users';
import AutocompleteInput from '../../../../../components/autocomplete/index';

const FilterHeader = ({
  filter,
  users,
  isLoading,
  handleFilter,
  handleClearFilter,
  handleSetFilter
}) => {
  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="table-filter-body">
            <div className="form-container">
              <div className="flex add-spacing field-container">
                <FormControl variant="standard" style={{ minWidth: 350 }}>
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="Customer Id"
                    name="customerId"
                    value={filter.customerId}
                    type="number"
                    onChange={(e) =>
                      handleSetFilter({ customerId: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl style={{ minWidth: 350 }} variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Environment
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="cardCancel"
                    value={filter.isUatEnv}
                    onChange={(val) => handleSetFilter({ isUatEnv: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={true}>UAT</MenuItem>
                    <MenuItem value={false}>PROD</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    MFA Enabled
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="cardCancel"
                    value={filter.mfaEnabled}
                    onChange={(val) => handleSetFilter({ mfaEnabled: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl style={{ minWidth: 350 }} variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Login Result
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="loginResult"
                    value={filter.loginResult}
                    onChange={(val) => handleSetFilter({ loginResult: val })}
                  >
                    <MenuItem value={'All'}>All</MenuItem>
                    <MenuItem value={true}>True</MenuItem>
                    <MenuItem value={false}>False</MenuItem>
                  </SelectInput>
                </FormControl>
              </div>
              <div className="flex add-spacing field-container">
                <div className="agen-issuance-button-container log-date-container ">
                  <span>Date Range</span>
                  <DatePicker
                    selectsRange={true}
                    startDate={filter.startDate}
                    endDate={filter.endDate}
                    onClickOutside={() =>
                      handleSetFilter({ startDate: null, endDate: null })
                    }
                    dateFormat="dd/MM/yyyy"
                    onChange={(update) => {
                      handleSetFilter({
                        startDate: update[0],
                        endDate: update[1]
                      });
                    }}
                    isClearable={true}
                    className="date-picker-field campaign-input"
                  />
                </div>
                <FormControl variant="standard">
                  <AutocompleteInput
                    id="demo-simple-select-standard"
                    label="User Id"
                    options={users}
                    getOptionLabel={(option) => option.email}
                    renderOption={(option) => (
                      <React.Fragment>
                        {option.email}&nbsp; ({getTcnCustomerId(users, option)})&nbsp;({option?.enable_mfa === 1 ? 'true' : 'false'})
                      </React.Fragment>
                    )}
                    value={users?.find(user => user.id === filter.userId)}
                    onChange={(e, val) => handleSetFilter({ userId: val?.id })}>
                  </AutocompleteInput>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                      Login Type
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="loginType"
                    value={filter.loginType}
                    onChange={(val) => handleSetFilter({ loginType: val })}
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="SSO">SSO</MenuItem>
                    <MenuItem value="User/Pass">User/Pass</MenuItem>
                  </SelectInput>
                </FormControl>
              </div>
              <div className="issued-card-filter-buttons">
                <div className="filter-buttons">
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleFilter}
                  >
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleClearFilter}
                  >
                    Clear Filters
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.object,
  users: PropTypes.array,
  agents: PropTypes.object,
  departments: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  handleExportTable: PropTypes.func,
  handleClearFilter: PropTypes.func
};


export default FilterHeader;