import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../../../components/loader';
import Notify from '../../../../components/notify';
import { getCardByIdentifierInUatAPI } from '../../../../services/api';
import './EdmApia.scss';

const SuncorpEdm = () => {
  const [recfn, setRecfn] = useState('');
  const [redemtionLink, setRedemtionLink] = useState('');
  const [initialValue, setIntialValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors] = useState({});

  const { identifier } = useParams();

  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = async () => {
    try {
      const card = await getCardByIdentifierInUatAPI(identifier);
      const fullName = card?.data?.name?.split(' ');
      const initialvalue = card?.data?.card_initial_value || 0;
      const link = card?.data?.redemptionlink || '';

      const firstName = fullName?.length > 0 ? fullName[0] : '';
      setRecfn(firstName);
      setIntialValue(initialvalue);
      setRedemtionLink(link);

      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Failed to get card details!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="edm-apia-wrapper">
        <div className="main_page">
          <div className="header_and_banner section_padding">
            <div className="header">
              <img
                className="logo1"
                src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/logo.png"
                alt="Image"
                border="0"
              />
            </div>
          </div>
          <div className="banner_area">
            <div className="banner_main">
              <img
                className="em_img"
                src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/image1.png"
                alt="Image"
              />
            </div>
            <div className="banner_contents">
              <h3>Get set for a little reward, {recfn}</h3>
              <p>
                For renewing your Apia Insurance we&apos;ve turned your $
                {initialValue} Apia Rewards balance into a digital Prepaid Visa
                card. It&apos;s yours to spend wherever Visa is accepted. Just
                follow the steps below.{' '}
              </p>
            </div>
          </div>
          <div className="redem_your_reward_main section_padding2">
            <div className="redem_your_reward_title">
              <h2>A few steps to your reward</h2>
            </div>
            <div className="redem_your_reward_items_all">
              <div className="redem_your_reward_single">
                <img
                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/icon1.png"
                  style={{ display: 'block' }}
                  alt="Icon"
                  width="44"
                  height="44"
                  border="0"
                />
                <p>
                  Click the button <br />
                  below to get started.
                </p>
              </div>
              <div className="redem_your_reward_single">
                <img
                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/icon2.png"
                  style={{ display: 'block' }}
                  alt="Icon"
                  width="44"
                  height="44"
                  border="0"
                />
                <p>
                  Enter your mobile number to receive the SMS with a link to
                  download The Card Network (TCN) App.{' '}
                </p>
              </div>
              <div className="redem_your_reward_single">
                <img
                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/icon3.png"
                  style={{ display: 'block' }}
                  alt="Icon"
                  width="44"
                  height="44"
                  border="0"
                />
                <p>
                  After downloading, you&apos;ll see your digital Prepaid Visa
                  card in TCN App.{' '}
                </p>
              </div>
              <div className="redem_your_reward_single">
                <img
                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/APIA/icon4.png"
                  style={{ display: 'block' }}
                  alt="Icon"
                  width="44"
                  height="44"
                  border="0"
                />
                <p>
                  Add your digital Prepaid Visa card to your phone&apos;s wallet
                  and spend on anything you like, wherever Visa is accepted.
                </p>
              </div>
            </div>
            <div className="redem_your_reward_textbtn">
              <p>
                <span>Already got the app?</span> The link will give you access
                to your personalised card.
              </p>
              <div className="redem_your_reward_btn">
                <a href={redemtionLink}>Redeem now</a>
              </div>
            </div>
          </div>
          <div className="footer_all_contents">
            <div className="footer_contents_need_help section_padding">
              <p>
                <span>Need help redeeming your reward? </span> Just call the
                support team at The Card Network on <br className="em_hide" />
                1300 375 346 from 9am-6pm on weekdays or 10am-5pm on Saturdays
                AEDT.
              </p>
              <p>
                The link within your SMS will expire three months after you
                receive it. You must download your digital Prepaid Visa card
                within this time, or you will lose the rewards balance within
                the card.{' '}
              </p>
            </div>

            <div className="from_the_brighter_reward_team">
              <h2>That&apos;s all from your Apia Rewards team.</h2>
            </div>

            <div className="this_email_has_been section_padding">
              <p>
                Sent by Think Wink Pty Ltd ABN 59 640 766 310, operator of the
                Apia Rewards platform. Think Wink is not a related company of
                AAI Limited trading as Apia or Australian Pensioners Agency Pty
                Ltd. Full terms and conditions are available{' '}
                <a
                  href="https://rewards.apia.com.au/terms"
                  style={{
                    color: '#737373',
                    textDecoration: 'underline',
                    fontSize: '11px'
                  }}
                >
                  here
                </a>
                . The Card Network (“TCN”) is the issuer of the digital Prepaid
                Visa Card (“Card”). You are responsible for all transactions on
                the Card, except where there has been fraud or negligence by TCN
                staff or agents. TCN may restrict or stop the use of the Card if
                suspicious activities are noticed. You are responsible for
                checking your transaction history, knowing the available balance
                and date of expiry for the Card. Balance and transaction history
                are available to you within the TCN App. Any changes to the
                Terms and Conditions can be viewed{' '}
                <a
                  href="https://thinkwink.com.au/tos"
                  style={{
                    color: '#737373',
                    textDecoration: 'underline',
                    fontSize: '11px'
                  }}
                >
                  here
                </a>
                . Information will be disclosed to third parties about the Card,
                or transactions made with the Card, whenever allowed by law, and
                also where necessary to operate the Card and process
                transactions. TCN’s privacy policy can be viewed{' '}
                <a
                  href="https://thecardnetwork.com.au/pages/privacy-policy"
                  style={{
                    color: '#737373',
                    textDecoration: 'underline',
                    fontSize: '11px'
                  }}
                >
                  here
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loading} />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
        successMessage=""
      />
    </>
  );
};

export default SuncorpEdm;
