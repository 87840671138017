import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id            : data[i].id,
      name          : data[i].name,
      createdByEmail: data[i]?.createdByInfo?.email || "",
      departmentName: data[i]?.department?.name || "",
      status        : data[i].status === 1 ? "Active" : "Inactive",
      createdAt     : data[i].created_at
    };

    rows.push(row);
  }
  return rows.reverse();;
};

const IssuanceTypeTable = ({ users, openYesNoModal }) => {
  const classes = useStyles();
  const rows = users.length > 0 ? createRows(users) : [];

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">ID </TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Department</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">CreatedBy</TableCell>
              <TableCell align="left">CreatedAt</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.departmentName}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.createdByEmail}</TableCell>

                  <TableCell align="left">
                    {row.createdAt
                      ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                      : '-'}
                  </TableCell>
                  <TableCell align="left">
                    {
                      row?.status === "Active" &&
                      (
                        <Button
                          variant="outlined"
                          onClick={() => openYesNoModal(true, row.id)}
                          className="w-100"
                        >
                          Deactivate
                        </Button>
                      )
                    }

                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

IssuanceTypeTable.propTypes = {
  users: PropTypes.array,
  openYesNoModal: PropTypes.func
};

export default IssuanceTypeTable;
