import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  departments: [],
  loading: false,
  error: [],
};

const department = createSlice({
  name: 'department',
  initialState,
  reducers: {
    getAllDepartments(state, action) {
      state.loading = true;
    },
    getAllDepartmentsSuccess(state, action) {
      state.loading = false;
      state.departments = action?.payload || [];
    },
    getAllDepartmentsFailure(state, action) {
      state.loading = false;
    }
  }
});

export const {
  getAllDepartments,
  getAllDepartmentsSuccess,
  getAllDepartmentsFailure
} = department.actions;

export default department.reducer;
