import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import HeaderPreview from './HeaderPreview';
import FailedCardPreview from './FailedCardPreview';
import { Alert } from '@material-ui/lab';
import { getCustomerInfoAPI } from '../../../services/api';

import "./BulkCardFinalize.scss";

const BulkCardFinalize = () => {
  const [customerInfo, setCustomerInfo] = useState({});
  const createCardsInBulkResponse = useSelector(
    (state) => state.cardValidation.bulkCardData
  );

  const successCard = createCardsInBulkResponse.successCard || [];
  const failedCard = createCardsInBulkResponse.failedCard || [];
  const history = useHistory();

  useEffect(() => {
    getCustomerInfo();
  }, []);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
    } catch (err) {
      console.log('err :: ', err);
    }
  };

  const redirectToBulkCardPage = () => {
    history.push('/bulkCard');
  }

  const redirectToDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <div className="preview-container">
      <HeaderPreview
        balance={customerInfo?.floatdata?.balance || 0}
        redirectToBulkCardPage={redirectToBulkCardPage}
        redirectToDashboard={redirectToDashboard}
      />

      {
        failedCard.length > 0 &&
        <Alert severity="error" className="gaps">
          Some cards failed to issue: see table below for details
        </Alert>
      }

      {
        successCard.length > 0 &&
        <Alert severity="success" className="gaps">
          {`Successfully Issued ${successCard.length} cards. `}
          {`Now Updated Balance is ${customerInfo?.floatdata?.balance || 0}`}
        </Alert>
      }

      {failedCard.length > 0 && <FailedCardPreview data={failedCard} />}
    </div>
  );
}

export default BulkCardFinalize;