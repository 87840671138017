import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { FormControl, InputLabel, MenuItem, TextField } from '@material-ui/core';
import DatePicker from 'react-datepicker';

import SelectInput from '../../../../components/Select';
import validateForm from './validateForm.helper';
import './CardModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

const EditCardInfoModel = ({
  modalIsOpen,
  closeModal,
  data,
  updateAdditionalInfoProxy
}) => {
  const [validationErrors, setValidationErrors] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState({
    firstName: data?.card_agent_customerissuancefname || '',
    lastName: data?.card_agent_customerissuancelname || '',
    mobile: data?.card_agent_customerissuancemobile || '',
    dob: data?.card_agent_customerissuancedob
      ? new Date(data?.card_agent_customerissuancedob)
      : null,
    address1: data?.card_agent_customerissuanceaddress1 || '',
    address2: data?.card_agent_customerissuanceaddress2 || '',
    suburb: data?.card_agent_customerissuancecity || '',
    state: data?.card_agent_customerissuancestate || '',
    country: data?.card_agent_customerissuancecountry || '',
    postcode: data?.card_agent_customerissuancezipcode || '',
    email: data?.card_agent_customerissuanceemail
  });

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleClick = () => {
    // const validate = validateForm(additionalDetails);
    // if (validate.hasError) {
    //   setValidationErrors(validate);
    //   return;
    // }
    updateAdditionalInfoProxy(additionalDetails);
  };

  const handleAdditionalChange = (payload) => {
    setAdditionalDetails({ ...additionalDetails, ...payload });
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Edit Additional Details </h2>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="First Name"
              type="text"
              name="firstName"
              value={additionalDetails.firstName}
              onChange={(e) =>
                handleAdditionalChange({ firstName: e.target.value })
              }
            />
            <TextField
              id="standard-basic"
              label="Last Name"
              type="text"
              name="lastName"
              value={additionalDetails.lastName}
              onChange={(e) =>
                handleAdditionalChange({ lastName: e.target.value })
              }
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Email"
              type="text"
              name="email"
              value={additionalDetails.email}
              error={validationErrors.email}
              onChange={(e) =>
                handleAdditionalChange({ email: e.target.value })
              }
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Mobile"
              type="text"
              name="mobile"
              value={additionalDetails.mobile}
              error={validationErrors.mobile}
              helperText="Mobile Number should be of 10 digits"
              onChange={(e) =>
                handleAdditionalChange({ mobile: e.target.value })
              }
            />
            <div className="date-container">
              <span>Date of Birth</span>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={additionalDetails.dob}
                onChange={(date) => handleAdditionalChange({ dob: date })}
                className="date-picker-field campaign-input"
              />
            </div>
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Address 1/ Company Name"
              type="text"
              name="address1"
              value={additionalDetails.address1}
              onChange={(e) =>
                handleAdditionalChange({ address1: e.target.value })
              }
            />
            <TextField
              id="standard-basic"
              label="Address 2"
              type="text"
              name="address2"
              value={additionalDetails.address2}
              onChange={(e) =>
                handleAdditionalChange({ address2: e.target.value })
              }
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Suburb"
              type="text"
              name="suburb"
              value={additionalDetails.suburb}
              onChange={(e) =>
                handleAdditionalChange({ suburb: e.target.value })
              }
            />
            <FormControl variant="standard">
              <InputLabel id="demo-simple-select-standard-label">
                State
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="state"
                value={additionalDetails.state}
                onChange={(val) => handleAdditionalChange({ state: val })}
              >
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="NSW">NSW</MenuItem>
                <MenuItem value="NT">NT</MenuItem>
                <MenuItem value="Qld">QLD</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                <MenuItem value="Vic">VIC</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
              </SelectInput>
            </FormControl>
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Post Code"
              type="text"
              name="postCode"
              value={additionalDetails.postcode}
              onChange={(e) =>
                handleAdditionalChange({ postcode: e.target.value })
              }
            />
            <TextField
              id="standard-basic"
              label="Country"
              type="text"
              name="country"
              value={additionalDetails.country}
              onChange={(e) =>
                handleAdditionalChange({ country: e.target.value })
              }
            />
          </div>
          <div className="button-container">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

EditCardInfoModel.propTypes = {
  selectedCard: PropTypes.object,
  data: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  updateAdditionalInfoProxy: PropTypes.func
};

export default EditCardInfoModel;
