// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Overlay--after-open {
  z-index: 101; }

.modal {
  background-color: black;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  width: 90vw; }
  .modal .close-icon {
    display: flex;
    justify-content: flex-end;
    padding: 0px 10px;
    cursor: pointer; }
  .modal .find-code-image-wrapper {
    display: flex;
    justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/pages/mfa-checkpoint/find-code-modal/FindCodeModal.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA;;AAGd;EACE,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,WAAW,EAAA;EATb;IAWI,aAAa;IACb,yBAAyB;IACzB,iBAAiB;IACjB,eAAe,EAAA;EAdnB;IAkBI,aAAa;IACb,uBAAuB,EAAA","sourcesContent":[".ReactModal__Overlay--after-open {\n  z-index: 101;\n}\n\n.modal {\n  background-color: black;\n  color: red;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  text-align: center;\n  width: 90vw;\n  .close-icon {\n    display: flex;\n    justify-content: flex-end;\n    padding: 0px 10px;\n    cursor: pointer;\n  }\n\n  .find-code-image-wrapper {\n    display: flex;\n    justify-content: center;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
