import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import { formatMoney } from '../../../../helpers/currency';
import "./Dialog.scss";

const ReplaceCardSummaryModal = ({
  openDialog,
  message,
  data,
  handleCloseDialog,
  handleYes,
  showCloseButton = false
}) => {
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking Yes, you will be updating the value of each card as per
            the below summary:
          </DialogContentText>

          <div className="mb-3">
            <div>
              <b>Product Id: </b> {data.productId}
            </div>
            <div>
              <b>Campaign Id: </b> {data.campaignId}
            </div>
            <div>
              <b>Value to Load Per Card: </b>
              {formatMoney(data.cardAmount)}
            </div>
            <div>
              <b>Total Load Value : </b>
              {formatMoney(data.cardAmount * data.noOfCardSelected)}
            </div>
            <div>
              <b>Reference : </b>
              {data.referenceId}
            </div>
          </div>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!showCloseButton && (
            <>
              <Button
                className="dialog-action-btn"
                onClick={handleYes}
                color="primary"
              >
                Yes
              </Button>
              <Button
                className="dialog-action-btn"
                onClick={handleCloseDialog}
                color="primary"
                autoFocus
              >
                No
              </Button>
            </>
          )}

          {showCloseButton && (
            <Button
              className="dialog-action-btn"
              onClick={handleCloseDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ReplaceCardSummaryModal.propTypes = {
  openDialog       : PropTypes.bool,
  data             : PropTypes.object,
  showCloseButton  : PropTypes.bool,
  message          : PropTypes.string,
  title            : PropTypes.string,
  handleCloseDialog: PropTypes.func,
  handleYes        : PropTypes.func
};


export default ReplaceCardSummaryModal;
