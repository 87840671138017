import React from 'react';
import PropTypes from 'prop-types';
import {format} from "date-fns";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TablePagination } from '@material-ui/core';
import { toString } from '../../../../constants/requestHandle';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const CardTable = ({
  loading,
  issuedCards,
  rowsPerPage,
  totalRowCount,
  page,
  handleSelectCard,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const classes = useStyles();
  const createRows = (data) => {
    const rows = [];
    for (let i = 0; i < data.length; i++) {
      const row = {
        cardId              : data[i].card_id,
        campaign            : data[i].promotion_id,
        campaignName        : data[i].promotion_name,
        cardCustomdata1     : data[i].card_customdata1,
        cardCustomdata2     : data[i].card_customdata2,
        product             : data[i].product_id,
        cardStatus          : data[i].card_status === "inactive" ? "inactive" : "active",
        cardValue           : data[i].card_initialvalue,
        deliveryMethod      : toString(parseInt(data[i].card_requesthandle)),
        email               : data[i].card_recipient_email,
        recipientName       : data[i].card_recipient_name,
        digitalCardSmsStatus: data[i].swapstatus,
        senderName          : `${data[i]?.card_original_fname || ''} ${
          data[i]?.card_original_lname || ''
        }`,
        cardCreationDate      : data[i].card_creation_date,
        digitalCardEmailStatus: data[i].logmailgun_deliverystatus,
        orderRef              : data[i].card_external_id,
        mobile                : data[i].swap_mobile,
        requestHandle         : data[i].card_requesthandle
      };

      rows.push(row);
    }
    return rows;
  };

  const rows = createRows(issuedCards);
  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Card Id</TableCell>
              <TableCell align="left">TCN Ref </TableCell>
              <TableCell align="left">Cust Ref 1</TableCell>
              <TableCell align="left">Cust Ref 2</TableCell>
              <TableCell align="left">Campaign</TableCell>
              <TableCell align="left">Product</TableCell>
              <TableCell align="left">Card Value</TableCell>
              <TableCell align="left">Sender Name</TableCell>
              <TableCell align="left">Recipient Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Mobile</TableCell>
              <TableCell align="left">Delivery Method</TableCell>
              <TableCell align="left">Card Status</TableCell>
              <TableCell align="left">Digital Card- SMS status</TableCell>
              <TableCell align="left">Digital Card- Email status</TableCell>
              <TableCell align="left">Card Creation date</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <div className="table-loading">Loading....</div>
            ) : rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.cardId}</TableCell>
                  <TableCell align="left">{row.orderRef}</TableCell>
                  <TableCell align="left">{row.cardCustomdata1}</TableCell>
                  <TableCell align="left">{row.cardCustomdata2}</TableCell>
                  <TableCell align="left">{row.campaign}</TableCell>
                  <TableCell align="left">{row.product}</TableCell>
                  <TableCell align="left">{row.cardValue}</TableCell>
                  <TableCell align="left">{row.senderName}</TableCell>
                  <TableCell align="left">{row.recipientName}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.mobile}</TableCell>
                  <TableCell align="left">{row.deliveryMethod}</TableCell>
                  <TableCell align="center">
                    <div>
                      <p>{row.cardStatus}</p>
                    </div>
                  </TableCell>
                  <TableCell align="left">{row.digitalCardSmsStatus}</TableCell>
                  <TableCell align="center">
                    <div>
                      <p> {row.digitalCardEmailStatus}</p>
                    </div>
                  </TableCell>
                  <TableCell align="left">
                    {format(
                      new Date(row.cardCreationDate),
                      "dd MMM yyyy 'at' HH:mm"
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => handleSelectCard(row)}
                      className="resend-email-btn"
                    >
                      Actions
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={totalRowCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

CardTable.propTypes = {
  issuedCards            : PropTypes.array,
  handleResend           : PropTypes.func,
  handleSelectCard       : PropTypes.func,
  page                   : PropTypes.number,
  rowsPerPage            : PropTypes.number,
  totalRowCount          : PropTypes.number,
  handleChangePage       : PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  loading                : PropTypes.bool
};

export default CardTable;
