import React from 'react';
import PropTypes from 'prop-types';

const FooterSection = ({ promotionid }) => {
  if (promotionid === '11248') {
  return (
    <div className="view2-footer">
      <div
        className="view2-footer-text suncorp-font"
      >
        The Card Network (“TCN”) is the issuer of the digital Prepaid Visa
        Card (“Card”). You are responsible for all transactions on the Card,
        except where there has been fraud or negligence by TCN staff or agents.
        TCN may restrict or stop the use of the Card if suspicious activities
        are noticed. You are responsible for checking your transaction history,
        knowing the available balance and date of expiry for the Card. Balance
        and transaction history are available to you within the TCN App. Any
        changes to the Terms and Conditions can be viewed{' '}
        <a href="https://thinkwink.com.au/tos" className="text-blue">
          here
        </a>
        . Information will be disclosed to third parties about the Card, or
        transactions made with the Card, whenever allowed by law, and also where
        necessary to operate the Card and process transactions. TCN’s privacy
        policy can be viewed{' '}
        <a
          href="https://thecardnetwork.com.au/pages/privacy-policy"
          className="text-blue"
        >
          here
        </a>
        .
      </div>
    </div>
  );
  } else  {
  return (
    <div className="view2-footer">
      <div className="view2-footer-text apia-font">
        The Card Network (“TCN”) is the issuer of the digital Prepaid Visa
        Card (“Card”). You are responsible for all transactions on the Card,
        except where there has been fraud or negligence by TCN staff or agents.
        TCN may restrict or stop the use of the Card if suspicious activities
        are noticed. You are responsible for checking your transaction history,
        knowing the available balance and date of expiry for the Card. Balance
        and transaction history are available to you within the TCN App. Any
        changes to the Terms and Conditions can be viewed{' '}
        <a href="https://thinkwink.com.au/tos" className="text-blue">
          here
        </a>
        . Information will be disclosed to third parties about the Card, or
        transactions made with the Card, whenever allowed by law, and also where
        necessary to operate the Card and process transactions. TCN’s privacy
        policy can be viewed{' '}
        <a
          href="https://thecardnetwork.com.au/pages/privacy-policy"
          className="text-blue"
        >
          here
        </a>
        .
      </div>
    </div>
  );
  }

};

FooterSection.propTypes = {
  promotionid: PropTypes.string
};

export default FooterSection;
