import React from 'react';
import PropTypes from 'prop-types';
import { TextField, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
    clearIndicator: {
        border: 'none !important',
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    },
    popupIndicator: {
        border: 'none !important',
        '&:hover': {
            backgroundColor: 'transparent !important'
        }
    }
});

const AutocompleteInput = ({ id, label, options, getOptionLabel, renderOption, value, onChange }) => {
  const classes = useStyles();

  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      value={value}
      onChange={onChange}
      classes={{
          clearIndicator: classes.clearIndicator,
          popupIndicator: classes.popupIndicator
      }}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

AutocompleteInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.node.isRequired,
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func
};


export default AutocompleteInput;
