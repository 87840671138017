import React, { useEffect, useState } from 'react';

import TableBody from "./table-body";
import Notify from "../../../../components/notify";
import Loader from "../../../../components/loader";
import { getAllWhiteListIpLogsAPI } from '../../../../services/api';
import NavBar from '../../../../components/Navbar';
import FilterHeader from './filter-header';

const IpLogList = () => {
  const [ipList, setIpLists]              = useState([]);
  
  const [loading, setLoading]             = useState(true);
  const [openNotify, setOpenNotify]       = useState(false);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const [errors, setErrors]               = useState({});

  useEffect(() => {
    getAllWhiteListIps(rowsPerPage, page);
  }, []);

  const getAllWhiteListIps = async (
    currentRowPerPage,
    currentPage,
  ) => {
    try {
      const response = await getAllWhiteListIpLogsAPI({
        limit: currentRowPerPage,
        page: currentPage,
      });

      setIpLists(response?.logs || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All White List Ips Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllWhiteListIps(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllWhiteListIps(newRowsPerPage, newPage);
  };

  const handleClearFilter = async () => {
    setLoading(true);
    getAllWhiteListIps(10, 0);
  };

  const handleSearch = (val) => {};

  return (
    <>
      <NavBar />
      <FilterHeader
        handleSearch={handleSearch}
      />
      <TableBody
        ipList={ipList}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default IpLogList;