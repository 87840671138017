const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data?.note) {
    errors.hasError = true;
    errors.note = true;
  }

  return errors;
};

export default validateForm;
