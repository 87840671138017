import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import UserForm from '../../../components/user-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from "../../../components/notify";
import {
  createNewUser,
  getAllDepartmentsAPI,
  generate2FAAPI
} from '../../../services/api';
import "./CreateNewUser.scss";

const CreateNewUser = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [departments, setDepartments]           = useState([]);
  const [mfaSecrets, setMfaSecrets]             = useState({});
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);
  const history = useHistory();

  useEffect(() => {
    getDepartments();
  }, []);

  const getDepartments = async () => {
    try {
      const departments = await getAllDepartmentsAPI();
      setDepartments(departments);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Departments Failed !!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  const generate2FA = async () => {
    try {
      const mfaCode = await generate2FAAPI();
      setMfaSecrets(mfaCode);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Generating MFA Secret Failed !!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      const response = await createNewUser(payload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        history.push('/user/list');
      }, 1000);
    } catch(err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'User Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  }

  const redirectToUserList = () => {
    history.push('/user/list');
  }

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h2>Create New User</h2>
          </div>
          <UserForm
            departments={departments}
            handleSubmit={handleSubmit}
            redirectToUserList={redirectToUserList}
            loading={loading}
            generate2FA={generate2FA}
            mfaSecrets={mfaSecrets}
          />
          <Notify
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            errors={errors}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CreateNewUser;