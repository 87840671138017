import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import UserForm from '../../../components/user-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from '../../../components/notify';
import {
  getAllDepartmentsAPI,
  updateUser,
  generate2FAAPI,
  getCustomerInfoAPI
} from '../../../services/api';
import './EditUser.scss';

const EditUser = () => {
  const history = useHistory();
  const params = useParams();
  const userId = params.id;
  const subUsersReducer                         = useSelector((state) => state.user.subUsers);
  const user                                    = subUsersReducer?.users?.find((user) => user.id === userId);
  const [customerInfo, setCustomerInfo]         = useState({});
  const [departments, setDepartments]           = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [mfaSecrets, setMfaSecrets]             = useState(user?.mfa_secret || '');
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);

  useEffect(() => {
    if (subUsersReducer.length === 0) {
      history.push('/user/list');
    }

    getCustomerInfo();
    getDepartments();
  }, []);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo?.customer || {});
    } catch (err) {
      setCustomerInfo({});
    }
  };

  const getDepartments = async () => {
    try {
      const departments = await getAllDepartmentsAPI();
      setDepartments(departments);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Departments Failed !!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  const redirectToUserList = () => {
    history.push('/user/list');
  };

  const generate2FA = async () => {
    try {
      const mfaCode = await generate2FAAPI();
      setMfaSecrets(mfaCode);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Generating MFA Secret Failed !!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  const handleSubmit = async(payload) => {
    try {
      setLoading(true);
      const response = await updateUser(userId, payload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        history.push('/user/list');
      }, 1000);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'User Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h3>Edit User</h3>
          </div>
          <UserForm
            user={user}
            departments={departments}
            loading={loading}
            handleSubmit={handleSubmit}
            redirectToUserList={redirectToUserList}
            generate2FA={generate2FA}
            mfaSecrets={mfaSecrets}
            customer={customerInfo}
          />
          <Notify
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            errors={errors}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditUser;
