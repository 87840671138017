import React from 'react';

import ListIssuanceType from "./issuance-type-list";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import './IssuanceType.scss';

const IssuanceTypeList = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center campain-table-container container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3">Issuance Type</h2>
          </div>
          <ListIssuanceType />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IssuanceTypeList;
