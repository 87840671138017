import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { FormControl, FormHelperText, InputLabel, MenuItem, TextField } from '@material-ui/core';

import SelectInput from '../../../../components/Select';
import validateForm from './validateForm.helper';
import './CardModal.scss';
import 'react-datepicker/dist/react-datepicker.css';

const CardModel = ({ modalIsOpen, closeModal, cancelCard }) => {
  const [reasonCardCancellation, setReasonCardCancellation] = useState(null);
  const [notes, setNotes] = useState('');
  const [validationErrors, setValidationErrors] = useState({});

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleClick = () => {
    const validate = validateForm({ reasonCardCancellation, notes });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    cancelCard({reasonCardCancellation, notes});
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Cancel Card</h2>
          <div className="bulkCardActionsForms">
            <FormControl
              variant="standard"
              error={validationErrors.reasonCardCancellation}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Reason for card cancellation
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="reasonCardCancellation"
                value={reasonCardCancellation}
                onChange={(val) => setReasonCardCancellation(val)}
              >
                <MenuItem value={'Lost'}>Lost</MenuItem>
                <MenuItem value={'Stolen'}>Stolen</MenuItem>
                <MenuItem value={'Damaged'}>Damaged</MenuItem>
                <MenuItem value={'Other'}>Other</MenuItem>
              </SelectInput>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>
            <TextField
              id="standard-basic"
              label="Notes"
              type="text"
              name="notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              error={validationErrors.notes}
            />
          </div>
          <div className="button-container">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

CardModel.propTypes = {
  selectedCard: PropTypes.object,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  cancelCard: PropTypes.func
};

export default CardModel;
