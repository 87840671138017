export const RequestHandleType = {
  CardNumberAndPin: 1,
  SMS             : 2,
  Email           : 3,
  Physical        : 4,
  SMSCard         : 5
}

const RequestHandles = ['Unique URL', 'SMS', 'Email', 'Physical', 'SMS - Card'];

export const toString = (id) => {
  switch (id) {
    case RequestHandleType.CardNumberAndPin:
      return RequestHandles[0];
    case RequestHandleType.SMS:
      return RequestHandles[1];
    case RequestHandleType.Email:
      return RequestHandles[2];
    case RequestHandleType.Physical:
      return RequestHandles[3];
    case RequestHandleType.SMSCard:
      return RequestHandles[4];
  }
}

export default RequestHandles;