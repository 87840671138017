import { startOfDay, endOfDay } from 'date-fns';

export const InitialFilterHeader = {
    tcnCustomerId: '',
    environment: false,
    campaignId: '',
    userId: 'All',
    productId: '',
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date())
};