import { RequestHandleType } from '../../../constants/requestHandle';

const validateForm = (deliveryMethod, data) => {
  let errors = { hasError: false, msg: {} };
  const emailformat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;


  if (deliveryMethod === RequestHandleType.SMS) {
    if (!data.recpFirstName || data.recpFirstName.length < 2) {
      errors.hasError = true;
      errors.recpFirstName = true;
      errors.msg.recpFirstName =
        '*First name should be at least 2 characters long.';
    }

    if (!data.recpLastName || data.recpLastName.length < 2) {
      errors.hasError = true;
      errors.recpLastName = true;
      errors.msg.recpLastName =
        '*Last name should be at least 2 characters long.';
    }

    if (!data.recpEmail || !data.recpEmail.match(emailformat)) {
      errors.hasError = true;
      errors.recpEmail = true;
      errors.msg.recpEmail = '*Email is not valid';
    }

    if (!data.recpMobile || data.recpMobile.length !== 9) {
      errors.hasError = true;
      errors.recpMobile = true;
      errors.msg.recpMobile =
        '*Mobile should be nine digits long with no leading zero.';
    }
  }

  if (deliveryMethod === RequestHandleType.Physical) {
    if (!data.postage) {
      errors.hasError = true;
      errors.postage = true;
    }

    if (!data.fulfilment) {
      errors.hasError = true;
      errors.fulfilment = true;
    }

    if (!data.recpFirstName) {
      errors.hasError = true;
      errors.recpFirstName = true;
    }

    if (!data.recpLastName) {
      errors.hasError = true;
      errors.recpLastName = true;
    }

    if (!data.address1) {
      errors.hasError = true;
      errors.address1 = true;
    }

    if (!data.city) {
      errors.hasError = true;
      errors.city = true;
    }

    if (!data.postCode) {
      errors.hasError = true;
      errors.postCode = true;
    }

    if (!data.state) {
      errors.hasError = true;
      errors.state = true;
    }

    if (!data.country) {
      errors.hasError = true;
      errors.country = true;
    }

    // if (!data.cardData1) {
    //   errors.hasError = true;
    //   errors.cardData1 = true;
    // }

    // if (!data.cardData2) {
    //   errors.hasError = true;
    //   errors.cardData2 = true;
    // }
  }

  if (deliveryMethod === RequestHandleType.Email) {
    if (!data?.senderName?.trim()) {
      errors.hasError = true;
      errors.senderName = true;
    }
    if (!data.senderEmail) {
      errors.hasError = true;
      errors.senderEmail = true;
    }
    if (!data.recpFirstName) {
      errors.hasError = true;
      errors.recpFirstName = true;
    }
    if (!data.recpEmail) {
      errors.hasError = true;
      errors.recpEmail = true;
    }
  }

  return errors;
};

export default validateForm;