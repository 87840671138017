import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import { getUserInfo } from '../../../../helpers/localStorage';

import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../../../../assets/images/search-yellow.svg';

import { getUserPermissionType } from '../../../../helpers/role';

const userInfo = getUserInfo();
const userPermissionType = getUserPermissionType(userInfo?.type, userInfo?.tcnCustomerId, userInfo?.customerId);
const allowUserCreation = userPermissionType === "Master User" || (userPermissionType === "Administrator" && userInfo?.customerExpectsso !== "TRUE");

const useStyles = makeStyles({
  fullWidth: {
    width: '700px'
  },
  root: {
    width: '300px'
  }
});

const TableHeader = ({ handleSearch }) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToCreateUser = () => {
    history.push('/user/create');
  };

  const redirectToListDepartment = () => {
    history.push('/department/list');
  };

  const redirectToListIssuanceType = () => {
    history.push('/issuance-type/list');
  };

  const userInfo = getUserInfo();

  return (
    <>
      <div className="table-filter-body">
        <div className="form-container">
          <div className="flex field-button-container">
            <FormControl variant="standard" className="form-control w-50">
              <TextField
                className={classes.root}
                id="standard-basic"
                label="Search for information"
                name="search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <img src={SearchIcon}></img>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
            <div className="flex flex-wrap justify-between w-50">
              {allowUserCreation && <Button variant="outlined" className='mb-2' onClick={redirectToCreateUser}>
                Create new User
              </Button>}
              <Button variant="outlined" onClick={redirectToListDepartment}>
                Departments
              </Button>
              { userInfo.customerIssuanceType === 'FALSE' || <Button variant="outlined" onClick={redirectToListIssuanceType}>
                Issuance Types
              </Button>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TableHeader.propTypes = {
  handleSearch: PropTypes.func
};

export default TableHeader;
