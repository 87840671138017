import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Container from '@material-ui/core/Container';

import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer';
import validateForm from './validateForm.helper';
import FindCodeModal from './find-code-modal';

import { getUserId } from '../../helpers/localStorage';
import './MfaCheckpoint.scss';
import Notify from '../../components/notify';

export default function MfaCheckPoint() {
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [openFindCodeModal, setOpenFindCodeModal]                   = useState(false);
  const [token, setToken] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const TCNCP_API = process.env.REACT_APP_TCNCP_API;
    const userId = getUserId();
    const payload = { token, userId };
    const validate = validateForm(payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    axios
      .post(`${TCNCP_API}/auth/2fa/verify`, payload)
      .then((res) => {
        localStorage.setItem('user_info', JSON.stringify(res.data.user));
        localStorage.setItem('auth_token', res.data.token);
        localStorage.setItem('test_mode', res.data.isTestMode);
        res?.data?.user?.applyAgentIssuanceOnly === 'TRUE'
          ? history.push('/agent-issuance')
          : history.push('/dashboard');
      })
      .catch((err) => {
        const errors = err?.response?.data
          ? err.response.data
          : { errormessage: 'Something Went Wrong !!!!' };
        setErrors(errors);
        setOpenSuccessNotify(true);
      });
  };

  const toggleFindCodeModel = (val) => {
    setOpenFindCodeModal(val);
  };

  return (
    <>
      <Container component="main" className="mfa-container flex-col container">
        <CssBaseline />
        <div className="flex flex-col items-center">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>
          <form className="login-form" noValidate onSubmit={handleSubmit}>
            <div className="checkpoint-info">
              Enter the 6-digit code from the authentication app you set up.
            </div>
            <TextField
              error={validationErrors.token}
              required
              fullWidth
              name="token"
              label="Login Code"
              type="token"
              id="token"
              onChange={(e) => setToken(e.target.value)}
              className="login-field"
            />
            <Button
              type="submit"
              fullWidth
              className="submit-btn"
              endIcon={
                <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
              }
            >
              Submit Code
            </Button>
            <div className="info-text">
              Not sure where to find the code? Click{' '}
              <span
                className="text-blue"
                onClick={() => toggleFindCodeModel(true)}
              >
                here
              </span>
            </div>
          </form>
        </div>
      </Container>
      <Footer />
      <Notify
        open={openSuccessNotify}
        onClose={() => setOpenSuccessNotify(false)}
        errors={errors}
        successMessage=""
      />
      <FindCodeModal
        modalIsOpen={openFindCodeModal}
        closeModal={() => toggleFindCodeModel(false)}
      />
    </>
  );
}
