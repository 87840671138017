import React from 'react';
import PropTypes from 'prop-types';
import './Loader.scss';

const Loader = ({ loading }) => {
  if(!loading) {
    return null;
  }
  return(
    <div className="loader">
        <div className="loader-stage">
            <div className="dot-typing"></div>
            <div className="loader-text"><p>Do not click back while task is processing.</p></div>
        </div>
    </div>
  )
}

Loader.propTypes = {
  loading: PropTypes.array
};

export default Loader;