// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.apexcharts-toolbar {
  display: none !important; }

.apexcharts-text tspan {
  font-size: 16px;
  text-align: center; }

.live-campaign-chart {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%; }
  .live-campaign-chart .apexcharts-canvas {
    overflow-x: auto;
    overflow-y: hidden; }

.live-campaign-container {
  padding: 80px 20px 36px; }
  @media screen and (min-width: 768px) {
    .live-campaign-container {
      padding: 140px 20px 93px; } }
  .live-campaign-container h2 {
    margin-bottom: 70px; }
    @media screen and (min-width: 768px) {
      .live-campaign-container h2 {
        margin-bottom: 65px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/live-campaign-chart/liveCampaigns.scss"],"names":[],"mappings":"AAAA;EACE,wBAAwB,EAAA;;AAE1B;EACE,eAAe;EACf,kBAAkB,EAAA;;AAEpB;EACE,cAAc;EACd,eAAe;EACf,WAAW,EAAA;EAHb;IAKI,gBAAgB;IAChB,kBAAkB,EAAA;;AAQtB;EACE,uBAAuB,EAAA;EACvB;IAFF;MAGI,wBAAuB,EAAA,EAQ1B;EAXD;IAMI,mBAAmB,EAAA;IACnB;MAPJ;QAQM,mBAAmB,EAAA,EAEtB","sourcesContent":[".apexcharts-toolbar {\n  display: none !important;\n}\n.apexcharts-text tspan {\n  font-size: 16px;\n  text-align: center;\n}\n.live-campaign-chart{\n  margin: 0 auto;\n  padding: 0 20px;\n  width: 100%;\n  .apexcharts-canvas{\n    overflow-x: auto;\n    overflow-y: hidden;\n    // &::-webkit-scrollbar{\n    //   display: none;\n    // }\n    // -ms-overflow-style: none;\n    // scrollbar-width: none;\n  }\n}\n.live-campaign-container{\n  padding: 80px 20px 36px;\n  @media screen and (min-width: 768px) {\n    padding:140px 20px 93px;\n  }\n  h2{\n    margin-bottom: 70px;\n    @media screen and (min-width: 768px) {\n      margin-bottom: 65px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
