import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import Button from '@material-ui/core/Button';
import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@material-ui/core/TextField';

import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from 'react-datepicker';

import Loader from "../../../components/loader";
import SelectInput from '../../../components/Select';
import DialogBox from "../../../components/dialog-box";
import Notify from "../../../components/notify";
import { createCardAPI } from '../../../services/api';
import { toString, RequestHandleType } from '../../../constants/requestHandle';
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { getUserName, getUserEmail, getDepartmentName } from '../../../helpers/localStorage';
import { formatMoney } from '../../../helpers/currency';
import validateForm from './validateForm.helper';
import "./finalStep.scss";
import YesNoDialog from '../../../components/yes-no-dialog';
import DialogContentBox from '../../../components/dialog-content-box';

const IssueCardFinalStep = () => {
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [senderName, setSenderName] = useState(getUserName());
  const [senderEmail, setSenderEmail] = useState(getUserEmail());
  const [openDialog, setOpenDialog] = useState(false);
  const [openContentDialog, setOpenContentDialog] = useState(false);
  const [openCardArtDialog, setOpenCardArtDialog] = useState(false);
  const [openYesNoDialog, setOpenYesNoDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);
  const [contentReport, setContentReport] = useState('');
  const [recpFirstName, setRecpFirstName] = useState('');
  const [recpLastName, setRecpLastName] = useState('');
  const [recpMobile, setRecpMobile] = useState('');
  const [fulfilment, setFulfilment] = useState('1');
  const [recpEmail, setRecpEmail] = useState('');
  const [recpEmailMsg, setRecpEmailMsg] = useState('');
  const [postage, setPostage] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('Australia');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerReference, setCustomerReference] = useState('');
  const [customerMobile, setCustomerMobile] = useState('');
  const [customerZipCode, setCustomerZipCode] = useState('');
  const [customerDob, setCustomerDob] = useState('');
  const [customerCountry, setCustomerCountry] = useState('');
  const [customerAddress1, setCustomerAddress1] = useState('');
  const [customerAddress2, setCustomerAddress2] = useState('');
  const [customerCity, setCustomerCity] = useState('');
  const [customerState, setCustomerState] = useState('');
  const [customerNote, setCustomerNote] = useState('');
  const [cardData1, setCardData1] = useState('');
  const [cardData2, setCardData2] = useState('');

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const issueCardInfo = useSelector((state) => state.issueCard.data);
  const deliveryMethod = issueCardInfo.deliveryMethod;
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const agentIssuance = searchParams.get('agentIssuance');
  const isAgentIssuanceDigital = agentIssuance === 'digital';

  useEffect(() => {
    if (Object.keys(issueCardInfo).length === 0) {
      history.push(
        isAgentIssuanceDigital ? 'agent-issuance' : '/issue-card-initial'
      );
      return;
    }
  }, []);

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1)
    },
    fullWidth: {
      width: '600px'
    }
  }));
  const classes = useStyles();

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'senderName':
        setSenderName(value);
        break;
      case 'senderEmail':
        setSenderEmail(value);
        break;
      case 'recpFirstName':
        setRecpFirstName(value);
        break;
      case 'recpLastName':
        setRecpLastName(value);
        break;
      case 'recpMobile':
        setRecpMobile(value);
        break;
      case 'recpEmail':
        setRecpEmail(value);
        break;
      case 'recpEmailMsg':
        setRecpEmailMsg(value);
        break;
      case 'address1':
        setAddress1(value);
        break;
      case 'address2':
        setAddress2(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'country':
        setCountry(value);
        break;
      default:
        break;
    }
  };

  const submit = async () => {
    try {
      setLoading(true);

      const { productName, ...issuedcardInfo } = issueCardInfo;

      const data = Object.assign(
        {},
        {
          ...issuedcardInfo,
          fulfilment,
          senderName,
          senderEmail,
          recpFirstName,
          recpLastName,
          recpMobile,
          recpEmail,
          recpEmailMsg,
          postage,
          address1,
          address2,
          city,
          postCode,
          state,
          country,
          cardData1,
          cardData2
        },
        deliveryMethod === RequestHandleType.CardNumberAndPin
          ? {
            senderEmail: null,
            senderName: null
          }
          : null,
        isAgentIssuanceDigital
          ? {
            agent: getUserName(),
            customerLabel: getDepartmentName(),
            customerEmail,
            customerMobile,
            customerZipCode,
            customerAddress1,
            customerAddress2,
            customerCity,
            customerNote,
            customerState,
            customerReference
          }
          : null
      );

      const response = await createCardAPI(data, isAgentIssuanceDigital);
      setErrors({});
      setValidationErrors({});
      setOpenSuccessNotify(true);
      if (deliveryMethod === RequestHandleType.CardNumberAndPin) {
        setOpenYesNoDialog(false);
        setOpenContentDialog(true);
        setContentReport(response);
        return;
      }

      if (deliveryMethod === RequestHandleType.SMS) {
        setDialogMessage(
          'Your order has been successfully completed.' +
          'Your recipient will receive an sms with a link to their digital card. '
        );
        setOpenDialog(true);
        return;
      }

      if (deliveryMethod === RequestHandleType.Email) {
        setDialogMessage(
          'Your order has been successfully completed. ' +
          'Your recipient will receive an email to access their digital card. '
        );
        setOpenDialog(true);
        return;
      }

      setTimeout(() => {
        setLoading(false);
        history.push(
          isAgentIssuanceDigital
            ? 'agent-issuance'
            : '/issue-card-initial'
        );
      }, 2000);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Card Creation Failed !!!!' };
      setErrors(errors);
      setValidationErrors({});
      setLoading(false);
      setOpenSuccessNotify(true);
    }
  };

  const handleSubmit = async () => {
    const { productName, ...issuedcardInfo } = issueCardInfo;
    const data = Object.assign(
      {},
      {
        ...issuedcardInfo,
        fulfilment,
        senderName,
        senderEmail,
        recpFirstName,
        recpLastName,
        recpMobile,
        recpEmail,
        recpEmailMsg,
        postage,
        address1,
        address2,
        city,
        postCode,
        state,
        country,
        cardData1,
        cardData2
      },
      deliveryMethod === RequestHandleType.CardNumberAndPin
        ? {
          senderEmail: null,
          senderName: null
        }
        : null
    );

    const validate = validateForm(deliveryMethod, data);
    if (validate.hasError) {
      setValidationErrors(validate);
      setLoading(false);
      return;
    }

    if (
      deliveryMethod === RequestHandleType.CardNumberAndPin ||
      deliveryMethod === RequestHandleType.Physical
    ) {
      setOpenYesNoDialog(true);
      return;
    } else {
      submit();
    }
  };

  const handleCancel = () => {
    history.push(
      isAgentIssuanceDigital ? 'agent-issuance' : '/issue-card-initial'
    );
  };

  const handleCloseDialog = () => {
    setOpenContentDialog(false);
    setOpenDialog(false);
    handleCancel();
  };

  const handleYes = async () => {
    submit();
  };

  const downloadContentFile = () => {
    let byteArray = new Uint8Array(contentReport?.report?.data || null);
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    );
    a.download = contentReport?.report?.externalorderid || '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderDialogContent = () => {
    return (
      <Button
        className="dialog-action-btn"
        onClick={downloadContentFile}
        color="primary"
        autoFocus
      >
        Download XSLX File
      </Button>
    );
  };

  const renderSenderFields = () => {
    const hideSenderName =
      deliveryMethod === RequestHandleType.CardNumberAndPin ||
      deliveryMethod === RequestHandleType.SMS ||
      deliveryMethod === RequestHandleType.SMSCard ||
      deliveryMethod === RequestHandleType.Physical;
    const showSenderEmail = [RequestHandleType.Email].includes(
      deliveryMethod
    );
    return (
      <>
        {!hideSenderName && (
          <TextField
            className={classes.fullWidth}
            id="standard-number"
            label="Sender Name"
            type="text"
            name="senderName"
            value={senderName}
            onChange={handleOnChange}
            helperText="*Is required."
            error={validationErrors.senderName}
          />
        )}
        {showSenderEmail && (
          <TextField
            className={classes.fullWidth}
            id="standard-number"
            label="Sender Email"
            type="email"
            name="senderEmail"
            value={senderEmail}
            onChange={handleOnChange}
            helperText="*Is required."
            error={validationErrors.senderEmail}
          />
        )}
      </>
    );
  };

  const renderRecipientFields = () => {
    const hideName = [
      RequestHandleType.CardNumberAndPin,
      RequestHandleType.SMSCard].includes(deliveryMethod);
    const showEmail = [
      RequestHandleType.SMS, RequestHandleType.Email,
      RequestHandleType.Physical
    ].includes(deliveryMethod);
    const showPhone = [RequestHandleType.SMS, RequestHandleType.SMSCard, RequestHandleType.Physical].includes(
      deliveryMethod
    );
    const hideRecipientMessage = [RequestHandleType.SMS].includes(
      deliveryMethod
    );

    const showFulfilment = [RequestHandleType.Physical].includes(deliveryMethod);

    let showHelperTextForEmail = '';
    if (RequestHandleType.Physical !== deliveryMethod &&
      validationErrors?.msg?.recpEmail) {
      showHelperTextForEmail = '*Is required';
    }

    return (
      <>
        {!hideName && (
          <>
            <TextField
              error={validationErrors.recpFirstName}
              className="halfWidth"
              id="standard-basic"
              label="Recipient first name"
              type="text"
              name="recpFirstName"
              onChange={handleOnChange}
              helperText={
                validationErrors?.msg?.recpFirstName || '*Is required.'
              }
            />
            <TextField
              error={validationErrors.recpLastName}
              className="halfWidth"
              id="standard-basic"
              label="Recipient last name"
              type="text"
              name="recpLastName"
              onChange={handleOnChange}
              helperText={validationErrors?.msg?.recpLastName || ''}
            />
          </>
        )}
        {showPhone && (
          <TextField
            error={validationErrors.recpMobile}
            className={classes.fullWidth}
            id="standard-number"
            label="Recipient mobile"
            type="number"
            name="recpMobile"
            onChange={handleOnChange}
            helperText={
              validationErrors?.msg?.recpMobile ||
              '*Mobile should be nine digits long with no leading zero, spaces or +61 (eg 499111222)'
            }
          />
        )}
        {showFulfilment && (
          <FormControl variant="standard" className="mb-2">
            <InputLabel id="demo-simple-select-standard-label">
              Fulfilment
            </InputLabel>
            <SelectInput
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={fulfilment}
              name="fulfilment"
              onChange={(val) => setFulfilment(val)}
              error={validationErrors.fulfilment}
            >
              <MenuItem value={'1'}>Packaging (Tent Card and Wallet)</MenuItem>
              <MenuItem value={'3'}>No Packaging (Card only)</MenuItem>
            </SelectInput>
            <FormHelperText>*Is required.</FormHelperText>
          </FormControl>
        )}
        {showEmail && (
          <>
            <TextField
              error={validationErrors.recpEmail}
              className={classes.fullWidth}
              id="standard-basic"
              label="Recipient email"
              type="email"
              name="recpEmail"
              onChange={handleOnChange}
              helperText={showHelperTextForEmail}
            />
            {(!hideRecipientMessage &&
              fulfilment === '1') && (
                <TextField
                  className={classes.fullWidth}
                  id="standard-textarea"
                  label="Recipient message"
                  type="text"
                  name="recpEmailMsg"
                  multiline
                  maxRows={4}
                  onChange={handleOnChange}
                />
              )}
          </>
        )}
      </>
    );
  };

  const renderLocationFields = () => {
    const showFields = deliveryMethod === RequestHandleType.Physical;
    return (
      <>
        {showFields && (
          <>
            <FormControl variant="standard" className="mb-2">
              <InputLabel id="demo-simple-select-standard-label">
                Postage
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={postage}
                name="postage"
                onChange={(val) => setPostage(val)}
                error={validationErrors.postage}
              >
                <MenuItem value={'0'}>Standard Postage </MenuItem>
                <MenuItem value={'1'}>Express Postage </MenuItem>
                <MenuItem value={'2'}>Registered</MenuItem>
                <MenuItem value={'3'}>Courier </MenuItem>
              </SelectInput>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>

            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Address1 / Company name"
              type="text"
              name="address1"
              onChange={handleOnChange}
              error={validationErrors.address1}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Address 2"
              type="text"
              name="address2"
              onChange={handleOnChange}
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Suburb"
              type="text"
              name="city"
              onChange={handleOnChange}
              error={validationErrors.city}
              helperText="*Is required."
            />
            <FormControl variant="standard" className="mb-2">
              <InputLabel id="demo-simple-select-standard-label">
                State
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={state}
                name="state"
                onChange={(val) => setState(val)}
                error={validationErrors.state}
              >
                <MenuItem value={'NSW'}>NSW</MenuItem>
                <MenuItem value={'VIC'}>VIC</MenuItem>
                <MenuItem value={'QLD'}>QLD</MenuItem>
                <MenuItem value={'SA'}>SA</MenuItem>
                <MenuItem value={'WA'}>WA</MenuItem>
                <MenuItem value={'TAS'}>TAS</MenuItem>
                <MenuItem value={'ACT'}>ACT</MenuItem>
                <MenuItem value={'NT'}>NT</MenuItem>
              </SelectInput>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Postcode"
              type="text"
              name="postCode"
              onChange={(e) => setPostCode(e.target.value)}
              error={validationErrors.postCode}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Country"
              type="text"
              value={country}
              name="country"
              onChange={handleOnChange}
              error={validationErrors.country}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Custom Data 1"
              type="text"
              value={cardData1}
              name="cardData1"
              onChange={(e) => setCardData1(e.target.value)}
              error={validationErrors.cardData1}
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Custom Data 2"
              type="text"
              value={cardData2}
              name="cardData2"
              onChange={(e) => setCardData2(e.target.value)}
              error={validationErrors.cardData2}
            />
          </>
        )}
      </>
    );
  };

  const renderAgentIssuanceDigitalFields = () => {
    const showFields = isAgentIssuanceDigital;
    return (
      <>
        {showFields && (
          <>
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer Mobile"
              type="text"
              name="address1"
              onChange={(e) => setCustomerMobile(e.target.value)}
              error={validationErrors.customerMobile}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer Reference"
              type="text"
              name="customerReference"
              onChange={(e) => setCustomerReference(e.target.value)}
              error={validationErrors.customerReference}
              helperText="*Is required."
            />
            <div className="date-container">
              <span>Date of Birth</span>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                selected={customerDob}
                onChange={(date) => setCustomerDob(date)}
                className="date-picker-field campaign-input"
              />
            </div>
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer Address1 / Company name"
              type="text"
              name="customerAddress1"
              onChange={(e) => setCustomerAddress1(e.target.value)}
              error={validationErrors.address1}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Address 2"
              type="text"
              name="customerAddress2"
              onChange={(e) => setCustomerAddress2(e.target.value)}
              error={validationErrors.customerAddress2}
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer City"
              type="text"
              name="customerCity"
              onChange={(e) => setCustomerCity(e.target.value)}
              error={validationErrors.customerCity}
              helperText="*Is required."
            />
            <FormControl variant="standard" className="mb-2">
              <InputLabel id="demo-simple-select-standard-label">
                Customer State
              </InputLabel>
              <SelectInput
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={customerState}
                name="customerState"
                onChange={(val) => setCustomerState(val)}
                error={validationErrors.customerState}
              >
                <MenuItem value={'NSW'}>NSW</MenuItem>
                <MenuItem value={'VIC'}>VIC</MenuItem>
                <MenuItem value={'QLD'}>QLD</MenuItem>
                <MenuItem value={'SA'}>SA</MenuItem>
                <MenuItem value={'WA'}>WA</MenuItem>
                <MenuItem value={'TAS'}>TAS</MenuItem>
                <MenuItem value={'ACT'}>ACT</MenuItem>
                <MenuItem value={'NT'}>NT</MenuItem>
              </SelectInput>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer ZipCode"
              type="text"
              name="customerZipCode"
              onChange={(e) => setCustomerZipCode(e.target.value)}
              error={validationErrors.customerZipCode}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Customer Country"
              type="text"
              value={customerCountry}
              name="customerCountry"
              onChange={(e) => setCustomerCountry(e.target.value)}
              error={validationErrors.customerCountry}
              helperText="*Is required."
            />
            <TextField
              className={classes.fullWidth}
              id="standard-basic"
              label="Card Notes"
              type="text"
              name="customerNote"
              onChange={(e) => setCustomerNote(e.target.value)}
              error={validationErrors.customerNote}
              helperText="*Is required."
            />
          </>
        )}
      </>
    );
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full bg-black text-white preview-card-container container">
        <div className="flex flex-col text-center">
          <h2>Issue a Card</h2>
        </div>
        <div className="counter-container">
          <ul className="counter-items">
            <li className="counter-item">
              <h1>{issueCardInfo.campaign}</h1>
              <p>Promotion</p>
            </li>
            <li className="counter-item">
              <h1>{issueCardInfo.productName}</h1>
              <p>Card Type</p>
            </li>
            <li className="counter-item">
              <h1>{issueCardInfo.units}</h1>
              <p>Total Cards Issued</p>
            </li>
            <li className="counter-item">
              <h1>
                {formatMoney(issueCardInfo.denomination * issueCardInfo.units)}
              </h1>
              <p>Value of Cards Issued</p>
            </li>
            <li className="counter-item">
              <h1>{toString(issueCardInfo.deliveryMethod)}</h1>
              <p>Delivery Method</p>
            </li>
            {issueCardInfo?.deliveryMethod === 4 && issueCardInfo?.variantDesignIdentifier && <li className="counter-item">
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.button}
                onClick={() => { setOpenCardArtDialog(true); }}
              >
                Preview card
              </Button>
            </li>
            }
          </ul>
        </div>
        <div className="flex flex-col text-center sub-heading"></div>
        <div className="flex flex-wrap justify-center">
          <div className="form-container">
            {renderSenderFields()}
            {renderRecipientFields()}
            {renderLocationFields()}
            {renderAgentIssuanceDigitalFields()}
            <div className="preview-header-action">
              <Button
                variant="contained"
                color="primary"
                disabled={loading}
                className={classes.button}
                endIcon={
                  <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                }
                onClick={handleSubmit}
              >
                Submit
              </Button>

              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Loader loading={loading} />
      <DialogBox
        openDialog={openDialog}
        message={dialogMessage}
        handleCloseDialog={handleCloseDialog}
      />
      <DialogContentBox
        openDialog={openContentDialog}
        renderContent={renderDialogContent}
        handleCloseDialog={handleCloseDialog}
      />
      <DialogContentBox
        title={`TCN ref: ${issueCardInfo?.variantGuid}`}
        openDialog={openCardArtDialog}
        renderContent={() => <img src={`https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/tcnvdi/${issueCardInfo?.variantDesignIdentifier}.png`} />}
        handleCloseDialog={() => { setOpenCardArtDialog(false); }}
      />
      <Notify
        open={openSuccessNotify}
        onClose={() => setOpenSuccessNotify(false)}
        errors={errors || 'Cards Creation Failed !!'}
        successMessage={
          RequestHandleType.Physical === deliveryMethod
            ? 'Your order has been successfully completed. Your physical cards will be dispatched by our team within the agreed timeframe.'
            : 'Cards Created Successfully !!'
        }
      />

      <YesNoDialog
        openDialog={openYesNoDialog}
        message={'Are you sure?'}
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
      />
    </>
  );
};

export default IssueCardFinalStep;