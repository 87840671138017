// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTypography-root {
  color: black !important; }

.dialog-action-btn {
  color: black !important; }

.file-summary-wrapper {
  overflow-y: auto; }
  .file-summary-wrapper .file-summary {
    display: flex;
    margin: 5px;
    flex-direction: column; }
`, "",{"version":3,"sources":["webpack://./src/pages/agent-issuance/available-cards/bulk-card-modal/confirmation-dialog/Dialog.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAA;;AAGzB;EACE,uBAAuB,EAAA;;AAGzB;EACE,gBAAgB,EAAA;EADlB;IAGI,aAAa;IACb,WAAW;IACX,sBAAsB,EAAA","sourcesContent":[".MuiTypography-root {\n  color: black !important;\n}\n\n.dialog-action-btn {\n  color: black !important;\n}\n\n.file-summary-wrapper {\n  overflow-y: auto;\n  .file-summary {\n    display: flex;\n    margin: 5px;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
