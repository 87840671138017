import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../../../components/loader';
import Notify from '../../../../components/notify';
import { getCardByIdentifierAPI } from '../../../../services/api';
import './EdmSuncorpBanking.scss';

const SuncorpBankingEdm = () => {
  const [recfn, setRecfn]                       = useState('');
  const [redemtionLink, setRedemtionLink]       = useState('');
  const [initialValue, setIntialValue]          = useState(0);
  const [loading, setLoading]                   = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors]                     = useState({});

  const { identifier } = useParams();

  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = async () => {
    try {
      const card = await getCardByIdentifierAPI(identifier);
      const fullName = card?.data?.name?.split(' ');
      const initialvalue = card?.data?.card_initial_value || 0;
      const link = card?.data?.redemptionlink || '';

      const firstName = fullName?.length > 0 ? fullName[0] : '';
      setRecfn(firstName);
      setIntialValue(initialvalue);
      setRedemtionLink(link);

      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Failed to get card details!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="edm-suncorp-wrapper">
        <table
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="em_full_wrap"
        >
          <tr>
            <td align="center" valign="top">
              <table
                className="em_main_table"
                align="center"
                width="700"
                border="0"
                cellSpacing="0"
                cellPadding="0"
              >
                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#FFFFFF' }}
                    >
                      <tr>
                        <td
                          className="em_padding1"
                          align="center"
                          style={{ padding: '14px 15px 14px 15px' }}
                        >
                          <table
                            className="em_wrapper"
                            align="center"
                            width="630"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '630px' }}
                          >
                            <tr>
                              <td className="em_font15" align="left">
                                Enjoy a digital Prepaid Visa card from Suncorp
                                Brighter Rewards
                              </td>
                            </tr>
                            <tr>
                              <td align="left">
                                <img
                                  className="logo1"
                                  src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/logo_header.png"
                                  border="0"
                                  alt="Image"
                                />
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#FFFFFF' }}
                    >
                      <tr>
                        <td align="center">
                          <img
                            className="em_img"
                            src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/Banking/main_logo.jpeg"
                            alt="Image"
                          />
                        </td>
                      </tr>

                      <tr>
                        <td align="center">
                          <table
                            className="em_wrapper"
                            align="center"
                            width="700"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '700px' }}
                          >
                            <tr>
                              <td className="em_padding15" align="center">
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="630"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{ width: '630px' }}
                                >
                                  <tr>
                                    <td className="em_font17" align="left">
                                      Ready for some great news {recfn}?
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="em_font15" align="left">
                                      You&apos;ve earned ${initialValue} in your
                                      Brighter Rewards wallet - and we&apos;ve
                                      automatically put that amount on a digital
                                      Prepaid Visa card for you to spend. To
                                      redeem it, just follow the steps below.
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#008C80' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="630"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '630px' }}
                          >
                            <tr>
                              <td className="em_font20" align="center">
                                Here is how to redeem your reward.
                              </td>
                            </tr>

                            <tr>
                              <td align="center">
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="600"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{ width: '600px' }}
                                >
                                  <tr>
                                    <td>
                                      <table
                                        className="em_wrapper"
                                        align="left"
                                        width="295"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '295px' }}
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{ padding: '40px 0 0 0' }}
                                          >
                                            <table
                                              className="em_wrapper"
                                              align="left"
                                              width="100%"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{ width: '100%' }}
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  width="140"
                                                  valign="top"
                                                >
                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="100%"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '100%' }}
                                                  >
                                                    <tr>
                                                      <td align="center">
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/icon11.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="49"
                                                          height="49"
                                                          border="0"
                                                          alt="Icon"
                                                        />
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        className="em_font12"
                                                        align="center"
                                                      >
                                                        Click the button
                                                        <br />
                                                        below to get started.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                                <td
                                                  align="center"
                                                  width="15"
                                                  valign="top"
                                                ></td>
                                                <td
                                                  align="center"
                                                  width="140"
                                                  valign="top"
                                                >
                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="1005"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '100%' }}
                                                  >
                                                    <tr>
                                                      <td align="center">
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/icon22.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="34"
                                                          height="49"
                                                          border="0"
                                                          alt="Icon"
                                                        />
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        align="center"
                                                        className="em_font12"
                                                      >
                                                        Enter your mobile
                                                        <br />
                                                        number to receive the
                                                        <br />
                                                        SMS with a link to
                                                        <br />
                                                        download The Card
                                                        <br />
                                                        Network (TCN) App.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>

                                      <table
                                        className="em_wrapper"
                                        align="left"
                                        width="2"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '2px' }}
                                      >
                                        <tr>
                                          <td></td>
                                        </tr>
                                      </table>

                                      <table
                                        className="em_wrapper"
                                        align="right"
                                        width="295"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{ width: '295px' }}
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{ padding: '40px 0 0 0' }}
                                          >
                                            <table
                                              className="em_wrapper"
                                              align="left"
                                              width="100%"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{ width: '100%' }}
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  width="140"
                                                  valign="top"
                                                >
                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="100%"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '100%' }}
                                                  >
                                                    <tr>
                                                      <td align="center">
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/icon33.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="55"
                                                          height="49"
                                                          border="0"
                                                          alt="Icon"
                                                        />
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        align="center"
                                                        className="em_font12"
                                                      >
                                                        After downloading,
                                                        <br />
                                                        you&apos;ll see your
                                                        digital
                                                        <br />
                                                        Prepaid Visa card
                                                        <br />
                                                        in TCN App.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                                <td
                                                  align="center"
                                                  width="15"
                                                  valign="top"
                                                ></td>
                                                <td
                                                  align="center"
                                                  width="140"
                                                  valign="top"
                                                >
                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="100%"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{ width: '100%' }}
                                                  >
                                                    <tr>
                                                      <td align="center">
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/Suncorp/EDM/icon44.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="59"
                                                          height="49"
                                                          border="0"
                                                          alt="Icon"
                                                        />
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        align="center"
                                                        className="em_font12"
                                                      >
                                                        Add your digital
                                                        <br />
                                                        Prepaid Visa card to
                                                        <br />
                                                        your phone&apos;s wallet
                                                        and
                                                        <br />
                                                        spend on anything you
                                                        <br />
                                                        like, wherever Visa is
                                                        <br />
                                                        accepted.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td className="em_font1" align="left">
                                <b>Already got the app?</b> The link will give
                                you access to your personalised card.
                              </td>
                            </tr>
                            <tr>
                              <td
                                align="center"
                                style={{ padding: '30px 0 40px 0' }}
                              >
                                <table
                                  align="center"
                                  width="220"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '220px',
                                    backgroundColor: '#FFCD05'
                                  }}
                                >
                                  <tr>
                                    <td className="em_font2" align="center">
                                      <a
                                        href={redemtionLink}
                                        style={{
                                          display: 'block',
                                          textDecoration: 'none',
                                          color: '#004346'
                                        }}
                                      >
                                        {' '}
                                        Redeem now
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#EEF2F1' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="630"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '630px' }}
                          >
                            <tr>
                              <td className="em_font3" align="left">
                                <span
                                  style={{
                                    color: '#004346',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  Need help?
                                </span>{' '}
                                Just call the support team at The Card Network
                                on 1300 375 346 from 9am-6pm on weekdays or
                                10am-5pm on Saturdays AEDT.
                                <br />
                                <br />
                                The link within your SMS will expire three
                                months after you receive it.{' '}
                                <span
                                  style={{
                                    color: '#004346',
                                    fontWeight: 'bold'
                                  }}
                                >
                                  You must download your digital Prepaid Visa
                                  card within this time, or you will lose the
                                  rewards balance within the card.
                                </span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#004346' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="630"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '630px' }}
                          >
                            <tr>
                              <td className="em_font16" align="left">
                                From the Brighter Rewards team,
                                <br />
                                we hope your reward will make a difference to
                                your day.
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="700"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '700px', backgroundColor: '#E9E9E9' }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="606"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ width: '606px' }}
                          >
                            <tr>
                              <td className="em_font4" align="left">
                                This email has been sent by TCN Group Pty Ltd
                                ABN 87 626 501 568 (‘TCN’), issuer of the
                                digital Prepaid Visa Card (“Card”). TCN is not a
                                Suncorp Group company. Full Brighter Rewards
                                terms and conditions are available{' '}
                                <a
                                  href="https://www.suncorp.com.au/loyalty-program-terms"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                . You are responsible for all transactions on
                                the Card, except where there has been fraud or
                                negligence by TCN staff or agents. TCN may
                                restrict or stop the use of the Card if
                                suspicious activities are noticed. You are
                                responsible for checking your transaction
                                history, knowing the available balance and date
                                of expiry for the Card. Balance and transaction
                                history are available to you within the TCN App.
                                Any changes to the Card Terms and Conditions can
                                be viewed{' '}
                                <a
                                  href="https://thinkwink.com.au/tos"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                . Information will be disclosed to third parties
                                about the Card, or transactions made with the
                                Card, whenever allowed by law, and also where
                                necessary to operate the Card and process
                                transactions. TCN’s privacy policy can be viewed{' '}
                                <a
                                  href="https://thecardnetwork.com.au/pages/privacy-policy"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                .
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <Loader loading={loading} />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
        successMessage=""
      />
    </>
  );
};

export default SuncorpBankingEdm;
