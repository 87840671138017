import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getAllDepartments,
  getAllDepartmentsSuccess
} from '../slices/department';
import { getAllDepartmentsAPI } from '../../services/api';

function* getAllDepartmentsSaga(action) {
  const data = yield call(getAllDepartmentsAPI);
  yield put(getAllDepartmentsSuccess(data));
}


export function* departmentSaga() {
  yield takeLatest(getAllDepartments, getAllDepartmentsSaga);
}