import React, { useEffect, useState } from 'react';

import TableBody from "./table-body";
import Notify from "../../../../components/notify";
import Loader from "../../../../components/loader";
import {
  getAllLoginAttemptsLogsAPI,
  getAllUsersAPI
} from '../../../../services/api';
import NavBar from '../../../../components/Navbar';
import FilterHeader from './filter-header';

const initialFilterHeader = {
  customerId: '',
  isUatEnv: 'All',
  userId: '',
  mfaEnabled: 'All',
  loginType: 'All',
  loginResult: 'All',
  startDate: null,
  endDate: null
};

const LogList = () => {
  const [logs, setLogs]                   = useState([]);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState(initialFilterHeader);
  const [logsFilter, setLogsFilter]       = useState([]);
  const [loading, setLoading]             = useState(true);
  const [openNotify, setOpenNotify]       = useState(false);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const [errors, setErrors]               = useState({});

  useEffect(() => {
    getAllUsers();
    getAllLogs(rowsPerPage, page);
  }, []);

  const getAllUsers = async () => {
    try {
      const response = await getAllUsersAPI({});
      const sortedUsers = response?.users?.sort(function (a, b) {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      })
      setUsers(sortedUsers || []);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All Users Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const getAllLogs = async(currentRowPerPage, currentPage, filterBy) => {
    try {
      const filterPayload = mapFilterValues(filterBy);
      const response = await getAllLoginAttemptsLogsAPI({
        limit: currentRowPerPage,
        page: currentPage,
        filter: filterPayload
      });
      setLogs(response?.logs || []);
      setLogsFilter(response?.logs || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch(err) {
      setErrors(err);
      setOpenNotify(true);
    }
  }

  const mapFilterValues = (payload) => {
    const { customerId, isUatEnv, userId, startDate, endDate, mfaEnabled, loginType, loginResult } =
      payload || filter;

    return {
      customerId,
      isUatEnv: isUatEnv === 'All' ? '' : isUatEnv,
      userId,
      mfaEnabled: mfaEnabled === 'All' ? '' : mfaEnabled,
      loginType: loginType === 'All' ? '' : loginType,
      loginResult: loginResult === 'All' ? '' : loginResult,
      startDate,
      endDate
    };
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllLogs(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllLogs(newRowsPerPage, newPage);
  };

  const handleFilter = () => {
    getAllLogs(rowsPerPage, page);
  };

  const handleClearFilter = async () => {
    setFilter(initialFilterHeader);
    getAllLogs(10, 0, initialFilterHeader);
  };

  const handleSetFilter = (payload) => {
    setFilter({
      ...filter,
      ...payload
    });
  };

  return (
    <>
      <NavBar />
      <FilterHeader
        filter={filter}
        users={users}
        handleFilter={handleFilter}
        handleSetFilter={handleSetFilter}
        handleClearFilter={handleClearFilter}
      />
      <TableBody
        logs={logsFilter}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default LogList;