import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ManageWhiteListIpsForm from '../../../components/manage-whitelist-ip-form';
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import Notify from "../../../components/notify";
import { createManageIpsAPI } from '../../../services/api';
import './CreateManageIp.scss';

const CreateManageWhiteListIp = () => {
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors]                     = useState({});
  const [loading, setLoading]                   = useState(false);
  const history = useHistory();

  const handleSubmit = async (payload) => {
    try {
      setLoading(true);
      const response = await createManageIpsAPI(payload);
      setErrors({});
      setOpenNotification(true);
      setTimeout(() => {
        setLoading(false);
        redirectToManageIpList();
      }, 1000);
    } catch(err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'White List Ip Creation Failed !!!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  }

  const redirectToManageIpList = () => {
    history.push('/manage-whitelist-ips/list');
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="create-user container">
          <div className="flex flex-col text-center heading">
            <h2>Create WhiteList Ips</h2>
          </div>
          <ManageWhiteListIpsForm
            manageIp={{}}
            handleSubmit={handleSubmit}
            redirectToManageIpList={redirectToManageIpList}
            loading={loading}
          />
          <Notify
            open={openNotification}
            onClose={() => setOpenNotification(false)}
            errors={errors}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default CreateManageWhiteListIp;