// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTypography-root {
  color: black !important; }

.dialog-action-btn {
  color: black !important; }
`, "",{"version":3,"sources":["webpack://./src/components/dialog-box/Dialog.scss"],"names":[],"mappings":"AAAA;EACE,uBAAuB,EAAA;;AAGzB;EACE,uBAAuB,EAAA","sourcesContent":[".MuiTypography-root {\n  color: black !important;\n}\n\n.dialog-action-btn {\n  color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
