export const validateSendEmailForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data.email) {
    errors.hasError = true;
    errors.email = true;
  }

  if (!data.cardActionNote) {
    errors.hasError = true;
    errors.cardActionNote = true;
  }

  return errors;
};

export const validateDeactivateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (!data.cardActionNote) {
    errors.hasError = true;
    errors.cardActionNote = true;
  }

  return errors;
};
