import differenceInHours from 'date-fns/differenceInHours';
import jwtDecode from 'jwt-decode';

import { getAuthToken, getUserInfo } from './localStorage';

export const isTokenValid = () => {
  const authToken       = getAuthToken();
  const userInfo        = getUserInfo();
  const userId          = userInfo?.id || null;
  const tokenExpiryTime = process.env.REACT_APP_TOKEN_EXPIRY_IN_HRS;
  let tokenInfo = null;

  try {
    tokenInfo = jwtDecode(authToken);
  } catch (err) {
    return false;
  }

  return (
    (tokenInfo &&
      tokenInfo._id === userId &&
      differenceInHours(new Date(), new Date(tokenInfo.iat * 1000)) <=
        tokenExpiryTime) ||
    false
  );
};
