import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';

import UserType from '../../../../constants/userType';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      id            : data[i].id,
      name          : `${data[i].first_name || ''} ${data[i].last_name || ''}`,
      email         : data[i].email,
      mobile        : data[i].phone,
      lastLogin     : data[i].last_login,
      departmentName: data[i]?.department?.name || '-',
      type          : typeof data[i]?.type === 'number' ? data[i]?.type : '-',
      createdAt     : data[i].created_at
    };

    rows.push(row);
  }
  return rows;
};

const UserTable = ({ users }) => {
  const classes = useStyles();
  const history = useHistory();
  const rows = users.length > 0 ? createRows(users) : [];

  const redirectToEditUser = (userId) => {
    history.push(`/user/edit/${userId}`);
  }

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Name </TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Mobile</TableCell>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Department</TableCell>
              <TableCell align="left">Last Login</TableCell>
              <TableCell align="left">Manage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.email}</TableCell>
                  <TableCell align="left">{row.mobile}</TableCell>
                  <TableCell align="left">
                    {UserType.toString(row.type) || '-'}
                  </TableCell>
                  <TableCell align="left">{row.departmentName}</TableCell>
                  <TableCell align="left">
                    {row.lastLogin
                      ? format(parseISO(row.lastLogin), 'yyyy-MM-dd HH:mm:ss')
                      : '-'}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      onClick={() => redirectToEditUser(row.id)}
                      className="w-100"
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

UserTable.propTypes = {
  users: PropTypes.array
};

export default UserTable;
