import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';

import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '../../../../assets/images/search-yellow.svg';

const TableHeader = ({ handleSearch }) => {

  return (
    <>
      <div className="table-filter-body">
        <div className="form-container">
          <div className="flex field-button-container">
            <FormControl variant="standard" className="form-control w-50">
              <TextField
                id="standard-basic"
                label="Search for information"
                name="search"
                onChange={(e) => handleSearch(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <img src={SearchIcon}></img>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
};

TableHeader.propTypes = {
  handleSearch: PropTypes.func
};

export default TableHeader;
