import React from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

const createRows = (data) => {
  const rows = [];
  for (let i = 0; i < data.length; i++) {
    const row = {
      type: data[i].type,
      id: data[i].id,
      url: data[i].url,
      actorEmail: data[i]?.actorInfo?.email || '',
      affectedId: data[i].affectedUser,
      priorValue: data[i].priorValue,
      updatedValue: data[i].updatedValue,
      createdAt: data[i].createdAt
    };

    rows.push(row);
  }
  return rows;
};

const UserLogsTable = ({ usersLogs, handleViewLogs }) => {
  const rows = usersLogs.length > 0 ? createRows(usersLogs) : [];

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Id </TableCell>
              <TableCell align="left">Type </TableCell>
              <TableCell align="left">Url </TableCell>
              <TableCell align="left">Actor Email</TableCell>
              <TableCell align="left">Affected Id</TableCell>
              <TableCell align="left">Created At</TableCell>
              <TableCell align="left">View</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ? (
              rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                  <TableCell align="left">{row.url}</TableCell>
                  <TableCell align="left">{row.actorEmail}</TableCell>
                  <TableCell align="left">{row.affectedId}</TableCell>
                  <TableCell align="left">
                    {row.createdAt
                      ? format(parseISO(row.createdAt), 'yyyy-MM-dd HH:mm:ss')
                      : '-'}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="outlined"
                      onClick={() => handleViewLogs(row.id)}
                      className="w-100"
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 1 }}>
                <TableCell colSpan={6}> No data available in table </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

UserLogsTable.propTypes = {
  usersLogs: PropTypes.array,
  handleViewLogs: PropTypes.func
};

export default UserLogsTable;
