// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iplist-table-filter-body .form-container {
  display: flex;
  flex-direction: row; }

.iplist-table-filter-body button {
  margin: 15px 7px;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .iplist-table-filter-body button {
      width: calc(33.33% - 14px);
      margin-top: 0px; } }
`, "",{"version":3,"sources":["webpack://./src/pages/global-read-only-tables/ip-white-lists/IpLists.scss"],"names":[],"mappings":"AAAA;EAEI,aAAa;EACb,mBAAmB,EAAA;;AAHvB;EAOI,gBAAgB;EAChB,WAAW,EAAA;EACX;IATJ;MAUM,0BAA0B;MAC1B,eAAe,EAAA,EAElB","sourcesContent":[".iplist-table-filter-body {\n  .form-container {\n    display: flex;\n    flex-direction: row;\n  }\n\n  button {\n    margin: 15px 7px;\n    width: 100%;\n    @media screen and (min-width: 768px) {\n      width: calc(33.33% - 14px);\n      margin-top: 0px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
