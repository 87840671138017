const validateForm = (data) => {
  let errors = { hasError: false };
  if (!data.file) {
    errors.hasError = true;
    errors.file = true;
  }

  if (!data.fileName) {
    errors.hasError = true;
    errors.fileName = true;
  }

  return errors;
};

export default validateForm;