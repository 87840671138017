import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { FormControl } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import 'react-datepicker/dist/react-datepicker.css';

const FilterHeader = ({
  filter,
  isLoading,
  handleFilter,
  handleClearFilter,
  handleSetFilter
}) => {
  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="iplist-table-filter-body">
            <div className="form-container">
              <FormControl variant="standard">
                <TextField
                  className="campaign-input"
                  id="standard-basic"
                  label="Customer Id"
                  name="customerId"
                  value={filter.tcnCustomerId}
                  type="number"
                  onChange={(e) =>
                    handleSetFilter({ tcnCustomerId: e.target.value })
                  }
                />
              </FormControl>
              <Button variant="outlined" className="" onClick={handleFilter}>
                Filter
              </Button>
              <Button
                variant="outlined"
                className=""
                onClick={handleClearFilter}
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  filter: PropTypes.object,
  users: PropTypes.array,
  agents: PropTypes.object,
  departments: PropTypes.object,
  isLoading: PropTypes.bool,
  handleSetFilter: PropTypes.func,
  handleFilter: PropTypes.func,
  handleExportTable: PropTypes.func,
  handleClearFilter: PropTypes.func
};

export default FilterHeader;
