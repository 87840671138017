import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Notify from '../../../../components/notify';
import Loader from '../../../../components/loader';
import { getCardByIdentifierAPI } from '../../../../services/api';
import './EdmAami.scss';

const AAMIEdm = () => {
  const [recfn, setRecfn]                 = useState('');
  const [redemtionLink, setRedemtionLink] = useState('');
  const [initialValue, setIntialValue]    = useState(0);
  const [loading, setLoading]             = useState(true);
  const [openNotification, setOpenNotification] = useState(false);
  const [errors, setErrors] = useState({});

  const { identifier } = useParams();

  useEffect(() => {
    getCardInfo();
  }, []);

  const getCardInfo = async () => {
    try {
      const card = await getCardByIdentifierAPI(identifier);
      const fullName = card?.data?.name?.split(' ');
      const initialvalue = card?.data?.card_initial_value || 0;
      const link = card?.data?.redemptionlink || "";

      const firstName = fullName?.length > 0 ? fullName[0] : '';
      setRecfn(firstName);
      setIntialValue(initialvalue);
      setRedemtionLink(link);

      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Failed to get card details!!' };
      setErrors(errors);
      setOpenNotification(true);
      setLoading(false);
    }
  };

  return (
    <>
      <div className="edm-aami-wrapper">
        <table
          width="100%"
          border="0"
          cellSpacing="0"
          cellPadding="0"
          className="em_full_wrap"
        >
          <tr>
            <td
              align="left"
              style={{
                fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                fontSize: '0px',
                lineHeight: '0px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                color: '#F7F7F7',
                display: 'none'
              }}
            >
              <a
                href=""
                style={{
                  textDecoration: 'none',
                  display: 'none',
                  color: '#F7F7F7'
                }}
              >
                Download your digital Prepaid Visa card
              </a>
            </td>
          </tr>
          <tr>
            <td align="center" valign="top">
              <table
                className="em_main_table"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{
                  width: '680px',
                  tableLayout: 'fixed'
                }}
              >
                <tr>
                  <td align="center">
                    <table
                      className="em_wrapper"
                      align="center"
                      width="680"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{
                        width: '680px',
                        backgroundColor: '#F7F7F7'
                      }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="620"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '620px',
                              backgroundColor: '#F7F7F7'
                            }}
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '20px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#000000',
                                  padding: '20px 0 10px 0'
                                }}
                              >
                                Download your Digital Prepaid Visa card
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">
                          <table
                            className="em_wrapper"
                            align="center"
                            width="680"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '680px',
                              backgroundColor: '#f7f7f7'
                            }}
                          >
                            <tr>
                              <td
                                align="center"
                                style={{ padding: '0 15px 0 15px' }}
                              >
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="620"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '620px',
                                    backgroundColor: '#be0101'
                                  }}
                                >
                                  <tr>
                                    <td align="center">
                                      <img
                                        className="em_img"
                                        src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/logo-sm.png"
                                        style={{ display: 'block' }}
                                        width="620"
                                        height="72"
                                        border="0"
                                        alt="Image"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td align="center">
                                      <img
                                        className="em_img"
                                        src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/AamiLogoLarge.png"
                                        style={{ display: 'block' }}
                                        width="620"
                                        height="230"
                                        border="0"
                                        alt="Image"
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>

                            <tr>
                              <td
                                align="center"
                                style={{ padding: '0 15px 20px 15px' }}
                              >
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="620"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '620px',
                                    backgroundColor: '#ffffff'
                                  }}
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ padding: '0 15px 0 15px' }}
                                    >
                                      <table
                                        className="em_wrapper"
                                        align="center"
                                        width="550"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{
                                          width: '550px'
                                        }}
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{ padding: '0 0 30px 0' }}
                                          >
                                            <table
                                              className="em_wrapper"
                                              align="center"
                                              width="550"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{
                                                width: '550px'
                                              }}
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  style={{
                                                    fontFamily:
                                                      'Neuzeit S LT Std, Arial, Tahoma',
                                                    fontSize: '20px',
                                                    lineHeight: '36px',
                                                    fontWeight: 650,
                                                    fontStyle: 'normal',
                                                    color: '#535353',
                                                    padding: '30px 0 0 0'
                                                  }}
                                                >
                                                  Hi {recfn},
                                                  <br />
                                                </td>
                                              </tr>

                                              <tr>
                                                <td
                                                  className="em_font22"
                                                  align="center"
                                                  style={{
                                                    fontFamily:
                                                      'Neuzeit S LT Std, Arial, Tahoma',
                                                    fontSize: '30px',
                                                    lineHeight: '36px',
                                                    fontWeight: 650,
                                                    fontStyle: 'normal',
                                                    color: '#535353',
                                                    padding: '0 0 10px 0',
                                                    borderBottom:
                                                      '2px solid #cc0000'
                                                  }}
                                                >
                                                  Here’s your ${initialValue}{' '}
                                                  reward!
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">
                          <table
                            className="em_wrapper"
                            align="center"
                            width="680"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '680px',
                              backgroundColor: '#f7f7f7'
                            }}
                          >
                            <tr>
                              <td
                                align="center"
                                style={{ padding: '0 15px 20px 15px' }}
                              >
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="620"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '620px',
                                    backgroundColor: '#ffffff'
                                  }}
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ padding: '30px 0 0 0' }}
                                    >
                                      <img
                                        className="em_img"
                                        src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/reward.png"
                                        style={{
                                          display: 'block',
                                          maxWidth: '320px'
                                        }}
                                        width="320"
                                        height="200"
                                        border="0"
                                        alt="Image"
                                      />
                                    </td>
                                  </tr>

                                  <tr>
                                    <td
                                      align="center"
                                      style={{ padding: '0 15px 0 15px' }}
                                    >
                                      <table
                                        className="em_wrapper"
                                        align="center"
                                        width="555"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{
                                          width: '555px'
                                        }}
                                      >
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '15px',
                                              lineHeight: '22px',
                                              fontWeight: 650,
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '20px 0 0 0'
                                            }}
                                          >
                                            Renewing your AAMI insurance means
                                            your ${initialValue} worth of AAMI
                                            rewards credit has been turned into
                                            a digital Prepaid Visa card. How
                                            easy was that? The only hard part is
                                            choosing how you’ll spend it.
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            align="center"
                                            style={{ padding: '28px 0 30px 0' }}
                                          >
                                            <table
                                              align="center"
                                              width="212"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{
                                                width: '212px',
                                                backgroundColor: '#92b605',
                                                borderRadius: '4px'
                                              }}
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  style={{
                                                    fontFamily:
                                                      'Neuzeit S LT Std, Arial, Tahoma',
                                                    fontSize: '13px',
                                                    lineHeight: '20px',
                                                    fontWeight: 'bold',
                                                    fontStyle: 'normal',
                                                    color: '#ffffff',
                                                    padding: '15px 0 13px 0',
                                                    textTransform: 'uppercase',
                                                    backgroundColor: '#92b605',
                                                    borderRadius: '4px'
                                                  }}
                                                >
                                                  <a
                                                    href={redemtionLink}
                                                    style={{
                                                      color: '#ffffff',
                                                      display: 'block',
                                                      textDecoration: 'none'
                                                    }}
                                                  >
                                                    Redeem your reward
                                                  </a>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td align="center">
                          <table
                            className="em_wrapper"
                            align="center"
                            width="680"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '680px',
                              backgroundColor: '#f7f7f7'
                            }}
                          >
                            <tr>
                              <td
                                align="center"
                                style={{ padding: '0 15px 20px 15px' }}
                              >
                                <table
                                  className="em_wrapper"
                                  align="center"
                                  width="620"
                                  border="0"
                                  cellSpacing="0"
                                  cellPadding="0"
                                  style={{
                                    width: '620px',
                                    backgroundColor: '#ffffff'
                                  }}
                                >
                                  <tr>
                                    <td
                                      align="center"
                                      style={{ padding: '0 15px 0 15px' }}
                                    >
                                      <table
                                        className="em_wrapper"
                                        align="center"
                                        width="542"
                                        border="0"
                                        cellSpacing="0"
                                        cellPadding="0"
                                        style={{
                                          width: '542px'
                                        }}
                                      >
                                        <tr>
                                          <td
                                            className="em_font22"
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '28px',
                                              lineHeight: '34px',
                                              fontWeight: 650,
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '25px 0 0 0'
                                            }}
                                          >
                                            Follow the steps below to download
                                            <br />
                                            your digital Prepaid Visa card.
                                          </td>
                                        </tr>

                                        <tr>
                                          <td align="center">
                                            <table
                                              className="em_wrapper"
                                              align="center"
                                              width="530"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{
                                                width: '530px'
                                              }}
                                            >
                                              <tr>
                                                <td>
                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="370"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{
                                                      width: '370px'
                                                    }}
                                                  >
                                                    <tr>
                                                      <td>
                                                        <table
                                                          className="em_wrapper"
                                                          align="left"
                                                          border="0"
                                                          width="150"
                                                          cellPadding="0"
                                                          cellSpacing="0"
                                                          style={{
                                                            width: '150px'
                                                          }}
                                                        >
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              style={{
                                                                padding:
                                                                  '18px 0 0 0'
                                                              }}
                                                            >
                                                              <img
                                                                src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/1.png"
                                                                style={{
                                                                  display:
                                                                    'block'
                                                                }}
                                                                width="60"
                                                                height="60"
                                                                border="0"
                                                                alt="Image"
                                                              />
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              align="center"
                                                              style={{
                                                                fontFamily:
                                                                  'Neuzeit S LT Std, Arial, Tahoma',
                                                                fontSize:
                                                                  '14px',
                                                                lineHeight:
                                                                  '18px',
                                                                fontWeight:
                                                                  'normal',
                                                                fontStyle:
                                                                  'normal',
                                                                color:
                                                                  '#535353',
                                                                padding:
                                                                  '8px 10px 10px 10px'
                                                              }}
                                                            >
                                                              Click on the
                                                              button below to
                                                              enter your mobile
                                                              number to receive
                                                              an SMS with a link
                                                              to download The
                                                              Card Network (TCN)
                                                              App.
                                                            </td>
                                                          </tr>
                                                        </table>

                                                        <table
                                                          className="em_wrapper"
                                                          align="left"
                                                          width="2"
                                                          border="0"
                                                          cellSpacing="0"
                                                          cellPadding="0"
                                                          style={{
                                                            width: '2px'
                                                          }}
                                                        >
                                                          <tr>
                                                            <td></td>
                                                          </tr>
                                                        </table>

                                                        <table
                                                          className="em_wrapper"
                                                          align="right"
                                                          width="210"
                                                          border="0"
                                                          cellSpacing="0"
                                                          cellPadding="0"
                                                          style={{
                                                            width: '210px'
                                                          }}
                                                        >
                                                          <tr>
                                                            <td
                                                              className="em_hide"
                                                              align="left"
                                                              valign="top"
                                                            >
                                                              <table
                                                                className="em_hide"
                                                                align="center"
                                                                border="0"
                                                                width="29"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                style={{
                                                                  width: '29px'
                                                                }}
                                                              >
                                                                <tr>
                                                                  <td
                                                                    className="em_padding20"
                                                                    align="center"
                                                                    style={{
                                                                      padding:
                                                                        '34px 0 0 0'
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/arrow.png"
                                                                      style={{
                                                                        display:
                                                                          'block'
                                                                      }}
                                                                      width="29"
                                                                      height="28"
                                                                      border="0"
                                                                      alt="Image"
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>

                                                            <td
                                                              align="left"
                                                              valign="top"
                                                              style={{
                                                                padding:
                                                                  '0 0 0 0'
                                                              }}
                                                            >
                                                              <table
                                                                className="em_wrapper"
                                                                align="center"
                                                                border="0"
                                                                width="152"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                style={{
                                                                  width: '152px'
                                                                }}
                                                              >
                                                                <tr>
                                                                  <td
                                                                    align="center"
                                                                    style={{
                                                                      padding:
                                                                        '18px 0 0 0'
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/2.png"
                                                                      style={{
                                                                        display:
                                                                          'block'
                                                                      }}
                                                                      width="60"
                                                                      height="60"
                                                                      border="0"
                                                                      alt="Image"
                                                                    />
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    align="center"
                                                                    style={{
                                                                      fontFamily:
                                                                        'Neuzeit S LT Std, Arial, Tahoma',
                                                                      fontSize:
                                                                        '14px',
                                                                      lineHeight:
                                                                        '18px',
                                                                      fontWeight:
                                                                        'normal',
                                                                      fontStyle:
                                                                        'normal',
                                                                      color:
                                                                        '#535353',
                                                                      padding:
                                                                        '8px 10px 10px 10px'
                                                                    }}
                                                                  >
                                                                    You&apos;ll
                                                                    then see
                                                                    your digital
                                                                    Prepaid Visa
                                                                    card in the
                                                                    TCN App.
                                                                    <br />
                                                                    <br />
                                                                    Already have
                                                                    the App? The
                                                                    link will
                                                                    give you
                                                                    access to
                                                                    your
                                                                    <br />
                                                                    personalised
                                                                    card.
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>

                                                            <td
                                                              className="em_hide"
                                                              align="left"
                                                              valign="top"
                                                            >
                                                              <table
                                                                className="em_hide"
                                                                align="left"
                                                                border="0"
                                                                width="29"
                                                                cellPadding="0"
                                                                cellSpacing="0"
                                                                style={{
                                                                  width: '29px'
                                                                }}
                                                              >
                                                                <tr>
                                                                  <td
                                                                    className="em_padding20"
                                                                    align="center"
                                                                    style={{
                                                                      padding:
                                                                        '34px 0 0 0'
                                                                    }}
                                                                  >
                                                                    <img
                                                                      src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/arrow.png"
                                                                      style={{
                                                                        display:
                                                                          'block'
                                                                      }}
                                                                      width="29"
                                                                      height="28"
                                                                      border="0"
                                                                      alt="Image"
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        </table>
                                                      </td>
                                                    </tr>
                                                  </table>

                                                  <table
                                                    className="em_wrapper"
                                                    align="left"
                                                    width="2"
                                                    border="0"
                                                    cellSpacing="0"
                                                    cellPadding="0"
                                                    style={{
                                                      width: '2px'
                                                    }}
                                                  >
                                                    <tr>
                                                      <td></td>
                                                    </tr>
                                                  </table>

                                                  <table
                                                    className="em_wrapper"
                                                    align="right"
                                                    border="0"
                                                    width="150"
                                                    cellPadding="0"
                                                    cellSpacing="0"
                                                    style={{
                                                      width: '150px'
                                                    }}
                                                  >
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        style={{
                                                          padding: '18px 0 0 0'
                                                        }}
                                                      >
                                                        <img
                                                          src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/3.png"
                                                          style={{
                                                            display: 'block'
                                                          }}
                                                          width="60"
                                                          height="60"
                                                          border="0"
                                                          alt="Image"
                                                        />
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        align="center"
                                                        style={{
                                                          fontFamily:
                                                            'Neuzeit S LT Std, Arial, Tahoma',
                                                          fontSize: '14px',
                                                          lineHeight: '18px',
                                                          fontWeight: 'normal',
                                                          fontStyle: 'normal',
                                                          color: '#535353',
                                                          padding:
                                                            '8px 0 10px 0'
                                                        }}
                                                      >
                                                        Add your digital Prepaid
                                                        Visa card to your
                                                        phone&apos;s wallet.
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              padding: '20px 0 0 0'
                                            }}
                                          >
                                            <table
                                              align="center"
                                              width="212"
                                              border="0"
                                              cellSpacing="0"
                                              cellPadding="0"
                                              style={{
                                                width: '212px',
                                                backgroundColor: '#92b605',
                                                borderRadius: '4px'
                                              }}
                                            >
                                              <tr>
                                                <td
                                                  align="center"
                                                  style={{
                                                    fontFamily:
                                                      'Neuzeit S LT Std, Arial, Tahoma',
                                                    fontSize: '13px',
                                                    lineHeight: '20px',
                                                    fontWeight: 'bold',
                                                    fontStyle: 'normal',
                                                    color: '#ffffff',
                                                    padding: '15px 0 13px 0',
                                                    textTransform: 'uppercase',
                                                    backgroundColor: '#92b605',
                                                    borderRadius: '4px'
                                                  }}
                                                >
                                                  <a
                                                    href={redemtionLink}
                                                    style={{
                                                      color: '#ffffff',
                                                      display: 'block',
                                                      textDecoration: 'none'
                                                    }}
                                                  >
                                                    Redeem your reward
                                                  </a>
                                                </td>
                                              </tr>
                                            </table>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style={{
                                              fontFamily:
                                                'Neuzeit S LT Std, Arial, Tahoma',
                                              fontSize: '16px',
                                              lineHeight: '26px',
                                              fontWeight: 'normal',
                                              fontStyle: 'normal',
                                              color: '#535353',
                                              padding: '25px 0 25px 0'
                                            }}
                                          >
                                            The link within your SMS will expire
                                            three months after you receive it.
                                            You must download your digital
                                            Prepaid Visa card within this time,
                                            or you will lose the rewards balance
                                            within the card.
                                          </td>
                                        </tr>
                                      </table>
                                    </td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{
                  width: '680px',
                  backgroundColor: '#f7f7f7'
                }}
              >
                <tr>
                  <td
                    align="center"
                    style={{ padding: '0 15px 0 15px' }}
                    // style="padding: 0 15px 0 15px"
                  >
                    <table
                      className="em_wrapper"
                      align="center"
                      width="620"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{
                        width: '620px',
                        backgroundColor: '#ffffff'
                      }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="560"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '560px'
                            }}
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Neuzeit S LT Std, Arial, Tahoma',
                                  fontSize: '14px',
                                  lineHeight: '28px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#2a2a2a',
                                  padding: '30px 0 0 0'
                                }}
                              >
                                If you require assistance redeeming your reward,
                                please contact the support team at The Card
                                Network on 1300 375 346.
                                <br />
                                <br />
                                Available Monday to Friday, 9am – 6pm and
                                Saturday, 10am – 5pm AEDT.
                                <br />
                                <br />
                                Take Care,
                                <br />
                                The AAMI Rewards Team
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{
                  width: '680px',
                  backgroundColor: '#f7f7f7'
                }}
              >
                <tr>
                  <td align="center" style={{ padding: '0 15px 0 15px' }}>
                    <table
                      className="em_wrapper"
                      align="center"
                      width="620"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{ width: '620px', backgroundColor: '#ffffff' }}
                    >
                      <tr>
                        <td align="center">
                          <img
                            className="em_img"
                            src="https://tcncmpcardart.s3.ap-southeast-2.amazonaws.com/Tcncp/Suncorp/AAMI/Email/AamiFooter.png"
                            style={{
                              display: 'block'
                            }}
                            width="620"
                            height="108"
                            border="0"
                            alt="Image"
                          />
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td align="center">
              <table
                className="em_wrapper"
                align="center"
                width="680"
                border="0"
                cellSpacing="0"
                cellPadding="0"
                style={{ width: '680px', backgroundColor: '#f7f7f7' }}
              >
                <tr>
                  <td align="center" style={{ padding: '0 15px 0 15px' }}>
                    <table
                      className="em_wrapper"
                      align="center"
                      width="560"
                      border="0"
                      cellSpacing="0"
                      cellPadding="0"
                      style={{
                        width: '560px'
                      }}
                    >
                      <tr>
                        <td align="center" style={{ padding: '0 15px 0 15px' }}>
                          <table
                            className="em_wrapper"
                            align="center"
                            width="560"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{
                              width: '560px'
                            }}
                          >
                            <tr>
                              <td
                                align="left"
                                style={{
                                  fontFamily: 'Futura Std, Arial, Tahoma',
                                  fontSize: '10px',
                                  lineHeight: '15px',
                                  fontWeight: 'normal',
                                  fontStyle: 'normal',
                                  color: '#535353',
                                  padding: '15px 0 130px 0'
                                }}
                              >
                                This email has been sent by TCN Group Pty Ltd
                                ABN 87 626 501 568 (‘TCN’), issuer of the
                                digital Prepaid Visa Card (“Card”). TCN is not a
                                related company of AAI Limited trading as AAMI.
                                Full AAMI Rewards terms and conditions are
                                available{' '}
                                <a
                                  href="https://www.aami.com.au/aami-rewards-terms.html"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                . You are responsible for all transactions on
                                the Card, except where there has been fraud or
                                negligence by TCN staff or agents. TCN may
                                restrict or stop the use of the Card if
                                suspicious activities are noticed. You are
                                responsible for checking your transaction
                                history, knowing the available balance and date
                                of expiry for the Card. Balance and transaction
                                history are available to you within the TCN App.
                                Any changes to the Card Terms and Conditions can
                                be viewed{' '}
                                <a
                                  href="https://thinkwink.com.au/tos"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }} rel="noreferrer"
                                >
                                  here
                                </a>
                                . Information will be disclosed to third parties
                                about the Card, or transactions made with the
                                Card, whenever allowed by law, and also where
                                necessary to operate the Card and process
                                transactions. TCN’s privacy policy can be viewed{' '}
                                <a
                                  href="https://thecardnetwork.com.au/pages/privacy-policy"
                                  target="_blank"
                                  style={{
                                    color: '#535353',
                                    textDecoration: 'underline',
                                    fontSize: '10px'
                                  }}
                                  rel="noreferrer"
                                >
                                  here
                                </a>
                                .
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </div>
      <Loader loading={loading} />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotification(false)}
        errors={errors}
        successMessage=""
      />
    </>
  );
};

export default AAMIEdm;
