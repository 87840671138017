import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WelcomeSection = ({ promotionid }) => {
  return (
    <>
      <div
        className={classNames('view2-welcome-wrapper', {
          'apia-background-image': promotionid === '11249'
        })}
      >
        <div
          className={classNames('view2-welcome-text', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248' ? 'Redeem your' : 'A nice reward.'}
        </div>
        <div
          className={classNames('view2-welcome-text-highlight', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248' ? 'reward in no time.' : 'In no time.'}
        </div>

        <div
          className={classNames('view2-welcome-subtext', {
            'apia-font': promotionid === '11249',
            'suncorp-font': promotionid === '11248'
          })}
        >
          {promotionid === '11248'
            ? 'To get your hands on your Brighter Rewards credit we need to send you an SMS. Just confirm your mobile number below to access your reward.'
            : 'To enjoy your Apia Rewards credit we need to send you an SMS. Just confirm your mobile number below and you’re one step away from your reward.'}
        </div>
      </div>
    </>
  );
};

WelcomeSection.propTypes = {
  promotionid: PropTypes.string
};

export default WelcomeSection;
