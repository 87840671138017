import React from 'react';

import AgentIssuanceList from './agent-issuance-list';
import './AgentIssuanceLogs.scss';

const AgentIssuanceLogs = () => {
  return (
    <>
      <div className="flex justify-center items-center agent-issuance-log-table-container">
        <div className="table-wrapper w-full">
          <div className="agent-issuance-title">
            <h2 className="text-center mt-3 color-white">Agent Issuance Logs</h2>
          </div>
          <AgentIssuanceList />
        </div>
      </div>
    </>
  );
};

export default AgentIssuanceLogs;
