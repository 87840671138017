let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };
  if (!data.email) {
    errors.hasError = true;
    errors.email = true;
  }

  if (!data.password) {
    errors.hasError = true;
    errors.password = true;
  }

  if (data.password && !strongPassword.test(data.password)) {
    errors.hasError = true;
    errors.password = true;
    errors.msg.password =
      '*Password is weak (Should be atleast 8 character long, one lowercase letter, one uppercase letter and one digit)';
  }

  if (!data.confirmPassword) {
    errors.hasError = true;
    errors.confirmPassword = true;
  }

    if (data.password && data.confirmPassword && data.password !== data.confirmPassword) {
      errors.hasError = true;
      errors.confirmPassword = true;
      errors.msg.confirmPassword =
        '*Password and confirm password don\'t match';
    }

  return errors;
};

export default validateForm;
