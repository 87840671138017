import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { isTokenValid } from '../../helpers/token';
import { clearStorage } from '../../helpers/localStorage';

const PrivateRoute = ({ component: Component, ...rest }) => {
  if (isTokenValid()) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  clearStorage();
  return <Redirect to="/" />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func
};

export default PrivateRoute;
