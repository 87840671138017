export const getUserName = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return `${userInfo?.first_name || ''} ${userInfo?.last_name || ''}`;
}

export const getUserEmail = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.email || null;
}

export const getUserId = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.id || null;
};

export const getUserInfo = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo || null;
};

export const getUserType = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return typeof userInfo?.type === 'number' ? userInfo.type : null;
};

export const getTcnCustomerId = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.tcnCustomerId || null;
};

export const getCustomerId = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.customerId || null;
};

export const getApplyAgentIssuanceOnly = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.applyAgentIssuanceOnly === "TRUE";
};

export const getDepartmentName = () => {
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  return userInfo?.department?.name || null;
};

export const getAuthToken = () => {
  return localStorage.getItem('auth_token');
};

export const clearStorage = () => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('user_info');
  localStorage.removeItem('test_mode');
}
