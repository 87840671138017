const validateForm = (data) => {
  let errors = { hasError: false, msg: {} };

  if (data.fileName <= 0) {
    errors.hasError = true;
    errors.fileName = true;
  }

  // if (!data?.file) {
  //   errors.hasError = true;
  //   errors.file = true;
    // }

  if (data.customerInfo?.customer_issuance_type_flag !== 'FALSE' && !data.customerIssuanceTypeSource) {
    errors.hasError = true;
    errors.customerIssuanceTypeSource = true;
  }

  if (data.customerIssuanceTypeSource === 'fixed' && !data.customerIssuanceType) {
    errors.hasError = true;
    errors.customerIssuanceType = true;
  }

  return errors;
};

export default validateForm;
