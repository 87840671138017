import React, { useEffect, useState } from 'react';

import TableHeader from './table-header';
import CampaignTable from '../../components/campaign-table';
import Navbar from '../../components/Navbar';
import Footer from "../../components/Footer";
import { getCustomerInfoAPI, getPromotionDataAPI, exportCampaignData, getSettingAPI } from '../../services/api';
import { format } from "date-fns";

import "./Campaign.scss";
import Notify from '../../components/notify';

const Campaign = () => {
  const [customerInfo, setCustomerInfo] = useState({});
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);
  const [applyTestMode, setApplyTestMode] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsFilter, setCampaignsFilter] = useState([]);
  const [openNotify, setOpenNotify] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCustomerInfo();
    getSetting();
  }, []);

  useEffect(() => {
    getPromotions();
  }, [isCustomerLoaded]);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setIsCustomerLoaded(true);
    } catch (err) {
      setCustomerInfo([]);
    }
  };

  const getSetting = async () => {
    try {
      const response = await getSettingAPI();
      setApplyTestMode(response?.applyTestMode || 0);
    } catch (err) {
      setErrors(err);
      setOpenNotify(true);
    }
  };

  const getPromotions = async () => {
    if (!isCustomerLoaded)
      return;

    try {
      const response = await getPromotionDataAPI();

      const filteredPromotions = response?.promotion?.filter((promotion) => customerInfo?.promotion?.find((promo) => promotion.promotion_id === promo.promotion_id)?.promotion_hide_flag === "FALSE");

      setCampaigns(filteredPromotions || []);
      setCampaignsFilter(filteredPromotions || []);
    } catch (err) {
      setCampaigns([]);
      setErrors(err || { errormessage: 'Something went wrong!!!' });
      setOpenNotify(true);
    }
  };

  const handleSearch = (val) => {
    const searchText = val?.trim()?.toLowerCase() || '';
    const filteredRow = [];
    campaigns.forEach(campaign => {
      const promotionId = campaign?.promotion_id?.toLowerCase() || '';
      const promotionName = campaign?.promotion_name?.toLowerCase() || '';

      if (
        promotionId.includes(searchText) ||
        promotionName.includes(searchText)
      ) {
        filteredRow.push(campaign);
      }
    });
    setCampaignsFilter(filteredRow);
  };

  const exportData = async () => {
    const response = await exportCampaignData();
    let byteArray = new Uint8Array(response.data);
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(
      new Blob([byteArray], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
    );
    a.download = `TcncpCampaignReport_${customerInfo?.customer[0]?.customer_id}_${format(new Date(), `yyyy-MM-dd`)}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center campain-table-container container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3">Campaigns</h2>
            <TableHeader
              handleSearch={handleSearch}
              exportData={exportData}
              applyTestMode={applyTestMode}
            />
            <CampaignTable campaigns={campaignsFilter} />
          </div>
        </div>
      </div>
      <Footer />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
};

export default Campaign;