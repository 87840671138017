import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { getMenuListItems } from './MenuItems';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useMsal } from '@azure/msal-react';

import logo from '../../assets/images/tcn-logo.svg';
import userIcon from '../../assets/images/user-icon.svg';
import userIconGreen from '../../assets/images/user-icon-green.png';
import Dots from '../../assets/images/dots.svg';
import Close from '../../assets/images/close.svg';
import Search from '../../assets/images/search.svg';
import { getTcnCustomerId, getDepartmentName } from '../../helpers/localStorage';
import { generateLogoUrl } from '../../helpers/common';

import { getUserPermissionType } from '../../helpers/role';
import { getUserInfo, clearStorage } from '../../helpers/localStorage';

const NavBar = () => {
  const [clicked, setClicked]               = useState(false);
  const [scroll, setScroll]                 = useState(false);
  const [open, setOpen]                     = useState(false);
  const [logoUrl, setLogoUrl]               = useState(false);
  const anchorRef                           = useRef(null);
  const [dropdownActive, setDropdownActive] = useState(false);
  const { instance, accounts } = useMsal();
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60);
    });

    const customerId = getTcnCustomerId();
    setLogoUrl(generateLogoUrl(customerId));
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setDropdownActive( !dropdownActive );
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = () => {
    clearStorage();
    if(accounts?.length > 0) {
      instance.logout();
    } else {
      history.push("/login");
    }
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const renderMenuList = () => {
    const menuListItems = getMenuListItems();

    return menuListItems.map((item, index) => {
      if (item.authorized) {
        return (
          <li key={index}>
            <NavLink to={item.link} className={item.class}>
              {item.title}
              <ArrowForwardOutlinedIcon className="navigation-more" />
            </NavLink>
          </li>
        );
      }
      return null;
    });
  }

  const imageNotLoading = () => {
    setLogoUrl(logo);
  }

  const userLogo = localStorage.getItem('test_mode') === 'true' ? userIconGreen : userIcon;
  const userInfo = getUserInfo();
  const userPermission = getUserPermissionType(userInfo?.type, userInfo?.tcnCustomerId, userInfo?.customerId);

  return (
    <header className="site-header">
      <div
        className={
          scroll ? 'navigation-container scroll' : 'navigation-container'
        }
      >
        <div className="navbar">
          <h1 className="navbar-logo">
            <img src={logoUrl} alt="tcn-logo" onError={imageNotLoading} />
          </h1>
          <div className="site-header-navigation">
            <ul className={clicked ? 'primary-menu active' : 'primary-menu'}>
              {renderMenuList()}
              <li className="main-navigation-search">
                <img src={Search} alt="search-icon"></img>
              </li>
            </ul>
          </div>
          <div className="site-header-profile">
            <div className="search-icon-holder">
              <img
                src={Search}
                alt="search-icon"
                className="navigation-search"
              ></img>
            </div>
            <div className="profile-icon-holder">
              <div className="flex align-end">
                <img
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  src={userLogo}
                  alt="user-icon"
                ></img>
                {dropdownActive ? (
                  <ArrowDropDownIcon
                    onClick={handleToggle}
                    className="dropdown-icon"
                  />
                ) : (
                  <ArrowDropUpIcon
                    onClick={handleToggle}
                    className="dropdown-icon"
                  />
                )}
              </div>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className="menu-dropdown-holder"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                          className="sub-menu-list"
                        >
                          <MenuItem>
                            Name: {userInfo?.first_name} {userInfo?.last_name}<br />
                            Email: {userInfo?.email}<br />
                            Customer Id: {userInfo?.tcnCustomerId}<br />
                            {userPermission !== "User" && <>User Type: {getUserPermissionType(userInfo?.type, userInfo?.tcnCustomerId, userInfo?.customerId)}</> }
                          </MenuItem>
                          {
                            !getTcnCustomerId() &&
                            <MenuItem>
                              Department: {getDepartmentName()}
                            </MenuItem>
                          }
                          <MenuItem className="logout-item">
                            <span
                              className="logout-link"
                              onClick={handleLogout}
                            >
                              Logout
                            </span>
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <div
              className="dots-icon-holder"
              onClick={() => setClicked(!clicked)}
            >
              {clicked ? (
                <img
                  src={Close}
                  alt="close-icon"
                  className="navigation-dots"
                ></img>
              ) : (
                <img
                  src={Dots}
                  alt="dots-icon"
                  className="navigation-dots"
                ></img>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default NavBar;