import React from 'react';
import PropTypes from 'prop-types';
import { Select, makeStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import "./SelectInput.scss";

const useStyles = makeStyles({
  select: {
    '&:before': {
      borderColor: 'yellow'
    },
    '&:after': {
      borderColor: 'yellow'
    }
  },
  icon: {
    fill: 'yellow'
  }
});

const SelectInput = ({ children, labelId, id, name, value, onChange, error}) => {
  const classes = useStyles();

  return (
    <Select
      className={classes.select}
      labelId={labelId}
      id={id}
      value={value}
      name={name}
      onChange={(e) => onChange(e.target.value)}
      error={error}
      inputProps={{
        classes: {
          icon: classes.icon
        }
      }}
      IconComponent={() => <KeyboardArrowDown style={{ cursor: "pointer" }} /> }
    >
      {children}
    </Select>
  );
};

SelectInput.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  labelId : PropTypes.string,
  id      : PropTypes.string,
  name    : PropTypes.string,
  value   : PropTypes.string,
  error   : PropTypes.bool
};


export default SelectInput;
