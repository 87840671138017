import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';

import SelectInput from '../Select';
import validateForm from './validateForm.helper';
import "./IssuanceTypeForm.scss";

const IssuanceTypeForm = ({
  departments,
  issuanceType,
  loading = true,
  handleSubmit,
  redirectToIssuanceList
}) => {
  const isEditIssuanceType = Object.keys(issuanceType).length > 0;

  const [name, setName] = useState(issuanceType?.name || '');
  const [departmentId, setDepartmentId] = useState(issuanceType?.departmentId || '');
  const [status, setStatus] = useState(typeof issuanceType?.status === 'number' ? issuanceType?.status : 1 );
  const [validationErrors, setValidationErrors] = useState({});

  const submit = () => {
    const payload = { name, departmentId, status };
    const validate = validateForm(isEditIssuanceType, payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    handleSubmit(payload);
  };

  return (
    <div className="issuance-type-form-container">
      <TextField
        id="standard-basic"
        label="Name"
        type="text"
        name="name"
        helperText="*Is required."
        error={validationErrors.name}
        value={name}
        inputProps={{ readOnly: isEditIssuanceType }}
        onChange={(e) => setName(e.target.value)}
      />

      <FormControl
        variant="standard"
        className="form-control mb-3"
        error={validationErrors.departmentId}
      >
        <InputLabel id="demo-simple-select-standard-label">
          Department
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="department"
          value={departmentId}
          onChange={(e) => setDepartmentId(e.target.value)}
          inputProps={{ readOnly: isEditIssuanceType }}
        >
          {departments?.map((depart) => (
            <MenuItem key={depart.id} value={depart.id}>
              {depart.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      <FormControl variant="standard" error={validationErrors.status}>
        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
        <SelectInput
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          name="enableMFA"
          value={status}
          onChange={(val) => setStatus(val)}
          className="mt-4"
        >
          <MenuItem value={1}>Active</MenuItem>
          <MenuItem value={0}>Inactive</MenuItem>
        </SelectInput>
        <FormHelperText>*Is required.</FormHelperText>
      </FormControl>

      <div className="action-footer">
        <Button
          disabled={loading}
          variant="outlined"
          endIcon={
            loading ? (
              <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
            ) : (
              <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
            )
          }
          onClick={submit}
        >
          Continue
        </Button>
        <Button variant="outlined" onClick={redirectToIssuanceList}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

IssuanceTypeForm.propTypes = {
  departments: PropTypes.object,
  issuanceType: PropTypes.object,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  redirectToIssuanceList: PropTypes.func
};

export default IssuanceTypeForm;