import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import { getCustomerInfoAPI } from '../../../services/api';
import { setIssueCardData } from '../../../store/slices/issueCard';
import validateForm from "./validateForm.helper";
import Navbar from '../../../components/Navbar';
import Footer from '../../../components/Footer';
import { formatMoney } from '../../../helpers/currency';
import { getHelperText } from '../../../helpers/issueCard';
import './initialStep.scss';
import { RequestHandleType } from '../../../constants/requestHandle';
import Notify from '../../../components/notify';
import { getTcnCustomerId } from '../../../helpers/localStorage';
import { hasAdminUserPermissionForCTA } from '../../../helpers/permission';

const IssueCardInitialStep = () => {
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [units, setUnits] = useState('');
  const [customerInfo, setCustomerInfo] = useState({});
  const [cardType, setCardType] = useState([]);
  const [denomination, setDenomination] = useState('');
  const [availableBalance, setAvailableBalance] = useState('');
  const [customerOrderReference, setCustomerOrderReference] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [campaign, setCampaign] = useState('');
  const [product, setProduct] = useState(null);
  const [orderReference, setOrderReference] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [variants, setVariants] = useState([]);
  const [variant, setVariant] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const agentIssuance = searchParams.get('agentIssuance');
  const isAgentIssuanceDigital = agentIssuance === 'digital';

  useEffect(() => {
    getCustomerInfo();
    if (isAgentIssuanceDigital) {
      setInitialValueForDigitalAgentIssuance();
    }
  }, []);

  useEffect(() => {
    if (deliveryMethod) {
      filterCardType();
      //getCardTypeValue(deliveryMethod);
      getFilteredVariants();
    }
  }, [deliveryMethod]);

  useEffect(() => {
    if (product) {
      getFilteredVariants();
    }
  }, [product]);

  const setInitialValueForDigitalAgentIssuance = () => {
    setOrderReference(2);
  };

  const filterCardType = () => {
    if (
      customerInfo?.product?.length > 0 &&
      customerInfo?.variant?.length > 0
    ) {
      if (
        deliveryMethod === RequestHandleType.Email ||
        deliveryMethod === RequestHandleType.CardNumberAndPin ||
        deliveryMethod === RequestHandleType.SMS ||
        deliveryMethod === RequestHandleType.SMSCard
      ) {
        const productControlInteger = ['1', '2'];
        const filteredProducts = customerInfo.product.filter((product) => {
          if (productControlInteger.includes(product.product_control_integer)) {
            return product;
          }
        });
        setCardType(filteredProducts);
      } else {
        const productControlInteger = ['1', '3'];
        const filteredProducts = customerInfo.product.filter((product) => {
          if (productControlInteger.includes(product.product_control_integer)) {
            return product;
          }
        });
        setCardType(filteredProducts);
      }
    }
  };

  const getCardTypeValue = (deliveryMethod) => {
    if (
      deliveryMethod === RequestHandleType.Email ||
      deliveryMethod === RequestHandleType.CardNumberAndPin ||
      deliveryMethod === RequestHandleType.SMS ||
      deliveryMethod === RequestHandleType.SMSCard
    ) {
      const productControlInteger = ['1', '2'];
      if (customerInfo?.product?.length > 0) {
        const product = customerInfo.product.find((product) =>
          productControlInteger.includes(product.product_control_integer)
        );
        setProduct(product || null);
      }
    } else {
      const productControlInteger = ['1', '3'];
      if (
        customerInfo?.product?.length > 0 &&
        customerInfo?.variant?.length > 0
      ) {
        const product = customerInfo.product.find((product) =>
          productControlInteger.includes(product.product_control_integer)
        );

        const variant = customerInfo.variant.find(
          (variant) => variant.variant_product_id === product.product_id
        );

        if (variant) {
          setProduct(product || null);
        }
      }
    }
  };

  const getFilteredVariants = () => {
    const customerId = getTcnCustomerId();
    const filteredVariants = customerInfo?.variant
      .filter(
        (va) =>
          va.variant_status === 'active' &&
          va.variant_product_id === product?.product_id &&
          (va.variant_classification === 'ALL' ||
            va.variant_classification.includes(customerId)
          ) &&
          (customerInfo?.customer?.[0]?.customer_dynamicvariant_flag !== "TRUE" || product?.product_productgroup_id === "1" || va.variant_product_populateamount === "Dynamic")
      )
      .sort((a, b) => b.variant_id - a.variant_id);

    setVariants(filteredVariants);
  };

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setCardType(customerInfo?.product || []);
      setIsLoading(false);
    } catch (err) {
      console.log('err :: ', err);
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'units':
        setUnits(value);
        break;
      case 'denomination':
        setDenomination(value);
        break;
      case 'availableBalance':
        setAvailableBalance(value);
        break;
      case 'customerOrderReference':
        setCustomerOrderReference(value);
        break;
      case 'deliveryMethod':
        setDeliveryMethod(value);
        break;
      case 'campaign':
        setCampaign(value);
        break;
      case 'product':
        setProduct(value);
        break;
      case 'orderReference':
        setOrderReference(value);
        break;
      default:
        break;
    }
  };

  const notifyClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccessNotify(false);
  };

  const handleSubmit = () => {
    const data = {
      units,
      denomination,
      availableBalance,
      customerOrderReference,
      deliveryMethod,
      campaign,
      product: product?.product_id,
      orderReference,
      balance: customerInfo.floatdata.balance,
      variantGuid: variant?.variant_guid,
      variantPopulateAmount: variant?.variant_product_populateamount
    };

    const validate = validateForm(data, deliveryMethod);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    dispatch(
      setIssueCardData({
        units,
        denomination,
        availableBalance,
        customerOrderReference,
        deliveryMethod,
        campaign,
        product: product?.product_id,
        productName: product?.product_name,
        orderReference,
        balance: customerInfo.floatdata.balance,
        variantGuid: variant?.variant_guid,
        variantPopulateAmount: variant?.variant_product_populateamount,
        variantDesignIdentifier: variant?.variant_design_identifier
      })
    );
    history.push(`/issue-card-final${isAgentIssuanceDigital ? '?agentIssuance=digital' : ''}`);
  };

  const renderDeliveryMethods = () => {
    if (isAgentIssuanceDigital) {
      return (
        <FormControl variant="standard" error={validationErrors.deliveryMethod}>
          <InputLabel id="demo-simple-select-standard-label">
            Delivery method
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            name="deliveryMethod"
            onChange={handleOnChange}
            value={deliveryMethod}
          >
            <MenuItem value={3}>Email</MenuItem>
          </Select>
          <FormHelperText>{getHelperText(deliveryMethod)}</FormHelperText>
        </FormControl>
      );
    } else {
      return (
        <FormControl variant="standard" error={validationErrors.deliveryMethod}>
          <InputLabel id="demo-simple-select-standard-label">
            Delivery method
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            name="deliveryMethod"
            onChange={handleOnChange}
            value={deliveryMethod}
          >
            <MenuItem value={3}>Email</MenuItem>
            <MenuItem value={4}>Physical</MenuItem>
            <MenuItem value={2}>SMS</MenuItem>
            <MenuItem value={1}>Unique URL</MenuItem>
          </Select>
          <FormHelperText>{getHelperText(deliveryMethod)}</FormHelperText>
        </FormControl>
      );
    }
  };

  const renderCampaigns = () => {
    let promotions = customerInfo?.promotion?.filter(
      (promo) => promo.promotion_hide_flag !== "TRUE"
    );
    if (isAgentIssuanceDigital) {
      promotions = customerInfo?.promotion?.filter(
        (promo) => promo.promotion_agent_requireissuance === "TRUE"
      );
      return promotions?.map((promotion) => (
        <MenuItem key={promotion.promotion_id} value={promotion.promotion_id}>
          {promotion.promotion_id}&nbsp;-&nbsp;
          {promotion.promotion_name}
        </MenuItem>
      ));
    } else {
      return (
        promotions?.map((promotion) => (
          <MenuItem
            key={promotion.promotion_id}
            value={promotion.promotion_id}
          >
            {promotion.promotion_id}&nbsp;-&nbsp;
            {promotion.promotion_name}
          </MenuItem>
        ))
      );
    }
  };

  const renderAvailableBalance = () => {
    return (<>
      <TextField
        id="standard-number"
        label="Available Balance"
        type="text"
        name="availableBalance"
        onChange={handleOnChange}
        defaultValue={
          customerInfo.floatdata.creditflag === '1'
            ? formatMoney(
              parseFloat(customerInfo.floatdata.balance) +
              parseFloat(customerInfo.floatdata.creditlimit)
            )
            : formatMoney(customerInfo.floatdata.balance)
        }
        InputProps={{
          readOnly: true
        }}
      />
    </>);
  };

  const renderUnits = () => {
    return (<TextField
      error={validationErrors.units}
      id="standard-number"
      label="Units"
      type="number"
      name="units"
      onChange={handleOnChange}
      helperText={validationErrors?.msg?.units || '*Is required.'}
      InputProps={{ inputProps: { min: 0 } }}
    />);
  };

  const renderDenomination = () => {
    return (<TextField
      error={validationErrors.denomination}
      id="standard-number"
      label="Denomination"
      type="number"
      name="denomination"
      value={denomination}
      onChange={handleOnChange}
      helperText={
        validationErrors?.msg?.denomination ||
        'Value of card to be issued (50, 100, 150 etc). Needs to be entered as a number, $ is not required'
      }
      InputProps={{
        readOnly: deliveryMethod === RequestHandleType.Physical && variant?.variant_product_populateamount === "Expected"
      }}
    />);
  };

  const onSetVariant = (variant) => {
    setVariant(variant);

    if (variant?.variant_product_populateamount === "Expected") {
      setDenomination(variant.variant_expectedamount);
    }
  };

  const renderVariants = () => {
    return (<FormControl
      variant="standard"
      error={validationErrors.variant}
    >
      <InputLabel id="demo-simple-select-standard-label">
        Variant
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        name="variants"
        value={variant}
        onChange={(e) => onSetVariant(e.target.value)}
      >
        {variants.map((variant) => (
          <MenuItem
            key={variant.variant_id}
            value={variant}
          >
            {variant.variant_name}(
            {variant.variant_expectedamount})
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>*Is required</FormHelperText>
    </FormControl>);
  };

  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center bg-black text-white container">
        <div className="issue-card-container container">
          <div className="flex flex-col text-center">
            <h2>Issue a Card</h2>
            <h3>Step 1- Issue cards to the recipient</h3>
          </div>

          {isLoading ? (
            <div className="loading-container"> Loading.... </div>
          ) : (
            <div className="bulk-card-body">
              <div className="form-container">
                <div className="flex field-container justify-between fix-width w-50">
                  {renderDeliveryMethods()}
                  <FormControl
                    variant="standard"
                    error={validationErrors.campaign}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Campaign
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      name="campaign"
                      value={campaign}
                      onChange={handleOnChange}
                    >
                      {renderCampaigns()}
                    </Select>
                    <FormHelperText>
                      Select the Campaign your selected Card Type should be
                      issued under
                    </FormHelperText>
                  </FormControl>
                </div>

                <div className="flex field-container">
                  <FormControl
                    variant="standard"
                    error={validationErrors.product}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Card Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={product}
                      name="product"
                      onChange={handleOnChange}
                    >
                      {cardType.map((promotion) => (
                        <MenuItem
                          key={promotion.product_id}
                          value={promotion}
                        >
                          {promotion.product_id}&nbsp;-&nbsp;
                          {promotion.product_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      This is the type of card you would like issued to the
                      recipient. You must select a Card Type to proceed.
                    </FormHelperText>
                  </FormControl>
                </div>

                {deliveryMethod === RequestHandleType.Physical && product ?
                  <div className="flex field-container justify-between fix-width w-50">
                    {renderUnits()}
                    {renderAvailableBalance()}
                  </div>
                  :
                  <div className="flex field-container">
                    {renderUnits()}
                  </div>
                }

                <div className="flex field-container justify-between fix-width w-50">
                  {deliveryMethod === RequestHandleType.Physical && product ? <>
                    {renderVariants()}
                    {renderDenomination()}
                  </> : <>
                    {renderDenomination()}
                    {renderAvailableBalance()}
                  </>
                  }
                </div>

                <div className="flex field-container">
                  <FormControl
                    variant="standard"
                    error={validationErrors.orderReference}
                  >
                    <InputLabel id="demo-simple-select-standard-label">
                      Order Reference
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={orderReference}
                      name="orderReference"
                      onChange={handleOnChange}
                      inputProps={{ readOnly: isAgentIssuanceDigital }}
                    >
                      <MenuItem value={1}>Customer generated</MenuItem>
                      <MenuItem value={2}>System generated</MenuItem>
                    </Select>
                    <FormHelperText>
                      {orderReference === 1
                        ? 'This is your reference number for the card issuance. Select Customer generated if using your own unique reference. '
                        : 'Select System generated if you would like this field to be auto generated '}
                    </FormHelperText>
                  </FormControl>
                </div>

                {orderReference === 1 && (
                  <div className="flex field-container">
                    <TextField
                      id="standard-number"
                      label="Customer order reference"
                      type="text"
                      name="customerOrderReference"
                      onChange={handleOnChange}
                    />
                  </div>
                )}
              </div>

              <div className="action-footer">
                <Button
                  variant="outlined"
                  onClick={handleSubmit}
                  disabled={!hasAdminUserPermissionForCTA()}
                  endIcon={
                    <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                  }
                >
                  Continue
                </Button>
                <Button variant="outlined">Cancel</Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
      <Notify
        open={openSuccessNotify}
        onClose={() => setOpenSuccessNotify(false)}
        errors={errors}
      />
    </>
  );
};

export default IssueCardInitialStep;
