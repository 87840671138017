import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  issuanceTypes: [],
  loading: false,
  error: [],
};

const issuanceType = createSlice({
  name: 'issuanceType',
  initialState,
  reducers: {
    getAllIssuanceType(state, action) {
      state.loading = true;
    },
    getAllIssuanceTypeSuccess(state, action) {
      state.loading = false;
      state.issuanceTypes = action?.payload || [];
    },
    getAllIssuanceTypeFailure(state, action) {
      state.loading = false;
    }
  }
});

export const {
  getAllIssuanceType,
  getAllIssuanceTypeSuccess,
  getAllIssuanceTypeFailure
} = issuanceType.actions;

export default issuanceType.reducer;
