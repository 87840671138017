import React, { useEffect, useState } from 'react';

import { getAllCustomerActivityLogAPI } from '../../../services/api';
import TableBody from "./table-body";
import Notify from "../../../components/notify";
import Loader from "../../../components/loader";
import NavBar from '../../../components/Navbar';

const ActivityLogList = () => {
  const [customerActivityLogs, setCustomerActivityLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openNotify, setOpenNotify] = useState(false);
  const [page, setPage] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getAllCustomerActivityLog(rowsPerPage, page);
  }, []);

  const getAllCustomerActivityLog = async (currentRowPerPage, currentPage) => {
    try {
      const response = await getAllCustomerActivityLogAPI({
        limit: currentRowPerPage,
        page: currentPage
      });
      setCustomerActivityLogs(response?.logs || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All customer activity logs failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllCustomerActivityLog(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllCustomerActivityLog(newRowsPerPage, newPage);
  };


  return (
    <>
      <NavBar />
      <TableBody
        customerActivityLogs={customerActivityLogs}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify open={openNotify} onClose={() => setOpenNotify(false)} errors={errors} />
    </>
  );
};

export default ActivityLogList;