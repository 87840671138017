import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer';
import validateForm from './validateForm.helper';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import Notify from "../../components/notify";
import { clearStorage } from '../../helpers/localStorage';
import { verifyResetPasswordTokenAPI } from '../../services/api';
import './ResetPassword.scss';
import Loader from '../../components/loader';


export default function SignIn() {
  const [openNotification, setOpenNotify] = useState(false);
  const [email, setEmail]                         = useState('');
  const [password, setPassword]                   = useState('');
  const [confirmPassword, setConfirmPassword]     = useState('');
  const [validationErrors, setValidationErrors]   = useState({});
  const [errors, setErrors]                       = useState({});
  const [loading, setLoading]                     = useState(false);
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const token = params.token;

  useEffect(() => {
    verifyToken(token);
  }, []);

  const verifyToken = async(token) => {
    try {
      const user = await verifyResetPasswordTokenAPI(token);
      setEmail(user?.email);
      setLoading(false);
    } catch(err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Something Went Wrong !!!!' };
      setErrors(errors);
      setLoading(false);
      setOpenNotify(true);
      setTimeout(() => {
        history.push('/login');
      }, 1000);
    }
  }

  const useQuery = () => {
    return new URLSearchParams(location.search);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    clearStorage();
    let query = useQuery();
    const token = query.get('token');
    const TCNCP_API = process.env.REACT_APP_TCNCP_API;
    const payload = { email, password, token };
    const validate = validateForm(Object.assign({}, payload, { confirmPassword }));
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }
    setLoading(true);
    axios
      .put(`${TCNCP_API}/auth/reset-password`, payload)
      .then((res) => {
        setLoading(false);
        setOpenNotify(true);
        setTimeout(() => {
          history.push('/login');
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        const errors =
          err && Object.keys(err).length > 0
            ? err
            : { errormessage: 'Something Went Wrong !!!!' };
        setErrors(errors);
        setOpenNotify(true);
      });
  };

  return (
    <>
      <Container
        component="main"
        className="login-container flex-col container"
      >
        <CssBaseline />
        <div className="flex flex-col items-center">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>
          <form className="login-form" noValidate onSubmit={handleSubmit}>
            <TextField
              error={validationErrors.email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              value={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              inputProps={{ readOnly: true }}
              className="login-field"
              helperText="*Is required."
            />
            <TextField
              error={validationErrors.password}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              className="login-field"
              helperText={validationErrors?.msg?.password || '*Is required.'}
            />
            <TextField
              error={validationErrors.confirmPassword}
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="login-field"
              helperText={
                validationErrors?.msg?.confirmPassword || '*Is required.'
              }
            />
            <Button
              type="submit"
              fullWidth
              className="submit-btn"
              disabled={loading}
              endIcon={
                <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
              }
            >
              Reset
            </Button>
          </form>
        </div>
      </Container>
      <Footer />
      <Notify
        open={openNotification}
        onClose={() => setOpenNotify(false)}
        errors={errors}
        successMessage="You have successfully updated your password. "
      />
      <Loader loading={loading} />
    </>
  );
}
