// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulk-card-container {
  width: 690px;
  margin: 0 1rem; }

@media (max-width: 768px) {
  .bulk-card-container {
    max-width: 690px;
    min-width: unset; } }

.bulk-card-body {
  margin-top: 100px; }
  .bulk-card-body .text-field-container {
    margin-bottom: 20px; }
  .bulk-card-body .upload-file-container {
    margin-bottom: 17px; }
    .bulk-card-body .upload-file-container .file-upload-btn {
      color: white;
      border: 2px solid white;
      border-radius: 30px;
      width: 192px;
      height: 48px;
      text-transform: unset; }
      .bulk-card-body .upload-file-container .file-upload-btn:hover {
        color: var(--color-yellow);
        border: 2px solid var(--color-yellow); }
        .bulk-card-body .upload-file-container .file-upload-btn:hover .MuiSvgIcon-root {
          color: var(--color-yellow) !important; }
    .bulk-card-body .upload-file-container .file-upload-message {
      display: flex;
      align-items: center;
      margin-left: 10px; }
  .bulk-card-body .action-footer {
    margin-top: 46px;
    display: flex; }
    @media (min-width: 720px) {
      .bulk-card-body .action-footer {
        margin-top: 26px; }
        .bulk-card-body .action-footer button:first-child {
          margin-right: 1rem; } }
`, "",{"version":3,"sources":["webpack://./src/pages/bulk-card-issue/BulkCardIssue.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc,EAAA;;AAGhB;EACE;IACE,gBAAgB;IAChB,gBAAgB,EAAA,EACjB;;AAGH;EACE,iBAAiB,EAAA;EADnB;IAII,mBAAmB,EAAA;EAJvB;IAQI,mBAAmB,EAAA;IARvB;MAWM,YAAY;MACZ,uBAAuB;MACvB,mBAAmB;MACnB,YAAY;MACZ,YAAY;MACZ,qBAAqB,EAAA;MAhB3B;QAmBQ,0BAA0B;QAC1B,qCAAqC,EAAA;QApB7C;UAuBU,qCAAqC,EAAA;IAvB/C;MA6BM,aAAa;MACb,mBAAmB;MACnB,iBAAiB,EAAA;EA/BvB;IAoCI,gBAAgB;IAChB,aAAa,EAAA;IAEb;MAvCJ;QAwCM,gBAAgB,EAAA;QAxCtB;UA2CQ,kBAAkB,EAAA,EACnB","sourcesContent":[".bulk-card-container {\n  width: 690px;\n  margin: 0 1rem;\n}\n\n@media (max-width: 768px) {\n  .bulk-card-container {\n    max-width: 690px;\n    min-width: unset;\n  }\n}\n\n.bulk-card-body {\n  margin-top: 100px;\n\n  .text-field-container {\n    margin-bottom: 20px;\n  }\n\n  .upload-file-container {\n    margin-bottom: 17px;\n\n    .file-upload-btn {\n      color: white;\n      border: 2px solid white;\n      border-radius: 30px;\n      width: 192px;\n      height: 48px;\n      text-transform: unset;\n\n      &:hover {\n        color: var(--color-yellow);\n        border: 2px solid var(--color-yellow);\n\n        .MuiSvgIcon-root {\n          color: var(--color-yellow) !important;\n        }\n      }\n    }\n\n    .file-upload-message {\n      display: flex;\n      align-items: center;\n      margin-left: 10px;\n    }\n  }\n\n  .action-footer {\n    margin-top: 46px;\n    display: flex;\n\n    @media (min-width: 720px) {\n      margin-top: 26px;\n\n      button:first-child {\n        margin-right: 1rem;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
