// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-user {
  width: 690px;
  margin: 0 1rem; }
  .create-user .heading {
    margin-bottom: 25px; }
  .create-user .action-footer {
    margin-top: 46px; }
    .create-user .action-footer button {
      width: 100%; }
      .create-user .action-footer button:first-child {
        margin-bottom: 8px; }
    @media (min-width: 720px) {
      .create-user .action-footer {
        margin-top: 26px; }
        .create-user .action-footer button {
          width: calc( 50% - 4px); }
          .create-user .action-footer button:first-child {
            margin-right: 8px;
            margin-bottom: 0; } }
`, "",{"version":3,"sources":["webpack://./src/pages/departments/create-new-department/CreateNewDepartment.scss"],"names":[],"mappings":"AAMA;EACE,YAAY;EACZ,cAAc,EAAA;EAFhB;IAII,mBAAmB,EAAA;EAJvB;IAOI,gBAAgB,EAAA;IAPpB;MASM,WAAW,EAAA;MATjB;QAWQ,kBAAkB,EAAA;IAGtB;MAdJ;QAeM,gBAAgB,EAAA;QAftB;UAiBQ,uBAAwB,EAAA;UAjBhC;YAmBU,iBAAiB;YACjB,gBAAgB,EAAA,EACjB","sourcesContent":["// .sub-heading{\n//   padding-top: 76px;\n//   @media screen and (min-width: 768px) {\n//     padding: 145px 0 24px;\n//   }\n// }\n.create-user{\n  width: 690px;\n  margin: 0 1rem;\n  .heading{\n    margin-bottom: 25px;\n  }\n  .action-footer {\n    margin-top: 46px;\n    button{\n      width: 100%;\n      &:first-child {\n        margin-bottom: 8px;\n      }\n    }\n    @media (min-width: 720px) {\n      margin-top: 26px;\n      button{\n        width: calc( 50% - 4px );\n        &:first-child {\n          margin-right: 8px;\n          margin-bottom: 0;\n        }\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
