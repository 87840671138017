import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import "./Dialog.scss";

const ConfirmationDialog = ({
  openDialog,
  message,
  data,
  handleCloseDialog,
  handleYes,
  showCloseButton = false
}) => {
  const renderFileSummary = () => {
      return data?.bulkFileData?.summary?.map((summary) => {
        return (
          <div className="file-summary" key={summary.productId}>
            <div>
              <b>Product Name: </b> {summary.productName}
            </div>
            <div>
              <b>Promotion Name: </b> {summary.promotionName}
            </div>
            <div>
              <b>No Of Cards Selected: </b> {summary.noOfCardsSelected}
            </div>
            <div>
              <b>Total Load Value: </b> {summary.loadValue}
            </div>
          </div>
        );
      });
    //  return <b>Summary</b>;
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking Yes, you will be updating the value of each card as per
            the below summary:
          </DialogContentText>

          <div className="mb-3">
            <div>
              <b>File Name: </b> {data.fileName}
            </div>
            <div>
              <b>Department: </b> {data.department}
            </div>
            <div>
              <b>Issuance Type: </b> {data.customerIssuanceType}
            </div>
            <div>
              <b>Card Not Found: </b> {data?.bulkFileData?.cardNotFound || 0}
            </div>
            <div>
              <b>No of Cards Failed: </b> {data?.bulkFileData?.cardFailed || 0}
            </div>
            <div className="file-summary-wrapper">{renderFileSummary()}</div>
          </div>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!showCloseButton && (
            <>
              <Button
                className="dialog-action-btn"
                onClick={handleYes}
                color="primary"
              >
                Yes
              </Button>
              <Button
                className="dialog-action-btn"
                onClick={handleCloseDialog}
                color="primary"
                autoFocus
              >
                No
              </Button>
            </>
          )}

          {showCloseButton && (
            <Button
              className="dialog-action-btn"
              onClick={handleCloseDialog}
              color="primary"
              autoFocus
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ConfirmationDialog.propTypes = {
  openDialog       : PropTypes.bool,
  data             : PropTypes.object,
  showCloseButton  : PropTypes.bool,
  message          : PropTypes.string,
  title            : PropTypes.string,
  handleCloseDialog: PropTypes.func,
  handleYes        : PropTypes.func
};


export default ConfirmationDialog;
