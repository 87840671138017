import Axios from "./axios";
import { getUserId } from '../helpers/localStorage';

const TCNCP_API = process.env.REACT_APP_TCNCP_API;

export const validateBulkCard = (formdata) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/bulkIssue`, formdata)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data;
        return reject(error);
      });
  });
};

export const createDepartmentAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/department/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const getAllDepartmentsAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/department/all`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const updateDepartmentAPI = (id, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/department/${id}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getAllIssuanceTypeAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/issuanceType/all`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getAllIssuanceTypeAssoDepartmentAPI = (departmentId) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/issuanceType/department/${departmentId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const createIssuanceTypeAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/issuanceType/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const updateAgentIssuanceAPI = (id, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/issuanceType/${id}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const createCardAPI = (data, isAgentIssuanceDigital) => {
  return new Promise(function (resolve, reject) {
    Axios.post(
      `${TCNCP_API}/card/create${isAgentIssuanceDigital ? '?agentIssuanceDigital=true' : ''}`,
      data
    )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const createBulkCard = (cards,) => {
  return Axios.post(`${TCNCP_API}/card/createInBulk`, cards)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      Promise.reject(err);
    });
};

export const getCardsAPI = (data, agentIssuanceFlag) => {
  return new Promise(function (resolve, reject) {
    Axios.post(
      `${TCNCP_API}/card/getCards${agentIssuanceFlag ? '?agentIssuanceFlag=true' : ''}`,
      data
    )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardTransactionAPI = (cardProxy) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/getCardTransaction/${cardProxy}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardLiveTransactionAPI = (cardProxy) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/getLiveTransactions/${cardProxy}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const updateAgentIssueAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/card/agentissuecard`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const replaceCardAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/card/replaceCard`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const updateBulkAgentIssueAPI = (data, viewFile) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/card/agentissuecard/bulk${viewFile ? '?view_file=true' : ''}`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const createCardNoteAPI = (cardId, data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/${cardId}/notes`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardNoteAPI = (cardId) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/${cardId}/notes`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardByIdentifierAPI = (identifier) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/identifier/${identifier}`, {})
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardByIdentifierInUatAPI = (identifier) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/uat/identifier/${identifier}`, {})
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const exportCardsDataAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/exportdata`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const exportAgentIssuanceDataAPI = (data, isAvailableCard) => {
  return new Promise(function (resolve, reject) {
    Axios.post(
      `${TCNCP_API}/cardIssuance/exportdata${isAvailableCard ? '?key=availablecard' : '?key=issuedcard'
      }`,
      data
    )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getUserInfoAPI = () => {
  const userId = getUserId();

  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/user/${userId}/info`, {})
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getCustomerInfoAPI = () => {
  const userId = getUserId();

  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/user/${userId}`, {})
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getPromotionDataAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/campaign/getPromotionData`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const createPromotionAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/campaign/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const exportCampaignData = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/campaign/exportdata`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createNewUser = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/user/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getAllUsersAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/user/all`, { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getSubUsersAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/user/subUsers`, { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const updateUser = (userId, data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/user/${userId}`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getAllWhiteListIpsAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/ipwhitelist/all`, { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const getAllWhiteListIpLogsAPI = ({ limit, page }) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/logs/ip-white-list/all`, { limit, page })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};


export const createManageIpsAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/ipwhitelist/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data;
        return reject(error);
      });
  });
};

export const updateManageIpsAPI = (id, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/ipwhitelist/${id}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data || {
          errormessage: 'Something went wrong !!!'
        };
        return reject(error);
      });
  });
};

export const getCardAPI = (cardId) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/${cardId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getCardByProxyAPI = (cardProxy) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/card/proxy/${cardProxy}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const cancelCardByProxyAPI = (cardProxy, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/cancel/${cardProxy}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const blockCardInBulkAPI = (payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/blockCardInBulk`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getCardIssuanceLogsAPI = (cardId) => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/cardIssuance//card-id/${cardId}`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const updateCardStatusAPI = (cardId, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/updateCardStatus/${cardId}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const resendEmailAPI = (data) => {
  const payload = { email: data.email };

  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/resendEmail/${data.cardId}`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const sendCardNoteAPI = (cardId, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/card/${cardId}/notes`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const verifyResetPasswordTokenAPI = (token) => {
  return new Promise(function (resolve, reject) {
    Axios.get(
      `${TCNCP_API}/auth/verify-reset-password-token/${token}`
    )
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};


//TestMode- Settings
export const createSettingsAPI = (data) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/setting/create`, data)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const getSettingAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/setting`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        const error = err?.response?.data?.err;
        return reject(error);
      });
  });
};

export const createSwapAPI = (payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/swap/create`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const createUatSwapAPI = (payload) => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/swap/uat/create`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const updateCardAdditionalInfoAPI = (proxy, payload) => {
  return new Promise(function (resolve, reject) {
    Axios.put(`${TCNCP_API}/card/proxy/${proxy}/additionalInfo`, payload)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const generate2FAAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/auth/2fa`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const crownSsoLoginAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.post(`${TCNCP_API}/auth/crown/login`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getAllUserLogsAPI = () => {
  return new Promise(function (resolve, reject) {
    Axios.get(`${TCNCP_API}/user/logs`)
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || { errormessage: 'Something went wrong !!!' }
        );
      });
  });
};

export const getAllLoginAttemptsLogsAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post('/logs/all', { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || {
            errormessage: 'Something went wrong !!!'
          }
        );
      });
  });
};

export const getAllAgentIssuanceLogAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post('/logs/agent-issuance/all', { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || {
            errormessage: 'Something went wrong !!!'
          }
        );
      });
  });
};

export const getAllIssuedCardLogAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post('/logs/issued-card/all', { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || {
            errormessage: 'Something went wrong !!!'
          }
        );
      });
  });
};

export const getAllCustomerActivityLogAPI = ({ limit, page, filter }) => {
  return new Promise(function (resolve, reject) {
    Axios.post('/logs/customer-activity/all', { limit, page, filter })
      .then((response) => {
        return resolve(response.data);
      })
      .catch((err) => {
        return reject(
          err?.response?.data || {
            errormessage: 'Something went wrong !!!'
          }
        );
      });
  });
};