import React from 'react';

import IssuedCardList from './issued-card-list';
import './IssuedCardLogs.scss';

const IssuedCardLogs = () => {
  return (
    <>
      <div className="flex justify-center items-center issued-card-log-table-container">
        <div className="table-wrapper w-full">
          <div className="issued-card-log-title">
            <h2 className="text-center mt-3 color-white">Issued Card Logs</h2>
          </div>
          <IssuedCardList />
        </div>
      </div>
    </>
  );
};

export default IssuedCardLogs;
