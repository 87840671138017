import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormTitleSection = ({ promotionid }) => {
  return (
    <>
      <div
        className={classNames('view2-form-title', {
          'apia-font': promotionid === '11249',
          'suncorp-font': promotionid === '11248'
        })}
      >
        Please know this is a safe and secure process that&apos;s all about you.
        The Card Network (TCN) needs your mobile number to send you a link to
        download their App and start spending your reward. Already have the app?
        You will receive an SMS containing a personalised link to your digital
        Prepaid Visa card.
      </div>
      <div
        className={classNames('view2-form-subtitle', {
          'apia-font': promotionid === '11249',
          'suncorp-font': promotionid === '11248'
        })}
      >
        The link within your SMS will expire three months after you receive it.
        You must download your digital Prepaid Visa card within this time, or
        you will lose the rewards balance within the card.
      </div>
    </>
  );
};

FormTitleSection.propTypes = {
  promotionid: PropTypes.string
};


export default FormTitleSection;
