// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactModal__Overlay--after-open {
  z-index: 101; }

.modal {
  background-color: black;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  text-align: center;
  width: 90vw; }
  .modal .note-table-wrapper {
    margin: 20px 0px; }
  @media screen and (min-width: 480px) {
    .modal {
      width: 450px; } }
  @media screen and (min-width: 768px) {
    .modal {
      width: 600px; } }
  .modal .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -5px; }
    .modal .button-container button {
      margin: 5px; }
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/issue-card-table/view-note-modal/ViewNoteModal.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAA;;AAOd;EACE,uBAAuB;EACvB,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,kBAAkB;EAClB,WAAW,EAAA;EATb;IAYI,gBAAgB,EAAA;EAIlB;IAhBF;MAkBI,YAAY,EAAA,EAiBf;EAdC;IArBF;MAsBI,YAAY,EAAA,EAaf;EAnCD;IA0BI,aAAa;IACb,eAAe;IACf,uBAAuB;IACvB,mBAAmB,EAAA;IA7BvB;MAgCM,WAAW,EAAA","sourcesContent":[".ReactModal__Overlay--after-open {\n  z-index: 101;\n  // background-color: rgba(255, 255, 255, 0.1) !important;\n  // background-color: rgba(85, 76, 76, 0.253) !important;\n  // background-color: red !important;\n  // background-color: unset !important;\n}\n\n.modal {\n  background-color: black;\n  color: red;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  padding: 20px;\n  text-align: center;\n  width: 90vw;\n\n  .note-table-wrapper {\n    margin: 20px 0px;\n  }\n\n  // border-radius: 5%;\n  @media screen and (min-width: 480px) {\n    // border-radius: 10%;\n    width: 450px;\n  }\n\n  @media screen and (min-width: 768px) {\n    width: 600px;\n  }\n\n  .button-container {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    margin-bottom: -5px;\n\n    button {\n      margin: 5px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
