import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DatePicker from 'react-datepicker';

import SelectInput from '../../../../components/Select';
import ConfirmationDialog from './confirmation-dialog';
import validateForm from './validateForm.helper';
import './CardModal.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { formatMoney } from '../../../../helpers/currency';
import { getInitialAdditionalDetails, getInitialDepartment } from './initialValues';
import { isMainTcncpUser } from '../../../../helpers/role';
import { getAllIssuanceTypeAssoDepartmentAPI } from '../../../../services/api';

const CardModel = ({
  modalIsOpen,
  closeModal,
  data,
  departments,
  updateAgentIssueCard,
  customerInfo,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [cardAmount, setCardAmount] = useState(0);
  const [referenceId, setReferenceId] = useState('');
  const [department, setDepartment] = useState(getInitialDepartment());
  const [customerIssuanceType, setCustomerIssuanceType] = useState(null);
  const [issuanceTypes, setIssuanceTypes] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [additionalDetails, setAdditionalDetails] = useState(
    getInitialAdditionalDetails()
  );
  const [expanded, setExpanded] = useState(false);

  const customerPrivacyInteger = customerInfo?.customer?.[0]?.customer_privacy_integer;
  const belongsToCustomerPrivacyInteger = ["3", "4"].includes(customerPrivacyInteger);

  useEffect(() => {
    if(departments?.length && department) {
      const departmentId = departments.find(depart => depart.name === department)?.id || null;
      getIssuanceTypes(departmentId);
    }
  }, [departments, department]);

  const getIssuanceTypes = async (department) => {
    try {
      const issuanceTypes = await getAllIssuanceTypeAssoDepartmentAPI(department);
      setIssuanceTypes(issuanceTypes);
      issuanceTypes?.length && setCustomerIssuanceType(issuanceTypes[0].name);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const handleResetFormData = () => {
    setAdditionalDetails(getInitialAdditionalDetails());
    setDepartment(getInitialDepartment());
    setReferenceId('');
    setCardAmount(0);
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseModel = () => {
    setValidationErrors({});
    closeModal();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleYes = async () => {
    const requestPayload = Object.assign({}, {
      cardAmount,
      referenceId,
      additionalDetails,
      department,
      customerPrivacyInteger
    },
    belongsToCustomerPrivacyInteger ? { customerIssuanceType } : null
    )
    handleCloseDialog();
    handleCloseModel();
    handleResetFormData();
    updateAgentIssueCard(requestPayload);
  };

  const handleClick = () => {
    const validate = validateForm(belongsToCustomerPrivacyInteger, customerInfo, {
      cardAmount,
      additionalDetails,
      referenceId,
      department,
      customerIssuanceType
    });
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    setOpenDialog(true);
  };

  const handleAdditionalChange = (payload) => {
    setAdditionalDetails({ ...additionalDetails, ...payload });
  };

  const renderDepartment = () => {
    return (
      <div className="cardActionsForms">
        {!isMainTcncpUser() ?
            <TextField
              id="standard-basic"
              label="Department"
              type="text"
              name="department"
              value={department}
              className='mb-2'
              inputProps={{ readOnly: true }}
            /> :
            <FormControl
              variant="standard"
              className="form-control mb-2"
              error={validationErrors.department}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                {departments?.map((depart) => (
                  <MenuItem key={depart.id} value={depart.name}>
                    {depart.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>*Is required.</FormHelperText>
            </FormControl>
          }
      </div>
    );
  };

  const renderIssuanceType = () => {
    if(belongsToCustomerPrivacyInteger) {
      return (
        <div className="cardActionsForms">
          <FormControl
            variant="standard"
            className="form-control mb-2"
            error={validationErrors.customerIssuanceType}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Issuance Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="customerIssuanceType"
              value={customerIssuanceType}
              onChange={(e) => setCustomerIssuanceType(e.target.value)}
            >
              {issuanceTypes?.map((issuanceType) => (
                <MenuItem key={issuanceType.id} value={issuanceType.name}>
                  {issuanceType.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>*Is required.</FormHelperText>
          </FormControl>
        </div>
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Card Modal"
        className="modal"
      >
        <div>
          <h2>Card Actions </h2>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Product"
              type="text"
              name="productId"
              value={data.productId}
              inputProps={{ readOnly: true }}
            />
            <TextField
              id="standard-basic"
              label="Campaign Name"
              type="text"
              name="campaignName"
              value={data.campaignName}
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="Serial Range"
              type="text"
              name="serialRange"
              value={`${data.serialStart} - ${data.serialEnd}`}
              inputProps={{ readOnly: true }}
            />
            <TextField
              id="standard-basic"
              label="Reference ID"
              type="text"
              name="referenceId"
              className="mt-3"
              error={validationErrors.referenceId}
              value={referenceId}
              helperText="*Is required."
              onChange={(e) => setReferenceId(e.target.value)}
            />
          </div>
          <div className="cardActionsForms">
            <TextField
              id="standard-basic"
              label="No. of cards selected"
              type="text"
              name="noOfCardSelected"
              value={data.selectedCard}
              inputProps={{ readOnly: true }}
            />
            <TextField
              id="standard-basic"
              label="Value to load per card"
              type="text"
              name="cardAmount"
              className="mt-3"
              value={cardAmount}
              error={validationErrors.cardAmount}
              helperText={
                validationErrors.cardAmount
                  ? 'The card issuance value you have entered is outside of your approved program range.'
                  : `*is required. Min value ${
                      formatMoney(customerInfo?.customer?.[0]?.customer_card_min_value)
                    } and Max value ${
                      formatMoney(customerInfo?.customer?.[0]?.customer_card_max_value)
                    }`
              }
              onChange={(e) => setCardAmount(e.target.value)}
            />
          </div>
          {renderDepartment()}
          {renderIssuanceType()}
          <Accordion
            className="accordionWrapper"
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className="accordionHeading">
                Additional Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="First Name"
                  type="text"
                  name="firstName"
                  value={additionalDetails.firstName}
                  onChange={(e) =>
                    handleAdditionalChange({ firstName: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Last Name"
                  type="text"
                  name="lastName"
                  value={additionalDetails.lastName}
                  onChange={(e) =>
                    handleAdditionalChange({ lastName: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields field-button-container">
                <TextField
                  id="standard-basic"
                  label="Mobile"
                  type="text"
                  name="mobile"
                  value={additionalDetails.mobile}
                  error={validationErrors.mobile}
                  helperText="Mobile Number should be of 10 digits"
                  onChange={(e) =>
                    handleAdditionalChange({ mobile: e.target.value })
                  }
                />

                <div className="date-container">
                  <span>Date of Birth</span>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={additionalDetails.dob}
                    onChange={(date) => handleAdditionalChange({ dob: date })}
                    className="date-picker-field campaign-input"
                  />
                </div>
              </div>

              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Email"
                  type="text"
                  name="email"
                  value={additionalDetails.email}
                  onChange={(e) =>
                    handleAdditionalChange({ email: e.target.value })
                  }
                />
              </div>

              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Address 1/ Company Name"
                  type="text"
                  name="address1"
                  value={additionalDetails.address1}
                  onChange={(e) =>
                    handleAdditionalChange({ address1: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Address 2"
                  type="text"
                  name="address2"
                  value={additionalDetails.address2}
                  onChange={(e) =>
                    handleAdditionalChange({ address2: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Suburb"
                  type="text"
                  name="suburb"
                  value={additionalDetails.suburb}
                  onChange={(e) =>
                    handleAdditionalChange({ suburb: e.target.value })
                  }
                />
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    State
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="state"
                    value={additionalDetails.state}
                    onChange={(val) => handleAdditionalChange({ state: val })}
                  >
                    <MenuItem value="ACT">ACT</MenuItem>
                    <MenuItem value="NSW">NSW</MenuItem>
                    <MenuItem value="NT">NT</MenuItem>
                    <MenuItem value="Qld">QLD</MenuItem>
                    <MenuItem value="SA">SA</MenuItem>
                    <MenuItem value="Vic">VIC</MenuItem>
                    <MenuItem value="WA">WA</MenuItem>
                  </SelectInput>
                </FormControl>
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Post Code"
                  type="text"
                  name="postCode"
                  onChange={(e) =>
                    handleAdditionalChange({ postcode: e.target.value })
                  }
                />
                <TextField
                  id="standard-basic"
                  label="Country"
                  type="text"
                  name="country"
                  onChange={(e) =>
                    handleAdditionalChange({ country: e.target.value })
                  }
                />
              </div>
              <div className="accordionFields">
                <TextField
                  id="standard-basic"
                  label="Notes"
                  type="text"
                  name="notes"
                  value={additionalDetails.notes}
                  onChange={(e) =>
                    handleAdditionalChange({ notes: e.target.value })
                  }
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <div className="button-container">
            <Button variant="outlined" color="primary" onClick={handleClick}>
              Submit
            </Button>

            <Button
              variant="outlined"
              onClick={handleCloseModel}
              color="primary"
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmationDialog
        openDialog={openDialog}
        message={'Are you sure you want to continue?'}
        handleCloseDialog={handleCloseDialog}
        handleYes={handleYes}
        data={{
          campaignName: data.campaignName,
          productName: data.productName,
          noOfCardSelected: data.selectedCard,
          initialCardValue: '',
          cardAmount,
          referenceId,
          customerIssuanceType
        }}
      />
    </>
  );
};

CardModel.propTypes = {
  selectedCard        : PropTypes.object,
  data                : PropTypes.object,
  modalIsOpen         : PropTypes.bool,
  closeModal          : PropTypes.func,
  updateAgentIssueCard: PropTypes.func,
  departments         : PropTypes.array,
  customerInfo        : PropTypes.object,
  issuanceTypes       : PropTypes.array,
};

export default CardModel;
