import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const CardNotes = ({ cardNotes }) => {
  const renderTableBody = () => {
    const filteredCardNote = cardNotes.filter(
      (card) => card.publicnoteflag === 'true'
    );
    if (filteredCardNote?.length > 0) {
      return filteredCardNote.map((row, index) => (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="right">{row.user}</TableCell>
          <TableCell align="right">{row.notes}</TableCell>
          <TableCell align="right">{row.timestamp}</TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow style={{ height: 53 * 1 }}>
        <TableCell colSpan={6}> No data available in table </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper} className="table-container">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Note</TableCell>
              <TableCell align="right">TimeStamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

CardNotes.propTypes = {
  cardNotes: PropTypes.array
};

export default CardNotes;
