import React from 'react';

import ListUserLogs from "./user-logs";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import './User.scss';

const User = () => {
  return (
    <>
      <Navbar />
      <div className="min-h-full flex justify-center items-center campain-table-container container">
        <div className="table-wrapper w-full">
          <div className="campain-title">
            <h2 className="text-center mt-3">Users Logs</h2>
          </div>
          <ListUserLogs />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default User;
