import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer';
import './Home.scss';

export default function Home() {
  return (
    <>
      <Container component="main" className="home-container flex-col container">
        <CssBaseline />
        <div className="flex flex-col items-center">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>

          <div className="info-text">
            If you are a corporate customer, contact your account manager for
            your login details.
          </div>
          <div className="info-subtext">
            If you received a gift card from TCN and need support, visit{' '}
            <a
              href="https://thecardnetwork.com.au/pages/contact-us"
              target="_blank"
              className="text-blue" rel="noreferrer"
            >
              this page
            </a>{' '}
            for FAQs and support.
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}
