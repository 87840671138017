import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField';

import {
  FormControl,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "../../../../assets/images/search-yellow.svg";
import SelectInput from "../../../../components/Select";
import { getCustomerInfoAPI } from '../../../../services/api';

const FilterHeader = ({ handleFilter, handleClearFilter, handleExportTable }) => {
  const [customerInfo, setCustomerInfo]     = useState({});
  const [campaign, setCampaign]             = useState('');
  const [product, setProduct]               = useState('');
  const [searchText, setSearchText]         = useState('');
  const [dateRange, setDateRange]           = useState([null, null]);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [isLoading, setIsLoading]           = useState(true);
  const [startDate, endDate] = dateRange;

  useEffect(() => {
    getCustomerInfo();
  }, []);

  const getCustomerInfo = async () => {
    try {
      const customerInfo = await getCustomerInfoAPI();
      setCustomerInfo(customerInfo);
      setIsLoading(false);
    } catch (err) {
      console.log('err :: ', err);
    }
  };

  const filter = () => {
    handleFilter({
      promotion: campaign,
      product,
      createstart  : startDate ? format(startDate, 'yyyy-MM-dd'): '',
      createend    : endDate ? format(endDate, 'yyyy-MM-dd')    : '',
      requesthandle: deliveryMethod,
      search       : searchText
    });
  };

  const exportData = () => {
    handleExportTable({
      promotion: campaign,
      product,
      createstart: startDate ? format(startDate, 'yyyy-MM-dd') : '',
      createend: endDate ? format(endDate, 'yyyy-MM-dd') : '',
      requesthandle: deliveryMethod,
      search: searchText
    });
  }

  const handleClearFilters = () => {
    setCampaign('');
    setProduct('');
    setSearchText('');
    setDateRange([null, null]);
    setDeliveryMethod('');
    handleClearFilter();
  };

  const handleClickOutside = () => {
    if (!startDate || !endDate) {
      setDateRange([null, null])
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-container"> Loading.... </div>
      ) : (
        <>
          <div className="table-filter-body">
            <div className="form-container">
              <div className="flex justify-between flex-wrap  fix-width field-container">
                <FormControl variant="standard" className="form-control">
                  <InputLabel id="demo-simple-select-standard-label">
                    Campaign
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campaign"
                    value={campaign}
                    onChange={(val) => setCampaign(val)}
                  >
                    {customerInfo.promotion.map((promotion) => (
                      <MenuItem
                        key={promotion.promotion_id}
                        value={promotion.promotion_id}
                      >
                        {promotion.promotion_id}&nbsp;-&nbsp;
                        {promotion.promotion_name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard" className="form-control">
                  <InputLabel id="demo-simple-select-standard-label">
                    Product
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="product"
                    value={product}
                    onChange={(val) => setProduct(val)}
                  >
                    {customerInfo.product.map((promotion) => (
                      <MenuItem
                        key={promotion.product_id}
                        value={promotion.product_id}
                      >
                        {promotion.product_id}&nbsp;-&nbsp;
                        {promotion.product_name}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </FormControl>
              </div>
              <div className="flex justify-between flex-wrap fix-width field-container">
                <FormControl variant="standard" className="form-control">
                  <InputLabel id="demo-simple-select-standard-label">
                    Delivery method
                  </InputLabel>
                  <SelectInput
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="deliveryMethod"
                    value={deliveryMethod}
                    onChange={(val) => setDeliveryMethod(val)}
                  >
                    <MenuItem value={3}>Email</MenuItem>
                    <MenuItem value={4}>Physical</MenuItem>
                    <MenuItem value={2}>SMS</MenuItem>
                    <MenuItem value={5}>SMS - Card</MenuItem>
                    <MenuItem value={1}>Unique URL</MenuItem>
                  </SelectInput>
                </FormControl>
                <FormControl variant="standard" className="form-control">
                  <TextField
                    className="campaign-input search-field"
                    id="standard-basic"
                    label="Search for information"
                    name="search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon}></img>
                        </InputAdornment>
                      )
                    }}
                  />
                </FormControl>
              </div>
              <div className="flex justify-start flex-wrap fix-width field-button-container">
                <div className="date-container">
                  <span>Date Range</span>
                  <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onClickOutside={handleClickOutside}
                    dateFormat="dd/MM/yyyy"
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                    className="date-picker-field campaign-input"
                  />
                </div>
                <div className="buttons">
                  <Button variant="outlined" className="" onClick={filter}>
                    Filter
                  </Button>
                  <Button
                    variant="outlined"
                    className=""
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </Button>
                  <Button variant="outlined" className="" onClick={exportData}>
                    Export table
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

FilterHeader.propTypes = {
  handleFilter     : PropTypes.func,
  handleClearFilter: PropTypes.func,
  handleExportTable: PropTypes.func
};


export default FilterHeader;