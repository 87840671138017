import { getApplyAgentIssuanceOnly, getUserInfo } from '../../helpers/localStorage';
import { UserType } from '../../constants/userType';


export const getMenuListItems = () => {
  const userInfo = getUserInfo();
  const isAdministratorUser = userInfo?.type === UserType.Administrator;
  const allowCustomerIssuance = userInfo?.allowCustomerIssuance === 'TRUE';
  const applyAgentIssuanceOnly = getApplyAgentIssuanceOnly();
  const customerWhiteListFlag = userInfo?.customerWhiteListFlag === 'TRUE';

  return [
    {
      title: 'Dashboard',
      link: '/dashboard',
      class: 'nav-links',
      authorized: !applyAgentIssuanceOnly
    },
    {
      title: 'Issue a Card',
      link: '/issue-card-initial',
      class: 'nav-links',
      authorized: !applyAgentIssuanceOnly
    },
    {
      title: 'Bulk Card Issues',
      link: '/bulkCard',
      class: 'nav-links',
      authorized: !applyAgentIssuanceOnly
    },
    {
      title: 'Users',
      link: '/user/list',
      class: 'nav-links',
      authorized: isAdministratorUser
    },
    {
      title: 'Campaigns',
      link: '/campaign',
      class: 'nav-links',
      authorized: true
    },
    {
      title: 'Agent Issuance',
      link: '/agent-issuance',
      class: 'nav-links',
      authorized: allowCustomerIssuance
    },
    {
      title: 'Manage IP',
      link: '/manage-whitelist-ips/list',
      class: 'nav-links',
      authorized: customerWhiteListFlag
    }
  ];;
}
