import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import { Paper, TableHead } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { getCardNoteAPI,  } from '../../../../services/api';
import "./ViewNoteModal.scss";

const ViewNoteModel = ({
  selectedCard,
  modalIsOpen,
  closeModal,
}) => {

  const [cardNotes, setCardNotes] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleCloseModal = () => {
    setCardNotes([]);
    closeModal();
  }

  useEffect(() => {
    if (modalIsOpen) {
      getCardNote();
    }
  }, [modalIsOpen]);

  const getCardNote = async () => {
    try {
      const response = await getCardNoteAPI(selectedCard.cardId);
      setCardNotes(response?.response?.notes || []);
      setLoading(false);
    } catch (err) {
      console.log('err: ', err);
    }
  };

  const renderTableBody = () => {
    if (cardNotes.length > 0) {
      const filteredCardNote = cardNotes.filter((card) => card.publicnoteflag === 'true');
      return filteredCardNote.map((row, index) => (
        <TableRow
          key={index}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="right">{row.user}</TableCell>
          <TableCell align="right">{row.notes}</TableCell>
          <TableCell align="right">{row.timestamp}</TableCell>
        </TableRow>
      ));
    }
    return (
      <TableRow style={{ height: 53 * 1 }}>
        <TableCell colSpan={6}> No data available in table </TableCell>
      </TableRow>
    );
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleCloseModal}
      contentLabel="Card Modal"
      className="modal"
    >
      <h2>View Note</h2>
      {loading ? (
        <div>
          <h3 className="mt-2">Loading...</h3>
        </div>
      ) : (
        <>
          <div className="note-table-wrapper">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 800 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">User</TableCell>
                    <TableCell align="right">Note</TableCell>
                    <TableCell align="right">TimeStamp</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderTableBody()}</TableBody>
              </Table>
            </TableContainer>
          </div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseModal}
          >
            Close
          </Button>
        </>
      )}
    </Modal>
  );
};


ViewNoteModel.propTypes = {
  selectedCard      : PropTypes.object,
  modalIsOpen       : PropTypes.bool,
  closeModal        : PropTypes.func,
  customStyles      : PropTypes.object,
  handleDeactiveCard: PropTypes.func,
  handleResendEmail : PropTypes.func,
  handleSendNote    : PropTypes.func
};

export default ViewNoteModel;
