import { takeLatest, call, put } from 'redux-saga/effects';
import { getAllIssuanceType, getAllIssuanceTypeSuccess} from '../slices/issuanceType';
import { getAllIssuanceTypeAPI } from '../../services/api';

function* getAllIssuanceTypeSaga(action) {
  const data = yield call(getAllIssuanceTypeAPI);
  yield put(getAllIssuanceTypeSuccess(data));
}


export function* issuanceTypeSaga() {
  yield takeLatest(getAllIssuanceType, getAllIssuanceTypeSaga);
}