import React, { useEffect, useState } from 'react';

import TableBody from "./table-body";
import Notify from "../../../../components/notify";
import Loader from "../../../../components/loader";
import { getAllWhiteListIpsAPI } from '../../../../services/api';
import NavBar from '../../../../components/Navbar';
import FilterHeader from './filter-header';

const initialFilterHeader = {
  tcnCustomerId: ''
};

const IpList = () => {
  const [ipList, setIpLists]              = useState([]);
  const [filter, setFilter]               = useState(initialFilterHeader);
  const [loading, setLoading]             = useState(true);
  const [openNotify, setOpenNotify]       = useState(false);
  const [page, setPage]                   = useState(0);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [rowsPerPage, setRowsPerPage]     = useState(10);
  const [errors, setErrors]               = useState({});

  useEffect(() => {
    getAllWhiteListIps(rowsPerPage, page, filter);
  }, []);

  const getAllWhiteListIps = async (
    currentRowPerPage,
    currentPage,
    filterPayload
  ) => {
    try {
      const response = await getAllWhiteListIpsAPI({
        limit: currentRowPerPage,
        page: currentPage,
        filter: filterPayload
      });
      setIpLists(response?.ipLists || []);
      setTotalRowCount(response?.count || 0);
      setLoading(false);
    } catch (err) {
      const errors =
        err && Object.keys(err).length > 0
          ? err
          : { errormessage: 'Getting All White List Ips Failed !!' };
      setErrors(errors);
      setOpenNotify(true);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllWhiteListIps(rowsPerPage, newPage, filter);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    setPage(newPage);
    setRowsPerPage(newRowsPerPage);
    getAllWhiteListIps(newRowsPerPage, newPage, filter);
  };

  const handleClearFilter = async () => {
    setLoading(true);
    setFilter(initialFilterHeader);
    getAllWhiteListIps(10, 0, initialFilterHeader);
  };

  const handleSetFilter = (payload) => {
    setFilter({
      ...filter,
      ...payload
    });
  };

  const handleFilter = () => {
    setLoading(true);
    getAllWhiteListIps(rowsPerPage, page, filter);
  };

  return (
    <>
      <NavBar />
      <FilterHeader
        filter={filter}
        handleFilter={handleFilter}
        handleSetFilter={handleSetFilter}
        handleClearFilter={handleClearFilter}
      />
      <TableBody
        ipList={ipList}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        totalRowCount={totalRowCount}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <Loader loading={loading} />
      <Notify
        open={openNotify}
        onClose={() => setOpenNotify(false)}
        errors={errors}
      />
    </>
  );
}

export default IpList;