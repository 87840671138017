import React, {useState} from 'react';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { CircularProgress } from '@material-ui/core';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';

import logo from '../../assets/images/tcn-logo.svg';
import Footer from '../../components/Footer'
import { clearStorage } from '../../helpers/localStorage';
import Notify from '../../components/notify';
import validateForm from './validateForm.helper';
import './Login.scss'

export default function SignIn() {
  const [loading, setLoading]                     = useState(false);
  const [openSuccessNotify, setOpenSuccessNotify] = useState(false);
  const [email, setEmail]                         = useState("");
  const [password, setPassword]                   = useState("");
  const [validationErrors, setValidationErrors]   = useState({});
  const [errors, setErrors]                       = useState({});
  const history = useHistory();

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    clearStorage();
    const TCNCP_API = process.env.REACT_APP_TCNCP_API;
    const payload = { email, password };
    const validate = validateForm(payload);
    if (validate.hasError) {
      setValidationErrors(validate);
      return;
    }

    axios
      .post(`${TCNCP_API}/auth/login`, payload)
      .then((res) => {
        localStorage.setItem('user_info', JSON.stringify(res.data.user));
        if (res?.data?.mfaEnabled && !res?.data?.user?.last_login) {
          history.push('/login/mfa-guide');
          return;
        }

        if (res?.data?.mfaEnabled && res?.data?.user?.last_login) {
          history.push('/checkpoint/mfa');
          return;
        }
        setLoading(false);
        localStorage.setItem('auth_token', res.data.token);
        localStorage.setItem('test_mode', res.data.isTestMode);
        res?.data?.user?.applyAgentIssuanceOnly === 'TRUE'
          ? history.push('/agent-issuance')
          : history.push('/dashboard');
      })
      .catch((err) => {
        const errors = err?.response?.data || { errormessage: 'Something Went Wrong !!!!' };
        setErrors(errors);
        setLoading(false);
        setOpenSuccessNotify(true);
      });
  }

  const redirectToForgetPassword = () => {
    history.push('/forget-password');
  }

  return (
    <>
      <Container
        component="main"
        className="login-container flex-col container"
      >
        <CssBaseline />
        <div className="flex flex-col items-center">
          <Avatar className="avatar">
            <img src={logo} alt="login-logo"></img>
          </Avatar>
          <form className="login-form" noValidate onSubmit={handleSubmit}>
            <TextField
              error={validationErrors.email}
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              className="login-field"
            />
            <TextField
              error={validationErrors.password}
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
              className="login-field"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
              className="mui-checkbox"
            />
            <Button
              type="submit"
              fullWidth
              className="submit-btn"
              endIcon={
                loading ? (
                  <CircularProgress style={{ color: '#FFFB00' }} size="20px" />
                ) : (
                  <ArrowForwardOutlinedIcon style={{ color: '#FFFB00' }} />
                )
              }
            >
              Sign In
            </Button>
            <Grid container className="mt-2 form-links">
              <Grid item>
                <Link
                  onClick={redirectToForgetPassword}
                  className="forgot-password"
                >
                  Forget Password?
                </Link>
              </Grid>
              <Grid item className="signup-link">
                <Link href="#">{"Don't have an account? Sign Up"}</Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Notify
          open={openSuccessNotify}
          onClose={() => setOpenSuccessNotify(false)}
          errors={errors}
        />
      </Container>
      <Footer />
    </>
  );
}