const validateForm = (data) => {
  let errors = { hasError: false };
  if (!data.token) {
    errors.hasError = true;
    errors.token = true;
  }

  return errors;
};

export default validateForm;