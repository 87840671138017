// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intial-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; }
  .intial-step-wrapper .box-wrapper {
    padding: 60px;
    border: 2px solid red; }
    .intial-step-wrapper .box-wrapper .error-message {
      color: red; }
`, "",{"version":3,"sources":["webpack://./src/pages/custom-redeem/intialStep/customRedeem.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAA;EAJf;IAOI,aAAa;IACb,qBAAqB,EAAA;IARzB;MAUM,UAAU,EAAA","sourcesContent":[".intial-step-wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n\n  .box-wrapper {\n    padding: 60px;\n    border: 2px solid red;\n    .error-message {\n      color: red;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
